import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import configure from "configure"
import Loader from "pages/Separate/Loader"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"

const ZithasCredential = ({ id, verticalActiveTab }) => {
  const [isLoading, setLoading] = useState(false)
  const [fixButtons, setFixButtons] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [ztplCredentialEmail, setZtplCredentialEmail] = useState("")
  const [ztplCredentialPass, setZtplCredentialPass] = useState("")
  const [bclf, setBclf] = useState(0)
  const [zohoEmail, setZohoEmail] = useState("")
  const [zohoPass, setZohoPass] = useState("")
  const [microsoftEmail, setMicrosoftEmail] = useState("")
  const [microsoftPass, setMicrosoftPass] = useState("")
  const [skypeEmail, setSkypeEmail] = useState("")
  const [skypePass, setSkypePass] = useState("")
  const [gitCredentials, setGitCredentials] = useState("")
  const [gitCredentialsPassword, setGitCredentialsPassword] = useState("")
  const [gitSecId, setGitSecId] = useState("")
  const [showZohoPassword, setShowZohoPassword] = useState(false)
  const [showMicrosoftPass, setShowMicrosoftPass] = useState(false)
  const [showSkypePass, setShowSkypePass] = useState(false)
  const [showGitPass, setShowGitPass] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", id)
    formData.append("zoho_email", zohoEmail)
    formData.append("zoho_password", zohoPass)
    formData.append("email", ztplCredentialEmail)
    formData.append("password", ztplCredentialPass)
    formData.append("permission", bclf)
    formData.append("teams_email", microsoftEmail)
    formData.append("teams_password", microsoftPass)
    formData.append("skype_email", skypeEmail)
    formData.append("skype_password", skypePass)
    formData.append("git_username", gitCredentials)
    formData.append("git_password", gitCredentialsPassword)
    formData.append("git_secretid", gitSecId)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staffcre`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff zithas credential details edited successfully",
        }).then(data => {
          // setverticalActiveTab("8")
        })
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getCtaDetails = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const item = response.data.data.filter(temp => temp.id == id)[0]
        setZtplCredentialEmail(item.email)
        setZtplCredentialPass(item.crm_password)
        setBclf(item.permission)
        setZohoEmail(item.zoho_email)
        setZohoPass(item.zoho_password)
        setMicrosoftEmail(item.teams_email)
        setMicrosoftPass(item.teams_password)
        setSkypeEmail(item.skype_email)
        setSkypePass(item.skype_password)
        setGitCredentials(item.git_username)
        setGitCredentialsPassword(item.git_password)
        setGitSecId(item.git_secret_id)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    if(verticalActiveTab== "tab6"){
      getCtaDetails();
    }
  }, [verticalActiveTab])
  const handleCheckboxChange = e => {
    e.target.checked = !e.target.checked
    const newValue = e.target.checked ? 1 : 0
    setBclf(newValue)
  }

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Form className="" onSubmit={handleSubmit}>
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon "></i>
              <span>Cancel</span>
            </button>
          </div>
          <div className="mb-3 bg-white p-4 shadow ">
            <Row className="mb-4">
              <div className="col-md-6">
                <h4 className="text-dark">ZTPL Credential</h4>
              </div>
              <div className="col-md-6  text-md-end">
                <button className="btn btn-save me-md-0 me-2 btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>SAVE
                </button>
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className=" btn btn-red ms-md-2 btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>

            <Row>
              <div className="col-md-4 mb-3 mb-md-0 ">
                <label htmlFor="">
                  Email Id <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control "
                  id=""
                  value={ztplCredentialEmail}
                  onChange={e => setZtplCredentialEmail(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0 password">
                <label htmlFor="">
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  placeholder="Enter Password"
                  className="form-control "
                  id=""
                  value={ztplCredentialPass}
                  onChange={e => setZtplCredentialPass(e.target.value)}
                  required
                />
                <i
                  className={`fas fa-eye${
                    showPassword ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              </div>
              <div className="col-md-4 mb-md-0 d-flex flex-column align-items-center justify-content-center  ">
                <h5 className="">Bypass - Clear Login Functionality </h5>
                <div className="form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd"
                    checked={bclf == "1"}
                    onClick={e => handleCheckboxChange(e)}
                  />
                </div>
              </div>
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Zoho Mail</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control "
                  id=""
                  value={zohoEmail}
                  onChange={e => setZohoEmail(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                  type={`${showZohoPassword ? "text" : "password"}`}
                  placeholder="Enter Password"
                  className="form-control "
                  id=""
                  value={zohoPass}
                  onChange={e => setZohoPass(e.target.value)}
                />
                 <i
                  className={`fas fa-eye${
                    showZohoPassword ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowZohoPassword(!showZohoPassword)}
                ></i>
              </div>
             
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Microsoft 365</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control "
                  id=""
                  value={microsoftEmail}
                  onChange={e => setMicrosoftEmail(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                  type={`${showMicrosoftPass ? "text" : "password"}`}

                  placeholder="Enter Password"
                  className="form-control "
                  id=""
                  value={microsoftPass}
                  onChange={e => setMicrosoftPass(e.target.value)}
                />
                  <i
                  className={`fas fa-eye${
                    showMicrosoftPass ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowMicrosoftPass(!showMicrosoftPass)}
                ></i>
              </div>
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Skype</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control "
                  id=""
                  value={skypeEmail}
                  onChange={e => setSkypeEmail(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                  type={`${showSkypePass ? "text" : "password"}`}
                  placeholder="Enter Password"
                  className="form-control "
                  id=""
                  value={skypePass}
                  onChange={e => setSkypePass(e.target.value)}
                />
                 <i
                  className={`fas fa-eye${
                    showSkypePass ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowSkypePass(!showSkypePass)}
                ></i>
              </div>
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Git Credentials</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Username</label>
                <input
                  type="text"
                  placeholder="Enter Username"
                  className="form-control "
                  id=""
                  value={gitCredentials}
                  onChange={e => setGitCredentials(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                  type={`${showGitPass ? "text" : "password"}`}
                  placeholder="Enter Password"
                  className="form-control "
                  id=""
                  value={gitCredentialsPassword}
                  onChange={e => setGitCredentialsPassword(e.target.value)}
                />
                <i
                  className={`fas fa-eye${
                    showGitPass ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowGitPass(!showGitPass)}
                ></i>
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Secret Id</label>
                <input
                  type="number"
                  placeholder="Enter Secret Id"
                  className="form-control "
                  id=""
                  value={gitSecId}
                  onChange={e => setGitSecId(e.target.value)}
                />
              </div>
            </Row>
          </div>
        </Form>
      </div>
    </>
  )
}

export default ZithasCredential
