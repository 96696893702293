import React, {  useState } from "react"
import { Row } from "reactstrap"

const ZithasCtc = ({ zithasCTC}) => {
  return (
    <>
      <div>
        <div>
          <div className="mb-3 p-4 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark above">Zithas CTC</h4>
              </div>
            </Row>
            <h5 className="mb-4 text-dark">Earnings</h5>
            <Row>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  B.P{" "}
                  <i
                    title="Basic Pay"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Basic Pay"
                  className="form-control "
                  id=""
                  value={zithasCTC?.basic_pay}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  D.A{" "}
                  <i
                    title="Dearness Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Dearness Allowance"
                  className="form-control "
                  id=""
                  value={zithasCTC?.dearness_allowance}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  M.A{" "}
                  <i
                    title="Medical Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Medical Allowance"
                  className="form-control "
                  id=""
                  value={zithasCTC?.medical_allowance}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  H.R.A{" "}
                  <i
                    title="House Rent Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter House Rent Allowance"
                  className="form-control "
                  id=""
                  value={zithasCTC?.house_rent_allowance}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  C.A
                  <i
                    title="Conveyance Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Conveyance Allowance"
                  className="form-control "
                  id=""
                  value={zithasCTC?.conveyance_allowance}
                  disabled={true}
                />
              </div>
            </Row>
          </div>

          <div className="mb-3 p-4 bg-white shadow">
            <h5 className="mb-4 text-dark">Deductions</h5>
            <Row>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  E.S.I{" "}
                  <i
                    title="Employee State Insurance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Employee State Insurance"
                  className="form-control "
                  id=""
                  value={zithasCTC?.employee_state_insurance}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  P.F{" "}
                  <i
                    title="Provident Fund"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Provident Fund"
                  className="form-control "
                  id=""
                  value={zithasCTC?.provident_fund}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  P.T{" "}
                  <i
                    title="Professional Tax"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Professional Tax"
                  className="form-control "
                  id=""
                  value={zithasCTC?.professional_tax}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  C/O.D{" "}
                  <i
                    title="Canteen/Other Deduction"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Canteen/Other Deduction"
                  className="form-control "
                  id=""
                  value={zithasCTC?.other_deduction}
                />
              </div>
            </Row>
          </div>

          <div className="mb-3 p-4 bg-white shadow">
            <h5 className="mb-4 text-dark">Per Hour Rate</h5>
            <Row>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  P.H.R{" "}
                  <i
                    title="Per Hour Rate"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Per Hour Rate"
                  className="form-control "
                  id=""
                  value={zithasCTC?.per_rate_hour}
                  disabled={true}
                />
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default ZithasCtc
