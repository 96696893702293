import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/css/Staff/Staff.css"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"
import classnames from "classnames"

// components
import Education from "./Education"
import WorkExperience from "./WorkExperience"
import ZithasCtc from "./ZithasCtc"
import Documents from "./Documents"
import ZithasCredential from "./ZithasCredential"
import Permission from "./Permission"
import PersonalDetails from "./PersonalDetails"
import RolesAndpermission from "./RolesAndpermission"
import GenerateDocument from "./GenerateDocument"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

const AddStaff = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [toggleColor, setToggleColor] = useState(false)

  const emails = useLocation();
  const { allEmail } = emails.state || {};

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Add Staff | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState)
    setToggleColor(prevState => !prevState)
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [verticalActiveTab])

  const location = useLocation()

  useEffect(() => {
    const staffId = localStorage.getItem("staffId")
    const roleId = localStorage.getItem("roleId")
    const cleanup = () => {
      if (staffId) {
        localStorage.removeItem("staffId")
      }
      if (roleId) {
        localStorage.removeItem("roleId")
      }
    }
    cleanup()
  }, [location])

  const isShowPerTab = ({ id }) => {
    const isAddStaff = localStorage.getItem("roleId")
    if (isAddStaff) {
      toggleVertical("7")
    } else {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Information",
        text: "Please add staff personal details before proceeding with permissions.",
      }).then(()=>{
        toggleVertical("1")
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Staff"} />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Personal Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Zithas CTC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleVertical("8")
                    }}
                  >
                    Roles & Responsibility
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Work Experience
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Education
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "9",
                    })}
                    onClick={() => {
                      toggleVertical("9")
                    }}
                  >
                    Generate Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Zithas Credential
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      isShowPerTab({ id: "7" })
                    }}
                  >
                    Permission
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className={classnames({
                      "mb-2": true,
                      active:
                        verticalActiveTab === "7" || verticalActiveTab === "8",
                    })}
                  >
                    <DropdownToggle
                      style={{ cursor: "pointer" }}
                      className={`fs-6 nav-link ${
                        toggleColor ? "text-white" : "text-dark"
                      } ${
                        verticalActiveTab === "7" || verticalActiveTab === "8"
                          ? "active text-white"
                          : ""
                      }`}
                      nav
                      caret
                    >
                      Permission
                    </DropdownToggle>
                    <DropdownMenu className="w-100">
                      <DropdownItem onClick={() => toggleVertical("7")}>
                        India
                      </DropdownItem>
                      <DropdownItem onClick={() => toggleVertical("8")}>
                        United Kingdom
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                 
                </NavItem> */}
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className="card bg-transparent mb-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <PersonalDetails
                      verticalActiveTab={verticalActiveTab}
                      setverticalActiveTab={setverticalActiveTab}
                      allEmail={allEmail}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <ZithasCtc setverticalActiveTab={setverticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="8">
                    <RolesAndpermission
                      setverticalActiveTab={setverticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <WorkExperience
                      setverticalActiveTab={setverticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <Education setverticalActiveTab={setverticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="5">
                    <Documents setverticalActiveTab={setverticalActiveTab} verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="9">
                    <GenerateDocument
                    verticalActiveTab={verticalActiveTab}
                      setverticalActiveTab={setverticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <ZithasCredential
                      setverticalActiveTab={setverticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="7">
                    <Permission
                      setverticalActiveTab={setverticalActiveTab}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddStaff
