import React, { useEffect, useMemo, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Input, Label, Modal
} from "reactstrap";
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import { IssuesColumns } from 'utils/columns/Human Resource/humanResource';
import AddIssues from "../../../assets/images/Human Resource/addIssues.jpg"
import EditIssues from "../../../assets/images/Human Resource/editIssues.jpg"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import Loader from 'pages/Separate/Loader';
import secureLocalStorage from 'react-secure-storage';
import { FetchPostData } from 'utils/common-fetchData';
import moment from 'moment';
import NewPagination from 'components/Common/Newpagination';
import { customGlobalFilter } from 'utils/common-helpers';

function TableContainer({ columns, data, setAddIssues, setIssueInfo }) {

    const [fixedContent, setFixedContent] = useState(false);

    const navigate = useNavigate()

    document.addEventListener('scroll', () => {
        if (window.scrollY > 256) {
            setFixedContent(true);
        }
        else {
            setFixedContent(false);
        }
    })

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            globalFilter: customGlobalFilter,
            initialState: {
                sortBy: [
                    {
                        id: "id",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { pageIndex, pageSize, globalFilter } = state;


    // Functionality to export table data to CSV //
    const excelColumns = []
    columns.forEach(column => {
        excelColumns.push(column.Header)
    });


    useEffect(() => {
        setPageSize(50)
    }, [])

    // Render the UI for your table
    return (
        <div >
            {/* fixed buttons */}
            <div className={`flex-column fixed-buttons ${fixedContent ? 'd-flex' : 'd-none'}`}>


                <button className=" btn btn-save btn-label"
                    onClick={() => setAddIssues(true)}
                >
                    <i className="fas fa-plus me-2 label-icon"></i>
                    Issues
                </button>
                <button onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                    <i className='dripicons-cross me-2 icon-center'></i>
                    <span>Cancel</span>
                </button>
            </div>
            <Row className='mb-2'>

                <Col md={12} className='mb-4'>
                    <div className='d-flex '>

                        <div className="col-12 d-flex justify-space-between align-items-center mt-0">
                            <div className='col-4'>
                                <h4>Issues</h4>
                            </div>
                            <div className='text-danger col-md-6 text-end'>
                                *This feature allows employees to report any concerns or issues related to their salary or other workplace matters, ensuring they are addressed promptly.
                            </div>

                        </div>
                    </div>

                </Col>
                <Col>
                    <select
                        className="border-radius form-select"
                        style={{ width: '100px' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(e.target.value)}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col md={8} className='d-flex justify-content-end align-items-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="border-radius form-control rounded h-100"
                                        placeholder={` Search...`}
                                        value={globalFilter || ''}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end align-items-center ms-3 mb-2">
                            <button className=" btn btn-save btn-label"
                                onClick={() => setAddIssues(true)}
                            >
                                <i className="fas fa-plus me-2 label-icon"></i>
                                Issues
                            </button>
                        </div>
                    </div>
                    <div >


                    </div>
                </Col>
            </Row>

            <div className='position-relative'>
                <Table className='table table-bordered mt-3' {...getTableProps()}>
                    <thead className={`${fixedContent ? 'fixed-setting-header' : ''} fix-gst-header`}>
                        {
                            headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th
                                            key={index}
                                            className={`t-col-${index + 1} ${column.className}`}
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            <div className="d-flex align-items-center">
                                                <span className="flex-grow-1 text-center header-text">
                                                    {column.render("Header")}
                                                </span>
                                                {column.isShortIcon && (
                                                    <span
                                                        onClick={() =>
                                                            column.getSortByToggleProps().onClick()
                                                        }
                                                        className="d-flex align-items-center ms-2"
                                                    >
                                                        <i
                                                            title="Ascending"
                                                            className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                                                    ? "text-dark"
                                                                    : ""
                                                                }`}
                                                        ></i>
                                                        <i
                                                            title="Descending"
                                                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                                    ? "text-dark"
                                                                    : ""
                                                                }`}
                                                        ></i>
                                                    </span>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody className="fix-gst-header" {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='row-hover' {...row.getRowProps()} key={i}>
                                        {
                                            row.cells.map((cell, index) => {
                                                return <td key={index} className={`t-col-${index + 1} ${cell.column.className}`} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>

            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <NewPagination
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    page={page}
                    data={data}
                    nextPage={nextPage}
                />
            </Row>



        </div>
    );
}

const IssuesIndex = ({ verticalActiveTab }) => {

    const [addIssues, setAddIssues] = useState(false);
    const [editIssues, setEditIssues] = useState(false);
    const [issuesApprovePopup, setIssueApprovePopup] = useState(false);
    const [issueInfo, setIssueInfo] = useState(false)
    const [type, setType] = useState('0')
    const [amount, setAmount] = useState('')
    const [reason, setReason] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [issue_id, setIssueID] = useState(false)
    const [app_amount, setAppAmount] = useState('')
    const [app_reason, setAppReason] = useState('')
    const [status, setStatus] = useState('')

    const currentMonthText = moment().format('MMMM');
    document.addEventListener("keydown", e => {
        if (e.key === "Escape") setAddIssues(false), setEditIssues(false)
    })

    const onEditOpen = (obj) => {
        setEditIssues(true)
        setIssueID(obj?.id);
        setReason(obj?.reason)
        setAmount(obj?.amount)
        setType(obj?.type)
    }

    const onDeletIssue = (issue_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "you want to delete this issue!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const auth = secureLocalStorage.getItem('auth_token')
                const entries = {
                    user_id: localStorage.getItem('auth_id'),
                    id: issue_id,
                    issue_type: 2
                }
                const response = await FetchPostData(toggleLoading, "deleteSwipeData", auth, entries);
                if (response?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        text: "Issue Deleted Successfully",
                    }).then(() => {
                        setData(response?.data?.data)
                    })
                }
            }
        })
    }

    const onOpenUnapprove = (status, amount, reason) => {
        setAppAmount(amount)
        setAppReason(reason)
        setStatus(status)
        setIssueApprovePopup(true)
    }

    const columns = useMemo(() => IssuesColumns(onEditOpen, onDeletIssue, onOpenUnapprove), [])
    const toggleLoading = (loading) => {
        setLoading(loading)
    }

    const getData = async () => {
        const auth = secureLocalStorage.getItem('auth_token')
        const entries = {
            user_id: localStorage.getItem('auth_id'),
            issue_type: 2
        }
        const response = await FetchPostData(toggleLoading, "swipeData", auth, entries);
        if (response?.data?.success) {
            setData(response.data.data)
        }
    }
    useEffect(() => {
        if (verticalActiveTab == "2") {
            setIssueInfo(true)
            if (data?.length == 0) {
                getData()
            }
        }
    }, [verticalActiveTab])

    const onAddIssues = async (e) => {
        e.preventDefault();
        const auth = secureLocalStorage.getItem('auth_token')
        const entries = {
            user_id: localStorage.getItem('auth_id'),
            issue_type: 2,
            type: type,
            amount: amount,
            reason: reason,
            id: editIssues ? issue_id : ''
        }
        const apiName = addIssues ? 'addSwipeData' : '' || editIssues ? "editSwipeData" : ''
        const response = await FetchPostData(toggleLoading, apiName, auth, entries);
        if (response?.data?.success) {
            Swal.fire({
                icon: "success",
                text: `Issues ${editIssues ? "Edited" : "Added"} Successfully`,
            }).then(() => {
                setData(response?.data?.data)
                setAmount('')
                setReason('')
                setEditIssues(false)
                setAddIssues(false)
            })
        }
    }


    return (
        <>
            {loading && (<Loader />)}
            <div>
                <Row className='ms-0 pe-0'>
                    <Col lg={12} className='p-0'>
                        <Card>
                            <CardBody>
                                <TableContainer
                                    columns={columns}
                                    data={data}
                                    setAddIssues={setAddIssues}
                                    setIssueInfo={setIssueInfo}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* Add Issues Popup */}
                <Modal isOpen={addIssues} size="xl" className="" centered>
                    <div className="modal-header pe-4" >
                        <h5 className="modal-title mt-0">Add Issues</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setAddIssues(false)
                            }}
                            className="btn-modal-close"
                        >
                            <span aria-hidden="true" className="btn-modal-span">
                                &times;
                            </span>
                        </button>
                    </div>

                    <div className="modal-body pe-4" >
                        <Form className="row d-flex justify-content-center align-items-center" onSubmit={onAddIssues}>
                            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                <div className="text-center">
                                    <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-column ">
                                <div className="col-md-12 d-flex flex-column mb-4 justify-content-start align-items-end ps-2">
                                    <div className="col-12 mb-md-0">
                                        <span className="me-4">
                                            <input
                                                type="radio"
                                                id="Days"
                                                onChange={e => {
                                                    setType("0");
                                                }}
                                                name="type"
                                                value="0"
                                                defaultChecked={true}
                                            />
                                            <label htmlFor="Days" className="ms-2">
                                                Days
                                            </label>
                                        </span>
                                        <span className="me-4">
                                            <input
                                                type="radio"
                                                id="amount"
                                                onChange={e => {
                                                    setType("1");
                                                }}
                                                name="type"
                                                value="1"
                                            />
                                            <label htmlFor="amount" className="ms-2">
                                                Amount
                                            </label>
                                        </span>
                                    </div>
                                    <div className="col-12 mt-2 mb-md-0">
                                        <div className="col-3">
                                            <Label>{type == "0" ? "Days" : "Amount"} <span className='text-danger'> *</span></Label>
                                            <input
                                                type="text"
                                                placeholder={`Enter ${type == "0" ? "Days" : "Amount"}`}
                                                className="form-control"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="col-md-12 ps-2">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Reason  <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            placeholder="Add Reason Here.."
                                            className="form-control"
                                            rows={5}
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="my-4 text-center">
                                    <button className=" btn btn-save btn-label">
                                        <i className="fas fa-save me-2 label-icon"></i>
                                        Save
                                    </button>
                                    <a
                                        onClick={() => setEditIssues(false)}
                                        className=" btn ms-md-2 btn-red btn-label"
                                    >
                                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                        CANCEL
                                    </a>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal >

                {/* Edit Issues Popup */}
                <Modal Modal isOpen={editIssues} size="xl" className="" centered >
                    <div className="modal-header pe-4" >
                        <h5 className="modal-title mt-0">Edit Issues</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setEditIssues(false)
                                setType('0');
                                setAmount('');
                                setReason('');
                            }}
                            className="btn-modal-close"
                        >
                            <span aria-hidden="true" className="btn-modal-span">
                                &times;
                            </span>
                        </button>
                    </div>

                    <div className="modal-body pe-4" >
                        <Form className="row d-flex justify-content-center align-items-center" onSubmit={onAddIssues}>
                            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                <div className="text-center">
                                    <img src={EditIssues} alt="Addepe" style={{ width: "90%" }} />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-column ">
                                <div className="col-md-12 d-flex flex-column mb-4 justify-content-start align-items-end ps-2">
                                    <div className="col-12 mb-md-0">
                                        <span className="me-4">
                                            <input
                                                type="radio"
                                                id="Days"
                                                onChange={e => {
                                                    setType("0");
                                                }}
                                                name="type"
                                                value={type}
                                                checked={type == 0}
                                            />
                                            <label htmlFor="Days" className="ms-2">
                                                Days
                                            </label>
                                        </span>
                                        <span className="me-4">
                                            <input
                                                type="radio"
                                                id="amount"
                                                onChange={e => {
                                                    setType("1");
                                                }}
                                                name="type"
                                                value={type}
                                                checked={type == 1}
                                            />
                                            <label htmlFor="amount" className="ms-2">
                                                Amount
                                            </label>
                                        </span>
                                    </div>
                                    <div className="col-12 mt-2 mb-md-0">
                                        <div className="col-3">
                                            <Label>{type == "0" ? "Days" : "Amount"} <span className='text-danger'> *</span></Label>
                                            <input
                                                type="text"
                                                placeholder={`Enter ${type == "0" ? "Days" : "Amount"}`}
                                                className="form-control"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">

                                    <div className="col-md-12 ps-2">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Reason  <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            placeholder="Add Reason Here.."
                                            className="form-control"
                                            style={{ height: "150px" }}
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>

                                <div className="my-4 text-center">
                                    <button className=" btn btn-save btn-label">
                                        <i className="fas fa-save me-2 label-icon"></i>
                                        Save
                                    </button>
                                    <a
                                        onClick={() => {
                                            setEditIssues(false)
                                            setType('0');
                                            setAmount('');
                                            setReason('');
                                        }}
                                        className=" btn ms-md-2 btn-red btn-label"
                                    >
                                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                        CANCEL
                                    </a>
                                </div>
                            </div>


                            {/* </div> */}
                        </Form>
                    </div>
                </Modal >

                {/* issue Approve Popup  */}
                < Modal isOpen={issuesApprovePopup} size="xl" className="" centered >
                    <div className="modal-header pe-4" >
                        <h5 className="modal-title mt-0">Issue {status == 1 ? "Approved" : "UnApproved"}</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setIssueApprovePopup(false)
                            }}
                            className="btn-modal-close"
                        >
                            <span aria-hidden="true" className="btn-modal-span">
                                &times;
                            </span>
                        </button>
                    </div>
                    <div className="modal-body pe-4" >
                        <Form className="row d-flex justify-content-center align-items-center">
                            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                <div className="text-center">
                                    <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
                                </div>
                            </div>

                            <div className="col-md-6 d-flex flex-column ">
                                <div className="col-md-12 mb-4">
                                    {status == 1 ? (
                                        <div className="col-4 mb-3">
                                            <Label>Final Amount / Days <span className="text-danger"> *</span></Label>
                                            <input type="text"
                                                disabled
                                                className="form-control"
                                                placeholder="Enter Here.."
                                                value={app_amount}
                                            >
                                            </input>
                                        </div>
                                    ) : null}
                                    <div className="col-md-12 ps-2">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Reason  <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            disabled
                                            placeholder="Add Reason Here.."
                                            value={app_reason}
                                            className="form-control"
                                            rows={5}
                                        />
                                    </div>

                                </div>


                            </div>


                            {/* </div> */}
                        </Form>
                    </div>
                </Modal>

                {/* Swipe Info Modeal  */}
                <Modal Modal
                    isOpen={issueInfo}
                    toggle={() => {
                        tog_center()
                    }}
                    className="bd-info-popup"
                    centered
                >
                    <div className="modal-header bd-head-info">
                        <h5 className="modal-title mt-0">
                            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
                            Issues Info
                        </h5>
                    </div>
                    <div className="modal-body pb-0">
                        <div className="d-flex">
                            <div className="col-5 pr-30">
                                <div>
                                    <img src={InfoGif} className="w-100" />
                                </div>
                            </div>
                            <div className="mt-20 ">
                                <div>
                                    <p>
                                        <i className="fas fa-angle-double-right pr-10"></i> The Issue feature provides employees with a convenient way to report any concerns or issues they may have regarding their salary, benefits, Whether it's a discrepancy in pay, this feature ensures that concerns are captured and addressed efficiently.
                                    </p>
                                    <p>
                                        <i className="fas fa-angle-double-right pr-10"></i> By using the Issue feature, employees can easily log their issues, which will be reviewed and resolved by the appropriate team or department. This helps maintain clear communication and ensures that any problems are promptly handled to support a smooth and productive work environment.
                                    </p>
                                    <div className="text-center mt-40">
                                        <button
                                            type="button"
                                            className="btn btn-success "
                                            onClick={() => setIssueInfo(false)}
                                        >
                                            I Understood
                                            <i className="dripicons-thumbs-up pl-10"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal >
            </div >
        </>
    )
}

export default IssuesIndex