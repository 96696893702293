import React, { useEffect, useState } from "react"
import { Row } from "reactstrap"


const WorkExperience = ({ id, staffData, verticalActiveTab }) => {
  // for education form
  const [inputFields_1, setInputFields1] = useState([{}])

  useEffect(() => {
    if (verticalActiveTab == "3") {
      let WorkExperienceDetails;
      if (staffData?.work_experience != undefined) {
        WorkExperienceDetails = JSON.parse(staffData?.work_experience)
      }
      if (WorkExperienceDetails !== null) {
        setInputFields1(WorkExperienceDetails)
      }
    }
  }, [verticalActiveTab])

  return (
    <>
      <div>
        <div className="repeater">
          <div className="p-4 pb-0 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark">Work Experience</h4>
              </div>
            </Row>
          </div>
          {(inputFields_1 || []).map((formRow, key) => (
            <div key={key} className="mb-3 p-4 py-5 bg-white shadow">
              <Row className="align-items-center">
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Company Name</label>
                  <input
                    type="text"
                    placeholder="Enter Company Name"
                    className="form-control "
                    id=""
                    name="company_name"
                    value={formRow.company_name || ""}
                    disabled={true}
                  />
                </div>
                <div className="col-md-2 mb-3" style={{ width: "18%" }}>
                  <label htmlFor="">From Date</label>
                  <input
                    className="form-control"
                    type="date"
                    id="example-date-input"
                    name="from_date"
                    value={formRow.from_date || ""}
                    disabled={true}
                  />
                </div>
                <div className="col-md-2 mb-3" style={{ width: "18%" }}>
                  <label htmlFor="">To Date</label>
                  <input
                    className="form-control"
                    type="date"
                    id="example-date-input"
                    name="to_date"
                    value={formRow.to_date || ""}
                    disabled={true}
                  />
                </div>

                <div className="col-md-2 mb-3" style={{ width: "18%" }}>
                  <label htmlFor="">Salary</label>
                  <input
                    type="text"
                    placeholder="Enter Salary"
                    className="form-control "
                    id=""
                    name="salary"
                    value={formRow.salary || ""}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Position</label>
                  <input
                    type="text"
                    placeholder="Enter Position"
                    className="form-control "
                    id=""
                    name="position"
                    value={formRow.position || ""}
                    disabled={true}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="">Reason For Leave</label>
                  <input
                    type="text"
                    placeholder="Enter Reason For Leave"
                    className="form-control "
                    id=""
                    name="reason_for_leave"
                    value={formRow.reason_for_leave || ""}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Job Description</label>
                  <input
                    type="text"
                    placeholder="Enter Job Description"
                    className="form-control "
                    id=""
                    name="job_description"
                    value={formRow.job_description || ""}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Contact Person</label>
                  <input
                    type="text"
                    placeholder="Enter Contact Person"
                    className="form-control "
                    id=""
                    name="contact_person"
                    value={formRow.contact_person || ""}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Email Id</label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    className="form-control "
                    id=""
                    name="we_email"
                    value={formRow.we_email || ""}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Phone Number</label>
                  <input
                    type="number"
                    placeholder="Enter Phone Number"
                    className="form-control "
                    id=""
                    name="we_phone_number"
                    value={formRow.we_phone_number || ""}
                    disabled={true}
                  />
                </div>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default WorkExperience
