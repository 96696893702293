import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import axios from "axios"
import configure from "configure"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"

const RoleDetails = ({ dashboardList, roleData, toggleVertical }) => {
  const [fixedContent, setFixedContent] = useState(false)
  const [role_name, setRoleName] = useState("")
  const [dashboardElem, setDashboardElem] = useState("")
  const [description, setDescription] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setRoleName(roleData?.role_name)
    if (roleData?.dashboard_elements != null) {
      const test = dashboardList?.filter(te => {
        return roleData?.dashboard_elements
          ?.split(",")
          ?.includes(te.value.toString())
      })
      setDashboardElem(test)
    }
    if (roleData?.kra != null) {
      setDescription(roleData?.kra)
    }
  }, [roleData])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 250) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const handleSubmit = async e => {
    e.preventDefault()

    if (dashboardElem.length !== 0) {
      if (description !== "") {
        setLoading(true)
        const formData = new FormData()
        formData.append("role_id", params.id)
        formData.append("role_name", role_name)
        formData.append(
          "elements",
          dashboardElem?.map(em => em.value).toString()
        )
        formData.append("kra", description)
        try {
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}/editRoles`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            Swal.fire({
              icon: "success",
              text: "Role edited successfully.",
            }).then(data => {
              toggleVertical("2")
            })
            setLoading(false)
          })
        } catch (err) {
          setLoading(false)
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please add key responsibility areas description",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please add at least one dashboard element.",
      })
    }
  }
  return (
    <>
      {loading && <Loader />}
      <form className="pb-5" onSubmit={handleSubmit}>
        <div className="row">
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixedContent ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save  fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="col-md-9">
            <div>
              <h4 className="text-dark above">Personal Details</h4>
            </div>
          </div>
          <div className="col-md-3 mb-3 text-md-end">
            <button className=" btn me-md-0 me-2 btn-save btn-label">
              <i className="fas fa-save me-2 label-icon"></i>SAVE
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className=" btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-3 mt-2 mb-3">
            <div className="form-group">
              <label>Role Name</label>
              <input
                type="text"
                placeholder="Add role"
                className="form-control form-control-solid form-control-md"
                name="role_name"
                required
                value={role_name}
                onChange={e => setRoleName(e.target.value)}
              />
            </div>
          </div>

          <div className="col-12 mt-2 mb-3">
            <div className="">
              <label>Dashboard Elements</label>
              <Select
                placeholder="Select Dashboard Element"
                className="basic-multi-select"
                classNamePrefix=" select"
                isMulti
                value={dashboardElem}
                options={dashboardList}
                onChange={selectedOption => {
                  setDashboardElem(selectedOption)
                }}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="">
              <div className="d-flex justify-space-between">
                <label htmlFor="">
                  Key Responsibility Areas{" "}
                  <span className="text-danger">*</span>
                </label>
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(description.length == 0) == error
                    ? "Please enter Roles & Responsibility"
                    : null}
                </span>
              </div>

              <CKEditor
                editor={ClassicEditor}
                data={description}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setDescription(data)
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default RoleDetails
