import React, { useEffect, useState } from "react"
import { Container, Form, Modal } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
import roleImg from "../../assets/images/role-3.avif"

// common component
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import NewPagination from "components/Common/Newpagination"
import confirmDeleteImg from "../../assets/images/confirmDelete.jpg"
import Select from "react-select"
import { checkPermissionsByModule } from "utils/common-helpers"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import { RolesColumns } from "utils/columns/Roles/RolesColumns"
import { useSelector } from "react-redux"
import secureLocalStorage from "react-secure-storage"

function TableContainer({ columns, data, setAccessDenied, setLoading }) {
  const [fixedHeader, setFixedHeader] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.25;
    if (window.scrollY > scrollThreshold) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  const [name_modal, setNameModal] = useState(false)
  const [action_modal, setActionModal] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_center() {
    setNameModal(false)
    removeBodyCss()
  }
  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 69)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/earth_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}addroles`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div id="Roles">
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
          }`}
      >
        <Link
          to={`${configure.appUrl}addroles`}
          className="btn btn-save  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Roles</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <button
              // to={`${configure.appUrl}addroles`}
              onClick={onAddPermission}
              className=" btn btn-save btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Role
            </button>
            {/* <Link
              to={`${configure.appUrl}addroles`}
              className=" btn btn-save btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Role
            </Link> */}
            <button
              onClick={() => navigate(-1)}
              className=" btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div className={`${fixedHeader ? "rolesIndexImg" : ""}`}>
            <img src={roleImg} alt="roleImg" className="w-100" />
          </div>
        </div>
        <div className="col-md-8 pt-4">
          <Table
            id="roles-table"
            className="table table-bordered shadow-sm"
            {...getTableProps()}
          >
            <thead className={`${(fixedHeader && page.length >= 10) ? "fix-header-roles-table" : ""} fix-roles-table`}>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      className={`t-head-${index + 1} ${column.className} customShortIcon`}
                      key={index}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                      {column.isInfoIcon ? (
                        <a
                          onClick={() => {
                            // if(column)
                            if (column.Header == "Role Name") {
                              setNameModal(true)
                            } else if (column.Header == "Action") {
                              setActionModal(true)
                            }
                          }}
                        >
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </a>
                      ) : null}

                      {column.isShortIcon && (
                        <span
                          onClick={(e) =>
                            column.getSortByToggleProps().onClick(e)
                          }
                          className="ms-2 cursor-pointer"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                              }`}
                          ></i>
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody  {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr className="row-hover" {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className={cell.column.className}
                          key={index}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      {/* Roles Name Modal */}
      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Roles Name Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i>It show All
                  Roles Name having particular Permission that has been given
                  while adding Role.This Roles is added as per their Location.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Acion Modal */}
      <Modal
        isOpen={action_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Action Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i>This column
                  has an Edit Button for editing roles as needed, as well as a
                  Delete Button for Soft deleting roles that are no longer
                  needed.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setActionModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Roles = () => {
  //meta title
  document.title = "Roles & Permission  | Zithas Crm"
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false)
  const roles = useSelector(state => state?.Roles?.apiData)
  const [rolesOption, setRoleOption] = useState([])
  const [new_role, setNewRole] = useState([])
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const fetchRoles = async () => {
    setLoading(true)
    const authToken = secureLocalStorage.getItem("auth_token")
    const response = await fetchData(toggleLoading, 'roles', authToken);
    if (response?.success) {
      setDatas(response.data?.reverse())
      setRoleOption(response.data?.map((obj) => ({
        value: obj.id,
        label: obj.role_name,
      })))

    }
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  const navigate = useNavigate();
  const [roleId, setRoleId] = useState('')
  // table columns
  const onDeleteRole = (id) => {
    const permission = checkPermissionsByModule(roles, 69, 5);
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this role !",
        icon: "warning",
        showCancelButton: true,
      }).then(async willDelete => {
        if (willDelete.isConfirmed) {
          const authToken = secureLocalStorage.getItem("auth_token")
          const formEntries = {
            role_id: id,
            new_role: new_role?.value
          }
          const response = await FetchPostData(toggleLoading, "deleteRoles", authToken, formEntries);
          if (response?.data?.success) {
            swal({
              icon: "success",
              text: "Roles Deleted Successfully",
            }).then(data => {
              setDatas(response?.data?.data?.reverse());
              setConfirmDeletePopup(false);
            })
          }

        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  const confirmDelete = (id, count) => {
    setRoleId(id)
    if (count > 0) {
      setConfirmDeletePopup(true);
    } else {
      onDeleteRole(id)
    }

  }
  const onEditPermission = (id) => {
    const permission = checkPermissionsByModule(roles, 69, 4);
    if (permission) {
      navigate(
        `${configure.appUrl}editroles/${id}`,
        { state: { roleId: id } }
      )
    }
  }
  const columns = useMemo(
    () => RolesColumns(confirmDelete, onEditPermission),
    [roleId]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Roles & Permission"}
          />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Access Denied */}


      {/* Confirm Delete  */}
      <Modal
        isOpen={confirmDeletePopup}
        toggle={() => {
          tog_center()
          setNewRole([])
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirm Delete</h5>
          <button
            type="button"
            onClick={() => {
              setConfirmDeletePopup(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <div className="col-5">
              <img src={confirmDeleteImg} className="img-fluid" />
            </div>
            <div className="col-7 d-flex flex-column align-items-start justify-content-center gap-4">
              <div className="col-md-12 d-flex flex-column mb-4 justify-content-start align-items-start heading-text-roles">
                <h3 className="text-danger text-start">
                  You are about to delete this role.
                </h3>
                <p className="text-danger text-start text-bold mb-1">
                  Please select a new role to assign to the affected staff. Their current permissions will be automatically transferred to the new role after deletion.
                </p>
              </div>
              <div>
                {/* <h5>Total staff assign count : {data.count.length} </h5> */}
                <h4>Total staff assign count : <span>1</span> </h4>
              </div>
              <div className="col-10">
                <label htmlFor="">
                  Select New Role
                  <span className="text-danger"> *</span>
                </label>
                <Select
                  placeholder="Select Role"
                  classNamePrefix="select"
                  className="basic-multi-select"
                  options={rolesOption?.filter((obj) => obj?.value != roleId)}
                  value={new_role}
                  onChange={(e) => setNewRole(e)}
                />
              </div>
            </div>
          </Row>
          <div className="border-bottom my-3"></div>
          <div className="text-end">
            <button className=" btn btn-purple btn-label" onClick={() => onDeleteRole(roleId)}>
              <i className="fas fa-save me-2 icon-size label-icon"></i>
              Save
            </button>
            <a
              onClick={() => {
                setConfirmDeletePopup(false)
                setNewRole([])
              }}
              className=" btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </a>
          </div>
        </div>
      </Modal>
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default Roles
