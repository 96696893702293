import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"

const Education = ({ id,  verticalActiveTab }) => {
  // for education form
  const [formRows, setFormRows] = useState([{}])
  const [fixButtons, setFixButtons] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [inputFields_1, setInputFields1] = useState([{}])
  const [institute, setInstitute] = useState("")
  const [field, setField] = useState("")
  const [year, setYear] = useState("")
  const [percentage, setPercentage] = useState("")

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // dynamic form add
  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
    setInputFields1([...inputFields_1, {}])
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", id)
    formData.append(
      "board",
      inputFields_1.map(field => field.board)?.toString()
    )
    formData.append(
      "field",
      inputFields_1.map(field => field.field)?.toString()
    )
    formData.append(
      "year",
      inputFields_1
        .map(field => field.year)
        .join(",")
        ?.toString()
    )
    formData.append(
      "class",
      inputFields_1
        .map(field => field.class)
        .join(",")
        ?.toString()
    )

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staffedu`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff education details edited successfully",
        }).then(data => {
          // setverticalActiveTab("5")
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getCtaDetails = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios.get(`${configure.apiUrl}/staff`, {
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      const item = response.data.data.find(temp => temp.id == id)
      if (item) {
        const educationGroup = JSON.parse(item.education_group)
        if (educationGroup) {
          setInputFields1(educationGroup)
        }
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if(verticalActiveTab == "tab4"){
      getCtaDetails();
    }
  }, [verticalActiveTab])

  const handleInputChange = (e, key) => {
    const { name, value } = e.target
    const values = [...inputFields_1]
    values[key][name] = value
    setInputFields1(values)
  }

  return (
    <>
      {/* fields */}
      {isLoading && <Loader />}
      <Form
        className="repeater"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <div
          className={`flex-column fixed-buttons pt-2 ${
            fixButtons ? "d-flex" : "d-none"
          }`}
        >
          <button className="btn btn-save fix-btn btn-label pe-0">
            <i className="fas fa-save me-2 label-icon"></i>
            <span>Save</span>
          </button>
          <a
            onClick={() => navigate(-1)}
            className="btn btn-red fix-btn btn-label pe-0"
          >
            <i className="dripicons-cross me-2 icon-center label-icon"></i>
            <span>Cancel</span>
          </a>
          <button
            onClick={e => {
              e.preventDefault()
              onAddFormRow()
            }}
            className="btn  btn-save fix-btn btn-label pe-0"
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>
            <span>Add</span>
          </button>
        </div>
        <div className="p-4 pb-0 bg-white shadow">
          <Row>
            <div className="col-md-6">
              <h4 className="text-dark">Education</h4>
            </div>
            <div className="col-md-6  text-md-end">
              <button
                type="button"
                onClick={e => {
                  e.preventDefault()
                  onAddFormRow()
                }}
                className="btn  btn-save me-2 btn-label"
              >
                <i className="fas fa-plus me-1 icon-size label-icon"></i>
                Add Education Details
              </button>
              <button className=" btn btn-save me-md-0 me-2 btn-label">
                <i className="fas fa-save me-2 label-icon"></i>SAVE
              </button>
              <button
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Row>
        </div>
        {(inputFields_1 || []).map((formRow, key) => (
          <div key={key} className="mb-3 p-4 bg-white shadow">
            <div className="row align-items-end">
              <div className="col-md-5 mb-3">
                <label htmlFor="">Board/Institute</label>
                <input
                  type="text"
                  placeholder="Enter Board/Institute"
                  className="form-control"
                  name="board"
                  value={formRow.board || ""}
                  onChange={e => handleInputChange(e, key)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Field</label>
                <input
                  type="text"
                  placeholder="Enter Field"
                  className="form-control"
                  name="field"
                  value={formRow.field || ""}
                  onChange={e => handleInputChange(e, key)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="">Year</label>
                    <input
                      type="text"
                      placeholder="year"
                      className="form-control"
                      name="year"
                      value={formRow.year || ""}
                      onChange={e => handleInputChange(e, key)}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="">Pct. %</label>
                    <input
                      type="text"
                      placeholder="%"
                      className="form-control"
                      name="class"
                      value={formRow.class || ""}
                      onChange={e => handleInputChange(e, key)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-1 mb-4">
                <button
                  onClick={e => {
                    e.preventDefault()
                    const updatedRows = inputFields_1.filter(
                      (_, idx) => idx !== key
                    )
                    setInputFields1(updatedRows)
                  }}
                  className="btn btn-red"
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </Form>
    </>
  )
}

export default Education
