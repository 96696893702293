import React, { useEffect, useState } from "react"
import { Row, Table } from "reactstrap"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import axios from "axios"
import secureLocalStorage from "react-secure-storage"

const Documents = ({ id, verticalActiveTab }) => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getDocuments = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", id)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/showStaffDocument`,
        data: formData,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const modifyArray = response?.data?.data?.filter(item => {
          const type = ["enrollingdocuments", "zithasdocuments"]
          const itemtypes = item.requirement_type
            ?.replace(/\s+/g, "")
            .toLowerCase()
          return type.includes(itemtypes)
        })
        setData(modifyArray)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    if(verticalActiveTab == "5"){
      if(data?.length == 0){
        getDocuments()  
      }
    }
  }, [verticalActiveTab])
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <div>
          <div className="mb-3 p-4 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark mb-3">Document Upload</h4>
              </div>

            </Row>

            <div className="p-4 bg-white ">
              <Row className="mt-4">
                <div className="col-12">
                  <Table className="table table-striped table-bordered table-hover shadow">
                    <thead>
                      <tr>
                        <th className="col-1 text-center">
                          Sr.No
                          <i
                            title="Document Id"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-5">
                          Name{" "}
                          <i
                            title="Document Name"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-4">
                          type{" "}
                          <i
                            title="Document Type"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-2 text-center">
                          Action{" "}
                          <i
                            title="Document Actions"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((ob, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td>{ob.file_name}</td>
                            <td>{ob.requirement_type}</td>
                            <td className="text-center">
                              <a
                                href={ob.link}
                                target="_blank"
                                className="btn btn-sm me-1 btn-outline-secondary"
                                title="Download Document"
                                rel="noreferrer"
                              >
                                <i className="bi bi-file-earmark-arrow-down"></i>
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                      {data.length == 0 && (
                        <tr>
                          <td colSpan={8} className="text-center">
                            {" "}
                            No Document Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Documents
