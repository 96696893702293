import React, { useEffect, useState } from "react"
import {
  Container,
  Form,
  Label,
  Input,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
} from "reactstrap"

// common component
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import CommonTable from "utils/CommonTable"

import editGsImg from "../../../../assets/images/settingImg/editGs.jpg"
import addGsImg from "../../../../assets/images/settingImg/addGs.jpg"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"
import { FetchPostData } from "utils/common-fetchData"
import { AccessActivityColumns } from "utils/columns/work/workColumns"
import NewPagination from "components/Common/Newpagination"
import Select from "react-select"


function TableContainer({
  columns,
  data,
  setAddTask,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  return (
    <div>

      {/* fixed buttons */}

      <div className="col-12 d-flex mb-3 position-relative " style={{ zIndex: "999" }}>
        <div className="col-6 d-flex justify-content-start align-items-center gap-4">
          <div className="col-2 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>

        </div>

        <div className="col-6 d-flex justify-content-end align-items-center gap-2">
          <div className="col-6 d-flex justify-content-start align-items-center mt-2">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>

          <Link
            onClick={() => setAddTask(true)}
            className="s btn text-white me-0 btn-save btn-label"
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Access Activity
          </Link>
        </div>
      </div>

      <div className="position-relative">
        <CommonTable
          getTableProps={getTableProps}
          fixedContent={fixedContent}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          ClassName="generalTaskSetting"
        />

      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
          nextPage={nextPage}
        />
      </Row>
    </div>
  )
}

const AccessActivityTab = ({ toggleLoading, datas, updateData, staffData, roleData }) => {
  const [formRows, setFormRows] = useState([{}])
  const [data, setData] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const [addTask, setAddTask] = useState(false)
  const [editTask, setEditTask] = useState(false)
  const [edit_title, setEditTitle] = useState('');
  const [taskId, setTaskId] = useState('')
  const [staff, setStaff] = useState(null)
  const [editStaff, setEditStaff] = useState(null)
  const [allActivity, setAllActivity] = useState(true)
  const [allEditActivity, setAllEditActivity] = useState(false)
  const [role, setRole] = useState(null)
  const [editRole, setEditRole] = useState(null)
  const [member, setMember] = useState(null)
  const [editMember, setEditMember] = useState(null)
  const [access_id, setAccessId] = useState('')

  useEffect(() => {
    setData(datas)
  }, [datas])

  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setAddTask(false)
  })
  const roles = useSelector(state => state?.Roles?.apiData)
  const onEditTask = (task) => {
    setEditStaff(staffData?.find((obj) => obj?.value == task?.staff_id))
    setAllEditActivity(task?.status == 0 ? true : false)
    setEditRole(roleData?.find((obj) => obj?.value == task?.roles))
    const rolesids = task?.members != null ? task?.members?.split(',') : []
    setEditMember(staffData.filter(item => rolesids.includes(String(item.value))))
    setAccessId(task.id)
    setEditTask(true);
  }
  // Add Task Api
  const addAccessAcitity = async (e) => {
    e.preventDefault();
    if (staff != null && (allActivity ? true : (role != null && member != null))) {
      // const permission = checkPermissionsByModule(roles, 73, 3);
      // if (permission) {
      const formData = {
        user_id: localStorage?.getItem('auth_id'),
        staff: staff?.value,
        role_id: role?.value,
        member_id: member?.map((obj) => obj?.value)?.join(','),
        status: allActivity ? 0 : 1
      }
      const response = await FetchPostData(toggleLoading, "addActitvityAccess", secureLocalStorage?.getItem('auth_token'), formData);
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Access Activity added successfully",
        }).then(data => {
          setAddTask(false);
          setData(response?.data?.data)
          updateData(response?.data?.data)
          setStaff(null)
          setRole(null)
          setMember(null)
        })
      }
      // }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }
  // Edit Task Api
  const editAccessActivity = async (e) => {
    e.preventDefault();
    // const permission = checkPermissionsByModule(roles, 73, 4);
    // if (permission) {
    const formData = {
      user_id: localStorage?.getItem('auth_id'),
      staff: editStaff?.value,
      role_id: editRole?.value,
      member_id: editMember?.map((obj) => obj?.value)?.join(','),
      status: allEditActivity ? 0 : 1,
      access_id: access_id
    }
    const response = await FetchPostData(toggleLoading, "editActitvityAccess", secureLocalStorage?.getItem('auth_token'), formData);
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Activity edited successfully",
      }).then(data => {
        setEditTask(false)
        setData(response?.data?.data)
        updateData(response?.data?.data)
      })
      // }
    }
  }
  // Delete Task Api
  const onDelete = async (id) => {
    // const permission = checkPermissionsByModule(roles, 73, 5);
    // if (permission) {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this Access!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formDatas = {
          access_id: id,
          user_id: localStorage?.getItem('auth_id'),
        }
        const response = await FetchPostData(toggleLoading, "deleteActitvityAccess", secureLocalStorage.getItem('auth_token'), formDatas)
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Activity Access deleted successfully",
          })
          setData(response?.data?.data)
        }
      }
    });
    // } else {
    //   setAccessDenied(true)
    // }

  }
  const columns1 = useMemo(
    () =>
      AccessActivityColumns(onDelete, onEditTask),
    [staffData, roleData]
  )
  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns1}
                      data={data}
                      setData={setData}
                      setAddTask={setAddTask}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Task  */}
      <Modal
        isOpen={addTask}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Access Activity</h5>
          <button
            type="button"
            onClick={() => {
              setAddTask(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={addAccessAcitity}>
            <Row>
              <div className="col-4">
                <img src={addGsImg} className="img-fluid" />
              </div>
              <div className="col-8 d-flex align-items-center justify-content-center flex-column">

                <div className="col-12 d-flex  align-items-start justify-content-start gap-2 mb-4">
                  <div className="mb-3 col-6">
                    <Label htmlFor="formrow-firstname-Input">Select Members <span className="text-danger">*</span></Label>
                    <Select
                      placeholder="Select Members"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={staffData}
                      value={staff}
                      onChange={(e) => setStaff(e)}
                    />
                  </div>
                  <div className="col-6 d-flex flex-column justify-content-center align-items-center gap-1 ms-3 pt-0">
                    <Label className="text-gray mt-0 mb-0">All Activity</Label>
                    <div className="form-switch form-switch-lg">
                      <input
                        title="Intial Days"
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        defaultChecked={allActivity}
                        onChange={() => {
                          setAllActivity(!allActivity);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {
                  allActivity == false ? (
                    <div className="col-12 d-flex align-items-start justify-content-start gap-2">
                      <div className="mb-3 col-4">
                        <Label htmlFor="formrow-firstname-Input">Select Role <span className="text-danger">*</span></Label>
                        <Select
                          placeholder="Select Roll"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={roleData}
                          value={role}
                          onChange={(e) => setRole(e)}
                        />
                      </div>
                      <div className="mb-3 col-8">
                        <Label htmlFor="formrow-firstname-Input">Select Member <span className="text-danger">*</span></Label>
                        <Select
                          placeholder="Select Member"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          isMulti="true"
                          options={staffData?.filter((obj) => obj?.roleId == role?.value)}
                          value={member}
                          onChange={(e) => setMember(e)}
                        />
                      </div>
                    </div>
                  ) : ""
                }

              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label"
              >
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                type="button"
                onClick={() => setAddTask(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Edit Task  */}

      <Modal
        isOpen={editTask}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Access Activity</h5>
          <button
            type="button"
            onClick={() => {
              setEditTask(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={editAccessActivity} >
            <Row>
              <div className="col-4">
                <img src={editGsImg} className="img-fluid" />
              </div>
              <div className="col-8 d-flex align-items-center justify-content-center flex-column">

                <div className="col-12 d-flex  align-items-start justify-content-start gap-2 mb-4">
                  <div className="mb-3 col-6">
                    <Label htmlFor="formrow-firstname-Input">Select Members <span className="text-danger">*</span></Label>
                    <Select
                      placeholder="Select Members"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={staffData}
                      value={editStaff}
                      onChange={(e) => setEditStaff(e)}
                    />
                  </div>
                  <div className="col-6 d-flex justify-content-center align-items-center gap-1 flex-column ms-3">
                    <Label className="text-gray mt-0 mb-0">All Activity</Label>
                    <div className="form-switch form-switch-lg">
                      <input
                        title="Intial Days"
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        defaultChecked={allEditActivity}
                        onChange={(e) => {
                          setAllEditActivity(e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {
                  allEditActivity == false ? (
                    <div className="col-12 d-flex align-items-start justify-content-start gap-2">
                      <div className="mb-3 col-4">
                        <Label htmlFor="formrow-firstname-Input">Select Roll <span className="text-danger">*</span></Label>
                        <Select
                          placeholder="Select Roll"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={roleData}
                          value={editRole}
                          onChange={(e) => setEditRole(e)}
                        />
                      </div>
                      <div className="mb-3 col-8">
                        <Label htmlFor="formrow-firstname-Input">Select Member <span className="text-danger">*</span></Label>
                        <Select
                          placeholder="Select Member"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          isMulti="true"
                          options={staffData?.filter((obj) => obj?.roleId == editRole?.value)}
                          value={editMember}
                          onChange={(e) => setEditMember(e)}
                        />
                      </div>
                    </div>
                  ) : ""
                }

              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label"

              >
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                type="button"
                onClick={() => setEditTask(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal>


      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default AccessActivityTab
