import React, { useEffect, useState } from "react"
import { Row } from "reactstrap"
import axios from "axios"
import configure from "configure"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"

const ZithasCredential = ({ id }) => {
  const [isLoading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [ztplCredentialEmail, setZtplCredentialEmail] = useState("")
  const [ztplCredentialPass, setZtplCredentialPass] = useState("")
  const [bclf, setBclf] = useState(0)
  const [zohoEmail, setZohoEmail] = useState("")
  const [zohoPass, setZohoPass] = useState("")
  const [microsoftEmail, setMicrosoftEmail] = useState("")
  const [microsoftPass, setMicrosoftPass] = useState("")
  const [skypeEmail, setSkypeEmail] = useState("")
  const [skypePass, setSkypePass] = useState("")
  const [gitCredentials, setGitCredentials] = useState("")
  const [gitCredentialsPassword, setGitCredentialsPassword] = useState("")
  const [gitSecId, setGitSecId] = useState("")

  const [showZohoPassword, setShowZohoPassword] = useState(false)
  const [showMicrosoftPass, setShowMicrosoftPass] = useState(false)
  const [showSkypePass, setShowSkypePass] = useState(false)
  const [showGitPass, setShowGitPass] = useState(false)

  const getCtaDetails = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const item = response.data.data.filter(temp => temp.id == id)[0]
        setZtplCredentialEmail(item.email)
        setZtplCredentialPass(item.crm_password)
        setBclf(item.permission)
        setZohoEmail(item.zoho_email)
        setZohoPass(item.zoho_password)
        setMicrosoftEmail(item.teams_email)
        setMicrosoftPass(item.teams_password)
        setSkypeEmail(item.skype_email)
        setSkypePass(item.skype_password)
        setGitCredentials(item.git_username)
        setGitCredentialsPassword(item.git_password)
        setGitSecId(item.git_secret_id)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getCtaDetails()
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <div>
          <div className="mb-3 bg-white p-4 shadow ">
            <Row className="mb-4">
              <div className="col-md-6">
                <h4 className="text-dark">ZTPL Credential</h4>
              </div>
            </Row>

            <Row>
              <div className="col-md-4 mb-3 mb-md-0 ">
                <label htmlFor="">
                  Email Id <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={ztplCredentialEmail}
                  disabled={true}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0 password">
                <label htmlFor="">
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={ztplCredentialPass}
                  disabled={true}
                />
                <i
                  className={`fas fa-eye${
                    showPassword ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              </div>
              <div className="col-md-4 mb-md-0 d-flex flex-column align-items-center justify-content-center  ">
                <h5 className="">Bypass - Clear Login Functionality </h5>
                <div className="form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd"
                    checked={bclf == "1"}
                    disabled={true}
                  />
                </div>
              </div>
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Zoho Mail</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={zohoEmail}
                  disabled={true}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                  type={`${showZohoPassword ? "text" : "password"}`}
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={zohoPass}
                  disabled={true}
                />
                  <i
                  className={`fas fa-eye${
                    showZohoPassword ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowZohoPassword(!showZohoPassword)}
                ></i>
              </div>
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Microsoft 365</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={microsoftEmail}
                  disabled={true}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                  type={`${showMicrosoftPass ? "text" : "password"}`}
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={microsoftPass}
                  disabled={true}
                />
                 <i
                  className={`fas fa-eye${
                    showMicrosoftPass ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowMicrosoftPass(!showMicrosoftPass)}
                ></i>
              </div>
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Skype</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={skypeEmail}
                  disabled={true}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                 type={`${showSkypePass ? "text" : "password"}`}
                  placeholder="-"
                  className="form-control"
                  id=""
                  value={skypePass}
                  disabled={true}
                />
                 <i
                  className={`fas fa-eye${
                    showSkypePass ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowSkypePass(!showSkypePass)}
                ></i>
              </div>
            </Row>
          </div>

          <div className="mb-3 bg-white p-4 shadow">
            <h5 className="mb-4 text-dark">Git Credentials</h5>
            <Row>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Username</label>
                <input
                  type="text"
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={gitCredentials}
                  disabled={true}
                />
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Password</label>
                <input
                   type={`${showGitPass ? "text" : "password"}`}
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={gitCredentialsPassword}
                  disabled={true}
                />
                 <i
                  className={`fas fa-eye${
                    showGitPass ? "-slash" : ""
                  } eye-icon`}
                  onClick={() => setShowGitPass(!showGitPass)}
                ></i>
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <label htmlFor="">Secret Id</label>
                <input
                  type="number"
                  placeholder="-"
                  className="form-control "
                  id=""
                  value={gitSecId}
                  disabled={true}
                />
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default ZithasCredential
