import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import axios from "axios"
import configure from "configure"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"

const WorkExperience = ({ id, verticalActiveTab }) => {
  // for education form
  const [inputFields_1, setInputFields1] = useState([{}])
  const [formRows, setFormRows] = useState([{}])
  const [fixButtons, setFixButtons] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // dynamic form add
  const onAddFormRow = key => {
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
    setInputFields1([...inputFields_1, {}])
  }


  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", id?.toString())
    formData.append(
      "company_name",
      inputFields_1.map(temp => temp.company_name).toString()
    )
    formData.append(
      "from_date",
      inputFields_1.map(field => field.from_date).toString()
    )
    formData.append(
      "to_date",
      inputFields_1.map(field => field.to_date).toString()
    )
    formData.append(
      "position",
      inputFields_1.map(field => field.position).toString()
    )
    formData.append(
      "salary",
      inputFields_1.map(field => field.salary).toString()
    )
    formData.append(
      "reason_for_leave",
      inputFields_1.map(field => field.reason_for_leave).toString()
    )
    formData.append(
      "job_description",
      inputFields_1.map(field => field.job_description).toString()
    )

    formData.append(
      "contact_person",
      inputFields_1.map(field => field.contact_person).toString()
    )
    formData.append(
      "we_email",
      inputFields_1.map(field => field.we_email).toString()
    )
    formData.append(
      "we_phone_number",
      inputFields_1.map(field => field.we_phone_number).toString()
    )
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staffwe`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff work experience details edited successfully",
        }).then(data => {
          // setverticalActiveTab("4")
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getCtaDetails = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const item = response.data.data.filter(temp => temp.id == id)[0]
        // console.log("Roles & Responsibility", item)
        const WorkExperienceDetails = JSON.parse(item.work_experience)
        if (WorkExperienceDetails !== null) {
          setInputFields1(WorkExperienceDetails)
        }
        // setFormRows([{}])
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    if(verticalActiveTab == "tab3"){
      getCtaDetails();
    }
  }, [verticalActiveTab])

  const handleInputChange = (e, key) => {
    const { name, value } = e.target
    const values = [...inputFields_1]
    values[key][name] = value
    setInputFields1(values)
  }

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Form className="repeater" onSubmit={handleSubmit}>
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
            <button
              onClick={e => {
                e.preventDefault()
                onAddFormRow()
              }}
              className="btn btn-purple  fix-btn"
            >
              <i className="fas fa-plus icon-size"></i>
              <span className="ps-2">Add</span>
            </button>
          </div>
          <div className="p-4 pb-0 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark">Work Experience</h4>
              </div>
              <div className="col-md-6  text-md-end">
                <button
                  onClick={e => {
                    e.preventDefault()
                    onAddFormRow()
                  }}
                  className=" btn text-white text-uppercase me-2 btn-save btn-label"
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                  More
                </button>

                <button className=" btn btn-save me-md-0 me-2 btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>SAVE
                </button>
                <button
                  onClick={() => navigate(-1)}
                  className=" btn ms-md-2 btn-red btn-label"
                  type="button"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>
          </div>
          {(inputFields_1 || []).map((formRow, key) => (
            <div key={key} className="mb-3 p-4 py-5 bg-white shadow">
              <Row className="align-items-center">
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Company Name</label>
                  <input
                    type="text"
                    placeholder="Enter Company Name"
                    className="form-control "
                    id=""

                    name="company_name"
                    value={formRow.company_name || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>
                <div className="col-md-2 mb-3" style={{ width: "18%" }}>
                  <label htmlFor="">From Date</label>
                  <input
                    className="form-control"
                    type="date"
                    id="example-date-input"
                    name="from_date"
                    value={formRow.from_date || ""}
                    onChange={e => handleInputChange(e, key)}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="col-md-2 mb-3" style={{ width: "18%" }}>
                  <label htmlFor="">To Date</label>
                  <input
                    className="form-control"
                    type="date"
                    id="example-date-input"
                    name="to_date"
                    value={formRow.to_date || ""}
                    onChange={e => handleInputChange(e, key)}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>

                <div className="col-md-2 mb-3" style={{ width: "18%" }}>
                  <label htmlFor="">Salary</label>
                  <input
                    type="text"
                    placeholder="Enter Salary"
                    className="form-control "
                    id=""
                    name="salary"
                    value={formRow.salary || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>
                {/* delete button */}
                <div
                  className={`col-md-1 text-end ${
                    key === 0 ? "d-none" : "d-block"
                  }`}
                >
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    title="Delete row"
                    onClick={e => {
                      e.preventDefault()
                      const updatedRows = inputFields_1.filter(
                        (_, idx) => idx !== key
                      )
                      setInputFields1(updatedRows)
                    }}
                  >
                    <i className="fas fa-trash-alt "></i>
                  </button>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Position</label>
                  <input
                    type="text"
                    placeholder="Enter Position"
                    className="form-control "
                    id=""
                    name="position"
                    value={formRow.position || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="">Reason For Leave</label>
                  <input
                    type="text"
                    placeholder="Enter Reason For Leave"
                    className="form-control "
                    id=""
                    name="reason_for_leave"
                    value={formRow.reason_for_leave || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Job Description</label>
                  <input
                    type="text"
                    placeholder="Enter Job Description"
                    className="form-control "
                    id=""
                    name="job_description"
                    value={formRow.job_description || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Contact Person</label>
                  <input
                    type="text"
                    placeholder="Enter Contact Person"
                    className="form-control "
                    id=""
                    name="contact_person"
                    value={formRow.contact_person || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Email Id</label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    className="form-control "
                    id=""
                    name="we_email"
                    value={formRow.we_email || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Phone Number</label>
                  <input
                    type="number"
                    placeholder="Enter Phone Number"
                    className="form-control "
                    id=""
                    name="we_phone_number"
                    value={formRow.we_phone_number || ""}
                    onChange={e => handleInputChange(e, key)}
                  />
                </div>
              </Row>
            </div>
          ))}
        </Form>
      </div>
    </>
  )
}

export default WorkExperience
