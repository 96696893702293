import React, { useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RelatedToOption } from "utils/common-options";
import moment from "moment";
import { Link } from "react-router-dom";
import configure from "configure";
import Swal from "sweetalert2";
import sideImg from "../../../assets/images/profile.png"

export const generalDashboardColumns = (handleEditTask, onDelete, handleChangeStatus, onManagePlan, handleAddHour, followChangeStatus, onAddTask) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_id",
        Cell: cellProps => {
            const [status, setStatus] = useState(cellProps?.row?.original?.plan_day);
            useEffect(() => {
                setStatus(cellProps?.row?.original?.plan_day)
            }, [cellProps?.row?.original])
            return (
                <div className="d-flex justify-content-center align-items-center gap-1">
                    <span>
                        <a
                            className="color-grey me-1"
                            title="Plan My Day"
                            onClick={() => {
                                onManagePlan(cellProps.row.original?.id, cellProps?.row?.original?.plan_day == 0 ? 1 : 0, cellProps?.row?.original?.type)
                                setStatus(status == 0 ? 1 : 0)
                            }}
                        >
                            <i className={`${status == 1 ? "bi bi-brightness-high-fill color-orange" : "bi bi-brightness-high "} fs-4`}></i>
                        </a>
                    </span>
                    <span>{cellProps.row.original.id}</span>
                </div>
            )
        },
    },
    {
        Header: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg-com-status text-center",
        Cell: cellProps => {
            const [isChecked, setIsChecked] = useState(cellProps.row.original.checked || false);
            const handleCheckboxChange = async (e, id) => {
                const checked = e.target.checked;
                setIsChecked(checked);
                const userConfirmed = await Swal.fire({
                    title: "Are you sure?",
                    text: "Do you want to change the status?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, change it!",
                    cancelButtonText: "No, cancel",
                });

                if (userConfirmed.isConfirmed) {
                    handleChangeStatus(e, cellProps.row.original.id);
                    setIsChecked(false);
                } else {
                    setIsChecked(false);
                }
            };


            return (
                <div className="d-flex align-items-center gap-3 justify-content-center">
                    <span>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</span>
                    <img
                        src={`${cellProps?.row?.original?.img ? `${configure?.imgUrl}${cellProps?.row?.original?.img}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title={cellProps?.row?.original?.added_by_name}
                    />
                    <div className="text-end" id="customCheckbox" >
                        {
                            cellProps.row.original.type === 1 ? (
                                <input
                                    type="checkbox"
                                    className="form-check-input custom-checkbox mt-2im general-status"
                                    id={`customCheck-${cellProps.row.id}`}
                                    value="complete"
                                    disabled={
                                        cellProps.row.original.added_by != localStorage.getItem("auth_id")
                                    }
                                    checked={isChecked}
                                    onChange={(e) => handleCheckboxChange(e, cellProps.row.original.id)}
                                />
                            ) : (
                                <div className="text-center">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() =>
                                            followChangeStatus({
                                                user_id: localStorage.getItem("auth_id"),
                                                followup_id: cellProps.row.original.id,
                                            })
                                        }
                                    >
                                        <i className="fas fa-check"></i>
                                    </button>
                                </div>
                            )
                        }
                    </div >
                </div>
            )
        }
    },
    {
        Header: "Related",
        accessor: "related",
        isInfoIcon: false,
        isShortIcon: false,
        className: "dg-related",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }
            return (
                <>
                    {priorty}
                    <a className={`fs-3 ms-2 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>
                    </a>
                </>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_task_name",
        Cell: cellProps => {
            const subTasks = cellProps.row?.original?.subtasks
            const isAssignee = subTasks?.filter((obj) => obj?.assign == localStorage.getItem('auth_id'))
            const shownSubTask = isAssignee != undefined && isAssignee?.length != 0 ? isAssignee : null
            return (
                <div>
                    <strong> {shownSubTask?.length != 0 && shownSubTask != null ? (
                        <i className="bx bx-subdirectory-right fs-5"></i>
                    ) : null}
                        {` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`}</strong>  {cellProps?.row?.original?.title}
                    <div className="ms-4">{shownSubTask?.length != 0 && shownSubTask != null ? (
                        <>
                            {shownSubTask?.map((obj, i) => {
                                return (
                                    <p className={`mb-0 subtask-title ${obj?.status == 1 ? "text-decoration-line-through text-success" : ""}`} key={i}>- {obj?.title} : <span className="fw-bold">{obj?.enddate}</span></p>
                                )
                            })}
                        </>
                    ) : null}</div>
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_end_date",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.enddate != null ? moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY') : "-"}</div>
            )
        }
    },
    {
        Header: "Spent Hrs",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_last_update",
        Cell: cellProps => {
            return (
                <div className="">
                    {cellProps?.row?.original?.spent_hours || "-"}
                </div>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "activity-action",
        Cell: cellProps => {
            return (
                <>
                    {cellProps?.row?.original?.type == 1 ? (
                        <div className="d-flex justify-content-center">
                            <a
                                className="btn btn-sm btn-outline-secondary me-1"
                                title="View Activity"
                                onClick={() => handleEditTask(cellProps?.row?.original, "view")}
                            >
                                <i className="fas fa-eye "></i>
                            </a>

                            <a title="Add Hours" className="btn btn-sm btn-outline-secondary me-1" onClick={() => handleAddHour(
                                cellProps.row.original.id, cellProps?.row?.original?.related_to, cellProps?.row?.original?.related_id)}>
                                <i className="bi bi-alarm-fill"></i>
                            </a>
                            <UncontrolledDropdown
                                className="dropdown"
                                style={{ width: "29px" }}
                            >
                                <DropdownToggle
                                    className="btn btn-sm btn-outline-secondary w-100"
                                    color="white"
                                >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <a
                                        className="dropdown-item"
                                        title="Edit Activity"
                                        onClick={() => handleEditTask(cellProps?.row?.original, "edit")}
                                    >
                                        <i className="fas fa-pen pr-10"></i>
                                        Edit Activity
                                    </a>
                                    <a title="Delete Activity" className="dropdown-item" onClick={() => onDelete(cellProps?.row?.original?.id)}>
                                        <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                        Delete Activity
                                    </a>


                                </DropdownMenu>

                            </UncontrolledDropdown>
                        </div>
                    ) : (
                        <>
                            <Link title="view lead" className="btn btn-sm btn-outline-secondary me-1" to={`${configure.appUrl}sales/edit-lead/${cellProps.row.original?.related_id}`} >
                                <i className="fas fa-arrow-right"></i>
                            </Link>
                            <a title="Add Task" className="btn btn-sm btn-outline-secondary me-1 "
                                onClick={() => onAddTask(cellProps.row.original?.related_id)}>
                                <i className="fas fa-clipboard-list"></i>
                            </a>
                        </>
                    )}
                </>

            )
        },
    },
]

export const todayPlanClolumns = (onEditTask, onDeleteTask, handleStatus, FollowupStatus, onAddHours, onFollowTask) => [
    {
        Header: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_status-id text-center",
        Cell: cellProps => {
            const [isChecked, setIsChecked] = useState(cellProps.row.original.checked || false);
            const handleCheckboxChange = async (e, id) => {
                const checked = e.target.checked;
                setIsChecked(checked);
                const userConfirmed = await Swal.fire({
                    title: "Are you sure?",
                    text: "Do you want to change the status?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, change it!",
                    cancelButtonText: "No, cancel",
                });

                if (userConfirmed.isConfirmed) {
                    handleStatus(e, cellProps.row.original.id);
                    setIsChecked(false);
                } else {
                    setIsChecked(false);
                }
            };


            return (
                <div className="d-flex align-items-center gap-3 justify-content-start">
                    <img
                        src={`${cellProps?.row?.original?.added_user ? `${configure?.imgUrl}${cellProps?.row?.original?.added_user}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title={cellProps?.row?.original?.added_by_name}
                    />
                    <div className="text-end" id="customCheckbox" >
                        {
                            cellProps.row.original.type != 'followup' ? (
                                <>
                                    {cellProps.row.original?.status != "complete" ? (
                                        <input
                                            type="checkbox"
                                            className="form-check-input custom-checkbox mt-2im general-status"
                                            id={`customCheck-${cellProps.row.id}`}
                                            value="complete"
                                            disabled={
                                                cellProps.row.original.added_by != localStorage.getItem("auth_id")
                                            }
                                            checked={isChecked}
                                            onChange={(e) => handleCheckboxChange(e, cellProps.row.original.id)}
                                        />
                                    ) : null}
                                </>
                            ) : (
                                <div className="text-center">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() =>
                                            followChangeStatus({
                                                user_id: localStorage.getItem("auth_id"),
                                                followup_id: cellProps.row.original.id,
                                            })
                                        }
                                    >
                                        <i className="fas fa-check"></i>
                                    </button>
                                </div>
                            )
                        }
                    </div >
                </div>
            )
        }
    },
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: 'pl_id',
        Cell: cellProps => {
            return (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                    <a className={`fs-5 line-height-0 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>

                    </a>
                    {cellProps.row.original.id}
                </div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_task_name",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }

            const subTasks = cellProps.row?.original?.subtasks
            const isAssignee = subTasks?.filter((obj) => obj?.assign == localStorage.getItem('auth_id'))
            const shownSubTask = isAssignee != undefined && isAssignee?.length != 0 ? isAssignee : null
            return (
                <div className="text-capitalize">{priorty}
                    <strong>
                        {shownSubTask?.length != 0 && shownSubTask != null ? (
                            <i className="bx bx-subdirectory-right fs-5"></i>
                        ) : null}
                        {` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`} </strong>
                    {cellProps.row?.original?.title || "-"}
                    <div className="ms-5">{shownSubTask?.length != 0 && shownSubTask != null ? (
                        <>
                            {shownSubTask?.map((obj, i) => {
                                return (
                                    <p className={`mb-0 subtask-title ${obj?.status == 1 ? "text-decoration-line-through text-success" : ""}`} key={i}>- {obj?.title} : <span className="fw-bold">{obj?.enddate}</span></p>
                                )
                            })}
                        </>
                    ) : null}</div>
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_end_date",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY')}</div>
            )
        }
    },

    {
        Header: "Est. Time",
        accessor: "estimate_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.estimate_time || "-"}</div>
            )
        }
    },
    {
        Header: "Act. Time",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_priority",
        Cell: cellProps => {
            return (
                <>
                    {cellProps?.row?.original?.type != 'followup' ? (
                        <div className="d-flex justify-content-center">
                            <a
                                className="btn btn-sm btn-outline-secondary me-1"
                                title="View Activity"
                                onClick={() => onEditTask(cellProps?.row.original, "view")}
                            >
                                <i className="fas fa-eye "></i>
                            </a>
                            <a title="Add Hours" className="btn btn-sm btn-outline-secondary me-1" onClick={() => onAddHours(cellProps.row.original.id)}>
                                <i className="bi bi-alarm-fill"></i>
                            </a>
                            <UncontrolledDropdown
                                className="dropdown"
                                style={{ width: "29px" }}
                            >
                                <DropdownToggle
                                    className="btn btn-sm btn-outline-secondary w-100"
                                    color="white"
                                >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <a
                                        className="dropdown-item"
                                        title="Edit Activity"
                                        onClick={() => onEditTask(cellProps?.row.original, "edit")}
                                    >
                                        <i className="fas fa-pen pr-10"></i>
                                        Edit Activity
                                    </a>
                                    <a title="Delete Activity" className="dropdown-item"
                                        onClick={() => onDeleteTask(cellProps?.row.original.id)}
                                    >
                                        <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                        Delete Activity
                                    </a>


                                </DropdownMenu>

                            </UncontrolledDropdown>
                        </div>
                    ) : (
                        <div className="d-flex gap-1">
                            <Link title="view lead" className="btn btn-sm btn-outline-secondary me-1" to={`${configure.appUrl}sales/edit-lead/${cellProps.row.original?.related_id}`} >
                                <i className="fas fa-arrow-right"></i>
                            </Link>
                            <a title="Add Task" className="btn btn-sm btn-outline-secondary me-1 "
                                onClick={() => onFollowTask(cellProps.row.original?.related_id)}>
                                <i className="fas fa-clipboard-list"></i>
                            </a>
                        </div>
                    )}
                </>
            )
        }

    },
]

export const YesterDayPlanClolumns = (onAddHours) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: 'pl_id',
        Cell: cellProps => {

            return (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                    <a className={`fs-5 line-height-0 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>

                    </a>
                    {cellProps.row.original.id}

                </div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_task_name",
        Cell: cellProps => {

            const subTasks = cellProps.row?.original?.subtasks
            const isAssignee = subTasks?.filter((obj) => obj?.assign == localStorage.getItem('auth_id'))
            const shownSubTask = isAssignee != undefined && isAssignee?.length != 0 ? isAssignee : null
            return (
                <div className="text-capitalize">
                    <strong>
                        {shownSubTask?.length != 0 && shownSubTask != null ? (
                            <i className="bx bx-subdirectory-right fs-5"></i>
                        ) : null}
                        {` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`} </strong>
                    {cellProps.row?.original?.title || "-"}
                    <div className="ms-5">{shownSubTask?.length != 0 && shownSubTask != null ? (
                        <>
                            {shownSubTask?.map((obj, i) => {
                                return (
                                    <p className={`mb-0 subtask-title ${obj?.status == 1 ? "text-decoration-line-through text-success" : ""}`} key={i}>- {obj?.title} : <span className="fw-bold">{obj?.enddate}</span></p>
                                )
                            })}
                        </>
                    ) : null}</div>
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_end_date",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Priority",
        accessor: "priorty",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_priority",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }
            return (
                <div>
                    {priorty}
                </div>
            )
        }
    },
    {
        Header: "Est. Time",
        accessor: "estimate_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.estimate_time || "-"}</div>
            )
        }
    },
    {
        Header: "Actual Time",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <a title="Add Hours" className="btn btn-sm btn-outline-secondary me-1" onClick={() => onAddHours(cellProps.row.original.id)}>
                    <i className="bi bi-alarm-fill"></i>
                </a>
            )
        }
    },
]

export const TeamsPlanClolumns = (onEditTask) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: 'tl_id',
        Cell: cellProps => {

            return (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                    <img
                        src={`${cellProps?.row?.original?.added_user ? `${configure?.imgUrl}${cellProps?.row?.original?.added_user}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title={cellProps?.row?.original?.added_by_name}
                    />
                    <a className={`fs-5 line-height-0 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>

                    </a>
                    {cellProps.row.original.id}

                </div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_task_name",
        Cell: cellProps => {
            return (
                <div>
                    <strong>{` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? `${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to} - ` : ""}`} </strong> {cellProps?.row?.original?.title}
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_end_date",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Priority",
        accessor: "priorty",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_priority",
        Cell: cellProps => {
            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }
            return (
                <div>
                    {priorty}
                </div>
            )
        }
    },
    {
        Header: "Est. Time",
        accessor: "estimate_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.estimate_time || "-"}</div>
            )
        }
    },
    {
        Header: "Actual Time",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl-esttime",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
    {
        Header: "",
        accessor: "action",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_id",
        Cell: cellProps => {
            return (
                <div className="">
                    <a className="btn btn-sm btn-outline-secondary me-1" title="View Activity" onClick={() => onEditTask(cellProps?.row.original, "view")}>
                        <i className="fas fa-eye " ></i>
                    </a>
                </div>
            )
        }
    },

]

export const TaskTabelColumns = () => [
    {
        Header: "Task ID",
        accessor: "sr_no",
        title: "ID",
        isInfoIcon: false,
        className: "text-center task_ids",
    },
    {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,
        // className: "task_name_box",
    },
    {
        Header: "Hours",
        accessor: "hours",
        isInfoIcon: false,
        // className: "status_box text-center",
    },
    {
        Header: "Status",
        accessor: "status_name",
        isInfoIcon: false,
        // className: "status_box text-center",
    },
    {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "timer",
        isInfoIcon: false,
        // className: "days_box text-center",
    },
    {
        Header: "Action",
        accessor: "action",
        // className: "task_action text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a className="btn btn-sm btn-outline-success me-1"
                        title="Start / Stop Timer"
                    >
                        <i className="fa fa-stopwatch"></i>
                    </a>
                    {cellProps?.row?.original?.action ? (
                        <a className="btn btn-sm btn-outline-primary me-1"
                            title="Start / Stop Timer"
                        >
                            <i className="fa fa-plus"></i>
                        </a>
                    ) : null}
                </div>
            )
        },
    },
]
export const DashboardSwipesColumns = (onDisapporve, onApprove) => [
    {
        Header: "ID",
        accessor: "id",
        className: "db_swipe_id",
        isShortIcon: true,
    },
    {
        Header: "Swipe & Issues",
        accessor: "db_swipe_name",
        className: "db_swipe_name",
        isShortIcon: true,
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-start gap-1 db_swipe_name_box">
                    <img
                        src={cellProps.row.original?.avatar != null ? `${configure?.imgUrl}${cellProps?.row?.original?.avatar}` : sideImg}
                        alt="user Image"
                        className="user_img_da img-fluid cursor-pointer me-2"
                        title={cellProps.row.original?.name}
                    />
                    <div className="dahboard_swipe">
                        {cellProps.row.original?.leave_type == 1 ? (
                            <span className="me-3">{moment(cellProps.row.original?.date).format('DD-MM-YYYY')} {cellProps.row?.original?.time}</span>
                        ) : cellProps.row.original?.leave_type == 2 ? (
                            <span className="me-3">{cellProps.row.original?.amount} {cellProps.row.original?.type == 0 ? "Days" : "Amount"} </span>
                        ) : null}

                        {cellProps.row.original.reason}

                    </div>
                </div>
            )
        },
    },
    {
        Header: "Date",
        accessor: "db_swipe_date",
        className: "db_swipe_date",
        isShortIcon: true,
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.created_at).format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "db_swipe_actions text-center",
        className: "db_swipe_actions text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a className="btn btn-sm btn-outline-primary me-1"
                        title="Approve"
                        onClick={() => onApprove(cellProps?.row?.original?.leave_type, cellProps?.row?.original?.id)}
                    >
                        <i className="bi bi-check-lg icon-center"></i>
                    </a>
                    <a className="btn btn-sm btn-outline-danger me-1"
                        title="Disapprove"
                        onClick={() => onDisapporve(cellProps?.row?.original?.leave_type, cellProps?.row?.original?.id)}
                    >
                        <i className="dripicons-cross icon-center"></i>
                    </a>
                </div>
            )
        },
    },
]