import React, { useEffect, useMemo, useState } from "react"
import { Row, Form, Table } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import "../../../assets/css/Staff/permissions.css"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"

const PermissionView = ({ id, verticalActiveTab }) => {
  const [isLoading, setLoading] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [locations, setLocations] = useState([])
  const [all_module, setModules] = useState([])
  const [show_module, setShowModule] = useState([])
  const [permissionActiveTab, setPermissionActiveTab] = useState({
    i: 0,
    countryId: null,
  })
  const [openIndex, setOpenIndex] = useState(null)
  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index)
  }


  document.addEventListener("scroll", () => {
    if (window.scrollY > 215) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  // Api integration

  const handleWarningPopup = ({ e, roleIdCH, countryId }) => {
    setShowModule([])
    setPermissionActiveTab({
      i: roleIdCH,
      countryId: countryId,
    })
    fetchTemplate(countryId)
  }

  const fetchModules = async () => {
    setLoading(true)
    try {
      const bearerToken = secureLocalStorage.getItem("auth_token")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/allModules`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setModules(response?.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchModules()
  }, [])

  useEffect(() => {
    if (verticalActiveTab == "7") {
    getStarted()
  }
  }, [verticalActiveTab])
 
  async function getStarted() {
    const locaId = await fetchLocation()
    await fetchTemplate(locaId)
  }


  const fetchLocation = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      setLocations(response.data.data)
      setPermissionActiveTab({ i: 0, countryId: response?.data?.data[0]?.id })
      setLoading(false)
      return response?.data?.data[0]?.id
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getRoleDetails = async (locaId) => {
    const bearerToken = secureLocalStorage.getItem("auth_token")
    const roleId = await axios({
      method: "get",
      url: `${configure.apiUrl}/staff`,
      headers: {
        Authorization: "Bearer " + bearerToken,
        Accept: "application/json",
      },
    })
    const item = roleId.data.data.filter(temp => temp.id == id)[0]
    const formData = new FormData()
    formData.append("role_id", item.roleid)
    formData.append("country_id", locaId)
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/showRoles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        const Permissions = response?.data?.data?.role?.permissions?.map(
          item => {
            return item.permission
          }
        )

        const permissionsArray = Permissions[0]
        // setIsChangePermission(permissionsArray)
        let newArrays

        if (permissionsArray !== null || permissionsArray !== undefined) {
          if (typeof permissionsArray === "string") {
            newArrays = JSON.parse(Permissions[0])?.map(str => {
              const [moduleId, Global, View, Add, Edit, Delete] = str.split(",")
              return {
                moduleId: Number(moduleId),
                Global: Number(Global),
                View: Number(View),
                Add: Number(Add),
                Edit: Number(Edit),
                Delete: Number(Delete),
              }
            })
          } else {
            newArrays = permissionsArray?.map(str => {
              const [moduleId, Global, View, Add, Edit, Delete] = str.split(",")
              return {
                moduleId: Number(moduleId),
                Global: Number(Global),
                View: Number(View),
                Add: Number(Add),
                Edit: Number(Edit),
                Delete: Number(Delete),
              }
            })
          }

          const ConvertArray = response.data.data.modules?.map(obj => {
            const matchingObj = newArrays?.find(
              item => item.moduleId === obj.module_id
            )
            const isModuleIdOne = obj.module_id === 1
            const mergedObj = {
              ...obj,
              ...matchingObj,
              ...(isModuleIdOne
                ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                : {}),
            }
            mergedObj.submodules = obj.submodules.map(sub => ({
              ...sub,
              ...newArrays?.find(item => item.moduleId === sub.module_id),
            }))
            return mergedObj
          })

          setShowModule([...ConvertArray])
          setLoading(false)
        } else {
          const ConvertArray = response.data.data.modules?.map(obj => {
            const isModuleIdOne = obj.module_id === 1
            const mergedObj = {
              ...obj,
              ...(isModuleIdOne
                ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                : {}),
            }

            mergedObj.submodules = obj.submodules.map(sub => {
              return {
                ...sub,
                ...(isModuleIdOne
                  ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                  : {}),
              }
            })
            return mergedObj
            setLoading(false)
          })

          setShowModule(ConvertArray)
        }

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const fetchTemplate = async (locaId) => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    const formData = new FormData()
    formData.append("staff_id", id)
    formData.append("country_id", locaId)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/staffPermission`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(async res => {
          const Permissions = JSON.parse(res?.data?.data?.permission?.permission)
          const permissionsArray = Permissions
          let newArrays
          if (typeof permissionsArray === "string") {
            newArrays = JSON.parse(Permissions)?.map(str => {
              const [moduleId, Global, View, Add, Edit, Delete] = str.split(",")
              return {
                moduleId: Number(moduleId),
                Global: Number(Global),
                View: Number(View),
                Add: Number(Add),
                Edit: Number(Edit),
                Delete: Number(Delete),
              }
            })
          } else {
            newArrays = permissionsArray?.map(str => {
              const [moduleId, Global, View, Add, Edit, Delete] = str.split(",")
              return {
                moduleId: Number(moduleId),
                Global: Number(Global),
                View: Number(View),
                Add: Number(Add),
                Edit: Number(Edit),
                Delete: Number(Delete),
              }
            })
          }
          const ConvertArray = res.data.data.modules?.map(obj => {
            const matchingObj = newArrays?.find(
              item => item.moduleId === obj.module_id
            )
            const isModuleIdOne = obj.module_id === 1
            const mergedObj = {
              ...obj,
              ...matchingObj,
              ...(isModuleIdOne
                ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                : {}),
            }
            mergedObj.submodules = obj.submodules.map(sub => ({
              ...sub,
              ...newArrays?.find(item => item.moduleId === sub.module_id),
            }))
            return mergedObj
          })
          setShowModule([...ConvertArray])
          setLoading(false)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const handleShow_module = useMemo(
    () => show_module,
    [show_module, permissionActiveTab.countryId]
  )

  
  return (
    <>
      {isLoading && <Loader />}
      <div className="p-4 bg-white shadow">
      
          <Row className="align-items-end justify-space-between">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-3">
                {locations.map((ob, i) => {
                  return (
                    <h4 className="mb-3 text-dark" key={ob.id}>
                      <input
                        className="form-check-input input-location"
                        type="radio"
                        name="exampleRadios1"
                        id={`exampleRadios1`}
                        checked={permissionActiveTab.i == i ? true : false}
                        // onClick={() => setPermissionActiveTab(i)}
                        onClick={e =>
                          handleWarningPopup({
                            e,
                            roleIdCH: i,
                            countryId: ob.id,
                          })
                        }
                      />
                      <label
                        className="form-check-label pl-10"
                        htmlFor={`exampleRadios`}
                      >
                        <img
                          src={ob.Link}
                          alt={ob.loaction}
                          width="28px"
                          height="18px"
                          className="ps-1"
                        />{" "}
                      </label>
                      {/* {item.location} */}
                    </h4>
                  )
                })}
              </div>
            </div>
          </Row>
          <div className="responsive-table mt-2 overflow-auto shadow">
            <Table className="table table-bordered m-0 text-center mt-4 role-table ">
              <thead
                className={`pt-2 ${
                  fixedContent ? "fixed-header shadow-lg pt-1" : ""
                }`}
              >
                <tr>
                  <th className="text-start ps-4">Module</th>
                  <th className="cell-width">
                    Global
                    <a onClick={() => setGlobalModal(true)}>
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon "
                      ></i>
                    </a>
                  </th>
                  <th className="cell-width">
                    View
                    <a onClick={() => setViewModal(true)}>
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    </a>
                  </th>
                  <th className="cell-width">
                    Add
                    <a onClick={() => setAddModal(true)}>
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    </a>
                  </th>
                  <th className="cell-width">
                    Update
                    <a onClick={() => setEditModal(true)}>
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    </a>
                  </th>
                  <th className="cell-width">
                    Delete
                    <a onClick={() => setDeleteModal(true)}>
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    </a>
                  </th>
                  <th className="cell-width">More</th>
                </tr>
              </thead>
              <tbody>
                {handleShow_module.map((module, i) => {
                  const checkboxsGlobal =
                    document.getElementById("Dashboardglobal")
                  const checkboxsView = document.getElementById("Dashboardview")
                  const checkboxsAdd = document.getElementById("Dashboardadd")
                  const checkboxsEdit = document.getElementById("Dashboardedit")
                  const checkboxsDelete =
                    document.getElementById("Dashboarddelete")
                  if (checkboxsGlobal != null) {
                    checkboxsGlobal.setAttribute("checked", "true")
                    checkboxsGlobal.setAttribute("disabled", "true")
                  }
                  if (checkboxsView != null) {
                    checkboxsView.setAttribute("checked", "true")
                    checkboxsView.setAttribute("disabled", "true")
                  }
                  if (checkboxsAdd != null) {
                    checkboxsAdd.setAttribute("checked", "true")
                    checkboxsAdd.setAttribute("disabled", "true")
                  }
                  if (checkboxsEdit != null) {
                    checkboxsEdit.setAttribute("checked", "true")
                    checkboxsEdit.setAttribute("disabled", "true")
                  }
                  if (checkboxsDelete != null) {
                    checkboxsDelete.setAttribute("checked", "true")
                    checkboxsDelete.setAttribute("disabled", "true")
                  }
                  const hasSubmodules = module.submodules.length > 0
                  const collapseId = `panelsStayOpen-collapse${i}`
                  const headerId = `panelsStayOpen-heading${i}`
                  const moduleId = module.module_name.replace(" ", "_")
                  return (
                    <>
                      <tr key={i} className="">
                        <td className="p-0" colSpan={7}>
                          <div
                            className="accordion"
                            id="accordionPanelsStayOpenExample"
                          >
                            <div className="accordion-item accordian-box">
                              <div className="accordion-header" id={headerId}>
                                <button
                                  className={`accordion-button fw-bold accordian-btn bd-accordian-btn ${
                                    openIndex === i ? "" : "collapsed"
                                  } ${hasSubmodules ? "" : "no-after"}`}
                                  type="button"
                                  onClick={() => handleToggle(i)}
                                >
                                  {module.module_name}
                                </button>

                                <input
                                  id={`module_id`}
                                  value={module.module_id}
                                  readOnly
                                  className="display-none bd-text-center"
                                />
                                <div className="outer-toggle-btns">
                                  <div className="form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className="form-check-input first_global"
                                      id={
                                        module.module_name.replace(" ", "_") +
                                        "global"
                                      }
                                      defaultChecked={module.Global == "1"}
                                      disabled
                                      // onClick={e => {
                                      //   allGlobChecked({
                                      //     e: e,
                                      //     name: module.module_name,
                                      //     isCheck: 1,
                                      //   })
                                      // }}
                                    />
                                  </div>
                                  <div className="form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className={`form-check-input second_view`}
                                      id={
                                        module.module_name.replace(" ", "_") +
                                        "view"
                                      }
                                      defaultChecked={module.View == "1"}
                                      disabled
                                      // onClick={e => {
                                      //   allViewChecked({
                                      //     e: e,
                                      //     name: module.module_name,
                                      //     isCheck: 2,
                                      //   })
                                      // }}
                                    />
                                  </div>
                                  <div className="form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className={`form-check-input third_add ${
                                        module.module_id === 1 ? "d-none" : ""
                                      }`}
                                      id={
                                        module.module_name.replace(" ", "_") +
                                        "add"
                                      }
                                      defaultChecked={module.Add == "1"}
                                      disabled
                                      // onClick={e => {
                                      //   handleActionClick({
                                      //     e: e,
                                      //     moduleId: moduleId,
                                      //     actionType: "add",
                                      //     name: module.module_name,
                                      //     isCheck: 3,
                                      //   })
                                      // }}
                                    />
                                  </div>
                                  <div className="form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className="form-check-input fourth_edit"
                                      id={
                                        module.module_name.replace(" ", "_") +
                                        "edit"
                                      }
                                      defaultChecked={module.Edit == "1"}
                                      disabled
                                      // onClick={e => {
                                      //   handleActionClick({
                                      //     e: e,
                                      //     moduleId: moduleId,
                                      //     actionType: "edit",
                                      //     name: module.module_name,
                                      //     isCheck: 4,
                                      //   })
                                      // }}
                                    />
                                  </div>
                                  <div className="form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className={`form-check-input fifth_delete ${
                                        module.module_id === 1 ? "d-none" : ""
                                      }`}
                                      id={
                                        module.module_name.replace(" ", "_") +
                                        "delete"
                                      }
                                      defaultChecked={module.Delete == "1"}
                                      disabled
                                      // onClick={e => {
                                      //   handleActionClick({
                                      //     e: e,
                                      //     moduleId: moduleId,
                                      //     actionType: "delete",
                                      //     name: module.module_name,
                                      //     isCheck: 5,
                                      //   })
                                      // }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                id={collapseId}
                                className={`accordion-collapse collapse ${
                                  openIndex === i ? "show" : ""
                                }`}
                                aria-labelledby={`heading${i}`}
                              >
                                <div className="accordion-body p-0 ">
                                  <Table className="mb-0 bd-hover-inner bg-grey">
                                    {module.submodules.map(
                                      (innerModule, index) => {
                                        const subModuleId =
                                          innerModule.module_name.replace(
                                            " ",
                                            "_"
                                          )
                                        if (module.module_name) {
                                          var one = 0
                                          var two = 0
                                          var three = 0
                                          var four = 0
                                          var five = 0
                                          var x1 = document.querySelectorAll(
                                            "#" +
                                              module.module_name.replace(
                                                " ",
                                                "_"
                                              ) +
                                              "one"
                                          )
                                          var x2 = document.querySelectorAll(
                                            "#" +
                                              module.module_name.replace(
                                                " ",
                                                "_"
                                              ) +
                                              "two"
                                          )
                                          var x3 = document.querySelectorAll(
                                            "#" +
                                              module.module_name.replace(
                                                " ",
                                                "_"
                                              ) +
                                              "three"
                                          )
                                          var x4 = document.querySelectorAll(
                                            "#" +
                                              module.module_name.replace(
                                                " ",
                                                "_"
                                              ) +
                                              "four"
                                          )
                                          var x5 = document.querySelectorAll(
                                            "#" +
                                              module.module_name.replace(
                                                " ",
                                                "_"
                                              ) +
                                              "five"
                                          )

                                          for (var i = 0; i < x1.length; i++) {
                                            if (x1[i].checked == true) {
                                              one = one + "1"
                                            } else {
                                              one = one + "0"
                                            }
                                          }
                                          for (var i = 0; i < x2.length; i++) {
                                            if (x2[i].checked == true) {
                                              two = two + "1"
                                            } else {
                                              two = two + "0"
                                            }
                                          }
                                          // Particular Loop for Three
                                          for (var i = 0; i < x3.length; i++) {
                                            if (x3[i].checked == true) {
                                              three = three + "1"
                                            } else {
                                              three = three + "0"
                                            }
                                          }
                                          // Particular Loop for Four
                                          for (var i = 0; i < x4.length; i++) {
                                            if (x4[i].checked == true) {
                                              four = four + "1"
                                            } else {
                                              four = four + "0"
                                            }
                                          }
                                          // Particular Loop for Five
                                          for (var i = 0; i < x5.length; i++) {
                                            if (x5[i].checked == true) {
                                              five = five + "1"
                                            } else {
                                              five = five + "0"
                                            }
                                          }

                                          let percentage
                                          let Twopercentage
                                          let Threepercentage
                                          let Fourpercentage
                                          let Fivepercentage
                                          if (one.length > 0) {
                                            const onesLength = (
                                              one.substring(1).match(/1/g) || []
                                            ).length
                                            const towLength = (
                                              two.substring(1).match(/1/g) || []
                                            ).length
                                            const threeLength = (
                                              three.substring(1).match(/1/g) ||
                                              []
                                            ).length
                                            const fourLength = (
                                              four.substring(1).match(/1/g) ||
                                              []
                                            ).length
                                            const fiveLength = (
                                              five.substring(1).match(/1/g) ||
                                              []
                                            ).length
                                            percentage =
                                              (onesLength /
                                                module.submodules.length) *
                                              100
                                            Twopercentage =
                                              (towLength /
                                                module.submodules.length) *
                                              100
                                            Threepercentage =
                                              (threeLength /
                                                module.submodules.length) *
                                              100
                                            Fourpercentage =
                                              (fourLength /
                                                module.submodules.length) *
                                              100
                                            Fivepercentage =
                                              (fiveLength /
                                                module.submodules.length) *
                                              100
                                          }

                                          if (percentage >= 1) {
                                            const checkbox =
                                              document.getElementById(
                                                `${module.module_name.replace(
                                                  " ",
                                                  "_"
                                                )}global`
                                              )
                                            checkbox.defaultChecked = true
                                          } else if (percentage <= 0) {
                                            const checkbox =
                                              document.getElementById(
                                                `${module.module_name.replace(
                                                  " ",
                                                  "_"
                                                )}global`
                                              )
                                            checkbox.defaultChecked = false
                                          }

                                          if (Twopercentage >= 1) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "view"
                                                }`
                                              )
                                            checkbox.defaultChecked = true
                                          } else if (Twopercentage <= 0) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "view"
                                                }`
                                              )
                                            checkbox.defaultChecked = false
                                          }

                                          if (Threepercentage >= 1) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "add"
                                                }`
                                              )
                                            checkbox.defaultChecked = true
                                          } else if (Threepercentage <= 0) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "add"
                                                }`
                                              )
                                            checkbox.defaultChecked = false
                                          }
                                          if (Fourpercentage >= 1) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "edit"
                                                }`
                                              )
                                            checkbox.defaultChecked = true
                                          } else if (Fourpercentage <= 0) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "edit"
                                                }`
                                              )
                                            checkbox.defaultChecked = false
                                          }

                                          if (Fivepercentage >= 1) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "delete"
                                                }`
                                              )
                                            checkbox.defaultChecked = true
                                          } else if (Fivepercentage <= 0) {
                                            const checkbox =
                                              document.getElementById(
                                                `${
                                                  module.module_name.replace(
                                                    " ",
                                                    "_"
                                                  ) + "delete"
                                                }`
                                              )
                                            checkbox.defaultChecked = false
                                          }
                                        }

                                        return (
                                          <tr key={index}>
                                            <td className="border text-start ps-4 text-black">
                                              <i
                                                className="bx bx-chevrons-right"
                                                style={{
                                                  padding: "0px 5px 0px 0px",
                                                }}
                                              ></i>
                                              <input
                                                id="module_id"
                                                value={innerModule.module_id}
                                                className="display-none"
                                                readOnly
                                              />
                                              {innerModule.module_name}
                                            </td>
                                            <td className="border cell-width ">
                                              <div className=" form-switch form-switch-md text-center">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input first_global ${
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "one"
                                                  } ${subModuleId}global`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "one"
                                                  }
                                                  defaultChecked={
                                                    innerModule.Global == "1"
                                                  }
                                                  disabled
                                                  // onChange={e => {
                                                  //   checkAllSubmodule({
                                                  //     e: e,
                                                  //     name: module.module_name,
                                                  //     count: "one",
                                                  //     globe: "global",
                                                  //   })
                                                  // }}
                                                />
                                              </div>
                                            </td>
                                            <td className="border cell-width ">
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input second_view ${
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "two"
                                                  } ${subModuleId}view`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "two"
                                                  }
                                                  defaultChecked={
                                                    innerModule.View == "1"
                                                  }
                                                  disabled
                                                  // onChange={e => {
                                                  //   checkAllSubmodule({
                                                  //     e: e,
                                                  //     name: module.module_name,
                                                  //     count: "two",
                                                  //     globe: "view",
                                                  //   })
                                                  // }}
                                                />
                                              </div>
                                            </td>
                                            <td className="border cell-width">
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input third_add ${
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "three"
                                                  } ${subModuleId}`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "three"
                                                  }
                                                  defaultChecked={
                                                    innerModule.Add == "1"
                                                  }
                                                  disabled
                                                  // onChange={e => {
                                                  //   handleActionSubClick({
                                                  //     e: e,
                                                  //     subModuleId: subModuleId,
                                                  //     actionType: "add",
                                                  //     name: module.module_name,
                                                  //     count: "three",
                                                  //     globe: "add",
                                                  //   })
                                                  // }}
                                                />
                                              </div>
                                            </td>
                                            <td className="border cell-width">
                                              <div className=" form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input fourth_edit ${
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "four"
                                                  }`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "four"
                                                  }
                                                  defaultChecked={
                                                    innerModule.Edit == "1"
                                                  }
                                                  disabled
                                                  // onChange={e => {
                                                  //   handleActionSubClick({
                                                  //     e: e,
                                                  //     subModuleId: subModuleId,
                                                  //     actionType: "edit",
                                                  //     name: module.module_name,
                                                  //     count: "four",
                                                  //     globe: "edit",
                                                  //   })
                                                  // }}
                                                />
                                              </div>
                                            </td>
                                            <td className="border cell-width">
                                              <div className=" form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input fifth_delete ${
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "five"
                                                  }`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "five"
                                                  }
                                                  defaultChecked={
                                                    innerModule.Delete == "1"
                                                  }
                                                  disabled
                                                  // onChange={e => {
                                                  //   handleActionSubClick({
                                                  //     e: e,
                                                  //     subModuleId: subModuleId,
                                                  //     actionType: "delete",
                                                  //     name: module.module_name,
                                                  //     count: "five",
                                                  //     globe: "delete",
                                                  //   })
                                                  // }}
                                                />
                                              </div>
                                            </td>
                                            <td className="cell-width"></td>
                                          </tr>
                                        )
                                      }
                                    )}
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Table>
          </div>
        {/* </form> */}
      </div>
    </>
  )
}

export default PermissionView
