import React, { useMemo } from "react";
import { Form } from "reactstrap";
import {
  TeamsPlanClolumns,
  todayPlanClolumns,
  YesterDayPlanClolumns,
} from "utils/columns/Dashboard/dashboardColumns";
import { groupAndMerge, sumTimes } from "utils/common-helpers";
import { CommonTableContainer } from "utils/CommonTableContainer";

const PlanMyDay = ({
  selectedPlanedTask,
  plannedData,
  refData,
  onEditTask,
  onDeleteTask,
  handleStatus,
  FollowupStatus,
  onAddHours,
  onFollowTask,
}) => {
  const column1 = useMemo(
    () =>
      todayPlanClolumns(
        onEditTask,
        onDeleteTask,
        handleStatus,
        FollowupStatus,
        onAddHours,
        onFollowTask
      ),
    [selectedPlanedTask]
  );
  const column2 = useMemo(() => YesterDayPlanClolumns(onAddHours), [selectedPlanedTask]);
  const column3 = useMemo(() => TeamsPlanClolumns(onEditTask), [selectedPlanedTask])
  const Projects = plannedData?.today_report?.work || [];
  const Sales = plannedData?.today_report?.sales || [];
  const HumanResource = plannedData?.today_report?.["human-resource"] || [];
  const Finance = plannedData?.today_report?.finance || [];
  const Other = plannedData?.today_report?.other || [];
  const FollowUp = plannedData?.today_followup || [];
  const FollowUpYt = plannedData?.yesterday_followup || [];
  const total = [...Projects, ...Sales, ...HumanResource, ...Finance, ...Other];
  const ProjectsYt = plannedData?.yesterday_report?.work || [];
  const SalesYt = plannedData?.yesterday_report?.sales || [];
  const HumanResourceYt =
    plannedData?.yesterday_report?.["human-resource"] || [];
  const FinanceYt = plannedData?.yesterday_report?.finance || [];
  const OtherYt = plannedData?.yesterday_report?.other || [];
  const totalYt = [
    ...ProjectsYt,
    ...SalesYt,
    ...HumanResourceYt,
    ...FinanceYt,
    ...OtherYt,
  ];
  const ProjectsAT = plannedData?.teams_activity?.work || []
  const SalesAT = plannedData?.teams_activity?.sales || []
  const HumanResourceAT = plannedData?.teams_activity?.["human-resource"] || []
  const FinanceAT = plannedData?.teams_activity?.finance || []
  const OtherAT = plannedData?.teams_activity?.other || []
  const FollowUpAT = plannedData?.followup_activity	 || []
  const totalAT = [
    ...ProjectsAT,
    ...SalesAT,
    ...HumanResourceAT,
    ...FinanceAT,
    ...OtherAT,
  ]

  return (
    <div className="form-container plan-right-tab">
      <Form className="row d-flex justify-content-center align-items-center">
        {selectedPlanedTask == "Td" ? (
          <div className="d-flex flex-column pln_Day_popup">
            <div className="ps-3 mb-4 ">
              <h5 className="total-est mb-4 pb-2">
                Total Estimated Time : {sumTimes(total, "estimate_time")}
                hrs
              </h5>
              {Projects?.length != 0 && (
                <>
                  {groupAndMerge(Projects)?.map((obj, i) => {
                    return (
                      <div className="mb-4" key={i}>
                        <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                          <h4 className="mb-0">
                            {obj.related_name != "-"
                              ? obj.related_name
                              : obj?.related_to}
                          </h4>
                        </div>
                        <CommonTableContainer
                          columns={column1}
                          data={obj?.list}
                          status={true}
                        />
                      </div>
                    );
                  })}
                </>
              )}
              {Sales?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Sales Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column1}
                    data={Sales}
                    status={true}
                  />
                </div>
              )}
              {HumanResource?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Human Resource Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column1}
                    data={HumanResource}
                    status={true}
                  />
                </div>
              )}
              {Finance?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Finance Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column1}
                    data={Finance}
                    status={true}
                  />
                </div>
              )}
              {Other?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Others Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column1}
                    data={Other}
                    status={true}
                  />
                </div>
              )}
              {FollowUp?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Follow Ups</h4>
                  </div>
                  <CommonTableContainer
                    columns={column1}
                    data={FollowUp}
                    status={true}
                  />
                </div>
              )}
            </div>
          </div>
        ) : selectedPlanedTask == "Yd" ? (
          <div className="d-flex flex-column pln_Day_popup">
            <div className="ps-3 mb-4">
              <div className="d-flex justify-content-end gap-4">
                <h5 className="total-est">
                  Total Estimated Time : {sumTimes(totalYt, "estimate_time")}
                  hrs
                </h5>
                <h5 className="total-est">
                  Total Actual Time : {sumTimes(totalYt, "spent_hours")}
                  hrs
                </h5>
              </div>
              {ProjectsYt?.length != 0 && (
                <>
                  {groupAndMerge(ProjectsYt)?.map((obj, i) => {
                    return (
                      <div className="mb-4" key={i}>
                        <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                          <h4 className="mb-0 text-capitalize">
                            {obj.related_name != "-"
                              ? obj.related_name
                              : obj?.related_to}
                          </h4>
                        </div>
                        <CommonTableContainer
                          columns={column2}
                          data={obj?.list}
                          status={true}
                        />
                      </div>
                    );
                  })}
                </>
              )}
              {SalesYt?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Sales Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column2}
                    data={SalesYt}
                    status={true}
                  />
                </div>
              )}
              {HumanResourceYt?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Human Resource Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column2}
                    data={HumanResourceYt}
                    status={true}
                  />
                </div>
              )}
              {FinanceYt?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Finance Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column2}
                    data={FinanceYt}
                    status={true}
                  />
                </div>
              )}
              {OtherYt?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Others Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column2}
                    data={OtherYt}
                    status={true}
                  />
                </div>
              )}
              {FollowUpYt?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Follow Ups</h4>
                  </div>
                  <CommonTableContainer
                    columns={column2}
                    data={FollowUpYt}
                    status={true}
                  />
                </div>
              )}
            </div>
          </div>
        ) : selectedPlanedTask == "TA" ? (
          <div className="d-flex flex-column pln_Day_popup">
            <div className="ps-3 mb-4">
              <div className="d-flex justify-content-end gap-4">
                <h5 className="total-est">
                  Total Estimated Time : {sumTimes(totalAT, "estimate_time")}
                  hrs
                </h5>
                <h5 className="total-est">
                  Total Actual Time : {sumTimes(totalAT, "spent_hours")}
                  hrs
                </h5>
              </div>
              {ProjectsAT?.length != 0 && (
                <>
                  {groupAndMerge(ProjectsAT)?.map((obj, i) => {
                    return (
                      <div className="mb-4" key={i}>
                        <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                          <h4 className="mb-0 text-capitalize">
                            {obj.related_name != "-"
                              ? obj.related_name
                              : obj?.related_to}
                          </h4>
                        </div>
                        <CommonTableContainer
                          columns={column3}
                          data={obj?.list}
                          status={true}
                        />
                      </div>
                    )
                  })}
                </>
              )}
              {SalesAT?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Sales Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column3}
                    data={SalesAT}
                    status={true}
                  />
                </div>
              )}
              {HumanResourceAT?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Human Resource Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column3}
                    data={HumanResourceAT}
                    status={true}
                  />
                </div>
              )}
              {FinanceAT?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Finance Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column3}
                    data={FinanceAT}
                    status={true}
                  />
                </div>
              )}
              {OtherAT?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Others Tasks</h4>
                  </div>
                  <CommonTableContainer
                    columns={column3}
                    data={OtherAT}
                    status={true}
                  />
                </div>
              )}
              {FollowUpAT?.length != 0 && (
                <div className="mb-4">
                  <div className="col-12 px-2 d-flex justify-space-between align-items-center mb-2">
                    <h4 className="mb-0">Follow Ups</h4>
                  </div>
                  <CommonTableContainer
                    columns={column3}
                    data={FollowUpAT}
                    status={true}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
      </Form>
    </div>
  );
};

export default PlanMyDay;
