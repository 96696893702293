import React, { useEffect, useState } from "react"
import {Row } from "reactstrap"


const Education = ({ staffData, verticalActiveTab }) => {
  const [inputFields_1, setInputFields1] = useState([{}])
  useEffect(() => {
    if (verticalActiveTab == "4") {
      let educationGroup;
      if (staffData?.education_group != undefined) {
        educationGroup = JSON.parse(staffData.education_group)
      }
      if (educationGroup !== null) {
        setInputFields1(educationGroup)
      }
    }
  }, [verticalActiveTab])

  return (
    <>
      <div className="repeater">
        <div className="p-4 pb-0 bg-white shadow">
          <Row>
            <div className="col-md-6">
              <h4 className="text-dark">Education</h4>
            </div>
          </Row>
        </div>
        {(inputFields_1 || []).map((formRow, key) => (
          <div key={key} className="mb-3 p-4 bg-white shadow">
            <div className="row align-items-end">
              <div className="col-md-5 mb-3">
                <label htmlFor="">Board/Institute</label>
                <input
                  type="text"
                  placeholder="Enter Board/Institute"
                  className="form-control"
                  name="board"
                  value={formRow.board || ""}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Field</label>
                <input
                  type="text"
                  placeholder="Enter Field"
                  className="form-control"
                  name="field"
                  value={formRow.field || ""}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mb-3">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="">Year</label>
                    <input
                      type="text"
                      placeholder="year"
                      className="form-control"
                      name="year"
                      value={formRow.year || ""}
                      disabled={true}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="">Percentage</label>
                    <input
                      type="text"
                      placeholder="%"
                      className="form-control"
                      name="class"
                      value={formRow.class || ""}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Education
