import React, { useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import indiaFlag from "../../../assets/images/flags/india.png"
import ukFlag from "../../../assets/images/flags/uk.png"
import { RelatedToOption } from "utils/common-options";
import configure from "configure";
import sideImg from "../../../assets/images/profile.png"
import moment from "moment";
import Swal from "sweetalert2";
import { checkPermissionsByModule } from "utils/common-helpers";
import { useSelector } from "react-redux";

export const customerColumns = (onViewPermission, onEditPermission, isPaused, setAddTask, setIsPaused, onDelete) => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center hosting_id",
    },
    {
        Header: "Location",
        title: "location",
        accessor: "location",
        isInfoIcon: false,
        isShortIcon: true,
        className: "glocation_name_box text-center",
        Cell: cellProps => {
            const location = cellProps.data[cellProps.row.id].location
            return (
                <div>
                    <img
                        src={location === "India" ? indiaFlag : ukFlag}
                        value={location}
                        className="location-img"
                        alt="flag"
                    />
                </div>
            )
        },
    },
    {
        Header: "Customer",
        title: "Customer",
        accessor: "customer",
        isInfoIcon: false,
        isShortIcon: true,
        className: "hosting_customer_name_box",
    },
    {
        Header: "Package",
        title: "Package",
        accessor: "package",
        isInfoIcon: false,
        isShortIcon: true,
        className: "package_box ",
    },
    {
        Header: "Package Price",
        title: "Package Price",
        accessor: "package_price",
        isInfoIcon: false,
        isShortIcon: true,
        className: "package_price_box text-center",
    },
    {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box",
    },
    {
        Header: "Renewal Duration",
        title: "Renewal Duration",
        accessor: "renewal_duration",
        isInfoIcon: false,
        isShortIcon: true,
        className: "renewal_duration_box",
    },
    {
        Header: "Due Date",
        title: "Due Date",
        accessor: "due_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "due_date_box",
    },
    {
        Header: "Action",
        accessor: "action",
        className: "project_action text-center",
        Cell: cellProps => (
            <div className="d-flex justify-content-center">
                <a
                    className="btn btn-sm btn-outline-secondary me-1"
                    title="View Project"
                    onClick={() => onViewPermission()}
                >
                    <i className="fas fa-eye"></i>
                </a>

                <a
                    //   onClick={onEditPermission}
                    className="btn btn-sm btn-outline-secondary me-1"
                    title="Edit Project"
                >
                    <i className="fas fa-pen"></i>
                </a>

                <UncontrolledDropdown
                    className="dropdown"
                    style={{ width: "29px" }}
                >
                    <DropdownToggle
                        className="btn btn-sm btn-outline-secondary w-100"
                        color="white"
                    >
                        <i className="mdi mdi-dots-horizontal"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <a
                            title="Add Task"
                            className="dropdown-item"
                        //   onClick={() => setAddTask(true)}
                        >
                            <i className="bi bi-list-check pr-10"></i> Add Task
                        </a>

                        {isPaused ? (
                            <a
                                title="Play Task"
                                className="dropdown-item"
                            // onClick={() => setIsPaused(false)}
                            >
                                <i className="bi bi-play-circle-fill pr-10"></i> Play
                                Project
                            </a>
                        ) : (
                            <a
                                title="Pause Task"
                                className="dropdown-item"
                            // onClick={() => setIsPaused(true)}
                            >
                                <i className="bi bi-pause-circle-fill pr-10"></i> Pause
                                Project
                            </a>
                        )}
                        <a
                            title="Move Project"
                            className="dropdown-item"
                        //   onClick={() => setAddTask(true)}
                        >
                            <i className="fas fa-cogs pr-10"></i> Move Project
                        </a>
                        <a
                            title="Delete Project"
                            className="dropdown-item"
                        //   onClick={() => onDelete()}
                        >
                            <i className="fas fa-trash-alt text-danger pr-10"></i> Delete
                            Project
                        </a>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        ),
    },
];


export const generaltaskColumns = (handleAddHour, onEditPermission, onDelete, handleChangeStatus, openAccessDenied) => [
    {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "g_id",
        Cell: cellProps => {
            return <div className="text-center">{cellProps.row.original.id}</div>
        },
    },
    {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "assignedg_name_box",
        Cell: cellProps => {

            return (
                <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
                    <img
                        src={`${cellProps?.row?.original?.img ? `${configure?.imgUrl}${cellProps?.row?.original?.img}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title="Assignee"
                    />
                </div>
            )
        },
    },
    {
        Header: "Location",
        title: "location",
        accessor: "location",
        isInfoIcon: false,
        isShortIcon: true,
        className: "glocation_name_box",
        Cell: cellProps => {
            const location = cellProps?.row?.original?.location
            return (
                <div>
                    <img
                        src={location == "1" ? indiaFlag : ukFlag}
                        value={location}
                        className="location-img"
                        alt="flag"
                    />
                </div>
            )
        },
    },

    {
        Header: "Task Name",
        title: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "gtask_name_box",
    },
    {
        Header: "Related To",
        title: "Related To",
        accessor: "related",
        isInfoIcon: false,
        isShortIcon: true,
        className: "grelatedto",
        Cell: cellProps => {
            return <div>{RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label || "-"}</div>
        }
    },
    {
        Header: "Due Date",
        title: "enddate",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "gdue-date",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.enddate ? moment(cellProps?.row?.original?.enddate).format('DD-MM-YYYY') : "-"}</div>
            )
        }
    },
    {
        Header: "Priority",
        title: "Priority",
        accessor: "priorty",
        isInfoIcon: false,
        isShortIcon: true,
        className: "gprioritys_col_box ",
        Cell: cellProps => {
            const cellValue = cellProps.row.original?.priorty
            let style = {}

            if (cellValue == "high") {
                style = { color: "rgb(255, 87, 51)" }
            } else if (cellValue === "medium") {
                style = { color: "rgb(253, 171, 153)" }
            } else if (cellValue === "low") {
                style = { color: "rgb(74, 179, 255)" }
            }

            return <div style={style} className="text-capitalize">{cellValue || "-"}</div>
        },
    },
    {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "gstatus_box",
        Cell: cellProps => {
            const roles = useSelector(state => state?.Roles?.apiData)
            const [status_value, setStatusValue] = useState(cellProps.row.original.status || '');
            const changeStatus = async (e, id) => {
                const permission = checkPermissionsByModule(roles, 8, 4)
                if (permission) {
                    const newValue = e.target.value;
                    const userConfirmed = await Swal.fire({
                        title: "Are you sure?",
                        text: "Do you want to change the status?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes, change it!",
                        cancelButtonText: "No, cancel",
                    });

                    if (userConfirmed.isConfirmed) {
                        handleChangeStatus(newValue, id);
                        setStatusValue(newValue);
                    } else {
                        e.target.value = status_value;
                    }
                } else {
                    openAccessDenied(true)
                }
            }
            return (
                <div className="custom-select-wrapper" style={{ width: "100%" }}>
                    <select
                        className={`form-select custom-select`}
                        onChange={(e) => changeStatus(e, cellProps.row.original.id)}
                        value={cellProps.row.original.status}
                    >
                        <option value="" className="">
                            - Select -
                        </option>
                        <option value="not-started" className="">
                            Not Started
                        </option>
                        <option value="in-progress" className="">
                            In Progress
                        </option>
                        <option value="complete" className="">
                            Complete
                        </option>
                    </select>
                </div>
            )
        },
    },
    {
        Header: "Spent Hours",
        title: "Spent Hours",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "gspent_hours-box text-start",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
    {
        Header: "Action",
        accessor: "action",
        className: "gtask_action custom-text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a
                        onClick={() => handleAddHour(cellProps.row.original.id, cellProps.row.original.related_to, cellProps.row.original.related_id)}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Add Hours"
                    >
                        <i className="bi bi-alarm-fill"></i>
                    </a>
                    <a
                        onClick={() => onEditPermission(cellProps?.row?.original)}
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Task"
                    >
                        <i className="fas fa-pen "></i>
                    </a>
                    <UncontrolledDropdown
                        className="dropdown"
                        style={{ width: "29px" }}
                    >
                        <DropdownToggle
                            className="btn btn-sm btn-outline-secondary w-100"
                            color="white"
                        >
                            <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <a
                                className="dropdown-item"
                                title="Delete Task"
                                onClick={() => onDelete(cellProps.row.original?.id)}
                            >
                                <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                Delete Task
                            </a>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        },
    },
]

export const TaskSettingColumns = (onDelete, onEditTask) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "gt_id",
        Cell: cellProps => {
            // console.log()
            return (
                <div>{cellProps?.row?.id}</div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "task",
        isInfoIcon: false,
        isShortIcon: true,
        className: "gt_name",
    },
    {
        Header: "Action",
        accessor: "action",
        className: "gt_action",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Task"
                        onClick={() => onEditTask(cellProps.row.original)}
                    >
                        <i className="fas fa-pen "></i>
                    </a>
                    <a
                        className="btn btn-sm btn-danger ms-2"
                        title="Delete Task"
                        onClick={() => onDelete(cellProps.row.original.id)}

                    >
                        <i className="fas fa-trash"></i>
                    </a>
                </div>
            )
        },
    },

]

export const AccessActivityColumns = (onDelete, onEditTask) => [
    {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "acc_id",
    },
    {
        Header: "Members",
        accessor: "staff_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "acc_name",
    },
    {
        Header: "Action",
        accessor: "acc_action",
        className: "acc_action",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a
                        className="btn btn-sm btn-outline-secondary me-1"
                        title="Edit Access Activity"
                        onClick={() => onEditTask(cellProps?.row?.original)}
                    >
                        <i className="fas fa-pen "></i>
                    </a>
                    <a
                        className="btn btn-sm btn-danger ms-2"
                        title="Delete Access Activity"
                        onClick={() => onDelete(cellProps?.row?.original?.id)}
                    >
                        <i className="fas fa-trash"></i>
                    </a>
                </div>
            )
        },
    },
]
