import moment from "moment"
import React from "react";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

export const staffColumns = (statusChange, onViewModal, onEditPermission, onClearLogin, onDeletePermission) => [
  {
    Header: "Emp Id ",
    accessor: "employee_code",
    isInfoIcon: false,
    isShortIcon: true,
    className: "employee_code"
  },
  {
    Header: "Name",
    accessor: "name",
    isInfoIcon: true,
    isShortIcon: true,
    className: "employee_name"
  },
  {
    Header: "Designation",
    accessor: "designation",
    isInfoIcon: true,
    isShortIcon: true,
    className: "employee_designation"
  },
  {
    Header: "Phone no",
    accessor: "phone_number",
    isInfoIcon: true,
    isShortIcon: true,
    className: "employee_phone"

  },
  {
    Header: "Email Id",
    accessor: "email",
    isInfoIcon: true,
    isShortIcon: true,
    className: "employee_email"
  },
  {
    Header: "Joining Date",
    accessor: "date_of_joining",
    isInfoIcon: false,
    isShortIcon: true,
    className: "employee_date_of_joining",
    Cell: cellProps => {
      return (
        <span>
          {moment(cellProps.row.original.date_of_joining).format(
            "DD-MM-YYYY"
          )}
        </span>
      )
    },
  },
  {
    Header: "Status",
    accessor: "status",
    className: "employee_status text-center",
    Cell: cellProps => {

      return (
        <div className="form-switch form-switch-md">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            defaultChecked={cellProps.row.original.login_access == "1"}
            onChange={e =>
              statusChange(
                e,
                cellProps.row.original.id,
                e.target.checked ? 1 : 0
              )
            }
          />
        </div>
      )
    },
    isInfoIcon: true,
  },
  {
    Header: "Action",
    accessor: "action",
    className: "employee_action",
    Cell: cellProps => {
      return (
        <div className="d-flex justify-content-center">
          <a
            className="btn btn-sm action-btn btn-outline-secondary me-1"
            onClick={() => {
              onViewModal(true, cellProps.row.original.id)
            }}
          >
            <i className="fas fa-eye"></i>
          </a>
          <Link
            onClick={() =>
              onEditPermission({
                id: cellProps.row.original.id,
                isEditType: 1,
              })
            }
            className="btn action-btn btn-sm me-1 btn-outline-secondary"
          >
            <i className="fas fa-pencil-alt"></i>
          </Link>

          <UncontrolledDropdown
            className="dropdown"
            style={{ width: "29px" }}
          >
            <DropdownToggle
              className="btn btn-sm btn-outline-secondary w-100"
              color="white"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <a
                onClick={() =>
                  onClearLogin({ id: cellProps.row.original.id })
                }
                className="dropdown-item"
                title="Clear Login"
              >
                <i className="fa fa-history pr-10"></i> Clear Login
              </a>
              <a
                onClick={() =>
                  onDeletePermission({ id: cellProps.row.original.id })
                }
                className="dropdown-item"
                title="delete"
              >
                <i className="fas fa-trash-alt pr-10 text-danger"></i>{" "}
                Delete Staff
              </a>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )
    },
  },
]

export const SwipeColumns = (onEditOpen, onInfoOpen, onDeletSwipe) => [
  {
    Header: "ID",
    accessor: "id",
    isInfoIcon: false,
    className: "swipe_id",
    isShortIcon: true,

  },
  {
    Header: "Swipes",
    accessor: "reason",
    grow: false,
    className: "swipe_name",
    isShortIcon: true,
  },
  {
    Header: "Status",
    accessor: "status",
    grow: false,
    className: "swipe_status",
    Cell: cellProps => {
      const swipeStatus = cellProps.row?.original.status
      let badge;
      if (swipeStatus == 0) {
        badge = <span className="badge bg-warning fs-14">Pending</span>
      } else if (swipeStatus == 1) {
        badge = <span className="badge bg-success fs-14">Approved</span>
      } else if (swipeStatus == 2) {
        badge = <span className="badge bg-danger fs-14">Rejected</span>
      }
      return (
        <div>{badge}</div>
      )
    }
  },
  {
    Header: "Applied Date",
    accessor: "date",
    grow: false,
    className: "swipe_date text-center",
    isShortIcon: true,
    Cell: cellProps => {
      return (
        <div>{moment(cellProps?.row?.original?.date).format('DD-MM-YYYY')}</div>
      )
    }
  },
  {
    Header: "Action",
    accessor: "swipe_action",
    className: "text-center swipe_action",
    Cell: cellProps => {
      return (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-sm me-1 btn-outline-secondary"
            title="Edit Swipe"
            onClick={() => onEditOpen(cellProps.row.original)}
          >
            <i className="fa fa-pencil-alt"></i>
          </button>
          <button
            className="btn btn-sm me-1 btn-outline-secondary"
            title="Delete Swipe"
            onClick={() => onDeletSwipe(cellProps.row.original?.id)}
          >
            <i className="fa fa-trash-alt text-danger"></i>
          </button>
          {cellProps?.row?.original?.status == 2 ? (
            <button
              className="btn btn-sm me-1 btn-outline-secondary"
              title="Info"
              onClick={() => onInfoOpen(cellProps.row.original?.reason)}
            >
              <i className="fa fa-info"></i>
            </button>
          ) : null}
        </div>
      )
    },
  },
]

export const IssuesColumns = (onEditOpen, onDeletIssue, onOpenUnapprove) => [
  {
    Header: "ID",
    accessor: "id",
    isInfoIcon: false,
    className: "issues_id",
    isShortIcon: true,
  },
  {
    Header: "Issues",
    accessor: "reason",
    grow: false,
    className: "issues_name",
    isShortIcon: true,
  },
  {
    Header: "Status",
    accessor: "status",
    grow: false,
    className: "issues_status",
    Cell: cellProps => {
      const swipeStatus = cellProps.row?.original.status
      let badge;
      if (swipeStatus == 0) {
        badge = <span className="badge bg-warning fs-14">Pending</span>
      } else if (swipeStatus == 1) {
        badge = <span className="badge bg-success fs-14">Approved</span>
      } else if (swipeStatus == 2) {
        badge = <span className="badge bg-danger fs-14">Rejected</span>
      }
      return (
        <div>{badge}</div>
      )
    }
  },

  {
    Header: "Action",
    accessor: "issues_action",
    className: "text-center issues_action",
    Cell: cellProps => {
      return (
        <div className="d-flex justify-content-start">

          <button
            className="btn btn-sm me-1 btn-outline-secondary"
            title="Edit Issue"
            onClick={() => onEditOpen(cellProps.row?.original)}
          >
            <i className="fa fa-pencil-alt"></i>
          </button>


          <button
            className="btn btn-sm me-1 btn-outline-secondary"
            title="Delete Issue"
            onClick={() => onDeletIssue(cellProps.row?.original?.id)}
          >
            <i className="fa fa-trash-alt text-danger"></i>
          </button>
          {cellProps?.row?.original?.status != 0 ? (
            <button
              className="btn btn-sm me-1 btn-outline-secondary"
              title="Info"
              onClick={() => onOpenUnapprove(cellProps.row?.original?.status, cellProps.row?.original?.final_decision, cellProps.row?.original?.approve_reason)}
            >
              <i className="fa fa-info"></i>
            </button>
          ) : null}

        </div>
      )
    },
  },
]