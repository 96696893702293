import React, { useState, useEffect } from "react";
import { Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import ReactSelect from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  priorityOption,
  RelatedToOption,
  TimeOption,
} from "utils/common-options";
import Swal from "sweetalert2";
import { FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";

const EditTaskTab = ({
  verticalActiveTab,
  editData,
  getLocations,
  alldependency,
  onCloseModal,
  toggleLoading,
  updateData,
  relatedData,
  task,
}) => {
  const [formRows, setFormRows] = useState([{}]);
  const [title, settitle] = useState("");
  const [location, setLocation] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [assigned, setAssigned] = useState([]);
  const [priority, setPriority] = useState([]);
  const [description, setDescription] = useState("");
  const [related_to, setRelatedTo] = useState([]);
  const [relatedId, setRelatedId] = useState([]);
  const [relatedOption, setRelatedOption] = useState([]);
  const [relatedSelected, setRelatedSelected] = useState([]);
  const [relatedIdOption, setRelatedIdOption] = useState([]);
  const [estimated, setEstimatedTime] = useState(null);

  useEffect(() => {
    settitle(editData?.title);
    setLocation(getLocations?.find((loc) => loc.value == editData?.location));
    setStartDate(editData?.startdate);
    setEndDate(editData?.enddate);
    setDescription(editData?.description || "");
    setPriority(priorityOption?.find((pro) => pro.value == editData?.priorty));
    const ids = editData?.assigned?.split(",").map(Number);
    const assignee = alldependency?.users?.filter((item) =>
      ids?.includes(item.value)
    );
    setEstimatedTime(
      TimeOption?.find((obj) => obj.value == editData?.estimate_time)
    );
    setAssigned(assignee);
    const relatedOption = RelatedToOption?.find(
      (rel) => rel.value == editData?.related
    );
    setRelatedSelected(relatedOption);
    setRelatedOption(relatedOption?.related);
    const realtedTo = relatedOption?.related?.find(
      (obj) => obj.value == editData?.related_to
    );
    setRelatedTo(realtedTo);
    const relatedOptions =
      realtedTo?.value == "lead"
        ? alldependency?.leads
        : realtedTo?.value == "proposal"
        ? alldependency?.proposal
        : realtedTo?.value == "digital-project"
        ? alldependency?.digital_projects
        : realtedTo?.value == "development-project"
        ? alldependency?.projects
        : realtedTo?.value == "introducer"
        ? alldependency.introducer
        : realtedTo?.value == "staff"
        ? alldependency.staff
        : [];
    setRelatedIdOption(relatedOptions);
    setRelatedId(
      relatedOptions?.find((obj) => obj.value == editData?.related_id)
    );
    // setForm
    const tasks = task?.find((obj) => obj.value == editData?.title);
    if (editData?.subtasks?.length == 0) {
      if (tasks != undefined) {
        const sub = tasks?.subtasks?.split("#@");
        setFormRows(
          sub?.map((obj) => ({
            task_name: obj,
            task_assign: "",
            task_status: 0,
            sub_enddate: "",
          }))
        );
      } else {
        setFormRows([]);
      }
    } else {
      setFormRows(
        editData?.subtasks?.map((obj) => ({
          task_name: obj?.title,
          task_id: obj?.id,
          task_assign: assignee?.find((user) => user.value == obj?.assign),
          task_status: obj?.status,
          sub_enddate: obj?.enddate,
        }))
      );
    }
  }, [getLocations]);

  useEffect(() => {
    if (Object.keys(relatedData).length != 0) {
      const related = RelatedToOption?.find(
        (obj) => obj.id == verticalActiveTab
      );
      setRelatedOption(related?.related);
      setRelatedSelected(related);
      setRelatedId([]);
      setRelatedIdOption([]);
      setRelatedTo([]);
    }
  }, [relatedData]);

  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1200,
    }),
  };

  const onAddFormRow = () => {
    setFormRows([...formRows, { task_id: 0, task_status: 0 }]);
  };

  const onDeleteFormRow = (index) => {
    const newInputs = [...formRows];
    newInputs.splice(index, 1);
    setFormRows(newInputs);
  };

  const handleInputChange = (key, event) => {
    const { name, value } = event?.target;
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, [name]: value } : row
    );
    setFormRows(updatedFormRows);
  };

  const handleSelectChange = (key, selectedOption) => {
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, task_assign: selectedOption } : row
    );
    setFormRows(updatedFormRows);
  };
  const handleEndChange = (key, e) => {
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, sub_enddate: e } : row
    );
    setFormRows(updatedFormRows);
  };

  const handleCheckboxChange = (key) => {
    const updatedRows = formRows.map((person, index) => {
      if (index === key) {
        return {
          ...person,
          task_status: person.task_status === 1 ? 0 : 1, // Toggle between 0 and 1
        };
      }
      return person;
    });
    setFormRows(updatedRows);
  };

  const onHandleEdit = async (e) => {
    e.preventDefault();
    const subtask_id = formRows.some((item) => item.hasOwnProperty("task_id"));
    const formDatas = {
      general_id: editData?.id,
      user_id: localStorage.getItem("auth_id"),
      location: location?.value,
      title: title,
      startdate: startDate,
      enddate: endDate,
      assigned: assigned?.map((obj) => obj.value)?.join(","),
      priority: priority?.value,
      related: relatedSelected?.value,
      related_to: related_to?.value,
      related_id: relatedId?.value,
      description: description,
      subtask: formRows?.map((obj) => obj?.task_name)?.join("#@"),
      subtask_assign: formRows
        ?.map((obj) => obj?.task_assign?.value)
        ?.join(","),
      subtask_id: formRows?.map((obj) => obj?.task_id)?.join(","),
      subtask_status: formRows?.map((obj) => obj?.task_status)?.join(","),
      sub_enddate: formRows?.map(obj => obj?.sub_enddate)?.join(","),
      edit_status: subtask_id ? 1 : 0,
      est_time: estimated?.value,
      type: 1,
    };
    const response = await FetchPostData(
      toggleLoading,
      "editGeneralTasks",
      secureLocalStorage.getItem("auth_token"),
      formDatas
    );
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "General task edited successfully.",
      }).then(() => {
        onCloseModal();
        updateData(response?.data?.data?.tasks);
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="text-end add-task-button">
        <button
          type="submit"
          className=" btn ms-md-2 btn-save btn-label"
          onClick={onHandleEdit}
        >
          <i className="fas fa-save me-1 label-icon"></i> Save
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onCloseModal();
          }}
          className=" btn ms-md-2 btn-red btn-label"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          CANCEL
        </button>
      </div>
      <form className="row justify-content-center align-items-center">
        <div className="col-md-12 d-flex flex-column new-task-modal ">
          <div className="col-md-12 d-flex flex-column new-task-modal ">
            <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
              <div className="col-sm-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">
                  Location <span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select Location"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  options={getLocations}
                  value={location}
                  onChange={(e) => setLocation(e)}
                />
              </div>
              <div className="col-md-10 ps-2">
                <Label htmlFor="formrow-firstname-Input">
                  Task Name <span className="text-danger">*</span>
                </Label>
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <Input
                    required
                    type="text"
                    className="form-control"
                    placeholder="Enter Task Name"
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
              <div className="col-md-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">Start Date</Label>
                <Input
                  type="date"
                  className="form-control"
                  placeholder="Select Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-md-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">End Date</Label>
                <Input
                  type="date"
                  className="form-control"
                  placeholder="Select Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className="col-md-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">Estimated Time</Label>
                <Select
                  placeholder="Select Time"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={TimeOption}
                  onChange={(e) => setEstimatedTime(e)}
                  value={estimated}
                />
              </div>
              <div className="col-md-4 ps-2">
                <Label htmlFor="formrow-firstname-Input">
                  Assignee <span className="text-danger">*</span>
                </Label>
                <ReactSelect
                  placeholder="Select Member"
                  className="basic-multi-select "
                  classNamePrefix="select"
                  isMulti
                  options={alldependency?.users}
                  value={assigned}
                  onChange={(e) => setAssigned(e)}
                  closeMenuOnSelect={false}
                />
              </div>
              <div className="col-md-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">
                  Priority <span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select Priority"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  options={priorityOption}
                  value={priority}
                  onChange={(e) => setPriority(e)}
                />
              </div>
            </div>
            <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
              {relatedOption?.length != 0 && (
                <div className="col-md-4 ps-2">
                  <label htmlFor="sales-dropdown">
                    {relatedSelected?.label}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder={`Select ${relatedSelected?.label}`}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={colourStyles}
                    options={relatedOption}
                    value={related_to}
                    onChange={(selectedOption) => {
                      setRelatedTo(selectedOption);
                      const relatedOptions =
                        selectedOption?.value == "lead"
                          ? alldependency?.leads
                          : selectedOption?.value == "proposal"
                          ? alldependency?.proposal
                          : selectedOption?.value == "digital-project"
                          ? alldependency?.digital_projects
                          : selectedOption?.value == "development-project"
                          ? alldependency?.projects
                          : selectedOption?.value == "introducer"
                          ? alldependency.introducer
                          : selectedOption?.value == "staff"
                          ? alldependency.staff
                          : [];
                      setRelatedIdOption(relatedOptions);
                    }}
                  />
                </div>
              )}

              {relatedIdOption?.length != 0 && (
                <div className="col-md-6 ps-2">
                  <label htmlFor="lead-dropdown">
                    {related_to?.label} <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder={`Select ${related_to?.label} `}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={colourStyles}
                    options={relatedIdOption}
                    value={relatedId}
                    onChange={(e) => {
                      setRelatedId(e);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="col-md-12 d-flex mb-0 justify-content-start align-items-end flex-column gap-4">
              <div className="col-12 ps-2">
                <label htmlFor="">Description</label>
                <div className="no-drag">
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {}}
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 pe-2 form-container mt-4 ms-2 ">
              <Row className="bg-white px-2 py-2 mt-3 shadow-md mb-3">
                <div className="d-flex justify-content-end align-items-end px-0 pe-2">
                  <div className="text-end mb-0">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        onAddFormRow();
                      }}
                      className="btn btn-purple  "
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add Subtask
                    </button>
                  </div>
                </div>
                {formRows?.map((person, key) => {
                  return (
                    <div key={key} className="">
                      <div className="d-flex gap-2">
                        <div className="custim-width contact-btns ml-10 d-flex justify-content-center align-items-center mb-md-0 mt-3">
                          <div className="text-end" id="customCheckbox">
                            <input
                              type="checkbox"
                              className="form-check-input custom-checkbox mt-2"
                              id="customCheck-outlinecolor2"
                              checked={person?.task_status == 1}
                              onClick={(e) => {
                                handleCheckboxChange(key);
                              }}
                            />
                          </div>
                        </div>
                        <div className="contact-form ">
                          <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                            <label htmlFor="">Subtask Name</label>
                            <input
                              type="text"
                              name="task_name"
                              placeholder="Enter Subtask Name"
                              className="form-control "
                              value={person.task_name}
                              onChange={(e) => handleInputChange(key, e)}
                            />
                          </div>
                        </div>
                        <div className="contact-column edit-contact-column mb-md-0 col-2">
                          <label htmlFor="">Assignee</label>
                          <ReactSelect
                            placeholder="Select Member"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            name="task_assign"
                            value={person?.task_assign}
                            options={assigned}
                            onChange={(selectedOption) =>
                              handleSelectChange(key, selectedOption)
                            }
                          />
                        </div>
                        <div className="contact-column edit-contact-column mb-md-0 col-2">
                          <label htmlFor="">End Date</label>
                          <input
                            type="date"
                            name="sub_enddate"
                            className="form-control"
                            value={person?.sub_enddate}
                            onChange={(e) =>
                              handleEndChange(key, e.target.value)
                            }
                          />
                        </div>
                        <div className=" contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                          <div className="text-end">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                onDeleteFormRow(key);
                              }}
                              className="btn btn-red"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      {key !== formRows.length - 1 ? (
                        <div className="border-bottom my-3"></div>
                      ) : null}
                    </div>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditTaskTab;
