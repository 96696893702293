import React, { useState, useMemo, useEffect } from "react";
import { Row, Col, Card, Table, Modal, Label, Form } from "reactstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
// assets
import Loader from "pages/Separate/Loader";
import NewPagination from "components/Common/Newpagination";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import AddIssues from "../../../assets/images/Human Resource/addIssues.jpg";
import { FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { DashboardSwipesColumns } from "utils/columns/Dashboard/dashboardColumns";
import { customGlobalFilter } from "utils/common-helpers";

function TableContainer({ columns, data, members, toggleLoading }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      globalFilter: customGlobalFilter,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize, globalFilter } = state;

  useEffect(() => {
    setPageSize(10);
  }, []);
  // Render the UI for your table

  return (
    <div>
      <Row>
        <Col xl="12">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-2">
              <div className="mb-3 card-title">Swipe & Issues</div>
            </div>
            <Row className="mb-2 justify-content-between">
              <div className="mb-3 mb-md-0 d-flex justify-space-between align-items-center">
                <div className="col-1 me-md-5">
                  <select
                    className="border-radius form-select"
                    style={{ width: "100px" }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    {[10, 25, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-10 d-flex justify-content-end align-items-center">
                  <div className="col-4 cusrtom_search_box">
                    <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
                      <div className="d-flex">
                        <div className="position-relative">
                          <label
                            htmlFor="search-bar-0"
                            className="search-label w-100 mb-0"
                          >
                            <span id="search-bar-0-label" className="sr-only">
                              Search this table
                            </span>
                            <input
                              onChange={(e) => setGlobalFilter(e.target.value)}
                              id="search-bar-0"
                              type="text"
                              className="border-radius form-control rounded h-100 w-100"
                              placeholder={`Search...`}
                              value={globalFilter || ""}
                            />
                          </label>
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <div className="position-relative table-responsive">
              <Table className="table table-bordered mt-4" {...getTableProps()}>
                <thead className={`fixed_view_payment_table`}>
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          className={`t-col-${index + 1} ${column.className}`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="d-flex align-items-center">
                            <span className="flex-grow-1 text-center header-text">
                              {column.render("Header")}
                            </span>
                            {column.isShortIcon && (
                              <span
                                onClick={() =>
                                  column.getSortByToggleProps().onClick()
                                }
                                className="d-flex align-items-center ms-2"
                              >
                                <i
                                  title="Ascending"
                                  className={`bi bi-arrow-up info-icon ${
                                    column.isSorted && !column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                  }`}
                                ></i>
                                <i
                                  title="Descending"
                                  className={`bi bi-arrow-down info-icon ${
                                    column.isSorted && column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                  }`}
                                ></i>
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody
                  className="fix-introducer-header"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const rowBackgroundColor =
                      row?.original?.leave_type == 2
                        ? "bg-light-red2"
                        : "bg-light-blue2";

                    return (
                      <tr
                        className={`row-hover ${rowBackgroundColor}`}
                        {...row.getRowProps()}
                        key={i}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className={`t-col-${index + 1} ${
                                cell.column.className
                              }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <Row className="justify-content-md-end justify-content-center align-items-center">
              <NewPagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                page={page}
                data={data}
                nextPage={nextPage}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const SwipeAndIssues = () => {
  const [modal_Access, setAccessDenied] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [issuePopup, setIssuesPopup] = useState(false);
  const [issuePopupUnApprove, setIssuesPopupUnApprove] = useState(false);
  const [data, setData] = useState([]);
  const [leave_type, setLeaveType] = useState("");
  const [id, setID] = useState("");
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState("");

  const toggleLoading = (loading) => {
    setLoading(loading);
  };

  const getData = async () => {
    const auth = secureLocalStorage.getItem("auth_token");
    const entries = {
      user_id: localStorage.getItem("auth_id"),
      issue_type: 0,
    };
    const response = await FetchPostData(
      toggleLoading,
      "swipeData",
      auth,
      entries
    );
    if (response?.data?.success) {
      setData(response.data.data?.filter((obj) => obj?.status == 0));
    }
  };
  useEffect(() => {
    if (data?.length == 0) {
      getData();
    }
  }, []);

  const onDisapporve = (leave_type, id) => {
    setID(id);
    setLeaveType(leave_type);
    setIssuesPopupUnApprove(true);
  };
  const onApprove = (leave_type, id) => {
    setID(id);
    setLeaveType(leave_type);
    if (leave_type == 2) {
      setIssuesPopup(true);
    } else {
      onHandleApprove(id);
    }
  };

  const onHandleDisapprove = async (e) => {
    e.preventDefault();
    const auth = secureLocalStorage.getItem("auth_id");
    const entries = {
      user_id: localStorage.getItem("auth_id"),
      issue_type: 0,
      id: id,
      approve_reason: reason,
      status: 2,
    };
    const response = await FetchPostData(
      toggleLoading,
      "approveSwipeData",
      auth,
      entries
    );
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: `${leave_type == 1 ? "Swipes" : "Issue"} UnApporved Successfully`,
      }).then(() => {
        setIssuesPopupUnApprove(false);
        setReason("");
        setData(response?.data?.data?.filter((obj) => obj?.status == 0));
      });
    }
  };

  const onHandleApprove = async (issue_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to approve this ${leave_type == 2 ? "yssue" : "Swipe"}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const auth = secureLocalStorage.getItem("auth_id");
        const entries = {
          user_id: localStorage.getItem("auth_id"),
          issue_type: 0,
          id: issue_id,
          approve_reason: reason,
          status: 1,
          final_amount: leave_type == 2 ? amount : "",
        };
        const response = await FetchPostData(
          toggleLoading,
          "approveSwipeData",
          auth,
          entries
        );
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            text: `${
              leave_type == 1 ? "Swipes" : "Issue"
            } Approved Successfully`,
          }).then(() => {
            setIssuesPopup(false);
            setReason("");
            setData(response?.data?.data?.filter((obj) => obj?.status == 0));
          });
        }
      }
    });
  };

  const column1 = useMemo(
    () => DashboardSwipesColumns(onDisapporve, onApprove),
    []
  );

  return (
    <React.Fragment>
      <>
        {isLoading && <Loader />}
        <div className="">
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <div className="responsive-table">
                    <TableContainer
                      columns={column1}
                      data={data}
                      toggleLoading={toggleLoading}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>

      {/* issue Approve Popup  */}
      <Modal isOpen={issuePopup} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Approve Issue</h5>
          <button
            type="button"
            onClick={() => {
              setIssuesPopup(false);
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            className="row d-flex justify-content-center align-items-center"
            onSubmit={(e) => {
              e.preventDefault();
              onHandleApprove(id);
            }}
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12">
                <div className="col-4 mb-3">
                  <Label>
                    Final Amount / Days <span className="text-danger"> *</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter  Amount / Days"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                  ></input>
                </div>

                <div className="col-md-12 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Reason <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    placeholder="Add Reason Here.."
                    className="form-control"
                    style={{ height: "150px" }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="my-4 text-center">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  className=" btn ms-md-2 btn-red btn-label"
                  onClick={() => setIssuesPopup(false)}
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>

            {/* </div> */}
          </Form>
        </div>
      </Modal>

      {/* issue Un-Approve Popup  */}
      <Modal isOpen={issuePopupUnApprove} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Disapproved Issue</h5>
          <button
            type="button"
            onClick={() => {
              setIssuesPopupUnApprove(false);
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            className="row d-flex justify-content-center align-items-center"
            onSubmit={onHandleDisapprove}
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  Reason <span className="text-danger">*</span>
                </Label>
                <textarea
                  type="text"
                  placeholder="Add Reason Here.."
                  className="form-control"
                  rows={5}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                />
              </div>
              <div className="my-4 text-center">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  className=" btn ms-md-2 btn-red btn-label"
                  onClick={() => setIssuesPopupUnApprove(false)}
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>

            {/* </div> */}
          </Form>
        </div>
      </Modal>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  );
};

export default SwipeAndIssues;
