import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Table, Modal } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"
import { FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import moment from "moment"

const CompanyCommitment = ({ staffData }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [commitment, setCommitment] = useState(null)
  const [logs, setLogs] = useState([])
  const [viewData, setViewData] = useState(false)
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if (staffData?.commitment != undefined) {
      setCommitment(staffData?.commitment)
    }
  }, [staffData])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const toggleLoading = (loading) => {
    setLoading(loading)
  }
  const handleSubmit = async e => {
    e.preventDefault();
    if (commitment != null) {
      const token = secureLocalStorage.getItem('auth_token')
      const formEntries = {
        user_id: localStorage.getItem("auth_id"),
        staff_id: params?.id,
        commitment: commitment
      }
      const postCommitment = await FetchPostData(toggleLoading, "staffcommitment", token, formEntries)
      if (postCommitment?.data.success) {
        Swal.fire({
          icon: "success",
          text: "Company Commitment Updated Successfully",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Add Company Commitment",
      })
    }
  }
  const onGetLog = async (e) => {
    const token = secureLocalStorage.getItem('auth_token')
    const formEntries = {
      staff_id: params?.id,
    }
    const postCommitment = await FetchPostData(toggleLoading, "commitment", token, formEntries)
    if (postCommitment?.data?.success) {
      setLogs(postCommitment?.data?.data)
      setViewData(true)
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Form onSubmit={handleSubmit}>
          <div
            className={`flex-column fixed-buttons pt-2 ${fixButtons ? "d-flex" : "d-none"
              }`}
          >
            <button className="btn btn-save fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
              type="button"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </button>
          </div>
          <div className="mb-3 p-4 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark above">Company Commitment</h4>
              </div>
              <div className="col-md-6  text-md-end">
                <button className=" btn text-white me-md-0 me-2 btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>SAVE
                </button>
                <button
                  onClick={() => navigate(-1)}
                  className=" btn text-white ms-md-2 btn-red btn-label"
                  type="button"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>
            <Row className="mt-3">
              <div className="col-md-12 mb-3">
                <label htmlFor="" className="d-flex justify-content-start align-items-center">
                  Description
                  <i
                    title="Company Commitment - Description"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>

                  <div className="ml-20">
                    <a
                      className="btn btn-sm activity-log-btn text-info"
                      onClick={() => {
                        onGetLog()

                      }}
                    >
                      <i className="fas fa-eye"></i> View
                    </a>
                  </div>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={commitment}
                  onReady={editor => {
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setCommitment(data)
                  }}
                />
              </div>
            </Row>
          </div>
        </Form>
        <Modal size="md" centered isOpen={viewData} className="activity-log">
          <div className="modal-header">
            <h5 className="modal-title mt-0">Log Details</h5>
            <button
              type="button"
              onClick={() => {
                setViewData(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table mb-0 table">
                <thead className="table-light">
                  <tr>
                    <th className="col-1">Date
                      <span className="ms-2 cursor-pointer"><i title="Ascending" className="bi bi-arrow-up info-icon "></i><i title="Descending" className="bi bi-arrow-down info-icon "></i></span>
                    </th>
                    <th className="col-5">Old Data
                      <span className="ms-2 cursor-pointer"><i title="Ascending" className="bi bi-arrow-up info-icon "></i><i title="Descending" className="bi bi-arrow-down info-icon "></i></span>
                    </th>
                    <th className="col-5">New Data
                      <span className="ms-2 cursor-pointer"><i title="Ascending" className="bi bi-arrow-up info-icon "></i><i title="Descending" className="bi bi-arrow-down info-icon "></i></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {logs?.reverse()?.map((obj, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{moment(obj?.created_at).format('DD-MM-YYYY')}</th>
                        <td>
                          <div
                            className="no-padding-desc"
                            dangerouslySetInnerHTML={{
                              __html: obj?.old_data || "-",
                            }}
                          />

                        </td>
                        <td>
                          <div
                            className="no-padding-desc"
                            dangerouslySetInnerHTML={{
                              __html: obj?.new_data,
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default CompanyCommitment
