import React, { useState, useEffect } from "react"
import { Row } from "reactstrap"
import Select from "react-select"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import secureLocalStorage from "react-secure-storage"

const PersonalDetails = ({ id, verticalActiveTab, staffData }) => {
  document.title = "View Staff | Zithas Crm"

  const [roles, setRoles] = useState([])
  const [departments, setDepartments] = useState([])
  const [staffMember, setStaffMember] = useState([])
  const [swift, setSwift] = useState([])
  const [swiftDec, setSwiftDec] = useState({
    label: null,
    value: null,
  })
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCites] = useState([])
  const [inputFields, setInputFields] = useState([{}])
  const [inputFields_1, setInputFields1] = useState([{}])
  const [isLoading, setLoading] = useState(false)
  const [image, setImage] = useState("")
  const [role_name, setRoleName] = useState({
    label: null,
    value: null,
  })
  const [department, setDepartment] = useState({
    label: null,
    value: null,
  })
  const [designation, setDesignation] = useState("")
  const [emp_id, setEmpId] = useState("")
  const [punch_id, setPunchId] = useState("")
  const [date_join, setDateJoin] = useState("")
  const [date_resign, setDateResign] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordType, setPasswordType] = useState("password")
  const [name, setName] = useState("")
  const [custType, setCustType] = useState("new")
  const [alias_name, setAliasName] = useState("")
  const [mobile, setMobile] = useState("")
  const [gender, setGender] = useState("")
  const [birth_date, setBirthDate] = useState("")
  const [marital_status, setMaritialStatus] = useState("")
  const [religion, setReligion] = useState("")
  const [linkedin, setlinkedin] = useState("")
  const [address_1, setAddress1] = useState("")
  const [address_2, setAddress2] = useState("")
  const [country, setCountry] = useState({
    label: null,
    value: null,
  })
  const [state, setState] = useState({
    label: null,
    value: null,
  })
  const [city, setCity] = useState({
    label: null,
    value: null,
  })
  const [pincode, setPincode] = useState("")
  const [pf_account, setPfAccount] = useState("")
  const [aadhaar, setAadhar] = useState("")
  const [pan_account, setPanAccount] = useState("")
  const [passport, setPassport] = useState("")

  const getPersonalDetails = async allOptions => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      // const datas  = response.data.data.filter(async item => {
      const item = response.data.data?.filter(temp => temp.id == id)[0]
      staffData(item)
      if (item?.id == id) {
        // { option, optionRole, optionDepartment, optionStaff }
        const role = allOptions?.optionRole?.filter(ob => {
          return ob.value == item?.roleid
        })
        setRoleName({
          label: role[0]?.label,
          value: role[0]?.value,
        })
        const depart = allOptions?.optionDepartment.filter(ob => {
          return ob.value == item.department
        })
        setDepartment({
          label: depart[0]?.label,
          value: depart[0]?.value,
        })
        const shift = allOptions?.option?.filter(ob => {
          return ob.value == item.shift_id
        })
        setSwiftDec({
          label: shift[0]?.label,
          value: shift[0]?.value,
        })

        try {
          const response = await axios({
            method: "get",
            url: `${configure.apiUrl}/departments`,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            const options = response.data.data.country?.map(ct => ({
              value: ct.id,
              label: ct.name,
            }))
            setCountries(options)
            const cntry = options?.filter(ob => {
              return ob.value == item.country
            })
            setCountry({
              label: cntry[0]?.label,
              value: cntry[0]?.value,
            })
          })
        } catch (error) {
          console.log(error)
        }

        try {
          const formData = new FormData()
          formData.append("country", item.country)
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}/states`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            const option = response.data.data?.map(st => ({
              value: st.id,
              label: st.name,
            }))
            setStates(option)
            const stat = option?.filter(ob => {
              return ob.value == item.state
            })
            setState({
              label: stat[0]?.label,
              value: stat[0]?.value,
            })
            setLoading(false)
          })
        } catch (err) {
          console.log(err)
          setLoading(false)
        }

        try {
          const formData = new FormData()
          formData.append("state", item.state)
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}/city`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            const option = response.data.data?.map(item => ({
              value: item.id,
              label: item.name,
            }))
            setCites(option)
            const cty = option?.filter(ob => {
              return ob.value == item.city
            })
            setCity({
              label: cty[0]?.label,
              value: cty[0]?.value,
            })
            setLoading(false)
          })
        } catch (err) {
          console.log(err)
          setLoading(false)
        }
        setPincode(item.zip_code)
        setPfAccount(item.pf_account_number)
        setAadhar(item.aadharcard_number)
        setPassport(item.passport_number)
        setPanAccount(item.pancard_number)
        setImage(`${configure.imgUrl}${item.avatar}`)
        setDesignation(item.designation)
        setEmpId(item.employee_code)
        setPunchId(item.punchid)
        setDateJoin(item.date_of_joining)
        setDateResign(item.date_of_resignation)
        setName(item.name)
        setPassword(item.crm_password)
        setEmail(item.email)
        setAliasName(item.alias_name)
        setMobile(item.phone_number)
        setGender(item.gender)
        setBirthDate(item.dob)
        setMaritialStatus(item.marital_status)
        setReligion(item.religion)
        setlinkedin(item.linkedin)
        setAddress1(item.address)
        setAddress2(item.address_line_2)
        const familyDetails = JSON.parse(item.family_details)
        if (familyDetails !== null) {
          setInputFields(familyDetails)
        }
        const bankDetails = JSON.parse(item.bank_details)
        if (bankDetails !== null) {
          setInputFields1(bankDetails)
        } else {
          // setInputFields1([...inputFields_1, {}])
        }
      }
      // })

      const newData = response.data.data?.filter(item => {
        return item.id == id
      })
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }
  const getStaffData = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staffData`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      const option = response.data.data.shift?.map(item => ({
        value: item.id,
        label: `${item.shift_name} - (${item.shift_start_time} - ${item.shift_end_time})`,
      }))
      setSwift(option)

      const optionRole = response.data.data.roles?.map(item => ({
        value: item.id,
        label: item.role_name,
      }))
      setRoles(optionRole)

      const optionDepartment = response.data.data.deparment?.map(item => ({
        // value: item.id,
        value: item.id,
        label: item.department_name,
      }))
      setDepartments(optionDepartment)

      const optionStaff = response.data.data.staff?.map(item => ({
        value: item.employee_name,
        label: item.employee_name,
      }))
      setStaffMember(optionStaff)
      setLoading(false)

      return { option, optionRole, optionDepartment, optionStaff }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const allOptions = await getStaffData()
    await getPersonalDetails(allOptions)
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  return (
    <>
      {isLoading && <Loader />}
      {/* Fields */}
      <div className="repeater" id="addstaff">
        <div className="mb-3 shadow bg-white p-4">
          <Row>
            <div className="col-md-6">
              <h4 className="text-dark above">Personal Details</h4>
            </div>
          </Row>
          <h5 className="mb-4 text-dark">General Info</h5>
          <Row>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Role Name</label>
              <Select
                placeholder="-"
                className="basic-multi-select "
                classNamePrefix="select"
                options={roles}
                value={role_name}
                isDisabled={true}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Department</label>
              <Select
                placeholder="-"
                className="basic-multi-select "
                classNamePrefix="select"
                options={departments}
                value={department}
                isDisabled={true}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Designation</label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                value={designation}
                disabled={true}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Shift</label>
              <Select
                placeholder="-"
                className="basic-multi-select "
                classNamePrefix="select"
                options={swift}
                value={swiftDec}
                isDisabled={true}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">
                Employee Id <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={emp_id}
                disabled={true}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">Punch Id</label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={punch_id}
                disabled={true}
              />
            </div>
            <div className="col-md-3 mb-3">
              <div className="pe-5">
                <label htmlFor="">Date Of Joining</label>
                <input
                  type="date"
                  className="form-control "
                  value={date_join}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Date of Resignation / Date of Termination
              </label>
              <input
                type="date"
                className="form-control "
                value={date_resign}
                disabled={true}
              />
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Email Id <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="-"
                className={`form-control`}
                value={email}
                disabled={true}
              />
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                {/* Password <span className="text-danger">*</span> */}
                Password
              </label>
              <div className="bd-pass">
                <input
                  // required
                  type={passwordType}
                  placeholder="Enter Password"
                  className="form-control "
                  id=""
                  value={password}
                  disabled={true}
                />
                <a className="bd-pass-icon" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <i className="fas fa-eye-slash "></i>
                  ) : (
                    <i className="fas fa-eye "></i>
                  )}
                </a>
              </div>
            </div>
          </Row>
        </div>

        <div className="shadow bg-white p-4">
          <h5 className="mb-4 text-dark">Personal Details</h5>
          <div className="col-12 mb-3">
            <label htmlFor="" className="d-block">
              Staff Type
            </label>
            <span className="me-4 ">
              <input
                className="form-check-input input-location"
                type="radio"
                id="new"
                defaultChecked={true}
                disabled={true}
                name="type"
                value="new"
              />
              <label htmlFor="new" className="ms-2">
                New
              </label>
            </span>
            <span>
              <input
                className="form-check-input input-location"
                type="radio"
                disabled={true}
                id="existing"
                name="type"
                value="existing"
              />
              <label htmlFor="existing" className="ms-2">
                {" "}
                Existing
              </label>
            </span>
          </div>
          <Row>
            <div className="col-md-3 col-lg-4 mb-3 empl-name-wrapper">
              <label htmlFor="">
                Employee Name <span className="text-danger">*</span>
              </label>
              {custType === "new" ? (
                <input
                  type="text"
                  placeholder="-"
                  className="form-control empl-name"
                  id=""
                  value={name}
                  disabled={true}
                // style={{ width: '100%', maxWidth: '600px', resize: 'none' }} // Adjust maxWidth as needed
                // rows={3} // Adjust number of rows as needed
                />
              ) : (
                <Select
                  placeholder="-"
                  className="basic-multi-select"
                  classNamePrefix="border-radius select"
                  styles={colourStyles}
                  onChange={e => setName(e.value)}
                  options={staffMember}
                  isDisabled={true}
                />
              )}
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">
                Alias Name <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={alias_name}
                disabled={true}
              />
            </div>
            <div className="col-md-3 col-lg-2 mb-3">
              <label htmlFor="">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={mobile}
                disabled={true}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">
                Gender <span className="text-danger">*</span>
              </label>
              <select
                required
                name=""
                value={gender}
                id=""
                className="form-select "
                disabled={true}
              >
                <option value="None">None</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="col-md-3 mb-3">
              <div className="pe-5">
                <label htmlFor="">Birth Date</label>
                <input
                  type="date"
                  className="form-control "
                  id=""
                  value={birth_date}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Marital Status</label>
              <select
                name=""
                value={marital_status}
                id=""
                className="form-select "
                disabled={true}
              >
                <option value="Nothing Selected">Nothing Selected</option>
                <option value="Unmarried">Unmarried</option>
                <option value="Married">Married</option>
                <option value="Divorce">Divorce</option>
              </select>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Religion</label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={religion}
                disabled={true}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">LinkedIn</label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={linkedin}
                disabled={true}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <h5 className="mb-4 text-dark">Address Details</h5>
          <Row>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Address Line 1</label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={address_1}
                disabled={true}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Address Line 2</label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={address_2}
                disabled={true}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Country</label>
              <Select
                placeholder="-"
                className="basic-multi-select "
                classNamePrefix="select"
                options={countries}
                value={country}
                isDisabled={true}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">State</label>
              <Select
                placeholder="-"
                className="basic-multi-select "
                classNamePrefix="select"
                options={states}
                value={state}
                isDisabled={true}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">City</label>
              <Select
                placeholder="-"
                className="basic-multi-select "
                classNamePrefix="select"
                options={cities}
                value={city}
                isDisabled={true}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Pin Code</label>
              <input
                type="number"
                placeholder="-"
                className="form-control "
                id=""
                value={pincode}
                disabled={true}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <h5 className="mb-4 text-dark">KYC Details</h5>
          <Row>
            <div className="col-md-4 mb-4">
              <label htmlFor="">PF Account Number </label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={pf_account}
                disabled={true}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label htmlFor="">Aadhar Card Number </label>
              <input
                type="number"
                placeholder="-"
                className="form-control "
                id=""
                value={aadhaar}
                disabled={true}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label htmlFor="">PAN Card Number </label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={pan_account}
                disabled={true}
              />
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <label htmlFor="">Passport Number </label>
              <input
                type="text"
                placeholder="-"
                className="form-control "
                id=""
                value={passport}
                disabled={true}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <div className="d-flex justify-content-between mb-4">
            <h5 className="mb-4 text-dark">Family Details</h5>
          </div>
          {inputFields?.map((item, index) => {
            return (
              <div className="display-flex align-items-end" key={index}>
                <div className=" mb-3 pr-row">
                  <label htmlFor="">Member Name</label>
                  <input
                    type="text"
                    placeholder="-"
                    className="form-control "
                    name="member_name"
                    value={item.member_name || ""}
                    disabled={true}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Relationship</label>
                  <input
                    type="text"
                    placeholder="-"
                    className="form-control "
                    name="relationship"
                    value={item.relationship || ""}
                    disabled={true}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Occupation</label>
                  <input
                    type="text"
                    placeholder="-"
                    className="form-control"
                    name="occupation"
                    value={item.occupation || ""}
                    disabled={true}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Contact No</label>
                  <input
                    type="number"
                    placeholder="-"
                    className="form-control "
                    name="contact_no"
                    value={item.contact_no || ""}
                    disabled={true}
                  />
                </div>
              </div>
            )
          })}
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <div className="d-flex justify-content-between mb-4">
            <h5 className="mb-4 text-dark">Bank Details</h5>
          </div>
          {inputFields_1?.map((item, index) => {
            return (
              <div className="display-flex align-items-end" key={index}>
                <div className=" mb-3 pr-row">
                  <label htmlFor="">Bank Name</label>
                  <input
                    type="text"
                    placeholder="-"
                    className="form-control "
                    name="bank_name"
                    value={item.bank_name || ""}
                    disabled={true}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Branch Name</label>
                  <input
                    type="text"
                    placeholder="-"
                    className="form-control "
                    name="branch_name"
                    value={item.branch_name || ""}
                    disabled={true}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Bank Account Number</label>
                  <input
                    type="number"
                    placeholder="-"
                    className="form-control "
                    name="account_number"
                    value={item.account_number || ""}
                    disabled={true}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">IFSC Code</label>
                  <input
                    type="text"
                    placeholder="-"
                    className="form-control "
                    name="ifsc_code"
                    value={item.ifsc_code || ""}
                    disabled={true}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default PersonalDetails
