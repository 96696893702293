import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Nav,
  NavItem,
  NavLink,
  Form,
  Modal,
  Label,
  Input,
} from "reactstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import Loader from "pages/Separate/Loader";
import moment from "moment";
import AddTaskImg from "../../assets/images/Dashboard/AddTaskImg.jpg";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ReactSelect from "react-select";
import SimpleBar from "simplebar-react";
import "../../assets/css/dashboard.css";
import classnames from "classnames";
import { priorityOption, TimeOption } from "utils/common-options";
import AddTime from "../../assets/images/AddTime.jpg";
import sideImg from "../../assets/images/profile.png"
import { fetchData, FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";
import {
  checkPermissionsByModule,
  customGlobalFilter,
  filterActivityDate,
  transformGeneralData,
} from "utils/common-helpers";
import AddTasks from "./GeneralTask/AddTasks";
import { generalDashboardColumns } from "utils/columns/Dashboard/dashboardColumns";
import EditTasks from "./GeneralTask/EditTasks";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import PlanMyDay from "./GeneralTask/PlanMyDay";
import Pagination from "components/Common/Pagination";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import NewPagination from "components/Common/Newpagination";

function TableContainer({
  columns,
  datas,
  allData,
  setAddGeneralTask,
  setPlanedDay,
  toggleLoading,
  storePlanedDayData,
}) {
  const roleID = secureLocalStorage.getItem("auth_role");
  const [data, setData] = useState([]);
  const [filterChange, setFilterChange] = useState(false);
  const [filterActivity, setFilterActivity] = useState(
    roleID == 6 ? "current" : ""
  );
  const [type, setType] = useState(false);
  useEffect(() => {
    if ((filterChange == false || filterActivity == "current") && roleID == 6) {
      setData(filterActivityDate("current", allData));
    } else {
      if (roleID == 6) {
        setData(filterActivityDate(filterActivity, allData));
      } else {
        setFilterActivity("all");
        setData(allData);
      }
    }
  }, [datas]);

  const [fixedHeader, setFixedHeader] = useState(false);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    gotoPage,
    nextPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      globalFilter: customGlobalFilter,
      initialState: {
        sortBy: [
          {
            id: "enddate",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  });

  useEffect(() => {
    setPageSize(10);
  }, []);
  // Render the UI for your table

  const onOpenTodayPlan = async () => {
    const formDatas = {
      user_id: localStorage.getItem("auth_id"),
      role_id: secureLocalStorage.getItem("auth_role"),
    };
    const response = await FetchPostData(
      toggleLoading,
      "dayPlanReport",
      secureLocalStorage?.getItem("auth_token"),
      formDatas
    );
    if (response?.data?.success) {
      storePlanedDayData(response.data.data);
      setPlanedDay(true);
    }
  };

  return (
    <div>
      <Row>
        <Col xl="12">
          <div className="card-body">
            <div className="mb-3 card-title">My Activities</div>
            <Row className="mb-2">
              <div className="mb-3 col-5 mb-md-0 d-flex ">
                <div className="col-md-3">
                  <select
                    className="border-radius form-select"
                    style={{ width: "100px" }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                  >
                    {[10, 25, 50, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 d-flex gap-3 align-items-center">
                  <a
                    className={`d-flex gap-3 align-items-center general-box ${
                      type == "task" ? "bg-purple " : ""
                    }`}
                    onClick={() => {
                      if (type == "task") {
                        setType("");
                        setData(filterActivityDate(filterActivity, allData));
                      } else {
                        setType("task");
                        setData(allData?.filter((obj) => obj?.type == 1));
                      }
                    }}
                  >
                    <h5 className="text-purple mb-0">Tasks : </h5>
                    <h5 className="text-primary mb-0">
                      {data?.filter((obj) => obj?.type == 1)?.length}
                    </h5>
                  </a>
                  <a
                    className={`d-flex gap-3 align-items-center general-box ${
                      type == "follow" ? "bg-purple " : ""
                    }`}
                    onClick={() => {
                      if (type == "follow") {
                        setType("");
                        setData(filterActivityDate(filterActivity, allData));
                      } else {
                        setType("follow");
                        setData(allData?.filter((obj) => obj?.type == 2));
                      }
                    }}
                  >
                    <h5 className="text-purple mb-0">Follow Ups : </h5>
                    <h5 className="text-primary mb-0">
                      {data?.filter((obj) => obj?.type == 2)?.length}
                    </h5>
                  </a>
                  <a
                    className={`d-flex gap-3 align-items-center general-box bg-light-red cursor-pointer ${
                      type == "new" ? "bg-purple" : ""
                    }`}
                    onClick={(e) => {
                      if (type == "new") {
                        setType("");
                        setData(filterActivityDate(filterActivity, allData));
                      } else {
                        setData(allData?.filter((obj) => obj?.enddate == null));
                        setType("new");
                      }
                    }}
                  >
                    <h5 className="text-danger mb-0">New : </h5>
                    <h5 className="text-danger mb-0">
                      {data?.filter((obj) => obj?.enddate == null)?.length}
                    </h5>
                  </a>
                </div>
              </div>
              <div className="col-7 d-flex justify-content-end align-items-center gap-3">
                <div className="col-6">
                  <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
                    <div className="d-flex gap-3">
                      <select
                        className="form-select w-50"
                        style={{ fontSize: "14px" }}
                        value={filterActivity}
                        onChange={(e) => {
                          setData(filterActivityDate(e.target.value, allData));
                          setFilterActivity(e.target.value);
                          setFilterChange(true);
                        }}
                      >
                        <option value="all">All Activities</option>
                        <option value="current">Today's Activities</option>
                        <option value="future">Future Activities</option>
                        <option value="past">Past Activities</option>
                      </select>
                      <div className="position-relative">
                        <label
                          htmlFor="search-bar-0"
                          className="search-label w-100 mb-0"
                        >
                          <span id="search-bar-0-label" className="sr-only">
                            Search this table
                          </span>
                          <input
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            id="search-bar-0"
                            type="text"
                            className="border-radius form-control rounded h-100"
                            placeholder={`Search...`}
                            value={globalFilter || ""}
                          />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <Link
                  onClick={() => setAddGeneralTask(true)}
                  className="s btn text-white me-0 btn-save btn-label "
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i>
                  Task
                </Link>

                <Link
                  onClick={() => {
                    onOpenTodayPlan();
                  }}
                  className="s btn text-white me-0 btn-primary btn-label "
                >
                  <i className="bi bi-calendar-day me-2 icon-size label-icon"></i>
                  Plan My Day
                </Link>
              </div>
            </Row>
            <div className="position-relative table-responsive">
              <Table className="table table-bordered mt-4" {...getTableProps()}>
                <thead
                  className={`${
                    fixedHeader && page.length >= 5
                      ? "fixed_view_payment_table"
                      : ""
                  }`}
                >
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          className={`t-col-${index + 1} ${column.className}`}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="d-flex align-items-center">
                            <span className="flex-grow-1 text-center header-text">
                              {column.render("Header")}
                            </span>
                            {column.isShortIcon && (
                              <span
                                onClick={() =>
                                  column.getSortByToggleProps().onClick()
                                }
                                className="d-flex align-items-center ms-2"
                              >
                                <i
                                  title="Ascending"
                                  className={`bi bi-arrow-up info-icon ${
                                    column.isSorted && !column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                  }`}
                                ></i>
                                <i
                                  title="Descending"
                                  className={`bi bi-arrow-down info-icon ${
                                    column.isSorted && column.isSortedDesc
                                      ? "text-dark"
                                      : ""
                                  }`}
                                ></i>
                              </span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="fix-introducer-header"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    const isPassedDate =
                    new Date(row.original?.enddate) < new Date()
                    return (
                      <tr
                        className={`row-hover ${
                          row?.original?.type == 2 ? "bg-orange-task" : ""
                        } ${isPassedDate ? "bg-light-red" : ""}`}
                        {...row.getRowProps()}
                        key={i}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className={`t-col-${index + 1} ${
                                cell.column.className
                              }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Row className="justify-content-md-end justify-content-center align-items-center">
              <NewPagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                page={page}
                data={data}
                nextPage={nextPage}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const GeneralTask = () => {
  const [fixButtons, setFixButtons] = useState(false);
  const [addGeneralTask, setAddGeneralTask] = useState(false);
  const [editGeneralTask, setEditGeneralTask] = useState(false);
  const [timeTakenPopup, setTimeTakenPopup] = useState(false);
  const [planedDay, setPlanedDay] = useState(false);
  const [noDrag, setNoDrag] = useState();
  const [boxWidth, setBoxWidth] = useState(0);
  const [fixedSideTab, setFixedSideTab] = useState(false);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [generalData, setGeneratData] = useState({});
  const [timeToken, setTimeToken] = useState(null);
  const [updateTodayData, setUpdateTodayData] = useState(false);
  const roles = useSelector((state) => state?.Roles?.apiData);
  const [notes, setNotes] = useState("")
  const [relatedTo, setRelatedTo] = useState("")
  const [relatedId, setRelatedId] = useState("")
  const [isNote, setIsNote] = useState(false)
  const [editTime, setEditTime] = useState(false)
  const [timeId, setTimeId] = useState("")

  const [selectedPlanedTask, setSelectedPlanedTask] = useState("Td");

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true);
    } else {
      setFixButtons(false);
    }
  });

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth;
      setBoxWidth(viewportWidth * 0.65);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const [modalType, setModalType] = useState("");
  const [timesData, setTimesData] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [addHours, setAddHours] = useState(false);
  const handleEditTask = (obj, type) => {
    setEditGeneralTask(true);
    setGeneratData(obj);
    setModalType(type);
  };

  const [modal_Access, setAccessDenied] = useState(false);

  const onDelete = async (id, update) => {
    const permission = checkPermissionsByModule(roles, 8, 5);
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this task !",
        icon: "warning",
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          const formEntities = {
            user_id: localStorage.getItem("auth_id"),
            general_id: id,
            type: 2,
          };
          const response = await FetchPostData(
            toggleLoading,
            "deleteGeneralTasks",
            secureLocalStorage?.getItem("auth_token"),
            formEntities
          );
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "Task deleted successfully.",
            }).then(async () => {
              if (update) {
                const formDatas = {
                  user_id: localStorage.getItem("auth_id"),
                  role_id: secureLocalStorage.getItem("auth_role"),
                  type: 2,
                };
                const response = await FetchPostData(
                  toggleLoading,
                  "dayPlanReport",
                  secureLocalStorage?.getItem("auth_token"),
                  formDatas
                );
                if (response?.data?.success) {
                  storePlanedDayData(response.data.data);
                }
              }
              const tasks = response?.data?.data?.tasks?.map((obj) => ({
                ...obj,
                type: 1,
              }));
              const follows = response?.data?.data?.followup?.map((obj) => ({
                ...obj,
                type: 2,
              }));
              const mergedData = [...tasks, ...follows];
              setData(mergedData?.filter((obj) => obj?.status != "complete"));
              setAllData(
                mergedData?.filter((obj) => obj?.status != "complete")
              );
            });
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };

  const handleChangeStatus = async (e, id) => {
    const formDatas = {
      user_id: localStorage.getItem("auth_id"),
      role_id: secureLocalStorage.getItem("auth_role"),
      general_id: id,
      status: e.target.value,
      type: 2,
    };
    const response = await FetchPostData(
      toggleLoading,
      "changeGeneralTaskStatus",
      secureLocalStorage?.getItem("auth_token"),
      formDatas
    );
    if (response?.data.success) {
      Swal.fire({
        icon: "success",
        title: "Status has been changed successfully!",
      }).then(async (data) => {
        if (updateTodayData) {
          const formDatas = {
            user_id: localStorage.getItem("auth_id"),
            role_id: secureLocalStorage.getItem("auth_role"),
            type: 2,
          };
          const response = await FetchPostData(
            toggleLoading,
            "dayPlanReport",
            secureLocalStorage?.getItem("auth_token"),
            formDatas
          );
          if (response?.data?.success) {
            storePlanedDayData(response.data.data);
          }
        }

        if (e.target.value == "complete") {
          const tasks = response?.data?.data?.tasks?.map((obj) => ({
            ...obj,
            type: 1,
          }));
          const follows = response?.data?.data?.followup?.map((obj) => ({
            ...obj,
            type: 2,
          }));
          const mergedData = [...tasks, ...follows];
          setData(mergedData?.filter((obj) => obj?.status != "complete"));
          setAllData(mergedData?.filter((obj) => obj?.status != "complete"));
          handleAddHour(id);
        }
      });
    }
  };

  const onManagePlan = async (id, status, type) => {
    const formDatas = {
      user_id: localStorage.getItem("auth_id"),
      role_id: secureLocalStorage.getItem("auth_role"),
      task_id: id,
      status: status,
      type: type,
    };
    const response = await FetchPostData(
      toggleLoading,
      "manageDayPlan",
      secureLocalStorage?.getItem("auth_token"),
      formDatas
    );
  };

  const handleAddHour = async (id, related_To, related_Id) => {
    const response = await fetchData(
      toggleLoading,
      `timeUpdateData/${id}`,
      secureLocalStorage.getItem("auth_token")
    )
    if (response?.success) {
      setTimesData(response?.data)
    }
    setAddHours(true)
    setTaskId(id)
    setRelatedTo(related_To)
    setRelatedId(related_Id)
  }

  const handleTimeToken = async (e) => {
    e.preventDefault();
    if (timeToken !== null && notes?.length != 0) {
      const formDatas = {
        user_id: localStorage.getItem("auth_id"),
        task_id: taskId,
        time: timeToken?.value,
        type: 2,
        note: notes,
        related_id: relatedId,
        related_to: relatedTo,
        copynotes: isNote,
        timeid: editTime ? timeId : "",
      }
      const response = await FetchPostData(
        toggleLoading,
        `${editTime ? "editTimeUpdate" : "timeUpdate"}`,
        secureLocalStorage?.getItem("auth_token"),
        formDatas
      )
      if (response?.data?.success) {
        if (updateTodayData) {
          const formDatas = {
            user_id: localStorage.getItem("auth_id"),
            role_id: secureLocalStorage.getItem("auth_role"),
            type: 2,
          };
          const response = await FetchPostData(
            toggleLoading,
            "dayPlanReport",
            secureLocalStorage?.getItem("auth_token"),
            formDatas
          );
          if (response?.data?.success) {
            storePlanedDayData(response.data.data);
          }
        }
        setNotes("")

        setLoading(false);
        setTimeToken(null);
        setTimesData(response?.data?.data?.times);
        const tasks = response?.data?.data?.tasks?.tasks?.map((obj) => ({
          ...obj,
          type: 1,
        }));
        const follows = response?.data?.data?.tasks?.followup?.map((obj) => ({
          ...obj,
          type: 2,
        }));
        const mergedData = [...tasks, ...follows];
        setData(mergedData?.filter((obj) => obj?.status != "complete"));
        setAllData(mergedData?.filter((obj) => obj?.status != "complete"));
        Swal.fire({
          icon: "success",
          text: "Time added Successfully.",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please add mendatory fields.",
      });
    }
  };

  const followChangeStatus = async ({ user_id, followup_id }) => {
    // const permission = checkPermissionsByModule(roles, 17, 1);
    const permission = true;
    if (permission) {
      Swal.fire({
        title: "Are You sure ?",
        text: "The follow-up is completed.",
        icon: "warning",
        showCancelButton: true,
      }).then(async (willOkay) => {
        if (willOkay.isConfirmed) {
          const formEntities = {
            user_id: user_id,
            followup_id: followup_id,
          };
          const response = await FetchPostData(
            toggleLoading,
            "followup_status",
            secureLocalStorage?.getItem("auth_token"),
            formEntities
          );
          if (response.data.success) {
            setLoading(false);
            Swal.fire({
              text: "FollowUp is completed",
              icon: "success",
            }).then(() => {
              const tasks = response.data?.data?.tasks?.map((obj) => ({
                ...obj,
                type: 1,
              }));
              const follows = response.data?.data?.followup?.map((obj) => ({
                ...obj,
                type: 2,
              }));
              const mergedData = [...tasks, ...follows];
              setData(mergedData?.filter((obj) => obj?.status != "complete"));
              setAllData(
                mergedData?.filter((obj) => obj?.status != "complete")
              );
            });
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };

  const [leadId, setLeadId] = useState("");
  const [addTaskPopup, setAddTaskPopup] = useState(false);
  const [assigned, setAssigned] = useState(null);
  const [priority, setPriority] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [description, setDescription] = useState();

  const onAddTask = (id) => {
    setAddTaskPopup(true);
    setLeadId(id);
  };

  const handleAddTaskSubmit = async (e) => {
    e.preventDefault();
    if (taskName != null && assigned != null && priority != null) {
      const formDatas = {
        user_id: localStorage.getItem("auth_id"),
        title: taskName?.value,
        assigned: assigned?.value,
        priority: priority?.value,
        related: "sales",
        related_to: "lead",
        related_id: leadId,
        description: description,
      };
      const response = await FetchPostData(
        toggleLoading,
        "addGeneralTasks",
        secureLocalStorage.getItem("auth_token"),
        formDatas
      );
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "General task added successfully.",
        }).then(() => {
          setAddTaskPopup(false);
          const tasks = response.data?.data?.tasks?.map((obj) => ({
            ...obj,
            type: 1,
          }));
          const follows = response.data?.data?.followup?.map((obj) => ({
            ...obj,
            type: 2,
          }));
          const mergedData = [...tasks, ...follows];
          setData(mergedData?.filter((obj) => obj?.status != "complete"));
          setAllData(mergedData?.filter((obj) => obj?.status != "complete"));
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      });
    }
  };

  const columns = useMemo(
    () =>
      generalDashboardColumns(
        handleEditTask,
        onDelete,
        handleChangeStatus,
        onManagePlan,
        handleAddHour,
        followChangeStatus,
        onAddTask
      ),
    []
  );

  const [isIndex, setIsIndex] = useState(false);
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [isLoading, setLoading] = useState(false);
  const [alldependency, setAllDependency] = useState({});
  const [tasks, setTask] = useState([]);
  const [taskDepends, setTaskDepends] = useState(null);
  const [plannedData, setPlanedDayData] = useState({});
  const [copynotes, setCopyNotes] = useState(false);

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  const handleHover = () => {
    setIsIndex(true);
  };

  const handleLeave = () => {
    setIsIndex(false);
  };

  const toggleLoading = (loading) => {
    setLoading(loading);
  };
  const fetchAllData = async () => {
    const authToken = secureLocalStorage.getItem("auth_token");
    const datas = await fetchData(toggleLoading, "generalData", authToken);
    setAllDependency(transformGeneralData(datas?.data));
    setTask(
      datas?.data?.subtasks?.map((obj) => ({
        id: obj.task,
        related: obj.related,
        subtasks: obj.subtasks,
        value: obj.task,
        label: obj.task,
      }))
    );
  };
  const FetchTasks = async () => {
    const formDataEntries = {
      role_id: secureLocalStorage.getItem("auth_role"),
      user_id: localStorage.getItem("auth_id"),
      type: 2,
    };
    const datas = await FetchPostData(
      toggleLoading,
      "/generalTasks",
      "",
      formDataEntries
    );
    if (datas?.data?.success) {
      const tasks = datas?.data?.data?.tasks?.map((obj) => ({
        ...obj,
        type: 1,
      }));
      const follows = datas?.data?.data?.followup?.map((obj) => ({
        ...obj,
        type: 2,
      }));
      const mergedData = [...tasks, ...follows];
      setData(mergedData?.filter((obj) => obj?.status != "complete"));
      setAllData(mergedData?.filter((obj) => obj?.status != "complete"));
    }
  };

  useEffect(() => {
    fetchAllData();
    FetchTasks();
  }, []);

  const onCloseAddModal = () => {
    setAddGeneralTask(false);
  };
  const onCloseEditModal = () => {
    setEditGeneralTask(false);
  };
  const onChangeDrag = (drag) => {
    setNoDrag(drag);
  };
  const updateData = (data) => {
    const tasks = data?.tasks?.map((obj) => ({
      ...obj,
      type: 1,
    }));
    const follows = data?.followup?.map((obj) => ({
      ...obj,
      type: 2,
    }));
    const mergedData = [...tasks, ...follows];
    setData(mergedData?.filter((obj) => obj?.status != "complete"));
    setAllData(mergedData?.filter((obj) => obj?.status != "complete"));
  };
  const storePlanedDayData = (data) => {
    setPlanedDayData(data);
  };

  const onEditTask = (obj, type) => {
    setEditGeneralTask(true);
    setGeneratData(obj);
    setModalType(type);
    setUpdateTodayData(true);
  };
  const onDeleteTask = (id) => {
    setUpdateTodayData(true);
    onDelete(id, true);
  };
  const handleStatus = (e, id) => {
    setUpdateTodayData(true);
    handleChangeStatus(e, id);
  };
  const FollowupStatus = ({ user_id, followup_id }) => {
    setUpdateTodayData(true);
    followChangeStatus({ user_id, followup_id });
  };
  const onAddHours = (id) => {
    setUpdateTodayData(true);
    handleAddHour(id);
  };
  const onFollowTask = (id) => {
    setAddTaskPopup(true);
    setLeadId(id);
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <div className="responsive-table">
                  <TableContainer
                    columns={columns}
                    datas={data}
                    allData={allData}
                    setAddGeneralTask={setAddGeneralTask}
                    setPlanedDay={setPlanedDay}
                    toggleLoading={toggleLoading}
                    storePlanedDayData={storePlanedDayData}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <div
          className={`note-sticky-form ${
            addGeneralTask ? "d-block" : "d-none"
          }`}
        >
          <Draggable
            handle={`${noDrag ? ".modal-header" : ""}`}
            cancel={`${noDrag ? ".modal-body" : ""}`}
          >
            <ResizableBox
              width={boxWidth}
              style={{
                position: "fixed",
                top: "1%",
                right: "18%",
                zIndex: "1055",
                backgroundColor: "#fff",
                maxHeight: "fit-content",
                transform: "translate(-50%, -50%)",
              }}
            >
              <AddTasks
                alldependency={alldependency}
                tasks={tasks}
                onCloseAddModal={onCloseAddModal}
                onChangeDrag={onChangeDrag}
                toggleLoading={toggleLoading}
                updateData={updateData}
              />
            </ResizableBox>
          </Draggable>
        </div>

        <Modal
          size="xl"
          isOpen={editGeneralTask}
          toggle={() => {
            tog_fullscreen();
          }}
          className="modal-fullscreen"
        >
          <EditTasks
            generalData={generalData}
            tasks={tasks}
            toggleLoading={toggleLoading}
            onChangeDrag={onChangeDrag}
            alldependency={alldependency}
            onCloseEditModal={onCloseEditModal}
            updateData={updateData}
            modalType={modalType}
          />
        </Modal>

        {/* Paln My Day  */}
        <Modal size="xl" isOpen={planedDay} className="modal-fullscreen">
          <div className="modal-header mb-0 py-0 px-4">
            <h5 className="modal-title mt-0">Plan My Day</h5>
            <div className="my-2 text-end">
              <a
                onClick={() => {
                  setPlanedDay(false);
                }}
                className="btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>

          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100" }}
          >
            <div className="modal-body pe-0 d-flex ">
              <div
                className={`px-0 col-1 plan-tab ${
                  fixedSideTab && "credtabFix"
                }`}
                style={isIndex ? { zIndex: "2" } : {}}
              >
                <div className="position-relative">
                  <div>
                    <SimpleBar
                      style={{ height: "55vh" }}
                      className={`${fixedSideTab && ""}`}
                    >
                      <Nav
                        pills
                        className={`${
                          fixedSideTab && "credtabFix-ul"
                        }  flex-column px-1 py-1 h-100`}
                      >
                        <NavItem
                          className={classnames({
                            "d-flex flex-column gap-2 mb-2": true,
                            active: verticalActiveTab == "1",
                          })}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "text-start crendential": true,
                              active: verticalActiveTab == "1",
                            })}
                            onClick={() => {
                              toggleVertical("1");
                              setSelectedPlanedTask("Td");
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            Today's Task
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            "d-flex flex-column gap-2 mb-2": true,
                            active: verticalActiveTab == "2",
                          })}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "text-start crendential": true,
                              active: verticalActiveTab == "2",
                            })}
                            onClick={() => {
                              toggleVertical("2");
                              setSelectedPlanedTask("Yd");
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            {" "}
                            Yesterday's Task
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            "d-flex flex-column gap-2 mb-2": true,
                            active: verticalActiveTab == "3",
                          })}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "text-start crendential": true,
                              active: verticalActiveTab == "3",
                            })}
                            onClick={() => {
                              toggleVertical("3");
                              setSelectedPlanedTask("TA");
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            {" "}
                            Team's Activity
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </SimpleBar>
                  </div>
                </div>
              </div>
              <PlanMyDay
                selectedPlanedTask={selectedPlanedTask}
                plannedData={plannedData}
                onEditTask={onEditTask}
                onDeleteTask={onDeleteTask}
                handleStatus={handleStatus}
                FollowupStatus={FollowupStatus}
                onAddHours={onAddHours}
                onFollowTask={onFollowTask}
              />
            </div>
          </div>
        </Modal>

        <div className={`note-sticky-form ${addHours ? "d-block" : "d-none"}`}>
          <Draggable>
            <ResizableBox
              width={1350}
              style={{
                position: "fixed",
                top: "5%",
                right: "1%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="modal-header pe-4">
                <h5 className="modal-title mt-0">Time Taken</h5>
              </div>

              <div className="modal-body pe-4">
                <Form
                  onSubmit={handleTimeToken}
                  className="row d-flex justify-content-center align-items-center"
                >
                  <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                    <div className="text-center">
                      <img
                        src={AddTime}
                        alt="Addepe"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-7 d-flex flex-column justify-space-between  gap-5 mb-4">
                    <div className="">
                      <div className="col-12 d-flex justify-space-between align-items-center">
                        <div className="col-5 d-flex gap-2 ">
                          <div className="col-md-8">
                            <label htmlFor="">
                              Select Time <span className="text-danger">*</span>
                            </label>
                            <ReactSelect
                              placeholder="Select Time"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={TimeOption}
                              value={timeToken}
                              onChange={selectedOption =>
                                setTimeToken(selectedOption)
                              }
                            />
                          </div>
                          <div className="">
                            {relatedId != null ? (
                              <>
                                <Label htmlFor="formrow-firstname-Input">
                                  Copy Notes
                                </Label>
                                <div className="form-switch form-switch-md mb-2 mt-1">
                                  <input
                                    title="Intial Days"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizemd"
                                    value={isNote}
                                    onChange={e => setIsNote(e.target.checked)}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="text-end mt-4">
                          <button className=" btn btn-save btn-label">
                            <i className="fas fa-save me-2 label-icon"></i>
                            Save
                          </button>
                          <a
                            onClick={() => {
                              setTaskId("")
                              setAddHours(false)
                              setTimeToken(null)
                              setEditTime(false)
                              setNotes("")
                            }}
                            className=" btn ms-md-2 btn-red btn-label"
                          >
                            <i className="dripicons-cross me-2 icon-center label-icon"></i>
                            CANCEL
                          </a>
                        </div>
                      </div>
                      <div
                        className="mt-3"
                        onDoubleClick={() => setNoDrag(true)}
                        onMouseLeave={() => setNoDrag(false)}
                        onMouseEnter={() => setNoDrag(true)}
                      >
                        <label htmlFor="">
                          Notes <span className="text-danger">*</span>
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={notes}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            setNotes(data)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
                <div className="table-responsive col-md-12 time-taken-table ms-3">
                  <Table className="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th className="header time-taken-user">User</th>
                        <th className="text-center header col-1">Date</th>
                        <th className="text-center header col-1">Time Taken</th>
                        <th className="text-center header col-8">Reason</th>
                        <th className="text-center header time-taken-user">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {timesData
                        ?.map((item, i) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <img
                                  src={sideImg}
                                  alt=""
                                  className="img-fluid assignee-img"
                                  title="Admin"
                                />
                              </td>
                              <td className="text-center">
                                {moment(item.created_at).format("DD-MM-YYYY")}
                              </td>
                              <td className="text-center">{item.times}</td>
                              <td className="">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.note || "-",
                                  }}
                                />
                              </td>
                              <td className="text-center">
                                <a
                                  className="btn btn-sm btn-outline-secondary me-1"
                                  title="View Activity"
                                  onClick={e => {
                                    setTimeId(item?.id)
                                    setEditTime(true)
                                    setNotes(item?.note)
                                    setTimeToken(
                                      TimeOption?.filter(
                                        obj => obj.value == item.times
                                      )
                                    )
                                  }}
                                >
                                  <i className="fas fa-pen "></i>
                                </a>
                              </td>
                            </tr>
                          )
                        })
                        ?.reverse()}
                      {timesData?.length == 0 && (
                        <tr>
                          <td className="text-center" colSpan={10}>
                            No Data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </ResizableBox>
          </Draggable>
        </div>

        <div
          className={`note-sticky-form ${addTaskPopup ? "d-block" : "d-none"}`}
        >
          <Draggable
            handle={`${noDrag ? ".modal-header" : ""}`}
            cancel={`${noDrag ? ".modal-body" : ""}`}
          >
            <ResizableBox
              width={boxWidth}
              style={{
                position: "fixed",
                top: "16%",
                right: "18%",

                zIndex: "1055",
                backgroundColor: "#fff",
                maxHeight: "fit-content",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div className="modal-header pe-4">
                <h5 className="modal-title mt-0">Add Task</h5>
              </div>

              <div className="modal-body pe-4">
                <form
                  onSubmit={(e) => handleAddTaskSubmit(e)}
                  className="row d-flex justify-content-center align-items-center"
                >
                  <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                    <div className="text-center">
                      <img
                        src={AddTaskImg}
                        alt="Addepe"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-7 d-flex flex-column justify-space-between  gap-3">
                    <div className="col-12 d-flex justify-content-start flex-column align-items-start gap-3">
                      <div className="col-12 d-flex justify-content-start align-items-start gap-3">
                        <div className="col-6 d-flex flex-column text-start  flex-wrap">
                          <label htmlFor="">
                            Assignee <span className="text-danger">*</span>
                          </label>
                          <ReactSelect
                            placeholder="Select Assignee"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={alldependency?.users}
                            value={assigned}
                            onChange={(selectedOption) =>
                              setAssigned(selectedOption)
                            }
                          />
                        </div>

                        {taskDepends && (
                          <div className="col-4">
                            <label htmlFor="">
                              Priority <span className="text-danger">*</span>
                            </label>
                            <ReactSelect
                              placeholder="Select Task"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={priorityOption}
                              value={priority}
                              onChange={(selectedOption) =>
                                setPriority(selectedOption)
                              }
                            />
                          </div>
                        )}
                        <div className="col-md-2 ps-2">
                          <Label htmlFor="formrow-firstname-Input">
                            Copy Notes
                          </Label>
                          <div className="form-switch form-switch-md mb-2">
                            <input
                              title="Intial Days"
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizemd"
                              onChange={(e) => setCopyNotes(e.target.checked)}
                              value={copynotes}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="">
                          Task Name <span className="text-danger">*</span>
                        </label>
                        <ReactSelect
                          placeholder="Select Task"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={[
                            ...tasks?.filter((obj) => obj?.related === "sales"),
                            { label: "Other", value: "other" },
                          ]}
                          onChange={(selectedOption) => {
                            setTaskDepends(selectedOption);
                          }}
                        />
                      </div>
                      {taskDepends?.value == "other" ? (
                        <div className="col-12">
                          <label htmlFor="">
                            Enter Task Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter Task Name"
                            value={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                            required
                          />
                        </div>
                      ) : null}
                    </div>
                    {taskDepends ? (
                      <>
                        <div className="col-12 d-flex justify-content-start align-items-center gap-3">
                          <div className="col-12">
                            <label htmlFor="">Description</label>
                            <div
                              className="no-drag"
                              onDoubleClick={() => setNoDrag(true)}
                              onMouseLeave={() => setNoDrag(false)}
                              onMouseEnter={() => setNoDrag(true)}
                            >
                              <CKEditor
                                editor={ClassicEditor}
                                onReady={(editor) => {}}
                                data={description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDescription(data);
                                }}
                                style={{ height: "700px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-end mt-3 ">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setAddTaskPopup(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                </form>
              </div>
            </ResizableBox>
          </Draggable>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GeneralTask;
