import React from "react";

export const RolesColumns = (confirmDelete, onEditPermission) => [
    {
        Header: "ID",
        accessor: "id",
        className: "id-width-roles",
        isShortIcon: true,
    },
    {
        Header: "Role Name",
        accessor: "role_name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "role-name-width",
    },
    {
        Header: "Count",
        accessor: "count",
        isInfoIcon: true,
        isShortIcon: true,
        className: "role-status-width text-center",
    },
    {
        Header: "Action",
        accessor: "action",
        isInfoIcon: true,
        isShortIcon: false,
        className: "action-roles",
        Cell: cellProps => {
            return (
                <div>
                    <a
                        onClick={() => onEditPermission(cellProps.row.original.id)}
                        className="btn action-btn btn-sm me-1 btn-outline-secondary"
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </a>
                    <a
                        onClick={() => confirmDelete(cellProps.row.original.id, cellProps?.row.original.count)}
                        className="btn btn-sm action-btn btn-outline-danger"
                    >
                        <i className="fas fa-trash-alt"></i>
                    </a>
                </div>
            )
        },
    },
]