import React, { useEffect, useState } from "react"
// import Breadcrumbs from "../../../components/Common/Breadcrumbs"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import "../../../../assets/css/Staff/Staff.css"
import "../../../../assets/css/Credential/Credential.css"
import "../../../../assets/css/Scollbar.css"
import "../../../../assets/css/Work/projects.css"
import "../../../../assets/css/Settings/generalSetting.css"

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import SimpleBar from "simplebar-react"
import GeneralTaskTab from "./GeneralTaskTab"
import { RelatedToOption } from "utils/common-options"
import { fetchData, FetchPostData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"
import AccessActivityTab from "./AccessActivityTab"

const AccessActivityIndex = ({ verticalActiveTabs }) => {
  const [isCollapse, setIsCollapse] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [staffData, setStaffData] = useState([])
  const [roleData, setRoleData] = useState([])
  document.title = "General Task Setting | Zithas Crm"

  const toggleLoading = loading => {
    setLoading(loading)
  }

  useEffect(() => {
    const fetchDatas = async () => {
      const response = await fetchData(toggleLoading, "allActitvityAccess", secureLocalStorage?.getItem('auth_token'));
      if (response?.success) {
        setData(response?.data?.activities)
        setStaffData(response?.data?.users?.map((obj) => ({
          value: obj.id,
          label: obj.name,
          roleId: obj.roleid,
        })))
        setRoleData(response?.data?.roles?.map((obj) => ({
          value: obj.id,
          label: obj.role_name,
        })))
      }
    }
    if (verticalActiveTabs == "6") {
      fetchDatas();
    }
  }, [verticalActiveTabs])


  const updateData = datas => {
    setData(datas)
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className=" mb-4">
        <Container fluid>
          <div id="credentialPage">
            <Row
              className={`justify-content-between di-flex align-items-top `}
              id="cred"
            >
              <Col
                md={isCollapse ? "12" : "11"}
                className={`z-1 pe-0  ${isCollapse && "width-100 ps-0"}`}
              >
                <div className="card p-0 mb-0 h-100">
                  <AccessActivityTab
                    toggleLoading={toggleLoading}
                    datas={data}
                    updateData={updateData}
                    staffData={staffData}
                    roleData={roleData}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AccessActivityIndex
