import React, { useEffect, useState } from "react"
import { Row, Form, Modal } from "reactstrap"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Loader from "pages/Separate/Loader"
import secureLocalStorage from "react-secure-storage"
import { FetchPostData } from "utils/common-fetchData"
import moment from "moment"

const CompanyCommitment = ({ id, verticalActiveTab, staffData }) => {
  const [isLoading, setLoading] = useState(false)
  const [shortDec, setShortDec] = useState('')
  const [viewData, setViewData] = useState(false)
  const [logs, setLogs] = useState([])
  useEffect(() => {
    if (staffData?.commitment != undefined) {
      setShortDec(staffData?.commitment)
    }
  }, [staffData])

  const onGetLog = async (e) => {
    const token = secureLocalStorage.getItem('auth_token')
    const formEntries = {
      staff_id: id,
    }
    const toggleLoading = (loading) => {
      setLoading(loading)
    }
    const postCommitment = await FetchPostData(toggleLoading, "commitment", token, formEntries)
    if (postCommitment?.data?.success) {
      setLogs(postCommitment?.data?.data)
      setViewData(true)
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Form >
          <div className="mb-3 p-4 bg-white shadow">
            <Row className="mt-3">
              <div className="col-md-12 mb-3">
                <label htmlFor="" className="d-flex justify-content-start align-items-center">
                  Description
                  <i
                    title="Company Commitment - Description"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>

                  <div className="ml-20">
                    <a
                      className="btn btn-sm activity-log-btn text-info"
                      onClick={() => {
                        onGetLog()
                      }}
                    >
                      <i className="fas fa-eye"></i> View
                    </a>
                  </div>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={shortDec}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setShortDec(data)
                  }}
                />
              </div>
            </Row>
          </div>
        </Form>
      </div>

      <Modal size="md" centered isOpen={viewData} className="activity-log">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Log Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewData(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th className="col-1">Date
                    <span className="ms-2 cursor-pointer"><i title="Ascending" className="bi bi-arrow-up info-icon "></i><i title="Descending" className="bi bi-arrow-down info-icon "></i></span>
                  </th>
                  <th className="col-5">Old Data
                    <span className="ms-2 cursor-pointer"><i title="Ascending" className="bi bi-arrow-up info-icon "></i><i title="Descending" className="bi bi-arrow-down info-icon "></i></span>
                  </th>
                  <th className="col-5">New Data
                    <span className="ms-2 cursor-pointer"><i title="Ascending" className="bi bi-arrow-up info-icon "></i><i title="Descending" className="bi bi-arrow-down info-icon "></i></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {logs?.reverse()?.map((obj, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{moment(obj?.created_at).format('DD-MM-YYYY')}</th>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: obj?.old_data || "-",
                          }}
                        />

                      </td>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: obj?.new_data,
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CompanyCommitment
