import React, { useState, useEffect, useMemo } from "react"
import { Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import WelcomeComp from "./WelcomeComp"
import { useDispatch } from "react-redux"
import { setApiData } from "../../store/Roles/actions"
//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"
import IndiaFlag from "../../assets/images/flags/india.png"
import UkFlag from "../../assets/images/flags/uk.png"
import creditSafeImg from "../../assets/images/creditsafe/icon-2.png"
import CanadaFlag from "../../assets/images/flags/canadian_flag.jpg"
import swal from "sweetalert"
import FavoriteLead from "./FavoriteLead"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import GeneralTask from "./GeneralTask"
import ActivityComponent from "pages/Elements/Dashboard/ActivityComponent"
import SwipeAndIssues from "pages/Elements/Dashboard/SwipeAndIssues"
import Absentees from "../Elements/Dashboard/Absentees"

const Dashboard = props => {
  const dispatch = useDispatch()

  //meta title
  document.title = "Dashboard | Zithas Technologies"

  const [isLoading, setLoading] = useState(false)
  const [isLeadAccess, setLeadAccess] = useState(false)
  const [isLeadEdit, setLeadEdit] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)

  useEffect(() => {
    RolesPermission()
    LeadData()
  }, [])

  const RolesPermission = async e => {
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staff_roles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        dispatch(setApiData(response.data.data))
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [potential_data, setPotentialData] = useState([])


  const LeadData = async e => {
    const formData = new FormData()
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/get_lead_potential`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        setPotentialData(response.data.data)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }



  const findLeadAccess = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 17)
    const response = await axios({
      method: "post",
      url: `${configure.apiUrl}/user_permission`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    if (response) {
      setLoading(false)
      if (response.data.data.india[2] == 1 || response.data.data.uk[2] == 1) {
        setLeadAccess(true)
      }
      if (response.data.data.india[4] == 1 || response.data.data.uk[4] == 1) {
        setLeadEdit(true)
      }
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    findLeadAccess()
  }, [])


  const swipeAccess = localStorage.getItem('auth_role') == 1 || localStorage.getItem('auth_role') == 4 | localStorage.getItem('auth_role') == 11 || localStorage.getItem('auth_id') == 32

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          {localStorage.getItem('auth_id') == 6 ? (
            <GeneralTask />
          ) : null}

          <Row className="">
            <Col xl="4" className="main-dash">
              <WelcomeComp />
            </Col>
            <Col xl="5" className="main-dash">
              {isLeadAccess && (
                <FavoriteLead setLoading={setLoading} isLeadEdit={isLeadEdit} />
              )}
            </Col>
            {localStorage.getItem('auth_id') == 4 || localStorage.getItem('auth_id') == 32 || localStorage.getItem('auth_id') == 46 ? (
              <Col xl="3" className="main-dash">
                <Absentees />
              </Col>
            ) : null}
          </Row>

          {localStorage.getItem('auth_id') != 6 ? (
          <GeneralTask />
          ) : null}
          <ActivityComponent />
          {/* Swipe and Issues  */}
          {swipeAccess ? (
            <SwipeAndIssues />
          ) : null}
          <section>
            {localStorage.getItem("auth_id") == 32 ||
              localStorage.getItem("auth_id") == 4 ||
              // localStorage.getItem("auth_id") == 1 ||
              localStorage.getItem("auth_id") == 140 ? (
              <div className="mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3 card-title">Lead Potentials</div>
                    <div className="table-responsive">
                      <table className="table table-bordered mb-0 table">
                        <thead>
                          <tr>
                            <th>Lead From</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th style={{ width: "3%" }}>Mobile No.</th>
                            <th>Industry</th>
                            <th>Service</th>
                            <th style={{ width: "13%" }}>Message</th>
                            <th style={{ width: "5%" }}>Date</th>
                            <th style={{ width: "5%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {potential_data?.map((items, i) => {
                            let backColor
                            if (items.lead_type == "Hot") {
                              backColor = "bd-hot-follow"
                            } else if (items.lead_type == "Cold") {
                              backColor = "bd-cold"
                            } else if (items.lead_type == "Warm") {
                              backColor = "bd-warm"
                            }

                            const onChangeStatus = async e => {
                              swal({
                                title: "Are you sure?",
                                text: "You Want to Remove Lead from list !",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then(async willDelete => {
                                if (willDelete) {
                                  setLoading(true)
                                  const formData = new FormData()
                                  formData.append("client_id", items.id)
                                  try {
                                    const response = await axios({
                                      method: "post",
                                      url: `${configure.apiUrl}/change_status`,
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "multipart/form-data",
                                      },
                                      data: formData,
                                    }).then(response => {
                                      setPotentialData(response.data.data)
                                      setLoading(false)
                                    })
                                  } catch (err) {
                                    console.log(err)
                                    setLoading(false)
                                  }
                                }
                              })
                            }

                            let LeadFrom
                            if (items.location == 1) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={IndiaFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 2) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={UkFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 3) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={CanadaFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 11) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <i
                                    className="fas fa-utensils text-success me-2"
                                    style={{ fontSize: "17px" }}
                                  ></i>
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 12) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={UkFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <img
                                    src={creditSafeImg}
                                    title="creditSafe"
                                    alt="No Img"
                                    className=""
                                    width="20"
                                    height="15"
                                  />
                                </div>
                              )
                            }

                            if (localStorage.getItem("auth_id") == 140) {
                              if (items.location == 11) {
                                return (
                                  <tr key={i} className={backColor}>
                                    <td>{LeadFrom}</td>
                                    <td scope="row">{items.name}</td>
                                    <td>{items.email}</td>
                                    <td>{items.contact}</td>
                                    <td>
                                      {items.industry != null
                                        ? items.industry
                                        : "-"}
                                    </td>
                                    <td>
                                      {items.service != null
                                        ? items.service
                                        : "-"}
                                    </td>
                                    <td>
                                      {items.message != null
                                        ? items.message
                                        : "-"}
                                    </td>
                                    <td>
                                      {moment(items.created_at).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                    </td>
                                    <td className="text-center">
                                      <a
                                        title="view lead"
                                        className="btn btn-sm btn-outline-secondary me-1"
                                        onClick={onChangeStatus}
                                      >
                                        <i className="fas fa-check"></i>
                                      </a>
                                    </td>
                                  </tr>
                                )
                              }
                            } else {
                              return (
                                <tr key={i} className={backColor}>
                                  <td>{LeadFrom}</td>
                                  <td scope="row">{items.name}</td>
                                  <td>{items.email}</td>
                                  <td>{items.contact}</td>
                                  <td>
                                    {items.industry != null
                                      ? items.industry
                                      : "-"}
                                  </td>
                                  <td>
                                    {items.service != null
                                      ? items.service
                                      : "-"}
                                  </td>
                                  <td>
                                    {items.message != null
                                      ? items.message
                                      : "-"}
                                  </td>
                                  <td>
                                    {moment(items.created_at).format(
                                      "DD-MM-YYYY hh:mm:ss"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <a
                                      title="view lead"
                                      className="btn btn-sm btn-outline-secondary me-1"
                                      onClick={onChangeStatus}
                                    >
                                      <i className="fas fa-check"></i>
                                    </a>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </section>
        </Container>
      </div>
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
