export const priorityOption = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
]

export const RelatedToOption = [
    {
        label: "Sales", value: "sales", text: "SA", id: 3, icon: "mdi mdi-chart-bar", color: "text-success", related: [
            { label: "Lead", value: "lead" },
            { label: "Introducer", value: "introducer" },
            { label: "Proposal", value: "proposal" },
            { label: "Contract", value: "contract" },
            { label: "Marketing", value: "marketing" },
        ]
    },
    {
        label: "Work", value: "work", text: "WK", id: 4, icon: "dripicons-suitcase", color: "text-brown", related: [
            { label: "Resource Plan", value: "resource-plan" },
            { label: "Digital Marketing Project", value: "digital-project" },
            { label: "Development Project", value: "development-project" },
            { label: "Support", value: "support" },
            { label: "Icode", value: "Icode" },
        ]
    },
    {
        label: "Human Resource", value: "human-resource", text: "HR", id: 1, icon: "dripicons-user-group", color: "text-purple", related: [
            { label: "Staff", value: "staff" },
            { label: "Freelancer", value: "freelancer" },
            { label: "Payroll Management", value: "payroll-management" },
            { label: "List of Holidays", value: "list-of-holiday" },
            { label: "Festival", value: "festival" }
        ]
    },
    {
        label: "Finance", value: "finance", text: "FN", id: 2, icon: "fas fa-coins", color: "text-warning", related: [
            { label: "Expense", value: "expense" },
            { label: "Credit Card", value: "credit-card" },
            { label: "Perfoma Invoice", value: "perfoma-invoice" },
            { label: "Invoice", value: "invoice" },
            { label: "GST", value: "gst" },
            { label: "TDS", value: "tds" },
        ]
    },
    {
        label: "Other", value: "other", text: "OT", icon: "mdi mdi-dots-horizontal-circle-outline", color: "color-grey", id: 5, related: [
        ]
    },
]
export const GeneralTaskWork = [
    { label: "All", value: "all" },
    { label: "Not Started", value: "not-started" },
    { label: "In Progress", value: "in-progress" },
    { label: "Complete", value: "complete" },
]

export const TimeOption = [
    { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "0.15", value: "0:15" },
    { label: "0.30", value: "0:30" },
    { label: "0.45", value: "0:45" },
    { label: "1.00", value: "1:00" },
    { label: "1.15", value: "1:15" },
    { label: "1.30", value: "1:30" },
    { label: "1.45", value: "1:45" },
    { label: "2.00", value: "2:00" },
    { label: "2.15", value: "2:15" },
    { label: "2.30", value: "2:30" },
    { label: "2.45", value: "2:45" },
    { label: "3.00", value: "3:00" },
]

export const colourStyles = {
    menuList: styles => ({
        ...styles,
        background: "white",
    }),
    menu: base => ({
        ...base,
        zIndex: 1200,
    }),
}

export const StaffOption = [

    {
        "value": 1,
        "label": "Admin"
    },
    {
        "value": 20,
        "label": "Janvi Bhagat"
    },
    {
        "value": 21,
        "label": "Zain Marchawali"
    },
    {
        "value": 44,
        "label": "Rahul Ovhal"
    },
    {
        "value": 46,
        "label": "zainual mukeshbhai marchawala"
    },
    {
        "value": 67,
        "label": "Mohit Kapadnis"
    },
    {
        "value": 68,
        "label": "All"
    },

]

export const ChanceOptions = [
    {
        value: "15",
        label: "15 Days"
    },
    {
        value: "30",
        label: "30 Days"
    },
    {
        value: "60",
        label: "60 Days"
    },
    {
        value: "90",
        label: "90 Days"
    }
]

export const DigitalServiceOptions = [
    {
        id: "1",
        text: "SEO",
        name: "Search Engine Optimization"
    },
    {
        id: "2",
        text: "SMM",
        name: "Social Media Marketing"
    },
    {
        id: "3",
        text: "PPC",
        name: "Pay Per Click"
    },
    {
        id: "4",
        text: "EM",
        name: "Email Marketing"
    }
]

export const recurringTypeOptions = [
    {
        value: 'daily',
        label: 'Daily'
    },
    {
        value: 'weekly',
        label: 'Weekly'
    },
    {
        value: 'monthly',
        label: 'Monthly'
    },
]

export const daysOption = [
    {
        value: 'monday',
        label: 'Monday'
    },
    {
        value: 'tuesday',
        label: 'Tuesday'
    },
    {
        value: 'wednesday',
        label: 'Wednesday'
    },
    {
        value: 'thursday',
        label: 'Thursday'
    },
    {
        value: 'friday',
        label: 'Friday'
    },
]