import html2canvas from "html2canvas";
import moment from "moment";

export const getPermissionsByModule = (data, moduleId) => {
  const result = {};

  data.forEach(item => {
    const permissions = JSON.parse(item.permission);

    // Find the specific permission entry for the given module_id
    const permissionEntry = permissions.find(p => {
      if (typeof p === 'string') {
        const parts = p.split(',');
        return parts[0] === moduleId.toString();
      }
      return false;
    });

    const countryKey = item.country_id === 1 ? 'india' : 'uk'; // Map country_id to country name
    if (permissionEntry) {
      // Convert the permission string to an array of numbers
      const permissionArray = permissionEntry.split(',').map(Number);
      result[countryKey] = permissionArray;
    }
  });

  return result;
};


export const checkPermissionsByModule = (data, moduleId, roleIndex) => {
  const permission = getPermissionsByModule(data, moduleId)
  return permission?.india[roleIndex] == 1 || (permission?.uk !== undefined && permission?.uk[roleIndex] == 1)
};


export const transformGeneralData = (data) => {
  const transformArray = (arr, key, label) => {
    return arr.map(item => ({
      value: item[key],
      label: item[label]
    }));
  };

  return {
    digital_projects: transformArray(data.digital_projects, 'id', 'project_name'),
    introducer: transformArray(data.introducer, 'id', 'company'),
    leads: transformArray(data.leads, 'id', 'company'),
    proposal: transformArray(data.proposal, 'id', 'proposalsubtitle'),
    projects: transformArray(data.projects, 'id', 'project_name'),
    users: transformArray(data.users, 'id', 'name'),
    staff: transformArray(data.users, 'id', 'name')
  };
};

export const sumTimes = (timeArray, key) => {
  const totalMinutes = timeArray?.reduce((total, item) => {
    const timeString = item[key];
    if (!timeString) return total;
    const [hours, minutes] = timeString.split(':').map(Number);
    if (!isNaN(hours) && !isNaN(minutes)) {
      return total + (hours * 60) + minutes;
    }
    return total;
  }, 0) || 0;

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};


export const groupAndMerge = (arrays) => {
  const grouped = {}
  arrays.forEach(item => {
    const { id, related_id, related_name, related_to } = item
    const key = `${related_id}`
    if (!grouped[key]) {
      grouped[key] = {
        id,
        related_id,
        list: [],
        related_name,
        related_to
      }
    }
    grouped[key].list.push(item)
  })

  const output = Object.values(grouped)
  return output
}

export const filterData = (dataArray, filters) => {
  return dataArray.filter(item => {
    const matchesLocation = filters.location ? filters.location == item.location : true;

    const matchesAssignee = filters.assigned?.length
      ? filters.assigned.some(assignee => item.assigned?.split(',').includes(assignee.toString()))
      : true;

    const matchesStatus = filters.status?.length
      ? filters.status.includes(item.status?.trim())
      : true;

    const matchesRelated = filters.related?.length
      ? filters.related.includes(item.related?.trim())
      : true;

    return (
      matchesLocation &&
      matchesAssignee &&
      matchesStatus &&
      matchesRelated
    );
  });
};

export const filterActivityDate = (value, allData) => {
  const currentDate = moment().format("YYYY-MM-DD")
  let tempData;
  if (value == "current") {
    const filterData = allData.filter(item => {
      return item.enddate == currentDate
    })
    tempData = filterData
  } else if (value == "past") {
    const filterData = allData.filter(item => {
      return item.enddate < currentDate
    })
    tempData = filterData
  } else if (value == "future") {
    const filterData = allData.filter(item => {
      return item.enddate > currentDate
    })
    tempData = filterData
  } else if (value == "all") {
    tempData = allData
  }

  return tempData
  // const shortedData = tempData.sort(
  //   (a, b) => new Date(a.date_time) - new Date(b.date_time)
  // )
  // setFollowUp(shortedData)
}

export const customGlobalFilter = (rows, columnIds, filterValue) => {
  return rows.filter(row => {
    const rowData = row.original;
    // Iterate over all fields in row.original to check for a match
    for (const key in rowData) {
      if (
        rowData[key] &&
        rowData[key]
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      ) {
        return true; // If any field matches, keep this row
      }
    }
    return false; // Otherwise, filter it out
  });
};

export const captureScreenshot = (modalRef) => {
  if (modalRef.current) {
    const modalElement = modalRef.current;

    // Save the current styles that have !important (so we can restore them)
    const originalMaxHeight = window.getComputedStyle(modalElement).maxHeight;
    const originalOverflowY = window.getComputedStyle(modalElement).overflowY;

    // Temporarily apply styles to remove the max-height and allow full content capture
    modalElement.style.maxHeight = 'none'; // Remove max-height restriction
    modalElement.style.overflowY = 'visible'; // Allow overflow

    // Ensure any scrollable content is fully captured by html2canvas
    html2canvas(modalElement, {
      useCORS: true,  // Handle cross-origin resources
      allowTaint: true,  // Allow tainted content (useful for external resources)
      scrollX: 0,        // Adjust for horizontal scrolling (if any)
      scrollY: -window.scrollY,  // Adjust for vertical scroll position
      width: modalElement.scrollWidth,  // Full width of the modal content (including overflow)
      height: modalElement.scrollHeight, // Full height of the modal content (including overflow)
    }).then((canvas) => {
      const imageUrl = canvas.toDataURL("image/png");

      // Create a download link and simulate a click to download the image
      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "screenshot.png";
      link.click();

      // Restore the original styles after capturing the screenshot
      modalElement.style.maxHeight = originalMaxHeight;
      modalElement.style.overflowY = originalOverflowY;
    });
  }

};

export const getUpdatedEntries = (array, changearray) => {
  return changearray.filter(change => {
    const id = change.split(',')[0];
    const original = array.find(item => item.split(',')[0] === id);
    return original !== change;
  });
}