import React, { useEffect, useState } from "react";
import { RelatedToOption } from "utils/common-options";
import moment from "moment";
import configure from "configure";
import "../../../assets/css/dashboard.css"
import sideImg from "../../../assets/images/profile.png"

export const MyActivityColumns = (onViewTask, onManagePlan) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_id",
        Cell: cellProps => {
            const [status, setStatus] = useState(cellProps?.row?.original?.plan_day);
            useEffect(() => {
                setStatus(cellProps?.row?.original?.plan_day)
            }, [cellProps?.row?.original])
            return (
                <div className="d-flex justify-content-center align-items-center gap-1">
                    <span>
                        <a
                            className="color-grey me-1"
                            title="Plan My Day"
                            onClick={() => {
                                onManagePlan(cellProps.row.original?.id, cellProps?.row?.original?.plan_day == 0 ? 1 : 0, cellProps?.row?.original?.type == 1 ? 3 : 4)
                                setStatus(status == 0 ? 1 : 0)
                            }}
                        >
                            <i className={`${status == 1 ? "bi bi-brightness-high-fill color-orange" : "bi bi-brightness-high "} fs-4`}></i>
                        </a>
                    </span>
                    <span>{cellProps.row.original.id}</span>
                </div>
            )
        },
    },
    {
        Header: "Added By",
        accessor: "teams_status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_added_by text-center",
        Cell: cellProps => {

            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }

            return (
                <div className="d-flex justify-content-start align-items-center gap-2">
                    <img
                        src={`${cellProps?.row?.original?.avatar ? `${configure?.imgUrl}${cellProps?.row?.original?.avatar}` : sideImg}`}
                        alt=""
                        className="img-fluid assignee-img"
                        title={cellProps?.row?.original?.added_by_name}
                    />
                    {priorty}
                </div>
            )
        }
    },

    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_task_name",
        Cell: cellProps => {
            return (
                <div className="d-flex align-items-center">
                    <a className={`fs-3 me-2 ${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.color}`} title={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}`}>
                        <i className={`${RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.icon}`}></i>
                    </a>
                    <a className="cursor-pointer color-grey" >
                        <strong>{` ${cellProps?.row?.original?.related_name != null ? `${cellProps?.row?.original?.related_name} - ` : ""}`}</strong>  {cellProps?.row?.original?.title}
                    </a>
                </div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_end_date",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.enddate != null ? moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY') : "-"}</div>
            )
        }
    },
    {
        Header: "Spent Hrs",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_last_update",
        Cell: cellProps => {
            return (
                <div className="">
                    {cellProps?.row?.original?.spent_hours || "-"}
                </div>
            )
        },
    },
    {
        Header: "",
        accessor: "action",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_id",
        Cell: cellProps => {
            return (
                <div className="">
                    <a className="btn btn-sm btn-outline-secondary me-1" title="View Activity" onClick={() => onViewTask(cellProps?.row?.original)}>
                        <i className="fas fa-eye " ></i>
                    </a>
                </div>
            )
        },
    },


]