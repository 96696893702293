import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { Editor } from "@tinymce/tinymce-react"
import { useMemo } from "react"
import staffDocuments from "../../../assets/images/staffDocuments.jpg"
import { lettersData } from "../GenData"
import JsPDF from "jspdf"

import { useTable, usePagination, useGlobalFilter } from "react-table"
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer"
// import India from "../../../assets/images/flags/india.png"
// import Uk from "../../../assets/images/flags/uk.png"
// import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import { Table, Row, Col, Card, CardBody, Modal, Form } from "reactstrap"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Contract.css"
// import AccessDenied from "../../assets/images/dribbble_1.gif"

// common component
import Pagination from "components/Common/Pagination"
import ProfileDetails from "components/Common/ProfileDetails"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
// import DownloadContract from "./DownloadContract"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import configure from "configure"
import Select from "react-select"
import swal from "sweetalert"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"

// components

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)
  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  return (
    <div>
      <div>
        <Row className="mb-2">
          <Col>
            <select
              className=" me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <Col className="d-flex justify-content-end col-md-10">
            <div className="me-4">
              <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <input
                      onChange={e => setGlobalFilter(e.target.value)}
                      id="search-bar-0"
                      type="text"
                      className=" form-control rounded h-100"
                      placeholder={` Search...`}
                      value={globalFilter || ""}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center pt-4">
            <div className={`${fixedContent ? "staffDocuments" : ""}`}>
              <img
                src={staffDocuments}
                alt="staffDocuments"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="position-relative">
              <Table className="table table-bordered mt-4" {...getTableProps()}>
                <thead
                  className={`fix-genrateDoc-header ${fixedContent ? "fix-header-genrateDoc" : ""
                    } `}
                >
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          key={index}
                          className={`t-col-${index + 1} ${column.className}`}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                          {column.isInfoIcon ? (
                            <a>
                              <i
                                title="info"
                                className="bx bx-info-circle ms-1 info-icon"
                              ></i>
                            </a>
                          ) : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody
                  className="fix-genrateDoc-header"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr className="row-hover" {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              onClick={() =>
                                cell.column.Header === "Company"
                                  ? navigate(
                                    `${configure.appUrl}sales/edit-contract`
                                  )
                                  : ""
                              }
                              className={`t-col-${index + 1} ${cell.column.className
                                } ${cell.column.Header === "Signature"
                                  ? cell.value === "Not-Signed"
                                    ? "text-danger"
                                    : "text-success"
                                  : ""
                                }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  {page.length == 0 && (
                    <tr >
                      <td className="text-center" colSpan={10}>No Data found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        {/* Pagination */}
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
        </Row>
      </div>
    </div>
  )
}

const GenerateDoc = ({ id, verticalActiveTab }) => {
  //meta title
  document.title = "View Staff | Zithas Crm"
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Letters Type",
        accessor: "requirement_type",
        isInfoIcon: true,
      },
      {
        Header: "Created",
        accessor: "created_at",
        isInfoIcon: true,
        className: "text-center",
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original?.created_at != null
                ? moment(cellProps.row.original?.created_at).format(
                  "DD-MM-YYYY"
                )
                : "-"}
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        Cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              {cellProps.row.original.approve == 1 && (
                <Link
                  title="View"
                  className="btn btn-sm btn-outline-secondary me-1"
                >
                  <i className="fas fa-eye "></i>
                </Link>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const fetchData = async () => {
    try {
      setLoading(true)
      const bearerToken = secureLocalStorage.getItem("auth_token")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staffLetters/${id}`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setDatas(response?.data?.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (verticalActiveTab == "8") {
      if (data?.length == 0) {
        fetchData()
      }
    }
  }, [verticalActiveTab])

  return (
    <React.Fragment>
      <div>
        {isLoading && <Loader />}
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Access Denied */}
    </React.Fragment>
  )
}

export default GenerateDoc
