import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import sideImg from "../../../assets/images/profile-img.png"
import India from "../../../assets/images/flags/india.png"
import Uk from "../../../assets/images/flags/uk.png"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Introducer.css"

// common component
import Pagination from "components/Common/Pagination"

// components
import ViewProfile from "./ViewIntroducer/ViewProfile"
import ViewActivity from "./ViewIntroducer/ViewActivity"
import ViewNotes from "./ViewIntroducer/ViewNotes"

// Hover card
import ProfileDetails from "components/Common/ProfileDetails"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
import ViewFiles from "./ViewIntroducer/ViewFiles"
import ViewPayment from "./ViewIntroducer/ViewPayment"
import configure from "configure"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

function TableContainer({ columns, data, setAccessDenied, setLoading }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [title, setTitle] = useState("")
  const [name_modal, setNameModal] = useState(false)

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Introducer Module, the <strong>Assigned</strong> column designates the specific team member or individual responsible for managing the relationship and interactions with the introducer who connects your company with potential clients.</p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong>  column is to ensure that there is clear accountability for maintaining and nurturing the relationship with the introducer. It helps streamline communication, track progress, and ensure that introducers are well-supported and informed about your company's services. This column fosters effective introducer management, enhancing the chances of successful client referrals and maintaining a strong network of introducers within the Mars System's framework.</p>",
    },
    {
      title: "Name",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Introducer Module, the  <strong>Name</strong> column represents the full name of the individual or business entity associated with the introducer who has connected your company with potential clients.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Name</strong> column is to provide specific identification of the introducer. It allows for personalized communication and relationship-building with the introducer, which can be essential in maintaining a strong partnership. Knowing the introducer's name ensures that interactions are respectful and tailored, fostering trust and collaboration within the Mars System's context.</p>",
    },
    {
      title: "Email ID",
      mean: "<p><strong>Meaning :</strong>  Within the Mars System's Introducer Module, the  <strong>Email ID</strong> column stores the email addresses of individuals or businesses who act as introducers, facilitating connections between clients and your company's services.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Email ID</strong>  column is to provide a direct and reliable means of communication with the introducer. Email addresses enable efficient correspondence, updates, and collaboration with introducers. This column ensures that your company can maintain open channels of communication, share important information, and coordinate effectively with introducers who play a vital role in connecting clients with your services within the Mars System's framework.</p>",
    },
    {
      title: "Phone",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Introducer Module, the <strong>Phone</strong> column contains the contact phone numbers of the individuals or businesses who serve as introducers, facilitating connections between clients and your company's services.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Phone</strong> column is to provide an additional communication channel with the introducers. Phone numbers enable your team to engage in voice calls and real-time conversations, which can be particularly valuable for discussing potential client opportunities and coordination. This column ensures that there is a versatile means of contacting and collaborating with introducers within the Mars System's framework, ultimately enhancing the efficiency of your introducer network.</p>",
    },
    {
      title: "Days",
      mean: " <p><strong>Meaning :</strong>  In the Mars System's Introducer Module, the <strong>Days</strong>  column represents the number of days that have elapsed since the last update or interaction with the introducer, or since the introducer facilitated a connection with a client.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Days</strong> column is to provide a time-based reference for the activity and engagement level with introducers. It helps in tracking the recency of interactions, ensuring that introducers are regularly engaged and updated about client referrals. This column assists in prioritizing introducers who require follow-ups or additional support, ensuring that your introducer network remains active and productive within the Mars System's context.</p>",
    },
    {
      title: "Status",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Introducer Module, the <strong>Status</strong> column indicates the current status or stage of the relationship and engagement with the introducer.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Status</strong> column is to provide a clear overview of the state of your interactions with the introducer. It allows you to categorize introducers into stages such as <strong>New </strong> ,  <strong>In-Process </strong>,  <strong>Needs Follow-up </strong>,  or  <strong>Converted to Introducer</strong>.  This status tracking helps in managing and prioritizing introducer relationships effectively. It ensures that introducers receive the appropriate level of attention and support within the Mars System's framework, facilitating mutually beneficial partnerships and successful client referrals.</p>",
    },
  ]

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 18)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}sales/add-introducer`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          // to={`${configure.appUrl}sales/add-introducer`}
          onClick={() => onAddPermission()}
          className="btn btn-save  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Introducer</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <div className="d-flex">
            {/* <button className="btn btn-info btn-soft  me-3">
              Kanban
            </button> */}
            <select
              className=" form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <Link
              onClick={() => onAddPermission()}
              className=" btn text-white me-2 btn-save btn-label"
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>
              Introducer
            </Link>
            <button
              onClick={() => navigate(-1)}
              className=" btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-header" : ""
            } fix-introducer-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true)
                          setTitle(column.Header)
                        }}
                      >
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-introducer-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map((item, i) => {
                  if (item.title == title) {
                    return (
                      <div key={i}>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </div>
                    )
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Introducer = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false)
  })

  //meta title
  document.title = "Introducer | Zithas Crm"

  const [data, setDatas] = useState([])
  const [view_customer, setVeiwCustomer] = useState([])
  const [isLoading, setLoading] = useState(false)

  const [modal_Access, setAccessDenied] = useState(false)
  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/introducer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [introducer_profile, setIntroduceProfile] = useState([])
  const [introducer_note, setIntroducerNote] = useState([])
  const [introducer_assign, setIntroduceAssign] = useState([])
  const [introducer_file, setIntroduceFile] = useState([])
  const [introducer_payment, setIntroducePayment] = useState([])
  const [introducer_activity, setCustomerActivity] = useState([])

  const navigate = useNavigate()

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Assigned",
        accessor: "assign",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex align-items-center">
              <div className="profileImg-box">
                <img
                  src={`https://mars.zithas.com/application/public/${cellProps.row.original.img}`}
                  alt=""
                  className="assigned-img mx-1"
                  height="20"
                  width="20"
                />
                <div className="profileImg-detail">
                  <div className="overflow-hidden mb-0 card">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-8">
                          <div className="text-primary text-start p-3">
                            <h5 className="text-primary ">
                              {cellProps.row.original.assigned_name}
                            </h5>
                            <p style={{ width: "200px" }}>
                              {cellProps.row.original.assigned_designation}
                            </p>
                          </div>
                        </div>
                        <div className="align-self-end col-4">
                          <img src={sideImg} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 mb-0 card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="avatar-md profile-user-wid mb-2">
                            <img
                              src={`https://mars.zithas.com/application/public/${cellProps.row.original.img}`}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className=" text-start">
                            <div className="row">
                              <div className="pe-1 col-5">
                                <h5 className="font-size-12 mb-1">Join Date</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.assigned_joining_date}
                                </p>
                              </div>
                              <div className="ps-0 col-7">
                                <h5 className="font-size-12 mb-1">Email</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.assigned_email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cellProps.row.original.leadbelogto == "1" ? (
                <img
                  src={India}
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              ) : (
                <img
                  src={Uk}
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              )}
            </div>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: "Name",
        accessor: "company",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <Link
              to={`${configure.appUrl}sales/edit-introducer/${cellProps.row.original.id}`}
              className="text-dark"
            >
              {cellProps.row.original.company}
            </Link>
          )
        },
      },
      {
        Header: "Email ID",
        accessor: "email_address",
        isInfoIcon: true,
      },
      {
        Header: "Phone",
        accessor: "phone_number",
        isInfoIcon: true,
      },
      {
        Header: "Days",
        accessor: "days",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: cellProps => {
          return (
            <Badge className={cellProps.row.original.color}>
              {cellProps.row.original.status_name}
            </Badge>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        Cell: cellProps => {
          const onPermission = async (e, id) => {
            e.preventDefault()
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 18)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  onDeleteIntroducer()
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onDeleteIntroducer = e => {
            Swal.fire({
              icon: "warning",
              title: "Are you sure?",
              text: "You want to Delete this Lead !",
              showCancelButton: true,
            }).then(async willDelete => {
              if (willDelete.isConfirmed) {
                setLoading(true)
                const formData = new FormData()
                formData.append("introducer_id", cellProps.row.original.id)
                formData.append("user_id", localStorage.getItem("auth_id"))
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_introducer`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    Swal.fire({
                      icon: "success",
                      text: "Introducer Deleted Successfully",
                    }).then(data => {
                      setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const viewData = async e => {
            // setVeiwCustomer([cellProps.row.original])
            setmodal_fullscreen(true)
            setLoading(true)
            const formData = new FormData()
            formData.append("introducer_id", cellProps.row.original.id)
            try {
              const response = await axios({
                method: "post",
                url: `${configure.apiUrl}/introducer_data`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(response => {
                setIntroduceProfile(response.data.data.introducer)
                setIntroducerNote(response.data.data.introducernote)
                setIntroduceAssign(
                  response.data.data.assigned?.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))
                )
                setIntroduceFile(response.data.data.introducer_files)
                setIntroducePayment(response.data.data.payments)
                setCustomerActivity(response.data.data.introducer_activity)
                setLoading(false)
              })
            } catch (err) {
              swal({
                icon: "error",
                text: "SomeThing Went Wrong",
              })
              setLoading(false)
            }
          }
          const onEditPermission = async ({ id }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 18)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  navigate(
                    `${configure.appUrl}sales/edit-introducer/${cellProps.row.original.id}`
                  )
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                title="view introducer"
                className="btn btn-sm btn-outline-secondary me-1"
                onClick={viewData}
              >
                <i className="fas fa-eye "></i>
              </a>
              <Link
                title="edit introducer"
                onClick={() =>
                  onEditPermission({ id: cellProps.row.original.id })
                }
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <a
                onClick={onPermission}
                title="delete"
                className="btn btn-sm  btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Introducer"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Introducer Modal */}
      <Modal
        size="xl"
        isOpen={modal_fullscreen}
        toggle={() => {
          tog_fullscreen()
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <h5 className="col-6 modal-title mt-0">Introducer Details</h5>
          </div>

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab h-100 py-3 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Payment
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Files
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="px-4 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <ViewProfile introducer_profile={introducer_profile} />
                  </TabPane>
                  <TabPane tabId="2">
                    <ViewNotes
                      introducer_note={introducer_note}
                      introducer_assign={introducer_assign}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <ViewPayment introducer_payment={introducer_payment} />
                  </TabPane>
                  <TabPane tabId="4">
                    <ViewFiles introducer_file={introducer_file} />
                  </TabPane>
                  <TabPane tabId="3">
                    <ViewActivity introducer_activity={introducer_activity} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default Introducer
