import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Form, Input, Label, Nav, NavItem, NavLink } from "reactstrap";
import {
  colourStyles,
  daysOption,
  priorityOption,
  recurringTypeOptions,
  RelatedToOption,
  TimeOption,
} from "utils/common-options";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { FetchPostData } from "utils/common-fetchData";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";

const AddTasks = ({
  alldependency,
  tasks,
  onCloseAddModal,
  onChangeDrag,
  toggleLoading,
  updateData,
}) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("sales");
  const [isIndex, setIsIndex] = useState(false);
  const getLocations = useSelector((state) => state?.location?.locations);
  const [title, settitle] = useState(null);
  const [taskOption, setTaskOption] = useState(
    tasks?.filter((obj) => obj?.related == "sales")
  );
  const [location, setLocation] = useState(null);
  const [addStartDate, setAddStartDate] = useState("");
  const [addEndDate, setAddEndDate] = useState("");
  const [addAssign, setAddAssign] = useState([]);
  const [addPriority, setAddPriority] = useState(null);
  const [description, setDescription] = useState("");
  const [relatedOptions, setRelatedOpion] = useState(
    RelatedToOption[0]?.related
  );
  const [selectedRelated, setSelectedRelated] = useState(RelatedToOption[0]);
  const [related_to, setRelatedto] = useState([]);
  const [related_id, setRelatedId] = useState([]);
  const [estimated, setEstimatedTime] = useState("");
  const [relatedIdOption, setRelatedIdOption] = useState([]);
  const [copynotes, setCopyNotes] = useState(false);
  const [recurring, setRecurring] = useState(false)
  const [recurringType, setRecuringType] = useState([])
  const [recurringDay, setRecurringDay] = useState([])
  const [recurringDay1, setRecurringDay1] = useState([])
  const [recurringEnd, setRecurringEnd] = useState([])
  const handleHover = () => {
    setIsIndex(true);
  };

  const handleLeave = () => {
    setIsIndex(false);
  };

  useEffect(() => {
    setTaskOption(tasks?.filter((obj) => obj?.related == verticalActiveTab));
    settitle([]);
  }, [verticalActiveTab, tasks]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      location != null &&
      title != null &&
      addPriority != null &&
      addAssign?.length != 0
    ) {
      const formDatas = {
        user_id: localStorage.getItem("auth_id"),
        location: location?.value,
        title: verticalActiveTab == "other" ? title : title?.value,
        startdate: addStartDate,
        enddate: addEndDate,
        assigned: addAssign?.map((obj) => obj.value)?.join(","),
        priority: addPriority?.value,
        related: selectedRelated?.value,
        related_to: related_to?.value,
        related_id: related_id?.value,
        description: description,
        est_time: estimated?.value,
        copynotes: copynotes,
        recurring: recurring ? 1 : 0,
        recurring_type: recurringType?.value,
        recurring_days:
          recurringType?.value == "daily"
            ? null
            : recurringType?.value == "monthly"
            ? moment(recurringDay1).format('DD')
            : recurringType?.value == "weekly"
            ? recurringDay?.map(obj => obj.value)?.join(",")
            : null,
        recurring_enddate: recurringEnd,
      };
      const response = await FetchPostData(
        toggleLoading,
        "addGeneralTasks",
        secureLocalStorage.getItem("auth_token"),
        formDatas
      );
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "Task added successfully.",
        }).then(() => {
          onCloseAddModal();
          updateData(response?.data?.data);
          settitle("");
          setLocation(null);
          setAddStartDate("");
          setAddEndDate("");
          setAddAssign(null);
          setDescription("");
          setEstimatedTime(null);
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="modal-header pe-0 mb-3">
          <h5 className="modal-title mt-0">Add Task</h5>
          <div className="my-2 text-end d-flex align-items-center gap-2">
            <div className="d-flex me-2">
              <Label htmlFor="formrow-firstname-Input">Recurring Task</Label>
              <div className="form-switch form-switch-md mb-2 ps-5  ">
                <input
                  title="Intial Days"
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  value={recurring}
                  onChange={(e) => setRecurring(e.target.checked)}
                />
              </div>
            </div>
            <button className="btn btn-save btn-label">
              <i className="fas fa-save me-2 label-icon"></i>
              Save
            </button>
            <a
              onClick={() => {
                onCloseAddModal(false);
              }}
              className="btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </a>
          </div>
        </div>

        <div className="modal-body pe-0 d-flex col-12">
          <div
            className={`px-0 col-1  `}
            style={isIndex ? { zIndex: "2" } : {}}
          >
            <div className="position-relative">
              <div>
                <SimpleBar
                  style={{ height: "55vh" }}
                  className={`overflow-visible`}
                >
                  <Nav pills className={` flex-column px-1 py-1 h-100`}>
                    {RelatedToOption?.map((obj, i) => {
                      return (
                        <NavItem
                          className={classNames({
                            "d-flex flex-column gap-2 mb-2": true,
                            active: verticalActiveTab == obj.value,
                          })}
                          key={i}
                        >
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              "text-center crendential": true,
                              active: verticalActiveTab == obj.value,
                            })}
                            onClick={() => {
                              setverticalActiveTab(obj?.value);
                              setRelatedOpion(obj?.related);
                              setSelectedRelated(obj);
                              setRelatedto([]);
                              setRelatedIdOption([]);
                              setRelatedId([]);
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            {obj.text}
                            <span className="link_name border-start border-white border-2">
                              {obj.label}
                            </span>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                </SimpleBar>
              </div>
            </div>
          </div>

          <div className="form-container col-11">
            <Form className="row d-flex justify-content-center align-items-center">
              <div className="col-md-12 d-flex flex-column new-task-modal ">
                <div className="col-md-12 d-flex justify-content-start align-items-start">
                  {recurring ? (
                    <>
                      <div className="col-md-3 ps-2 mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          Recurring Type
                        </Label>
                        <Select
                          placeholder="Select Priority"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={recurringTypeOptions}
                          value={recurringType}
                          onChange={(e) => setRecuringType(e)}
                        />
                      </div>
                      {recurringType?.value == "weekly" ? (
                        <div className="col-md-6 ps-2">
                          <Label htmlFor="formrow-firstname-Input">
                            Select Days
                          </Label>
                          <Select
                            placeholder="Select Priority"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            options={daysOption}
                            closeMenuOnSelect={false}
                            isMulti
                            onChange={(e) => setRecurringDay(e)}
                          />
                        </div>
                      ) : recurringType?.value == "monthly" ? (
                        <div className="col-md-3 ps-2">
                          <Label htmlFor="formrow-firstname-Input">
                            Select Date
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            placeholder="Select Date"
                            onChange={(e) => setRecurringDay1(e.target.value)}
                          />
                        </div>
                      ) : null}
                      <div className="col-md-3 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Recuring End Date
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          placeholder="Select Date"
                          onChange={(e) => setRecurringEnd(e.target.value)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                {recurring && recurringType?.value == "daily" ? (
                  <div className="ps-2 text-danger mb-3 ">
                    *A new task will be assigned daily through this feature,
                    with the expectation that it will be completed on daily
                    purpose.
                  </div>
                ) : recurring && recurringType?.value == "weekly" ? (
                  <div className="ps-2 text-danger mb-3 ">
                    * This feature will create tasks on the selected weekdays,
                    which are expected to be completed on selected weekdays
                  </div>
                ) : recurring && recurringType?.value == "monthly" ? (
                  <div className="ps-2 text-danger mb-3 ">
                    * This feature will generate a new task every month on the
                    selected date, which need to be completed on selected month
                    day .
                  </div>
                ) : null}
                <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                  <div className="col-sm-3 ps-2">
                    <Label htmlFor="formrow-firstname-Input">
                      Location <span className="text-danger">*</span>
                    </Label>
                    <Select
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={getLocations}
                      value={location}
                      onChange={(e) => setLocation(e)}
                    />
                  </div>
                  <div
                    className="col-9"
                    onDoubleClick={() => onChangeDrag(true)}
                    onMouseLeave={() => onChangeDrag(false)}
                    onMouseEnter={() => {
                      onChangeDrag(true);
                    }}
                  >
                    <label htmlFor="">
                      Task Name <span className="text-danger">*</span>
                    </label>
                    {verticalActiveTab == "other" ? (
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Task Name"
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                        required
                        onDoubleClick={() => onChangeDrag(true)}
                        onMouseLeave={() => onChangeDrag(false)}
                        onMouseEnter={() => {
                          onChangeDrag(true);
                        }}
                      />
                    ) : (
                      <Select
                        placeholder="Select Task"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={taskOption}
                        value={title}
                        onChange={(selectedOption) => {
                          settitle(selectedOption);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                  <div className="col-md-3 ps-2">
                    <Label htmlFor="formrow-firstname-Input">Start Date</Label>
                    <Input
                      type="date"
                      className="form-control"
                      placeholder="Select Date"
                      value={addStartDate}
                      onChange={(e) => setAddStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 ps-2">
                    <Label htmlFor="formrow-firstname-Input">End Date</Label>
                    <Input
                      type="date"
                      className="form-control"
                      placeholder="Select Date"
                      min={addStartDate}
                      value={addEndDate}
                      onChange={(e) => setAddEndDate(e.target.value)}
                    />
                  </div>

                  <div className="col-md-2 ps-2">
                    <Label htmlFor="formrow-firstname-Input">
                      Estimated Time
                    </Label>
                    <Select
                      placeholder="Select Time"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={TimeOption}
                      value={estimated}
                      onChange={(e) => setEstimatedTime(e)}
                    />
                  </div>

                  <div className="col-md-4 ps-2">
                    <Label htmlFor="formrow-firstname-Input">
                      Assignee <span className="text-danger">*</span>
                    </Label>
                    <Select
                      placeholder="Select Member"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      options={alldependency?.users}
                      closeMenuOnSelect={false}
                      isMulti
                      value={addAssign}
                      onChange={(e) => setAddAssign(e)}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                  <div className="col-md-3 ps-2">
                    <Label htmlFor="formrow-firstname-Input">
                      Priority <span className="text-danger">*</span>
                    </Label>
                    <Select
                      placeholder="Select Priority"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={priorityOption}
                      value={addPriority}
                      onChange={(e) => setAddPriority(e)}
                    />
                  </div>

                  {relatedOptions?.length != 0 && (
                    <div className="col-md-3 ps-2">
                      <label htmlFor="sales-dropdown">
                        {selectedRelated?.label}
                      </label>
                      <Select
                        placeholder={`Select ${selectedRelated?.label}`}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={relatedOptions}
                        value={related_to}
                        onChange={(selectedOption) => {
                          setRelatedto(selectedOption);
                          setRelatedIdOption(
                            selectedOption?.value == "lead"
                              ? alldependency?.leads?.filter(
                                  (obj) => obj?.lead_belong == location?.value
                                )
                              : selectedOption?.value == "proposal"
                              ? alldependency?.proposal
                              : selectedOption?.value == "digital-project"
                              ? alldependency?.digital_projects
                              : selectedOption?.value == "development-project"
                              ? alldependency?.projects
                              : selectedOption?.value == "introducer"
                              ? alldependency.introducer
                              : selectedOption?.value == "staff"
                              ? alldependency.staff
                              : []
                          );
                        }}
                      />
                    </div>
                  )}
                  <div className="related-to-width ps-2">
                    {relatedIdOption?.length != 0 && (
                      <>
                        <label htmlFor="sales-dropdown">
                          {related_to?.label}
                        </label>
                        <Select
                          placeholder={`Select ${related_to?.label}`}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={colourStyles}
                          options={relatedIdOption}
                          value={related_id}
                          onChange={(selectedOption) => {
                            setRelatedId(selectedOption);
                          }}
                        />
                      </>
                    )}
                  </div>
                  {verticalActiveTab == "work" ||
                  verticalActiveTab == "sales" ? (
                    <div className="">
                      <Label htmlFor="formrow-firstname-Input">
                        Copy Notes
                      </Label>
                      <div className="form-switch form-switch-md mb-2">
                        <input
                          title="Intial Days"
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitchsizemd"
                          onChange={(e) => setCopyNotes(e.target.checked)}
                          value={copynotes}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-12 d-flex mb-0 justify-content-start align-items-end flex-column gap-4">
                  <div className="col-12 ps-2">
                    <label htmlFor="">Description</label>
                    <div
                      className="no-drag"
                      onDoubleClick={() => onChangeDrag(true)}
                      onMouseLeave={() => onChangeDrag(false)}
                      onMouseEnter={() => onChangeDrag(true)}
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {}}
                        data={description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTasks;
