import React, { useMemo, useState, useEffect } from "react"
import { Table, Row, Col, Card, CardBody, Modal, Form } from "reactstrap"
import { Link } from "react-router-dom"
// import { useTable, useGlobalFilter, usePagination } from "react-table"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { useNavigate } from "react-router-dom"
// css
import "../../../assets/css/Settings/Setting.css"
// common component
import Pagination from "components/Common/Pagination"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import ShiftTimeImg from "../../../assets/images/ShiftTimeImg.jpg"
import AddShift from "../../../assets/images/EditTime.jpg"
import EditShiftImg from "../../../assets/images/editIShiftImg.jpg"
import NewPagination from "components/Common/Newpagination"
import Swal from "sweetalert2"
import configure from "configure"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import moment from "moment"

const ShiftTime = () => {
  const [add_modal, setAddModal] = useState(false)
  const [edit_modal, setEditModal] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [view_modal, setViewModal] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const [updateDepartment, setUpdateDepartment] = useState("")
  const [departmentId, setDepartmentId] = useState("")
  const [department, setDepartment] = useState("")
  const navigate = useNavigate()

  const [startTime, setStartTime] = useState("")
  const [startName, setStartName] = useState("")
  const [endTime, setEndTime] = useState("")
  const [fixedSideImg, setFixedSideImg] = useState(false)

  const [updateStartTime, setUpdateStartTime] = useState("")
  const [updateStartName, setUpdateStartName] = useState("")
  const [updateEndTime, setUpdateEndTime] = useState("")
  const [shiftId, setShiftId] = useState("")
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)

  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.25; 
    if (window.scrollY > scrollThreshold) {
    // if (window.scrollY > 68) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  document.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setFixedSideImg(true)
    } else {
      setFixedSideImg(false)
    }
  })

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // Center Modal
  function tog_center() {
    setAddModal(false)
    setEditModal(false)
    removeBodyCss()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "id",
        isShortIcon: true,
        className: "shift_id text-center",
      },
      {
        Header: "Shift Name",
        accessor: "shift_name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "shift-name-width",
      },
      {
        Header: "Shift Time",
        accessor: "shift_time",
        isInfoIcon: true,
        isShortIcon: true,
        className: "shift-time-width",
        Cell: cellProps => {
          const startTime = cellProps.row.original.shift_start_time
          const endTime = cellProps.row.original.shift_end_time
          const modifyShift = `${moment(startTime, "HH:mm").format(
            "h:mm A"
          )} to ${moment(endTime, "HH:mm").format("h:mm A")}`
          return <> {modifyShift} </>
        },
      },
      {
        Header: "Action",
        accessor: "action",
        isInfoIcon: false,
        className: "action-width-shift text-center",
        Cell: cellProps => {
          const onShiftDelete = async () => {
            try {
              setLoading(true)
              const formData = new FormData()
              formData.append("user_id", localStorage.getItem("auth_id"))
              formData.append("module_id", 70)
              const permissionResponse = await axios({
                method: "post",
                url: `${configure.apiUrl}/earth_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              })
              if (permissionResponse) {
                setLoading(false)
                if (
                  permissionResponse.data.data.india[5] == 1 ||
                  permissionResponse.data.data.uk[5] == 1
                ) {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to delete this shift !",
                    icon: "warning",
                    showCancelButton: true,
                  }).then(async willDelete => {
                    if (willDelete.isConfirmed) {
                      setLoading(true)
                      const formData = new FormData()
                      formData.append("shift_id", cellProps.row.original.id)
                      try {
                        const response = await axios({
                          method: "post",
                          url: `${configure.apiUrl}/deleteShifts`,
                          data: formData,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        }).then(response => {
                          Swal.fire({
                            icon: "success",
                            text: "Shift deleted successfully",
                          }).then(data => {
                            setDatas(response.data.data)
                          })
                          setLoading(false)
                        })
                      } catch (err) {
                        Swal.fire({
                          icon: "error",
                          text: "Something went wrong",
                        })
                        setLoading(false)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
              }
            } catch (error) {
              console.log(error)
            }
          }
          const onEditDepartment = async e => {
            try {
              setLoading(true)
              const formData = new FormData()
              formData.append("user_id", localStorage.getItem("auth_id"))
              formData.append("module_id", 70)
              const permissionResponse = await axios({
                method: "post",
                url: `${configure.apiUrl}/earth_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              })
              if (permissionResponse) {
                setLoading(false)
                if (
                  permissionResponse.data.data.india[4] == 1 ||
                  permissionResponse.data.data.uk[4] == 1
                ) {
                  setEditModal(true)
                  setShiftId(cellProps.row.original.id)
                  setUpdateStartName(cellProps.row.original.shift_name)
                  setUpdateStartTime(cellProps.row.original.shift_start_time)
                  setUpdateEndTime(cellProps.row.original.shift_end_time)
                } else {
                  setAccessDenied(true)
                }
              }
            } catch (error) {
              setLoading(false)
              console.log(error)
            }
          }
          return (
            <div>
              <button
                onClick={() => onEditDepartment()}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
              <a
                onClick={onShiftDelete}
                className="btn btn-sm action-btn btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )
  
  useEffect(() => {
    // fetch()
    setPageSize(50)
  }, [])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/shifts`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const handleAddClose = () => {
    setAddModal(false)
    setStartName("")
    setStartTime("")
    setEndTime("")
  }

  const handleEditClose = () => {
    setEditModal(false)
    setShiftId("")
    setUpdateStartName("")
    setUpdateStartTime("")
    setUpdateEndTime("")
  }

  const onAddShift = async e => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("module_id", 70)
      const permissionResponse = await axios({
        method: "post",
        url: `${configure.apiUrl}/earth_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (permissionResponse) {
        setLoading(false)
        if (
          permissionResponse.data.data.india[3] == 1 ||
          permissionResponse.data.data.uk[3] == 1
        ) {
          setAddModal(true)
        } else {
          setAccessDenied(true)
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleAdd = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("shift_name", startName)
    formData.append("start_time", startTime)
    formData.append("end_time", endTime)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addShifts`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        setDatas(response.data.data)
        setAddModal(false)
        Swal.fire({
          icon: "success",
          text: "Shift added successfully",
        })
      })
    } catch (err) {
      setLoading(false)
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
      })
    }
  }

  const handleEdit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("shift_id", shiftId)
    formData.append("shift_name", updateStartName)
    formData.append("start_time", updateStartTime)
    formData.append("end_time", updateEndTime)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/editShifts`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setEditModal(false)
        Swal.fire({
          icon: "success",
          text: "Shift edited successfully.",
        })
        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
      Swal.fire({
        icon: "error",
        text: "Something went wrong",
      })
    }
  }

  return (
    <>
      <div>
        {isLoading && <Loader />}
        <Row className="ms-0 pe-0">
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>
                <div>
                  <div
                    className={`flex-column fixed-buttons ${
                      fixedContent ? "d-flex" : "d-none"
                    }`}
                  >
                    <button
                      onClick={() => onAddShift()}
                      className="btn btn-purple border-radius fix-btn"
                    >
                      <i className="fas fa-plus me-2 icon-size"></i>
                      <span>Shift</span>
                    </button>
                    <a
                      onClick={() => navigate(-1)}
                      className="btn btn-red border-radius fix-btn"
                    >
                      <i className="dripicons-cross me-2 icon-center"></i>
                      <span>Cancel</span>
                    </a>
                  </div>
                  <Row className="mb-2">
                    <Col>
                      <select
                        className="border-radius form-select"
                        style={{ width: "100px" }}
                        value={pageSize}
                        onChange={e => setPageSize(e.target.value)}
                      >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={8} className="d-flex justify-content-end">
                      <div className="me-4">
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => setGlobalFilter(e.target.value)}
                                id="search-bar-0"
                                type="text"
                                className="border-radius form-control rounded h-100"
                                placeholder={` Search...`}
                                value={globalFilter || ""}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <button
                          onClick={() => onAddShift()}
                          className="btn btn-purple btn-label"
                        >
                          <i className="fas fa-plus me-2 icon-size label-icon"></i>
                          Shift
                        </button>
                        <button
                          onClick={() => navigate(-1)}
                          className="btn ms-md-2 btn-red btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          CANCEL
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className={`${fixedSideImg ? "departImg" : ""}`}>
                        <img
                          src={ShiftTimeImg}
                          alt="departmentImg"
                          className="w-100"
                        />
                      </div>
                    </div>

                    <div className="col-md-8 pt-4">
                      <Table
                        className="table table-bordered mt-4"
                        {...getTableProps()}
                      >
                        <thead
                          className={`${(fixedContent && page.length >= 10) ? "fixed-s-setting-header" : ""
                          }`}
                        >
                          {headerGroups.map((headerGroup, i) => (
                            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column, index) => (
                                <th
                                  key={index}
                                  className={`${column.className} customShortIcon`}
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  {column.render("Header")}
                                  {column.isInfoIcon ? (
                                    <a
                                      onClick={() => {
                                        // if(column)
                                        if (
                                          column.Header == "Department Name"
                                        ) {
                                          setViewModal(true)
                                        } else if (column.Header == "Action") {
                                          setActionModal(true)
                                        }
                                      }}
                                    >
                                      <i
                                        title="info"
                                        className="bx bx-info-circle ms-1 info-icon"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {column.isShortIcon && (
                                    <span
                                      onClick={() =>
                                        column.getSortByToggleProps().onClick()
                                      }
                                      className="ms-2 cursor-pointer"
                                    >
                                      <i
                                        title="Ascending"
                                        className={`bi bi-arrow-up info-icon ${
                                          column.isSorted &&
                                          !column.isSortedDesc
                                            ? "text-dark"
                                            : ""
                                        }`}
                                      ></i>
                                      <i
                                        title="Descending"
                                        className={`bi bi-arrow-down info-icon ${
                                          column.isSorted && column.isSortedDesc
                                            ? "text-dark"
                                            : ""
                                        }`}
                                      ></i>
                                    </span>
                                  )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                          {page.map((row, i) => {
                            prepareRow(row)
                            return (
                              <tr
                                className="row-hover"
                                {...row.getRowProps()}
                                key={i}
                              >
                                {row.cells.map((cell, index) => {
                                  return (
                                    <td
                                      key={index}
                                      className={`t-col-${index + 1} ${
                                        cell.column.className
                                      }`}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                          {page.length == 0 && (
                            <tr>
                              <td className="text-center" colSpan={10}>
                                No Data Found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>

                  {/* Pagination */}
                  <Row className="justify-content-md-end justify-content-center align-items-center">
                    <NewPagination
                      gotoPage={gotoPage}
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                      pageSize={pageSize}
                      page={page}
                      data={data}
                    />
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Add Shift Modal */}
      <Modal
        isOpen={add_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Shift</h5>
          <button
            type="button"
            onClick={() => handleAddClose()}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleAdd}>
            <Row>
              <div className="col-5">
                <img
                  src={AddShift}
                  className="img-fluid"
                  style={{ width: "90%" }}
                />
              </div>
              <div className="col-7 d-flex flex-column align-items-start justify-content-center gap-4">
                <div className="col-9 pe-4">
                  <label htmlFor="">
                    Shift Name
                    <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Shift Name"
                    className="form-control "
                    required
                    value={startName}
                    onChange={e => setStartName(e.target.value)}
                  />
                </div>
                <div className="col-12 d-flex justify-content-start align-items-center gap-3">
                  <div className="col-4">
                    <label htmlFor="">
                      Shift Start Time
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="time"
                      // placeholder="Enter Department Name"
                      className="form-control "
                      required
                      value={startTime}
                      onChange={e => setStartTime(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="">
                      Shift End Time
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="time"
                      // placeholder="Enter Department Name"
                      className="form-control "
                      required
                      value={endTime}
                      onChange={e => setEndTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <a
                onClick={() => handleAddClose()}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}

      {/* Edit Shift Modal */}
      <Modal
        isOpen={edit_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Shift</h5>
          <button
            type="button"
            onClick={() => handleEditClose()}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleEdit}>
            <Row>
              <div className="col-5">
                <img
                  src={EditShiftImg}
                  className="img-fluid"
                  style={{ width: "90%" }}
                />
              </div>
              <div className="col-7 d-flex flex-column align-items-start justify-content-center gap-4">
                <div className="col-9 pe-4">
                  <label htmlFor="">
                    Shift Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Shift Name"
                    className="form-control "
                    required
                    value={updateStartName}
                    onChange={e => setUpdateStartName(e.target.value)}
                  />
                </div>
                <div className="col-12 d-flex justify-content-start align-items-center gap-3">
                  <div className="col-4">
                    <label htmlFor="">
                      Shift Start Time
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      // placeholder="Enter Department Name"
                      className="form-control "
                      required
                      value={updateStartTime}
                      onChange={e => setUpdateStartTime(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="">
                      Shift End Time
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      // placeholder="Enter Department Name"
                      className="form-control "
                      required
                      value={updateEndTime}
                      onChange={e => setUpdateEndTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <a
                onClick={() => handleEditClose()}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}

      <Modal
        isOpen={view_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Shift Name Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setViewModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </>
  )
}

export default ShiftTime
