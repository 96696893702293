import React, { useState, useEffect } from "react"
import { Row, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"

const EmailPopup = ({ verticalActiveTab, popupMessage }) => (
  <div className="email-popup">
    <p>{popupMessage}</p>
  </div>
)

const PersonalDetails = ({
  verticalActiveTab,
  setverticalActiveTab,
  allEmail,
}) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const [roles, setRoles] = useState([])
  const [departments, setDepartments] = useState([])
  const [staffMember, setStaffMember] = useState([])
  const [swift, setSwift] = useState([])
  // {
  //   label: "None",
  //   value: "None",
  // },
  // {
  //   label: "General Shift - (09:30 - 06:30)",
  //   value: "General Shift - (09:30 - 06:30)",
  // },
  const [swiftDec, setSwiftDec] = useState({
    label: null,
    value: null,
  })
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCites] = useState([])
  const [emailIsvalid, setEmailIsvalid] = useState(true)
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    // fetch()
    fetch2()
  }, [])
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  // const fetch = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await axios({
  //       method: "get",
  //       url: `${configure.apiUrl}/roles`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(response => {
  //       const option = response.data.data.map(item => ({
  //         value: item.id,
  //         label: item.role_name,
  //       }))
  //       // setRoles(option)
  //       setLoading(false)
  //     })
  //   } catch (err) {
  //     console.log(err)
  //     setLoading(false)
  //   }
  // }
  const fetch2 = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/departments`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const option = response.data.data.department.map(item => ({
          value: item.id,
          label: item.department_name,
        }))
        const options1 = response.data.data.country.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setCountries(options1)
        // setDepartments(option)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  // const fetchSwift = async () => {
  //   setLoading(true)
  //   console.log("fetchSwift")
  //   try {
  //     const response = await axios({
  //       method: "get",
  //       url: `${configure.apiUrl}/shift`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(response => {
  //       console.log("swift", response)
  //       const option = response.data.data.department.map(item => ({
  //         value: item.id,
  //         label: item.department_name,
  //       }))
  //       const options1 = response.data.data.country.map(item => ({
  //         value: item.id,
  //         label: item.name,
  //       }))
  //       setSwift([...swift, option])
  //       setLoading(false)
  //     })
  //   } catch (err) {
  //     console.log(err)
  //     setLoading(false)
  //   }
  // }
  const getStaffData = async () => {
    setLoading(true)
    // console.log("fetchSwift")
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staffData`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log("staffData", response)
        // value: "General Shift - (09:30 - 06:30)",
        const option = response.data.data.shift.map(item => ({
          value: item.id,
          label: `${item.shift_name} - (${item.shift_start_time} - ${item.shift_end_time})`,
        }))
        setSwift(option)
        const optionRole = response.data.data.roles.map(item => ({
          value: item.id,
          label: item.role_name,
        }))
        setRoles(optionRole)
        const optionDepartment = response.data.data.deparment.map(item => ({
          value: item.id,
          label: item.department_name,
        }))
        setDepartments(optionDepartment)
        const optionStaff = response.data.data.staff?.map(item => ({
          value: item.id,
          label: item.employee_name,
        }))
        setStaffMember(optionStaff)
        // const option = response.data.data.department.map(item => ({
        //   value: item.id,
        //   label: item.department_name,
        // }))
        // const options1 = response.data.data.country.map(item => ({
        //   value: item.id,
        //   label: item.name,
        // }))
        // setSwift([...swift, option])
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    getStaffData()
    // fetchSwift()
  }, [])

  const onState = async e => {
    setCountry(e.value)
    setLoading(true)
    const formData = new FormData()
    formData.append("country", e.value)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/states`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const option = response.data.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setStates(option)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const onCity = async e => {
    setState(e.value)
    setLoading(true)
    const formData = new FormData()
    formData.append("state", e.value)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const option = response.data.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setCites(option)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // member Adding Functionality

  const [inputFields, setInputFields] = useState([{}])
  const onAddFamilyRow = () => {
    setInputFields([...inputFields, {}])
  }
  const onDeleteFamilyRow = (index, e) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields]
      values.splice(index.target.id, 1)
      setInputFields(values)
    }
  }

  // Bank Details Adding Functionality

  const [inputFields_1, setInputFields1] = useState([{}])
  const onAddBankRow = () => {
    setInputFields1([...inputFields_1, {}])
  }
  const onDeleteBankRow = (index, e) => {
    if (inputFields_1.length !== 1) {
      const values = [...inputFields_1]
      values.splice(index.target.id, 1)
      setInputFields1(values)
    }
  }

  const [isLoading, setLoading] = useState(false)
  const [logo, setLogo] = useState("")
  const [role_name, setRoleName] = useState("")
  const [department, setDepartment] = useState("")
  const [designation, setDesignation] = useState("")

  const [emp_id, setEmpId] = useState("")
  const [punch_id, setPunchId] = useState("")
  const [date_join, setDateJoin] = useState("")
  const [date_resign, setDateResign] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [custType, setCustType] = useState("new")
  const [alias_name, setAliasName] = useState("")
  const [mobile, setMobile] = useState("")
  const [gender, setGender] = useState("")
  const [birth_date, setBirthDate] = useState("")
  const [marital_status, setMaritialStatus] = useState("")
  const [religion, setReligion] = useState("")
  const [linkedin, setlinkedin] = useState("")
  const [address_1, setAddress1] = useState("")
  const [address_2, setAddress2] = useState("")
  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [pincode, setPincode] = useState("")
  const [pf_account, setPfAccount] = useState("")
  const [aadhaar, setAadhar] = useState("")
  const [pan_account, setPanAccount] = useState("")
  const [passport, setPassport] = useState("")
  const [member_name, setMemberName] = useState([])
  const [relationship, setRelationshp] = useState([])
  const [occupation, setOccupation] = useState([])
  const [contact, setContact] = useState([])
  const [bank_name, setBankName] = useState([])
  const [branch_name, setBranchName] = useState([])
  const [bank_account, setBankAccount] = useState([])
  const [bank_ifsc, setBankIfsc] = useState([])

  const [findAddStaff, setFindAddStaff] = useState("")

  useEffect(() => {
    setFindAddStaff(localStorage.getItem("staffId"))
  }, [verticalActiveTab])

  const onMemberName = (index, e) => {
    const values = [...inputFields]
    values[index][e.target.name] = e.target.value
    let first_item
    setMemberName(
      values.map(item => {
        first_item = item.member
        return first_item
      })
    )
  }
  const onRelationship = (index, e) => {
    const values = [...inputFields]
    values[index][e.target.name] = e.target.value
    let first_item
    setRelationshp(
      values.map(item => {
        first_item = item.relationship
        return first_item
      })
    )
  }
  const onOccupation = (index, e) => {
    const values = [...inputFields]
    values[index][e.target.name] = e.target.value
    let first_item
    setOccupation(
      values.map(item => {
        first_item = item.relationship
        return first_item
      })
    )
  }
  const onContact = (index, e) => {
    const values = [...inputFields]
    values[index][e.target.name] = e.target.value
    let first_item
    setContact(
      values.map(item => {
        first_item = item.contact
        return first_item
      })
    )
  }

  const onBankName = (index, e) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setBankName(
      values.map(item => {
        first_item = item.bank
        return first_item
      })
    )
  }
  const onBranchName = (index, e) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setBranchName(
      values.map(item => {
        first_item = item.branch
        return first_item
      })
    )
  }
  const onBankAccount = (index, e) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setBankAccount(
      values.map(item => {
        first_item = item.account
        return first_item
      })
    )
  }
  const onIFSC = (index, e) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setBankIfsc(
      values.map(item => {
        first_item = item.ifsc
        return first_item
      })
    )
  }
  const onAddStaff = async e => {
    e.preventDefault()
    setLoading(true)
    // console.log("typeof", member_name)
    const formData = new FormData()
    if (findAddStaff) {
      formData.append("id", findAddStaff)
    }
    formData.append("profile", logo)
    formData.append("role_id", role_name)
    formData.append("date_of_resignation", date_resign)
    formData.append("login_email", email)
    formData.append("login_password", password)
    formData.append("employee_code", emp_id)
    formData.append("name", name)
    formData.append("gender", gender)
    formData.append("phone_number", mobile)
    formData.append("linkedin", linkedin)
    formData.append("dob", birth_date)
    formData.append("marital_status", marital_status)
    formData.append("address", address_1)
    formData.append("address_line_2", address_2)
    formData.append("state", state)
    formData.append("city", city)
    formData.append("zip_code", pincode)
    formData.append("religion", religion)
    formData.append("pf_account_number", pf_account)
    formData.append("pancard_number", pan_account)
    formData.append("passport_number", passport)
    formData.append("alias_name", alias_name)
    formData.append("shift_id", swiftDec.value)
    formData.append("department", department)
    formData.append("designation", designation)
    formData.append("punchid", punch_id)
    formData.append("date_of_joining", date_join)
    formData.append("country", country)
    formData.append("aadharcard_number", aadhaar)
    formData.append("member_name", member_name?.toString())
    formData.append("relationship", relationship?.toString())
    formData.append("occupation", occupation?.toString())
    formData.append("contact_no", contact?.toString())
    formData.append("bank_name", bank_name?.toString())
    formData.append("branch_name", branch_name?.toString())
    formData.append("account_number", bank_account?.toString())
    formData.append("ifsc_code", bank_ifsc?.toString())
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/${findAddStaff ? "updatestaff" : "addstaff"}`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const newStaffId = findAddStaff
          ? findAddStaff
          : response.data?.data[0]?.id
        if (!findAddStaff) {
          localStorage.setItem("staffId", response.data?.data[0]?.id)
          localStorage.setItem("roleId", response.data?.data[0]?.roleid)
          setFindAddStaff(
            findAddStaff ? findAddStaff : response.data?.data[0]?.id
          )
        }
        Swal.fire({
          icon: "success",
          text: `Staff Personal Details ${findAddStaff ? "Updated" : "Added"}  Successfully`,
        }).then(data => {
          // setverticalActiveTab("2")
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [popupMessage, setPopupMessage] = useState("")
  const handleEmailChange = e => {
    const enteredEmail = e.target.value
    setEmail(enteredEmail)

    if (enteredEmail.trim() === "") {
      setEmailIsvalid(false)
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValid = emailRegex.test(enteredEmail)
      const isExits = !allEmail.includes(enteredEmail.trim())
      if (isValid && isExits) {
        setEmailIsvalid(true)
        setShowPopup(false)
      } else {
        setEmailIsvalid(false)
        setShowPopup(true)
      }
      if (!isValid) {
        setPopupMessage("Please enter a valid email address.")
      } else if (isExits) {
        setPopupMessage("Email is already registered.")
      }
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      {/* Fields */}
      <Form
        className="repeater"
        onSubmit={onAddStaff}
        encType="multipart/form-data"
        id="addstaff"
      >
        {/* Fixed Buttons */}
        <div
          className={`flex-column fixed-buttons pt-2 ${
            fixButtons ? "d-flex" : "d-none"
          }`}
        >
          <button className="btn btn-save fix-btn btn-label pe-0">
            <i className="fas fa-save me-2 label-icon"></i>
            <span>Save</span>
          </button>
          <button
            onClick={() => navigate(-1)}
            className="btn btn-red fix-btn btn-label pe-0"
            type="button"
          >
            <i className="dripicons-cross me-2 icon-center label-icon"></i>
            <span>Cancel</span>
          </button>
        </div>
        <div className="mb-3 shadow bg-white p-4">
          <Row>
            <div className="col-md-6">
              <h4 className="text-dark above">Personal Details</h4>
            </div>

            <div className="col-md-6 text-md-end">
              <button className="btn me-md-0 me-2 btn-save btn-label">
                <i className="fas fa-save me-2 label-icon"></i>SAVE
              </button>
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Row>
          <h5 className="mb-4 text-dark">General Info</h5>
          <Row>
            <label htmlFor="">Profile Picture</label>
            <div className="col-md-6 mb-4 ">
              <input
                type="file"
                className="form-control "
                id="document"
                onChange={e => setLogo(e.target.files[0])}
              />
            </div>
          </Row>
          <Row>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Role Name</label>
              <Select
                placeholder="Select Role"
                className="basic-multi-select "
                classNamePrefix="select"
                options={roles}
                onChange={e => setRoleName(e.value)}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Department</label>
              <Select
                placeholder="Select Department"
                className="basic-multi-select "
                classNamePrefix="select"
                options={departments}
                onChange={e => setDepartment(e.value)}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Designation</label>
              <input
                type="text"
                placeholder="Enter Designation"
                className="form-control "
                id=""
                onChange={e => setDesignation(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Shift</label>
              <Select
                placeholder="Select Shift"
                className="basic-multi-select "
                classNamePrefix="select"
                options={swift}
                onChange={e =>
                  setSwiftDec({
                    label: e.label,
                    value: e.value,
                  })
                }
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">
                Employee Id <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="e.g 99999"
                className="form-control "
                id=""
                onChange={e => setEmpId(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">Punch Id</label>
              <input
                type="text"
                placeholder="Enter Punch Id"
                className="form-control "
                id=""
                onChange={e => setPunchId(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <div className="pe-5">
                <label htmlFor="">Date Of Joining</label>
                <input
                  type="date"
                  className="form-control "
                  onChange={e => setDateJoin(e.target.value)}
                  id=""
                />
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Date of Resignation / Date of Termination
              </label>
              <input
                type="date"
                className="form-control "
                onChange={e => setDateResign(e.target.value)}
                id=""
              />
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Email Id <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Enter Email Id"
                className={`form-control  ${!emailIsvalid ? "is-invalid" : ""}`}
                onFocus={() => setShowPopup(false)}
                onBlur={() => setShowPopup(true)}
                id=""
                onChange={e => {
                  handleEmailChange(e)
                }}
              />
              {!emailIsvalid && showPopup && (
                <EmailPopup popupMessage={popupMessage} />
              )}
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Password <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Password"
                className="form-control "
                id=""
                onChange={e => setPassword(e.target.value)}
                required
              />
            </div>
          </Row>
        </div>

        <div className="shadow bg-white p-4">
          <h5 className="mb-4 text-dark">Personal Details</h5>
          <div className="col-12 mb-3">
            <label htmlFor="" className="d-block">
              Staff Type
            </label>
            <span className="me-4 ">
              <input
                className="form-check-input input-location"
                type="radio"
                id="new"
                defaultChecked={true}
                onInput={e => setCustType(e.target.value)}
                name="type"
                value="new"
              />
              <label htmlFor="new" className="ms-2">
                New
              </label>
            </span>
            <span>
              <input
                className="form-check-input input-location"
                type="radio"
                onInput={e => setCustType(e.target.value)}
                id="existing"
                name="type"
                value="existing"
              />
              <label htmlFor="existing" className="ms-2">
                {" "}
                Existing
              </label>
            </span>
          </div>
          <Row>
            <div className="col-md-3 mb-3">
              <label htmlFor="">
                Employee Name <span className="text-danger">*</span>
              </label>
              {custType === "new" ? (
                <input
                  required
                  type="text"
                  placeholder="Enter Employee Name"
                  className="form-control "
                  id=""
                  onChange={e => setName(e.target.value)}
                />
              ) : (
                <Select
                  placeholder="Employee Name"
                  className="basic-multi-select"
                  classNamePrefix="border-radius select"
                  styles={colourStyles}
                  onChange={e => setName(e.value)}
                  options={staffMember}
                />
              )}
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">
                Alias Name <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Enter Alias Name"
                className="form-control "
                id=""
                onChange={e => setAliasName(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Enter Phone No."
                className="form-control "
                minLength={10}
                maxLength={10}
                id=""
                onChange={e => setMobile(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">
                Gender <span className="text-danger">*</span>
              </label>
              <select
                required
                name=""
                id=""
                className="form-select "
                onChange={e => setGender(e.target.value)}
              >
                <option value="">None</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="col-md-3 mb-3">
              <div className="pe-5">
                <label htmlFor="">Birth Date</label>
                <input
                  type="date"
                  className="form-control "
                  id=""
                  onChange={e => setBirthDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Marital Status</label>
              <select
                name=""
                id=""
                className="form-select "
                onChange={e => setMaritialStatus(e.target.value)}
              >
                <option value="Nothing Selected">Nothing Selected</option>
                <option value="Unmarried">Unmarried</option>
                <option value="Married">Married</option>
                <option value="Divorce">Divorce</option>
              </select>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Religion</label>
              <input
                type="text"
                placeholder="Enter Religion"
                className="form-control "
                id=""
                onChange={e => setReligion(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">LinkedIn</label>
              <input
                type="text"
                placeholder="Enter LinkedIn"
                className="form-control "
                id=""
                onChange={e => setlinkedin(e.target.value)}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <h5 className="mb-4 text-dark">Address Details</h5>
          <Row>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Address Line 1</label>
              <input
                type="text"
                placeholder="Enter Address Line 1"
                className="form-control "
                id=""
                onChange={e => setAddress1(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Address Line 2</label>
              <input
                type="text"
                placeholder="Enter Address Line 2"
                className="form-control "
                id=""
                onChange={e => setAddress2(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Country</label>
              <Select
                placeholder="Select Country"
                className="basic-multi-select "
                classNamePrefix="select"
                options={countries}
                onChange={onState}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">State</label>
              <Select
                placeholder="Select State"
                className="basic-multi-select "
                classNamePrefix="select"
                options={states}
                onChange={onCity}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">City</label>
              <Select
                placeholder="Select City"
                className="basic-multi-select "
                classNamePrefix="select"
                options={cities}
                onChange={e => {
                  setCity(e.value)
                }}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Pin Code</label>
              <input
                type="number"
                placeholder="Enter Pin Code"
                className="form-control "
                id=""
                onChange={e => setPincode(e.target.value)}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <h5 className="mb-4 text-dark">KYC Details</h5>
          <Row>
            <div className="col-md-4 mb-4">
              <label htmlFor="">PF Account Number </label>
              <input
                type="text"
                placeholder="Enter Account Number"
                className="form-control "
                id=""
                onChange={e => setPfAccount(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label htmlFor="">Aadhar Card Number </label>
              <input
                type="number"
                placeholder="Enter Aadhar Number"
                className="form-control "
                id=""
                onChange={e => setAadhar(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label htmlFor="">PAN Card Number </label>
              <input
                type="text"
                placeholder="Enter Pan Number"
                className="form-control "
                id=""
                onChange={e => setPanAccount(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <label htmlFor="">Passport Number </label>
              <input
                type="text"
                placeholder="Enter Passport Number"
                className="form-control "
                id=""
                onChange={e => setPassport(e.target.value)}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <div className="d-flex justify-content-between mb-4">
            <h5 className="mb-4 text-dark">Family Details</h5>
            <div>
              <a
                className=" btn text-white text-uppercase me-2 btn-purple-light btn-label"
                onClick={e => {
                  e.preventDefault()
                  onAddFamilyRow()
                }}
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Family Details
              </a>
            </div>
          </div>
          {inputFields.map((item, index) => (
            <div className="display-flex align-items-end" key={index}>
              <div className=" mb-3 pr-row">
                <label htmlFor="">Member Name</label>
                <input
                  type="text"
                  placeholder="Enter Memeber Name"
                  className="form-control "
                  name="member"
                  onChange={e => onMemberName(index, e)}
                />
              </div>
              <div className=" mb-3 pr-row pl-row">
                <label htmlFor="">Relationship</label>
                <input
                  type="text"
                  placeholder="Enter Relationship"
                  className="form-control "
                  name="relationship"
                  onChange={e => onRelationship(index, e)}
                />
              </div>
              <div className=" mb-3 pr-row pl-row">
                <label htmlFor="">Occupation</label>
                <input
                  type="text"
                  placeholder="Enter Occupation"
                  className="form-control "
                  onChange={e => onOccupation(index, e)}
                  name="occupation"
                />
              </div>
              <div className=" mb-3 pr-row pl-row">
                <label htmlFor="">Contact No</label>
                <input
                  type="number"
                  placeholder="Enter Contact No"
                  className="form-control "
                  name="contact"
                  onChange={e => onContact(index, e)}
                />
              </div>
              <div className="mb-3 pl-row">
                <a
                  className="btn btn-outline-danger"
                  id={index}
                  onClick={onDeleteFamilyRow}
                >
                  <i className="fas fa-trash-alt"></i>
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <div className="d-flex justify-content-between mb-4">
            <h5 className="mb-4 text-dark">Bank Details</h5>
            <div>
              <button
                onClick={e => {
                  e.preventDefault()
                  onAddBankRow()
                }}
                className=" btn text-white text-uppercase me-2 btn-purple-light btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Bank Details
              </button>
            </div>
          </div>
          {inputFields_1.map((item, index) => (
            <div className="display-flex align-items-end" key={index}>
              <div className=" mb-3 pr-row">
                <label htmlFor="">Bank Name</label>
                <input
                  type="text"
                  placeholder="Enter Bank Name"
                  className="form-control "
                  name="bank"
                  onChange={e => onBankName(index, e)}
                />
              </div>
              <div className=" mb-3 pr-row pl-row">
                <label htmlFor="">Branch Name</label>
                <input
                  type="text"
                  placeholder="Enter Branch Name"
                  className="form-control "
                  name="branch"
                  onChange={e => onBranchName(index, e)}
                />
              </div>
              <div className=" mb-3 pr-row pl-row">
                <label htmlFor="">Bank Account Number</label>
                <input
                  type="number"
                  placeholder="Enter A/C Number"
                  className="form-control "
                  name="account"
                  onChange={e => onBankAccount(index, e)}
                />
              </div>
              <div className=" mb-3 pr-row pl-row">
                <label htmlFor="">IFSC Code</label>
                <input
                  type="text"
                  placeholder="Enter IFSC Code"
                  className="form-control "
                  name="ifsc"
                  onChange={e => onIFSC(index, e)}
                />
              </div>
              <div className="mb-3 pl-row">
                <a
                  className="btn btn-outline-danger"
                  id={index}
                  onClick={onDeleteBankRow}
                >
                  <i className="fas fa-trash-alt"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </Form>
    </>
  )
}

export default PersonalDetails
