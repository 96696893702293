import React, { useState, useEffect } from "react";
import { Form, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import ReactSelect from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  priorityOption,
  RelatedToOption,
  TimeOption,
} from "utils/common-options";
import Swal from "sweetalert2";
import { FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";

const AddTaskTab = ({
  verticalActiveTab,
  locationOptions,
  alldependency,
  onCloseModal,
  toggleLoading,
  updateData,
}) => {
  const [formRows, setFormRows] = useState([{}]);
  const [title, settitle] = useState(null);
  const [location, setLocation] = useState(null);
  const [addStartDate, setAddStartDate] = useState("");
  const [addEndDate, setAddEndDate] = useState("");
  const [addAssign, setAddAssign] = useState([]);
  const [addPriority, setAddPriority] = useState(null);
  const [description, setDescription] = useState("");
  const [relatedOptions, setRelatedOpion] = useState(
    RelatedToOption[0]?.related
  );
  const [selectedRelated, setSelectedRelated] = useState([]);
  const [estimated, setEstimatedTime] = useState("");
  const [related_to, setRelatedto] = useState([]);
  const [related_id, setRelatedId] = useState([]);
  const [relatedIdOption, setRelatedIdOption] = useState([]);
  const [copynotes, setCopyNotes] = useState([]);

  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1200,
    }),
  };
  const onAddFormRow = () => {
    setFormRows([...formRows, {}]);
  };
  const onDeleteFormRow = (index) => {
    const newInputs = [...formRows];
    newInputs.splice(index, 1);
    setFormRows(newInputs);
  };

  const handleInputChange = (key, event) => {
    const { name, value } = event?.target;
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, [name]: value } : row
    );
    setFormRows(updatedFormRows);
  };

  const handleSelectChange = (key, selectedOption) => {
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, task_assign: selectedOption } : row
    );
    setFormRows(updatedFormRows);
  };
  const handleEndChange = (key, e) => {
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, sub_enddate: e } : row
    )
    setFormRows(updatedFormRows)
  }

  const handleSubmit = async () => {
    if (
      location != null &&
      title != null &&
      addPriority != null &&
      addAssign?.length != 0
    ) {
      const formDatas = {
        user_id: localStorage.getItem("auth_id"),
        location: location,
        title: title,
        startdate: addStartDate,
        enddate: addEndDate,
        assigned: addAssign?.map((obj) => obj.value)?.join(","),
        priority: addPriority,
        related: selectedRelated?.value,
        related_to: related_to?.value,
        related_id: related_id?.value,
        est_time: estimated,
        description: description,
        subtask: formRows?.map((obj) => obj?.task_name)?.join("#@"),
        subtask_assign: formRows?.map((obj) => obj?.task_assign)?.join(","),
        sub_enddate: formRows?.map(obj => obj?.sub_enddate)?.join(","),
        type: 1,
        copynotes: copynotes,
      };
      const response = await FetchPostData(
        toggleLoading,
        "addGeneralTasks",
        secureLocalStorage.getItem("auth_token"),
        formDatas
      );
      if (response?.data?.success) {
        Swal.fire({
          icon: "success",
          text: "General task added successfully.",
        }).then(() => {
          onCloseModal();
          updateData(response?.data?.data?.tasks);
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      });
    }
  };

  useEffect(() => {
    const relatedOptions = RelatedToOption?.find(
      (obj) => obj.id == verticalActiveTab
    );
    setSelectedRelated(relatedOptions);
    setRelatedOpion(relatedOptions?.related);
  }, [verticalActiveTab]);
  return (
    <div>
      <div className="text-end add-task-button">
        <button
          type="submit"
          className=" btn ms-md-2 btn-save btn-label"
          onClick={handleSubmit}
        >
          <i className="fas fa-save me-1 label-icon"></i> Save
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onCloseModal();
          }}
          className=" btn ms-md-2 btn-red btn-label"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          CANCEL
        </button>
      </div>
      <form className="row justify-content-center align-items-center">
        <div className="col-md-12 d-flex flex-column new-task-modal ">
          <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
            <div className="col-sm-2 ps-2">
              <Label htmlFor="formrow-firstname-Input">
                Location <span className="text-danger">*</span>
              </Label>
              <Select
                placeholder="Select Location"
                className="basic-multi-select"
                classNamePrefix=" select"
                options={locationOptions}
                onChange={(e) => setLocation(e.value)}
              />
            </div>
            <div className="col-md-10 ps-2">
              <Label htmlFor="formrow-firstname-Input">
                Task Name <span className="text-danger">*</span>
              </Label>
              <div className="d-flex justify-content-start align-items-center gap-2">
                <Input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Enter Task Name"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
            <div className="col-md-2 ps-2">
              <Label htmlFor="formrow-firstname-Input">Start Date</Label>
              <Input
                type="date"
                className="form-control"
                placeholder="Select Date"
                value={addStartDate}
                onChange={(e) => setAddStartDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 ps-2">
              <Label htmlFor="formrow-firstname-Input">End Date</Label>
              <Input
                type="date"
                className="form-control"
                placeholder="Select Date"
                value={addEndDate}
                onChange={(e) => setAddEndDate(e.target.value)}
              />
            </div>
            <div className="col-md-2 ps-2">
              <Label htmlFor="formrow-firstname-Input">Estimated Time</Label>
              <Select
                placeholder="Select Time"
                className="basic-multi-select"
                classNamePrefix="select"
                options={TimeOption}
                onChange={(e) => setEstimatedTime(e.value)}
              />
            </div>
            <div className="col-md-4 ps-2">
              <Label htmlFor="formrow-firstname-Input">
                Assignee <span className="text-danger">*</span>
              </Label>
              <ReactSelect
                placeholder="Select Member"
                className="basic-multi-select "
                classNamePrefix="select"
                options={alldependency?.users}
                isMulti
                closeMenuOnSelect={false}
                onChange={(e) => setAddAssign(e)}
              />
            </div>
            <div className="col-md-2 ps-2">
              <Label htmlFor="formrow-firstname-Input">
                Priority <span className="text-danger">*</span>
              </Label>
              <Select
                placeholder="Select Priority"
                className="basic-multi-select"
                classNamePrefix=" select"
                options={priorityOption}
                onChange={(e) => setAddPriority(e.value)}
              />
            </div>
          </div>
          <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
            {relatedOptions?.length != 0 && (
              <div className="col-md-3 ps-2">
                <label htmlFor="sales-dropdown">{selectedRelated?.label}</label>
                <Select
                  placeholder={`Select ${selectedRelated?.label}`}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={colourStyles}
                  options={relatedOptions}
                  value={related_to}
                  onChange={(selectedOption) => {
                    setRelatedto(selectedOption);
                    setRelatedIdOption(
                      selectedOption?.value == "lead"
                        ? alldependency?.leads
                        : selectedOption?.value == "proposal"
                        ? alldependency?.proposal
                        : selectedOption?.value == "digital-project"
                        ? alldependency?.digital_projects
                        : selectedOption?.value == "development-project"
                        ? alldependency?.projects
                        : selectedOption?.value == "introducer"
                        ? alldependency.introducer
                        : selectedOption?.value == "staff"
                        ? alldependency.staff
                        : []
                    );
                  }}
                />
              </div>
            )}
            {relatedIdOption?.length != 0 && (
              <div className="col-md-3 ps-2">
                <label htmlFor="sales-dropdown">{related_to?.label}</label>
                <Select
                  placeholder={`Select ${related_to?.label}`}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={colourStyles}
                  options={relatedIdOption}
                  value={related_id}
                  onChange={(selectedOption) => {
                    setRelatedId(selectedOption);
                  }}
                />
              </div>
            )}
            {verticalActiveTab == "3" || verticalActiveTab == "4" ? (
              <div className="col-md-2 ps-2">
                <Label htmlFor="formrow-firstname-Input">Copy Notes</Label>
                <div className="form-switch form-switch-md mb-2">
                  <input
                    title="Intial Days"
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd"
                    onChange={(e) => setCopyNotes(e.target.checked)}
                    value={copynotes}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 d-flex mb-0 justify-content-start align-items-end flex-column gap-4">
            <div className="col-12 ps-2">
              <label htmlFor="">Description</label>
              <div className="no-drag">
                <CKEditor
                  editor={ClassicEditor}
                  onReady={(editor) => {}}
                  data={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 pe-2 form-container mt-4 ms-2">
            <Row className="bg-white px-2 py-2 mt-3 shadow-md mb-3">
              <div className="d-flex justify-content-end align-items-end px-0">
                <div className="text-end mb-0">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onAddFormRow();
                    }}
                    className="btn btn-purple  "
                  >
                    <i className="fas fa-plus me-2"></i>
                    Add Subtask
                  </button>
                </div>
              </div>
              {formRows?.map((person, key) => {
                return (
                  <div key={key} className="">
                    <div className="d-flex gap-2">
                      <div className="contact-form ">
                        <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                          <label htmlFor="">Subtask Name</label>
                          <input
                            type="text"
                            name="task_name"
                            placeholder="Enter Subtask Name"
                            className="form-control "
                            value={person.task_name}
                            onChange={(e) => handleInputChange(key, e)}
                          />
                        </div>
                      </div>
                      <div className="contact-column edit-contact-column mb-md-0 col-2">
                        <label htmlFor="">Assignee</label>
                        <ReactSelect
                          placeholder="Select Member"
                          className="basic-multi-select "
                          classNamePrefix="select"
                          name="task_assign"
                          options={addAssign}
                          onChange={(selectedOption) =>
                            handleSelectChange(key, selectedOption?.value)
                          }
                        />
                      </div>
                      <div className="contact-column edit-contact-column mb-md-0 col-2">
                        <label htmlFor="">End Date</label>
                        <input
                          type="date"
                          name="sub_enddate"
                          className="form-control"
                          value={person?.sub_enddate}
                          onChange={(e) => handleEndChange(key, e.target.value)}
                        />
                      </div>
                      <div className=" contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                        <div className="text-end">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              onDeleteFormRow(key);
                            }}
                            className="btn btn-red"
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    {key !== formRows.length - 1 ? (
                      <div className="border-bottom my-3"></div>
                    ) : null}
                  </div>
                );
              })}
            </Row>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTaskTab;
