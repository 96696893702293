import React, { useState, useEffect } from "react"
import { Row, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import { fetchData } from "utils/common-fetchData"

const EmailPopup = () => (
  <div className="email-popup">
    <p>Please enter a valid email address.</p>
  </div>
)

const PersonalDetails = ({ id, getStaffName }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [error, setError] = useState(false)

  const navigate = useNavigate()

  document.title = "Edit Staff | Zithas Crm"

  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.25;
    if (window.scrollY > scrollThreshold) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const [roles, setRoles] = useState([])
  const [departments, setDepartments] = useState([])
  const [staffMember, setStaffMember] = useState([])
  const [swift, setSwift] = useState([])
  const [swiftDec, setSwiftDec] = useState({
    label: null,
    value: null,
  })
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCites] = useState([])
  const [emailIsvalid, setEmailIsvalid] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const handleEmailChange = e => {
    const enteredEmail = e.target.value
    setEmail(enteredEmail) // Set the email state with the entered value

    // Check if entered email is empty
    if (enteredEmail.trim() === "") {
      setEmailIsvalid(false) // Set emailIsValid to false if email is empty
    } else {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValid = emailRegex.test(enteredEmail) // Check if entered email matches the pattern
      setEmailIsvalid(!isValid) // Set emailIsValid to true if email is not valid
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }


  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  const fetchDatas = async () => {
    const allOptions = await getStaffData()
    await getPersonalDetails(allOptions)
  }

  const getStaffDetails = async () => {
    const auth = secureLocalStorage.getItem('auth_token')
    const getData = await fetchData(toggleLoading, `showStaff/${id}`, auth);
    const staffData = getData?.data?.staff
    const roles = getData?.data?.roles?.filter(ob => {
      return ob.id == staffData?.roleid
    })

    setRoles(getData?.data?.roles?.map((obj) => ({
      value: obj.id,
      label: obj.role_name,
    })))

    setRoleName({
      label: roles[0]?.role_name,
      value: roles[0]?.id,
    })

    const shift = getData?.data?.department.filter(ob => {
      return ob.id == staffData.shift_id
    })

    const ShiftOption = getData?.data?.shifts?.map(item => ({
      value: item.id,
      label: `${item.shift_name} - (${item.shift_start_time} - ${item.shift_end_time})`,
    }))
    setSwift(ShiftOption)

    setSwiftDec({
      label: shift[0]?.department_name,
      value: shift[0]?.id,
    })
    setDepartments(getData?.data?.department?.map((obj) => ({
      value: obj.id,
      label: obj.department_name,
    })))

    getStaffName({
      name: staffData?.name,
      email: staffData?.email,
      avatar: staffData?.avatar,
      roleId: staffData?.roleid,
      Role: roles[0]?.role_name,
      commitment: staffData?.commitment
    })


    const CountryOptions = getData?.data?.country?.map(ct => ({
      value: ct.id,
      label: ct.name,
    }))
    setCountries(CountryOptions)
    const cntry = CountryOptions?.filter(ob => {
      return ob.value == staffData.country
    })
    setCountry({
      label: cntry[0]?.label,
      value: cntry[0]?.value,
    })

    const StateOption = getData?.data?.state?.map(st => ({
      value: st.id,
      label: st.name,
      country_id: st?.country_id
    }))
    setStates(StateOption?.filter((obj) => obj?.country_id == staffData.country))
    const stat = StateOption?.filter(ob => {
      return ob.value == staffData.state
    })
    setState({
      label: stat[0]?.label,
      value: stat[0]?.value,
      country_id: stat[0]?.country_id
    })

    const option = getData?.data?.city?.map(item => ({
      value: item.id,
      label: item.name,
      state_id: item?.state_id
    }))
    setCites(option?.filter((obj) => obj?.state_id == staffData.state))
    const cty = option?.filter(ob => {
      return ob.value == staffData.city
    })
    setCity({
      label: cty[0]?.label,
      value: cty[0]?.value,
    })

    setPincode(staffData.zip_code)
    setPfAccount(staffData.pf_account_number)
    setAadhar(staffData.aadharcard_number)
    setPassport(staffData.passport_number)
    setPanAccount(staffData.pancard_number)
    setImage(`${configure.imgUrl}${staffData.avatar}`)
    setDesignation(staffData.designation)
    setEmpId(staffData.employee_code)
    setPunchId(staffData.punchid)
    setDateJoin(staffData.date_of_joining)
    setDateResign(staffData.date_of_resignation)
    setName(staffData.name)
    setPassword(staffData.crm_password)
    setEmail(staffData.email)
    setAliasName(staffData.alias_name)
    setMobile(staffData.phone_number)
    setGender(staffData.gender)
    setBirthDate(staffData.dob)
    setMaritialStatus(staffData.marital_status)
    setReligion(staffData.religion)
    setlinkedin(staffData.linkedin)
    setAddress1(staffData.address)
    setAddress2(staffData.address_line_2)
    const familyDetails = JSON.parse(staffData.family_details)
    if (familyDetails !== null) {
      setInputFields(familyDetails)
    }
    const bankDetails = JSON.parse(staffData.bank_details)
    if (bankDetails !== null) {
      setInputFields1(bankDetails)
    }
  }
  useEffect(() => {
    getStaffDetails()
  }, [])

  const onState = async e => {
    setCountry({
      label: e.label,
      value: e.value,
    })
    setLoading(true)
    const formData = new FormData()
    formData.append("country", e.value)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/states`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const option = response.data.data?.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setStates(option)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const onCity = async e => {
    setState({
      label: e.label,
      value: e.value,
    })
    setLoading(true)
    const formData = new FormData()
    formData.append("state", e.value)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const option = response.data.data?.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setCites(option)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [inputFields, setInputFields] = useState([{}])
  const [inputFields_1, setInputFields1] = useState([{}])
  const [isLoading, setLoading] = useState(false)
  const [logo, setLogo] = useState("")
  const [image, setImage] = useState("")
  const [role_name, setRoleName] = useState({
    label: null,
    value: null,
  })

  const [department, setDepartment] = useState({
    label: null,
    value: null,
  })
  const [designation, setDesignation] = useState("")
  const [emp_id, setEmpId] = useState("")
  const [punch_id, setPunchId] = useState("")
  const [date_join, setDateJoin] = useState("")
  const [date_resign, setDateResign] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordType, setPasswordType] = useState("password")
  const [name, setName] = useState("")
  const [custType, setCustType] = useState("new")
  const [alias_name, setAliasName] = useState("")
  const [mobile, setMobile] = useState("")
  const [gender, setGender] = useState("")
  const [birth_date, setBirthDate] = useState("")
  const [marital_status, setMaritialStatus] = useState("")
  const [religion, setReligion] = useState("")
  const [linkedin, setlinkedin] = useState("")
  const [address_1, setAddress1] = useState("")
  const [address_2, setAddress2] = useState("")
  const [country, setCountry] = useState({
    label: null,
    value: null,
  })
  const [state, setState] = useState({
    label: null,
    value: null,
  })
  const [city, setCity] = useState({
    label: null,
    value: null,
  })
  const [pincode, setPincode] = useState("")
  const [pf_account, setPfAccount] = useState("")
  const [aadhaar, setAadhar] = useState("")
  const [pan_account, setPanAccount] = useState("")
  const [passport, setPassport] = useState("")

  const onUpdateStaff = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("id", id)
    formData.append("profile", logo)
    formData.append("role_id", role_name.value)
    formData.append("date_of_resignation", date_resign)
    formData.append("login_email", email)
    formData.append("login_password", password)
    formData.append("employee_code", emp_id)
    formData.append("name", name)
    formData.append("gender", gender)
    formData.append("phone_number", mobile)
    formData.append("linkedin", linkedin)
    formData.append("dob", birth_date)
    formData.append("marital_status", marital_status)
    formData.append("address", address_1)
    formData.append("address_line_2", address_2)
    formData.append("state", state.value)
    formData.append("city", city.value)
    formData.append("zip_code", pincode)
    formData.append("religion", religion)
    formData.append("pf_account_number", pf_account)
    formData.append("pancard_number", pan_account)
    formData.append("passport_number", passport)
    formData.append("alias_name", alias_name)
    formData.append("shift_id", swiftDec.value)
    formData.append("department", department.value)
    formData.append("designation", designation)
    formData.append("punchid", punch_id)
    formData.append("date_of_joining", date_join)
    formData.append("country", country.value)
    formData.append("aadharcard_number", aadhaar)
    formData.append(
      "member_name",
      inputFields.map(field => field.member_name)?.toString()
    )
    formData.append(
      "relationship",
      inputFields.map(field => field.relationship)?.toString()
    )
    formData.append(
      "occupation",
      inputFields.map(field => field.occupation)?.toString()
    )
    formData.append(
      "contact_no",
      inputFields.map(field => field.contact_no)?.toString()
    )
    formData.append(
      "bank_name",
      inputFields_1.map(field => field.bank_name)?.toString()
    )
    formData.append(
      "branch_name",
      inputFields_1.map(field => field.branch_name)?.toString()
    )
    formData.append(
      "account_number",
      inputFields_1.map(field => field.account_number)?.toString()
    )
    formData.append(
      "ifsc_code",
      inputFields_1.map(field => field.ifsc_code)?.toString()
    )
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/updatestaff`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        localStorage.setItem("staffId", response.data?.data[0]?.id)
        localStorage.setItem("roleId", response.data?.data[0]?.roleid)
        const useId = localStorage.getItem("auth_id")
        if (useId == id) {
          localStorage.setItem("auth_avt", response.data?.data[0]?.avatar)
        }
        Swal.fire({
          icon: "success",
          text: "Staff Personal Details Edited Successfully",
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  const handleInputFamilyChange = (e, key) => {
    const { name, value } = e.target
    const values = [...inputFields]
    values[key][name] = value
    setInputFields1(values)
  }
  const handleInputBankChange = (e, key) => {
    const { name, value } = e.target
    const values = [...inputFields_1]
    values[key][name] = value
    setInputFields1(values)
  }
  const onAddFamilyRow = () => {
    setInputFields([...inputFields, {}])
  }
  const onAddBankRow = () => {
    setInputFields1([...inputFields_1, {}])
  }
  return (
    <>
      {isLoading && <Loader />}
      {/* Fields */}
      <Form
        className="repeater"
        onSubmit={onUpdateStaff}
        encType="multipart/form-data"
        id="addstaff"
      >
        {/* Fixed Buttons */}
        <div
          className={`flex-column fixed-buttons pt-2 ${fixButtons ? "d-flex" : "d-none"
            }`}
        >
          <button className="btn btn-save fix-btn btn-label pe-0">
            <i className="fas fa-save me-2 label-icon"></i>
            <span>Save</span>
          </button>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-red fix-btn btn-label pe-0"
          >
            <i className="dripicons-cross me-2 icon-center label-icon"></i>
            <span>Cancel</span>
          </button>
        </div>
        <div className="mb-3 shadow bg-white p-4">
          <Row>
            <div className="col-md-6">
              <h4 className="text-dark above">Personal Details</h4>
            </div>

            <div className="col-md-6 text-md-end">
              <button className="btn me-md-0 me-2 btn-save btn-label">
                <i className="fas fa-save me-2 label-icon"></i>SAVE
              </button>
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Row>
          <h5 className="mb-4 text-dark">General Info</h5>
          {/* <Row>
            <label htmlFor="">Profile Picture</label>
            <div className="col-md-6 mb-4 ">
              <input
                type="file"
                className="form-control "
                id="document"
                onChange={e => setLogo(e.target.value)}
              />
            </div>
          </Row> */}
          <Row>
            <label htmlFor="">Profile Picture</label>
            <div className="col-md-5 mb-4">
              <input
                type="file"
                className="form-control"
                id="document"
                onChange={e => setLogo(e.target.files[0])}
              />
            </div>
            <div className="col-md-4">
              <img
                src={image}
                width={39}
                height="39"
                className="rounded-circle object-fit-cover"
                alt=""
              />
              {/* <span className="text-danger ms-2">
                {" "}
                ( /images/1673265690.png ){" "}
              </span> */}
            </div>
          </Row>
          <Row>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Role Name</label>
              <Select
                placeholder="Select Role"
                className="basic-multi-select "
                classNamePrefix="select"
                options={roles}
                value={role_name}
                onChange={e =>
                  setRoleName({
                    label: e.label,
                    value: e.value,
                  })
                }
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Department</label>
              <Select
                placeholder="Select Department"
                className="basic-multi-select "
                classNamePrefix="select"
                options={departments}
                value={department}
                onChange={e =>
                  setDepartment({
                    label: e.label,
                    value: e.value,
                  })
                }
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Designation</label>
              <input
                type="text"
                placeholder="Enter Designation"
                className="form-control "
                id=""
                value={designation}
                onChange={e => setDesignation(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Shift</label>
              <Select
                placeholder="Select Shift"
                className="basic-multi-select "
                classNamePrefix="select"
                options={swift}
                value={swiftDec}
                onChange={e =>
                  setSwiftDec({
                    label: e.label,
                    value: e.value,
                  })
                }
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">
                Employee Id <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="e.g 99999"
                className="form-control "
                id=""
                value={emp_id}
                onChange={e => setEmpId(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">Punch Id</label>
              <input
                type="text"
                placeholder="Enter Punch Id"
                className="form-control "
                id=""
                value={punch_id}
                onChange={e => setPunchId(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <div className="pe-5">
                <label htmlFor="">Date Of Joining</label>
                <input
                  type="date"
                  className="form-control "
                  value={date_join}
                  onChange={e => setDateJoin(e.target.value)}
                  id=""
                />
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Date of Resignation / Date of Termination
              </label>
              <input
                type="date"
                className="form-control "
                value={date_resign}
                onChange={e => setDateResign(e.target.value)}
                id=""
              />
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Email Id <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Enter Email Id"
                className={`form-control  ${emailIsvalid ? "is-invalid" : ""}`}
                onFocus={() => setShowPopup(false)}
                onBlur={() => setShowPopup(true)}
                id=""
                value={email}
                onChange={e => {
                  handleEmailChange(e)
                  // setEmail(e.target.value)
                }}
              />
              {emailIsvalid && showPopup && <EmailPopup />}
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label htmlFor="">
                Password  <span className="text-danger">*</span>
              </label>
              <div className="bd-pass">
                <input

                  type={passwordType}
                  placeholder="Enter Password"
                  className="form-control "
                  id=""
                  value={password}
                  required
                  onChange={e => setPassword(e.target.value)}
                />
                <a className="bd-pass-icon" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <i className="fas fa-eye-slash "></i>
                  ) : (
                    <i className="fas fa-eye "></i>
                  )}
                </a>
              </div>
            </div>
          </Row>
        </div>

        <div className="shadow bg-white p-4">
          <h5 className="mb-4 text-dark">Personal Details</h5>
          <div className="col-12 mb-3">
            <label htmlFor="" className="d-block">
              Staff Type
            </label>
            <span className="me-4 ">
              <input
                className="form-check-input input-location"
                type="radio"
                id="new"
                defaultChecked={true}
                onInput={e => setCustType(e.target.value)}
                name="type"
                value="new"
              />
              <label htmlFor="new" className="ms-2">
                New
              </label>
            </span>
            <span>
              <input
                className="form-check-input input-location"
                type="radio"
                onInput={e => setCustType(e.target.value)}
                id="existing"
                name="type"
                value="existing"
              />
              <label htmlFor="existing" className="ms-2">
                {" "}
                Existing
              </label>
            </span>
          </div>
          <Row>
            <div className="col-md-3 mb-3">
              <label htmlFor="">
                Employee Name <span className="text-danger">*</span>
              </label>
              {custType === "new" ? (
                <input
                  type="text"
                  placeholder="Enter Employee Name"
                  className="form-control "
                  id=""
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              ) : (
                <Select
                  placeholder="Employee Name"
                  className="basic-multi-select"
                  classNamePrefix="border-radius select"
                  styles={colourStyles}
                  onChange={e => setName(e.value)}
                  options={staffMember}
                />
              )}
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">
                Alias Name <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Enter Alias Name"
                className="form-control "
                id=""
                value={alias_name}
                onChange={e => setAliasName(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Enter Phone No."
                className="form-control "
                minLength={10}
                maxLength={10}
                value={mobile}
                onChange={e => setMobile(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="">
                Gender <span className="text-danger">*</span>
              </label>
              <select
                required
                name=""
                value={gender}
                id=""
                className="form-select "
                onChange={e => setGender(e.target.value)}
              >
                <option value="None">None</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="col-md-3 mb-3">
              <div className="pe-5">
                <label htmlFor="">Birth Date</label>
                <input
                  type="date"
                  className="form-control "
                  id=""
                  value={birth_date}
                  onChange={e => setBirthDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Marital Status</label>
              <select
                name=""
                value={marital_status}
                id=""
                className="form-select "
                onChange={e => setMaritialStatus(e.target.value)}
              >
                <option value="Nothing Selected">Nothing Selected</option>
                <option value="Unmarried">Unmarried</option>
                <option value="Married">Married</option>
                <option value="Divorce">Divorce</option>
              </select>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Religion</label>
              <input
                type="text"
                placeholder="Enter Religion"
                className="form-control "
                id=""
                value={religion}
                onChange={e => setReligion(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">LinkedIn</label>
              <input
                type="text"
                placeholder="Enter LinkedIn"
                className="form-control "
                id=""
                value={linkedin}
                onChange={e => setlinkedin(e.target.value)}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <h5 className="mb-4 text-dark">Address Details</h5>
          <Row>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Address Line 1</label>
              <input
                type="text"
                placeholder="Enter Address Line 1"
                className="form-control "
                id=""
                value={address_1}
                onChange={e => setAddress1(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Address Line 2</label>
              <input
                type="text"
                placeholder="Enter Address Line 2"
                className="form-control "
                id=""
                value={address_2}
                onChange={e => setAddress2(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Country</label>
              <Select
                placeholder="Select Country"
                className="basic-multi-select "
                classNamePrefix="select"
                options={countries}
                value={country}
                onChange={onState}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">State</label>
              <Select
                placeholder="Select State"
                className="basic-multi-select "
                classNamePrefix="select"
                options={states}
                value={state}
                onChange={onCity}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">City</label>
              <Select
                placeholder="Select City"
                className="basic-multi-select "
                classNamePrefix="select"
                options={cities}
                value={city}
                onChange={e => {
                  setCity({
                    label: e.label,
                    value: e.value,
                  })
                }}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <label htmlFor="">Pin Code</label>
              <input
                type="number"
                placeholder="Enter Pin Code"
                className="form-control "
                id=""
                value={pincode}
                onChange={e => setPincode(e.target.value)}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <h5 className="mb-4 text-dark">KYC Details</h5>
          <Row>
            <div className="col-md-4 mb-4">
              <label htmlFor="">PF Account Number </label>
              <input
                type="text"
                placeholder="Enter Account Number"
                className="form-control "
                id=""
                value={pf_account}
                onChange={e => setPfAccount(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label htmlFor="">Aadhar Card Number </label>
              <input
                type="number"
                placeholder="Enter Aadhar Number"
                className="form-control "
                id=""
                value={aadhaar}
                onChange={e => setAadhar(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-4">
              <label htmlFor="">PAN Card Number </label>
              <input
                type="text"
                placeholder="Enter Pan Number"
                className="form-control "
                id=""
                value={pan_account}
                onChange={e => setPanAccount(e.target.value)}
              />
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <label htmlFor="">Passport Number </label>
              <input
                type="text"
                placeholder="Enter Passport Number"
                className="form-control "
                id=""
                value={passport}
                onChange={e => setPassport(e.target.value)}
              />
            </div>
          </Row>
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <div className="d-flex justify-content-between mb-4">
            <h5 className="mb-4 text-dark">Family Details</h5>
            <div>
              <a
                className=" btn text-white text-uppercase me-2 btn-purple-light btn-label"
                onClick={e => {
                  e.preventDefault()
                  onAddFamilyRow()
                }}
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Family Details
              </a>
            </div>
          </div>
          {inputFields?.map((item, index) => {
            return (
              <div className="display-flex align-items-end" key={index}>
                <div className=" mb-3 pr-row">
                  <label htmlFor="">Member Name</label>
                  <input
                    type="text"
                    placeholder="Enter Memeber Name"
                    className="form-control "
                    // name="test"
                    // value={item.member_name}
                    // onChange={e => onMemberName(index, e)}
                    name="member_name"
                    value={item.member_name || ""}
                    onChange={e => handleInputFamilyChange(e, index)}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Relationship</label>
                  <input
                    type="text"
                    placeholder="Enter Relationship"
                    className="form-control "
                    name="relationship"
                    // value={item.relationship}
                    // onChange={e => onRelationship(index, e)}
                    value={item.relationship || ""}
                    onChange={e => handleInputFamilyChange(e, index)}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Occupation</label>
                  <input
                    type="text"
                    placeholder="Enter Occupation"
                    className="form-control"
                    // value={item.occupation}
                    // onChange={e => onOccupation(index, e)}
                    name="occupation"
                    value={item.occupation || ""}
                    onChange={e => handleInputFamilyChange(e, index)}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Contact No</label>
                  <input
                    type="number"
                    placeholder="Enter Contact No"
                    className="form-control "
                    name="contact_no"
                    // value={item.contact_no}
                    // onChange={e => onContact(index, e)}
                    value={item.contact_no || ""}
                    onChange={e => handleInputFamilyChange(e, index)}
                  />
                </div>
                <div className="mb-3 pl-row">
                  {inputFields.length !== 1 && (
                    <a
                      className="btn btn-outline-danger"
                      id={index}
                      // onClick={onDeleteFamilyRow}
                      onClick={e => {
                        e.preventDefault()
                        const updatedRows = inputFields.filter(
                          (_, idx) => idx !== index
                        )
                        setInputFields(updatedRows)
                      }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </a>
                  )}
                </div>
              </div>
            )
          })}
        </div>

        <div className="mb-3 shadow bg-white p-4">
          <div className="d-flex justify-content-between mb-4">
            <h5 className="mb-4 text-dark">Bank Details</h5>
            <div>
              <button
                onClick={e => {
                  e.preventDefault()
                  onAddBankRow()
                }}
                className=" btn text-white text-uppercase me-2 btn-purple-light btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Bank Details
              </button>
            </div>
          </div>
          {inputFields_1?.map((item, index) => {
            return (
              <div className="display-flex align-items-end" key={index}>
                <div className=" mb-3 pr-row">
                  <label htmlFor="">Bank Name</label>
                  <input
                    type="text"
                    placeholder="Enter Bank Name"
                    className="form-control "
                    // value={item.bank_name}
                    // onChange={e => onBankName(index, e)}
                    name="bank_name"
                    value={item.bank_name || ""}
                    onChange={e => handleInputBankChange(e, index)}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Branch Name</label>
                  <input
                    type="text"
                    placeholder="Enter Branch Name"
                    className="form-control "
                    name="branch_name"
                    // value={item.branch_name}
                    // onChange={e => onBranchName(index, e)}
                    value={item.branch_name || ""}
                    onChange={e => handleInputBankChange(e, index)}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">Bank Account Number</label>
                  <input
                    type="number"
                    placeholder="Enter A/C Number"
                    className="form-control "
                    name="account_number"
                    // value={item.account_number}
                    // onChange={e => onBankAccount(index, e)}
                    value={item.account_number || ""}
                    onChange={e => handleInputBankChange(e, index)}
                  />
                </div>
                <div className=" mb-3 pr-row pl-row">
                  <label htmlFor="">IFSC Code</label>
                  <input
                    type="text"
                    placeholder="Enter IFSC Code"
                    className="form-control "
                    name="ifsc_code"
                    // value={item.ifsc_code}
                    // onChange={e => onIFSC(index, e)}
                    value={item.ifsc_code || ""}
                    onChange={e => handleInputBankChange(e, index)}
                  />
                </div>
                <div className="mb-3 pl-row">
                  {inputFields_1.length !== 1 && (
                    <a
                      className="btn btn-outline-danger"
                      id={index}
                      // onClick={onDeleteBankRow}
                      onClick={e => {
                        e.preventDefault()
                        const updatedRows = inputFields_1.filter(
                          (_, idx) => idx !== index
                        )
                        setInputFields1(updatedRows)
                      }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </a>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Form>
    </>
  )
}

export default PersonalDetails
