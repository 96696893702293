import React, { useEffect, useMemo, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Input, Label, Modal
} from "reactstrap";
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import "../../../assets/css/Finance/Style/Festival/festival.css"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import { SwipeColumns } from 'utils/columns/Human Resource/humanResource';
import AddIssues from "../../../assets/images/Human Resource/addIssues.jpg"
import EditIssues from "../../../assets/images/Human Resource/editIssues.jpg"
import { FetchPostData } from 'utils/common-fetchData';
import secureLocalStorage from 'react-secure-storage';
import Loader from 'pages/Separate/Loader';
import NewPagination from 'components/Common/Newpagination';
import { customGlobalFilter } from 'utils/common-helpers';

function TableContainer({ columns, data, setAddSwipe, setSwipeInfo }) {

    const [fixedContent, setFixedContent] = useState(false);
    const [formPopup, setFormPopup] = useState(false);

    const navigate = useNavigate()

    document.addEventListener('scroll', () => {
        if (window.scrollY > 256) {
            setFixedContent(true);
        }
        else {
            setFixedContent(false);
        }
    })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            globalFilter: customGlobalFilter,
            initialState: {
                sortBy: [
                    {
                        id: "id",
                        desc: true,
                    },
                ],
            },
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { pageIndex, pageSize, globalFilter } = state;

    useEffect(() => {
        setPageSize(50)
    }, [])
    // Functionality to export table data to CSV //
    const excelColumns = []
    columns.forEach(column => {
        excelColumns.push(column.Header)
    });

    // Render the UI for your table
    return (
        <div >
            <div className={`flex-column fixed-buttons ${fixedContent ? 'd-flex' : 'd-none'}`}>


                <button className=" btn btn-save btn-label"
                    onClick={() => setAddSwipe(true)}
                >
                    <i className="fas fa-plus me-2 label-icon"></i>
                    Swipe
                </button>
                <button onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                    <i className='dripicons-cross me-2 icon-center'></i>
                    <span>Cancel</span>
                </button>
            </div>
            <Row className='mb-2'>

                <Col md={12} className='mb-4'>
                    <div className='d-flex '>

                        <div className="col-12 d-flex justify-space-between align-items-center mt-0">
                            <div className='col-4'>
                                <h4>Swipe</h4>
                            </div>
                            <div className='text-danger col-md-6 text-end'>
                                *This feature allows employees to update their attendance if they miss a punch due to work commitments or unforeseen circumstances
                            </div>

                        </div>
                    </div>

                </Col>
                <Col>
                    <select
                        className="border-radius form-select"
                        style={{ width: '100px' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(e.target.value)}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col md={8} className='d-flex justify-content-end align-items-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="border-radius form-control rounded h-100"
                                        placeholder={` Search...`}
                                        value={globalFilter || ''}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end align-items-center ms-3 mb-2">
                            <button className=" btn btn-save btn-label"
                                onClick={() => setAddSwipe(true)}
                            >
                                <i className="fas fa-plus me-2 label-icon"></i>
                                Swipe
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className='position-relative'>
                <Table className='table table-bordered mt-3' {...getTableProps()}>
                    <thead className={`${fixedContent ? 'fixed-setting-header' : ''} fix-gst-header`}>
                        {
                            headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th
                                            key={index}
                                            className={`t-col-${index + 1} ${column.className}`}
                                            {...column.getHeaderProps()}
                                        >
                                            <div className="d-flex align-items-center">
                                                <span className="flex-grow-1 text-center header-text">
                                                    {column.render("Header")}
                                                </span>
                                                {column.isShortIcon && (
                                                    <span
                                                        onClick={() =>
                                                            column.getSortByToggleProps().onClick()
                                                        }
                                                        className="d-flex align-items-center ms-2"
                                                    >
                                                        <i
                                                            title="Ascending"
                                                            className={`bi bi-arrow-up info-icon ${column.isSorted && !column.isSortedDesc
                                                                ? "text-dark"
                                                                : ""
                                                                }`}
                                                        ></i>
                                                        <i
                                                            title="Descending"
                                                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                                ? "text-dark"
                                                                : ""
                                                                }`}
                                                        ></i>
                                                    </span>
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody className="fix-gst-header" {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='row-hover' {...row.getRowProps()} key={i}>
                                        {
                                            row.cells.map((cell, index) => {
                                                return <td key={index} className={`t-col-${index + 1} ${cell.column.className}`} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}</td>;
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>

            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <NewPagination
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    page={page}
                    data={data}
                    nextPage={nextPage}
                />
            </Row>


        </div>
    );
}

const SwipeIndex = ({ verticalActiveTab }) => {
    const [addSwipe, setAddSwipe] = useState(false);
    const [editSwipe, setEditSwipe] = useState(false);
    const [swipeDisApprovedPopup, setSwipeDisApprovedPopup] = useState(false);
    const [swipeInfo, setSwipeInfo] = useState(false)
    const [date, setDate] = useState('')
    const [reason, setReason] = useState('')
    const [fromTime, setFromTime] = useState('')
    const [toTime, setToTime] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [swipeId, setSwipeId] = useState('')
    const [dis_reason, setDisReason] = useState('')


    document.addEventListener("keydown", e => {
        if (e.key === "Escape") setAddSwipe(false), setEditSwipe(false)
    })

    const getData = async () => {
        const auth = secureLocalStorage.getItem('auth_token')
        const entries = {
            user_id: localStorage.getItem('auth_id'),
            issue_type: 1
        }
        const response = await FetchPostData(toggleLoading, "swipeData", auth, entries);
        if (response?.data?.success) {
            setData(response.data.data)
        }
    }
    useEffect(() => {
        if (verticalActiveTab == "1") {
            setSwipeInfo(true)
            if (data?.length == 0) {
                getData()
            }
        }

    }, [verticalActiveTab])

    const onEditOpen = (obj) => {
        setEditSwipe(true)
        const time = obj?.time?.split('-')
        setSwipeId(obj?.id);
        setReason(obj?.reason)
        setDate(obj?.date)
        setFromTime(time[0])
        setToTime(time[1])
    }
    const onInfoOpen = (reason) => {
        setSwipeDisApprovedPopup(true)
        setDisReason(reason)
    }

    const onDeletSwipe = (issue_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "you want to delete this swipe!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const auth = secureLocalStorage.getItem('auth_token')
                const entries = {
                    user_id: localStorage.getItem('auth_id'),
                    id: issue_id,
                    issue_type: 1
                }
                const response = await FetchPostData(toggleLoading, "deleteSwipeData", auth, entries);
                if (response?.data?.success) {
                    Swal.fire({
                        icon: "success",
                        text: "Swipe Deleted Successfully",
                    }).then(() => {
                        setData(response?.data?.data)
                    })
                }
            }
        })
    }

    const columns = useMemo(() => SwipeColumns(onEditOpen, onInfoOpen, onDeletSwipe), [])

    const toggleLoading = (loading) => {
        setLoading(loading);
    }
    const onAddSwipes = async (e) => {
        e.preventDefault();
        const auth = secureLocalStorage.getItem('auth_token')
        const entries = {
            user_id: localStorage.getItem('auth_id'),
            issue_type: 1,
            date: date,
            time: `${fromTime}-${toTime}`,
            reason: reason,
            id: editSwipe ? swipeId : ''
        }
        const apiName = addSwipe ? 'addSwipeData' : '' || editSwipe ? "editSwipeData" : ''
        const response = await FetchPostData(toggleLoading, apiName, auth, entries);
        if (response?.data?.success) {
            Swal.fire({
                icon: "success",
                text: `Swipes ${editSwipe ? "Edited" : "Added"} Successfully`,
            }).then(() => {
                setData(response?.data?.data)
                setFromTime('')
                setDate('')
                setToTime('')
                setReason('')
                setAddSwipe(false)
                setEditSwipe(false)
            })

        }
    }

    return (
        <>
            {loading && (<Loader />)}
            <div>
                <Row className='ms-0 pe-0'>
                    <Col lg={12} className='p-0'>
                        <Card>
                            <CardBody>
                                <TableContainer
                                    columns={columns}
                                    data={data}
                                    setAddSwipe={setAddSwipe}
                                    setSwipeInfo={setSwipeInfo}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={addSwipe} size="xl" className="" centered>
                    <div className="modal-header pe-4" >
                        <h5 className="modal-title mt-0">Add Swipe</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setAddSwipe(false)
                            }}
                            className="btn-modal-close"
                        >
                            <span aria-hidden="true" className="btn-modal-span">
                                &times;
                            </span>
                        </button>
                    </div>

                    <div className="modal-body pe-4" >
                        <Form className="row d-flex justify-content-center align-items-center" onSubmit={onAddSwipes}>
                            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                <div className="text-center">
                                    <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
                                </div>
                            </div>

                            <div className="col-md-6 d-flex flex-column ">
                                <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                                    <div className="col-md-4 ps-2">

                                        <Label htmlFor="formrow-firstname-Input">
                                            Date <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            min="2024-11-05"
                                            max="2024-12-05"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className='col-md-3 ps-2 '>
                                        <Label htmlFor="time-range-picker">
                                            From Time <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="time"
                                            className="form-control"
                                            value={fromTime}
                                            onChange={(e) => setFromTime(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='col-md-3 ps-2 '>
                                        <Label htmlFor="time-range-picker">
                                            To Time <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="time"
                                            className="form-control"
                                            value={toTime}
                                            onChange={(e) => setToTime(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">

                                    <div className="col-md-12 ps-2">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Reason  <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            placeholder="Add Reason Here.."
                                            className="form-control"
                                            style={{ height: "150px" }}
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>

                                <div className="my-4 text-center">
                                    <button className=" btn btn-save btn-label">
                                        <i className="fas fa-save me-2 label-icon"></i>
                                        Save
                                    </button>
                                    <a
                                        onClick={() => setAddSwipe(false)}
                                        className=" btn ms-md-2 btn-red btn-label"
                                    >
                                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                        CANCEL
                                    </a>
                                </div>
                            </div>


                            {/* </div> */}
                        </Form>
                    </div>
                </Modal>

                <Modal isOpen={editSwipe} size="xl" className="" centered>
                    <div className="modal-header pe-4" >
                        <h5 className="modal-title mt-0">Edit Swipe</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setEditSwipe(false)
                                setFromTime('')
                                setToTime('')
                                setReason('')
                                setDate('')
                            }}
                            className="btn-modal-close"
                        >
                            <span aria-hidden="true" className="btn-modal-span">
                                &times;
                            </span>
                        </button>
                    </div>

                    <div className="modal-body pe-4" >
                        <Form className="row d-flex justify-content-center align-items-center" onSubmit={onAddSwipes}>
                            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                <div className="text-center">
                                    <img src={EditIssues} alt="Addepe" style={{ width: "90%" }} />
                                </div>
                            </div>

                            <div className="col-md-6 d-flex flex-column ">
                                <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">

                                    <div className="col-md-4 ps-2">

                                        <Label htmlFor="formrow-firstname-Input">
                                            Date <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            min="2024-11-05"
                                            max="2024-12-05"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='col-md-3 ps-2 '>
                                        <Label htmlFor="time-range-picker">
                                            From Time <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="time"
                                            className="form-control"
                                            value={fromTime}
                                            onChange={(e) => setFromTime(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='col-md-3 ps-2 '>
                                        <Label htmlFor="time-range-picker">
                                            To Time <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="time"
                                            className="form-control"
                                            value={toTime}
                                            onChange={(e) => setToTime(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>
                                <div className="col-md-12">

                                    <div className="col-md-12 ps-2">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Reason  <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            placeholder="Add Reason Here.."
                                            className="form-control"
                                            style={{ height: "150px" }}
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>

                                <div className="my-4 text-center">
                                    <button className=" btn btn-save btn-label">
                                        <i className="fas fa-save me-2 label-icon"></i>
                                        Save
                                    </button>
                                    <a
                                        className=" btn ms-md-2 btn-red btn-label"
                                        onClick={() => {
                                            setEditSwipe(false)
                                            setFromTime('')
                                            setToTime('')
                                            setReason('')
                                            setDate('')
                                        }}
                                    >
                                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                                        CANCEL
                                    </a>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>

                {/* Swipe Un-Approve Popup  */}
                <Modal isOpen={swipeDisApprovedPopup} size="xl" className="" centered>
                    <div className="modal-header pe-4" >
                        <h5 className="modal-title mt-0">Swipe Disapproved</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setSwipeDisApprovedPopup(false)
                            }}
                            className="btn-modal-close"
                        >
                            <span aria-hidden="true" className="btn-modal-span">
                                &times;
                            </span>
                        </button>
                    </div>

                    <div className="modal-body pe-4" >
                        <Form className="row d-flex justify-content-center align-items-center">
                            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                                <div className="text-center">
                                    <img src={AddIssues} alt="Addepe" style={{ width: "90%" }} />
                                </div>
                            </div>

                            <div className="col-md-6 d-flex flex-column ">
                                <div className="col-md-12">

                                    <div className="col-md-12 ps-2">
                                        <Label htmlFor="formrow-firstname-Input">
                                            Reason  <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            disabled
                                            type="text"
                                            placeholder="Add Reason Here.."
                                            value={dis_reason}
                                            className="form-control"
                                            style={{ height: "150px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>


                {/* Swipe Info Modeal  */}
                <Modal
                    isOpen={swipeInfo}
                    toggle={() => {
                        tog_center()
                    }}
                    className="bd-info-popup"
                    centered
                >
                    <div className="modal-header bd-head-info">
                        <h5 className="modal-title mt-0">
                            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
                            Swipe Info
                        </h5>
                    </div>
                    <div className="modal-body pb-0">
                        <div className="d-flex">
                            <div className="col-5 pr-30">
                                <div>
                                    <img src={InfoGif} className="w-100" />
                                </div>
                            </div>
                            <div className="mt-20 ">
                                <div>
                                    <p>
                                        <i className="fas fa-angle-double-right pr-10"></i> The Swipe feature allows employees to update their attendance or task logs if they miss a punch due to work commitments or unforeseen circumstances. Simply use the Swipe option to correct any missed punches, ensuring accurate and up-to-date records.


                                    </p>
                                    <p>
                                        <i className="fas fa-angle-double-right pr-10"></i> This feature provides flexibility and helps maintain seamless workflow tracking.
                                    </p>
                                    <div className="text-center mt-40">
                                        <button
                                            type="button"
                                            className="btn btn-success "
                                            onClick={() => setSwipeInfo(false)}
                                        >
                                            I Understood
                                            <i className="dripicons-thumbs-up pl-10"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default SwipeIndex