import React, { useEffect, useState } from "react"
import { Card, CardBody, Table } from "reactstrap"
import { fetchData } from "utils/common-fetchData"

const Absentees = () => {
  const [data, setData] = useState([])
  const toggleLoading = () => {}
  useEffect(() => {
    const getAbsentees = async () => {
      const response = await fetchData(toggleLoading, "todaysLeave", "")
      if (response) {
        setData(response)
      }
    }
    getAbsentees()
  }, [])

  return (
    <React.Fragment>
      <div>
        <Card>
          <CardBody className="">
            <div className="mb-4 d-flex justify-content-between">
              <p className="text-purple fs-5 mb-0 fw-bold">Absentees</p>
              <div className="text-purple absent-length">{data?.length}</div>
            </div>
            <div id="Fav-lead-table" className="responsive-table mb-4">
              <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="header col-9">Staff</th>
                    <th className="text-center header col-3">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((obj, i) => {
                    return (
                      <tr
                        key={i}
                        className={`${
                          obj?.half_day == 1 ? "bg-light-blue2" : ""
                        }`}
                      >
                        <td>{obj?.name}</td>
                        <td>{obj?.leave_status}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Absentees
