import React, { useEffect, useState } from "react"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Form,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import India from "../../../assets/images/flags/india.png"
import Uk from "../../../assets/images/flags/uk.png"
import ReactSelect from "react-select"
import Select from "react-select"
import viewbill from "../../../assets/images/Expenses/View_Bill.jpg"
import "../../../assets/css/Finance/Style/ExpenseSection/expense.css"
import addvendorpay from "../../../assets/images/Expenses/addExpense.jpg"
import editvendorpay from "../../../assets/images/Expenses/editExpense.jpg"
import viewvendorpay from "../../../assets/images/Expenses/viewExpense.jpg"
import paymentdone from "../../../assets/images/Expenses/payment.jpg"
import "../../../assets/css/Finance/vendorspay.css"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
import configure from "configure"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import moment from "moment"
import NewPagination from "components/Common/Newpagination"

function TableContainer({
  columns,
  data,
  setDatas,
  setLoading,
  projects,
  payments,
  location,
  allData,
  setAllData,
  recurringOptions,
  Categories,
  paymentsOption,
  setIsfilter,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  const [formPopup, setFormPopup] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [addVendorPay, setAddVendorPay] = useState(false)

  const navigate = useNavigate()

  // add vender
  const [addDate, setAddDate] = useState("")
  const [locationValue, setLocationValue] = useState(null)
  const [selectedBy, setSelectedBy] = useState(null)
  const [selectedByType, setSelectedByType] = useState([])
  const [selectedByTypeOp, setSelectedByTypeOp] = useState(null)
  const [selectedByLabel, setSelectedByLabel] = useState("")
  const [selectedByIsShow, setSelectedByIsShow] = useState(true)
  const [selectedProject, setSelectedProject] = useState(null)
  const [addAmount, setAddAmount] = useState("")
  const [addTds, setAddTds] = useState("")
  const [addGst, setAddGst] = useState("")
  const [addPaymentVia, setAddPaymentVia] = useState(null)
  const [recurring, setRecurring] = useState(0)
  const [recurringPeriod, setRecurringPeriod] = useState(null)
  const [recurringEndDate, setRecurringEndDate] = useState("")
  const [selectedByIsShowFilter, setSelectedByIsShowFilter] = useState(true)
  const [isFilterView, setIsFilterView] = useState(false)
  const [fStartDate, setFStartDate] = useState("")
  const [fendDate, setFendDate] = useState("")
  const [paymentVia, setPaymentVia] = useState(null)
  const [paymentCate, setPaymentCate] = useState(null)
  const [paymentCateOption, setPaymentCateOption] = useState(null)
  const [selectedByLabelFilter, setSelectedByLabelFilter] = useState(null)
  const [selectedByTypeFilterOption, setSelectedByTypeFilterOption] = useState(
    []
  )
  const [selectedByTypeOption, setSelectedByTypeOption] = useState(null)
  const [addVShow, setAddVShow] = useState(false)

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setAddVendorPay(false), setFormPopup(false)
  })

  document.addEventListener("scroll", () => {
    if (window.scrollY > 180) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  useEffect(() => {
    if (!data || data.length === 0) return // Handle empty data case

    const totalExpense = data.reduce((acc, item) => acc + (item.cost || 0), 0)

    const bankTotal = data
      .filter(list => list.payment_method === "1")
      .reduce((acc, item) => acc + (item.cost - item.tbal || 0), 0)

    const creditTotal = data
      .filter(list => list.payment_method === "2")
      .reduce((acc, item) => acc + (item.cost - item.tbal || 0), 0)

    const VendorBalanceAmount = bankTotal + creditTotal
    const modifyArray = {
      ...allData,
      vendor_pay: totalExpense,
      balance: VendorBalanceAmount,
      bank: bankTotal,
      credit_card: creditTotal,
    }
    setAllData(modifyArray)
  }, [data])

  const handleSelectedBy = async selectedOption => {
    setSelectedByTypeOp(null)
    setSelectedBy(selectedOption)
    if (selectedOption.value == "freelancer") {
      setAddVShow(true)
      setSelectedByIsShow(true)
      setSelectedByLabel("Freelancer")
      setSelectedByType([])
      // try {
      //   setLoading(true)
      //   const formData = new FormData()
      //   formData.append("user_id", localStorage.getItem("auth_id"))
      //   const response = await axios({
      //     method: "post",
      //     url: `${configure.apiUrl}/introducer`,
      //     data: formData,
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }).then(response => {
      //     console.log("introducer", response.data.data)
      //     const modifyArr = response.data.data?.map(item => {
      //       return {
      //         label: item.company,
      //         value: item.id,
      //       }
      //     })
      //     setSelectedByType(modifyArr)
      //     setLoading(false)
      //   })
      // } catch (error) {
      //   console.log(err)
      //   setLoading(false)
      // }
    } else if (selectedOption.value == "Introducer") {
      setAddVShow(true)
      setSelectedByIsShow(true)
      setSelectedByLabel("Introducer")
      try {
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/introducer`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const modifyArr = response.data.data?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          setSelectedByType(modifyArr)
          setLoading(false)
        })
      } catch (error) {
        console.log(err)
        setLoading(false)
      }
    } else if (selectedOption.value == "vendor") {
      setSelectedByIsShow(false)
      setAddVShow(false)
      setSelectedByLabel("")
      setSelectedByType(null)
    } else if (selectedOption.value == "other") {
      setSelectedByLabel("Other")
      setSelectedByType(null)
      setSelectedByIsShow(false)
    }
  }

  const handleAddVendar = async e => {
    e.preventDefault()
    if (
      locationValue !== null &&
      selectedBy !== null &&
      addPaymentVia !== null &&
      selectedProject !== null
    ) {
      setLoading(true)
      const userId = localStorage.getItem("auth_id")
      const formData = new FormData()
      // user_id,date,  location, payment_via, category, Project_id, amount, gst,tds
      formData.append("user_id", userId)
      formData.append("date", addDate)
      formData.append("location", locationValue?.value)
      formData.append("payment_via", addPaymentVia?.value)
      formData.append("category", selectedBy?.value)
      formData.append("cate_name", selectedByTypeOp?.value)
      formData.append("project_id", selectedProject?.value)
      formData.append("amount", addAmount)
      formData.append("tds", addTds)
      formData.append("gst", addGst)
      if (recurring == 1) {
        formData.append("recurrence", recurring)
        formData.append("recurrence_period", recurringPeriod?.value)
        formData.append("recurrence_date", recurringEndDate)
      }
      try {
        const response = await axios.post(
          `${configure.apiUrl}/addPayment`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response.data.success) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Vendor pay added successfully",
          }).then(() => {
            setAddDate("")
            setLocationValue(null)
            setSelectedByTypeOp(null)
            setSelectedByLabel("")
            setSelectedByIsShow(true)
            setSelectedProject(null)
            setAddAmount("")
            setAddTds("")
            setAddGst("")
            setSelectedBy(null)
            setAddPaymentVia(null)
            const expense = response.data.data?.payment?.filter(list => {
              const modifyAmount =
                Number(list.cost)?.toFixed(1) - Number(list.tbal)?.toFixed(1)
              return modifyAmount >= 1
            })
            setDatas(expense)
            setAllData(response?.data?.data)
            setAddVendorPay(false)
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${response.data.data}`,
          })
        }
      } catch (error) {
        console.log("Error", error)
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please fill all fields",
      })
    }
  }
  const handleSelectedByFilter = async selectedOption => {
    setIsFilterView(false)
    setSelectedByTypeOption(null)
    setPaymentCate(selectedOption)
    if (selectedOption.value == "freelancer") {
      setIsFilterView(true)
      setSelectedByIsShowFilter(true)
      setSelectedByLabelFilter("Freelancer")
      setSelectedByTypeFilterOption([])
      setSelectedByTypeOption(null)
      // try {
      //   setLoading(true)
      //   const formData = new FormData()
      //   formData.append("user_id", localStorage.getItem("auth_id"))
      //   const response = await axios({
      //     method: "post",
      //     url: `${configure.apiUrl}/introducer`,
      //     data: formData,
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }).then(response => {
      //     console.log("introducer", response.data.data)
      //     const modifyArr = response.data.data?.map(item => {
      //       return {
      //         label: item.company,
      //         value: item.id,
      //       }
      //     })
      //     setSelectedByType(modifyArr)
      //     setLoading(false)
      //   })
      // } catch (error) {
      //   console.log(err)
      //   setLoading(false)
      // }
    } else if (selectedOption.value == "Introducer") {
      setIsFilterView(true)
      setSelectedByIsShowFilter(true)
      setSelectedByLabelFilter("Introducer")
      try {
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/introducer`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const modifyArr = response.data.data?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          setSelectedByTypeFilterOption(modifyArr)
          setLoading(false)
        })
      } catch (error) {
        console.log(err)
        setLoading(false)
      }
    } else if (selectedOption.value == "vendor") {
      setIsFilterView(false)
      setSelectedByLabelFilter("")
      setSelectedByTypeOption(null)
      setSelectedByTypeFilterOption([])
      setSelectedByIsShowFilter(false)
    } else if (selectedOption.value == "other") {
      setIsFilterView(true)
      setSelectedByLabelFilter("Other")
      setSelectedByTypeOption(null)
      setSelectedByTypeFilterOption([])
      setSelectedByIsShowFilter(false)
    }
  }
  const onFilter = e => {
    e.preventDefault()

    if (fStartDate && fendDate && moment(fStartDate).isAfter(fendDate)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid date range",
        text: "end date must be after the start date.",
      })
      return
    }

    if (
      fStartDate ||
      fendDate ||
      paymentVia ||
      paymentCate ||
      selectedByTypeOption
    ) {
      try {
        setIsfilter(true)
        let filteredData = allData?.payment || []

        if (fStartDate) {
          filteredData = filteredData.filter(item =>
            moment(item.created_at).isSameOrAfter(moment(fStartDate))
          )
        }

        if (fendDate) {
          filteredData = filteredData.filter(item =>
            moment(item.created_at).isSameOrBefore(moment(fendDate))
          )
        }

        if (paymentVia) {
          filteredData = filteredData.filter(item => {
            console.log("item.payment_method", item.payment_method)
            return item.payment_method == paymentVia?.value
          })
        }

        if (paymentCate) {
          filteredData = filteredData.filter(
            item => item.category == paymentCate?.value
          )
        }
        // if (paymentCateOption) {
        //   filteredData = filteredData.filter(item => {
        //     if (paymentCate?.value == "freelancer") {
        //       return item.freelancer == selectedByTypeOption.value
        //     } else if (paymentCate?.value == "Introducer") {
        //       return item.introducer == selectedByTypeOption.value
        //     } else if (paymentCate?.value == "other") {
        //       return item.other == selectedByTypeOption.value
        //     }
        //   })
        // }

        const filterData = filteredData.filter(item => {
          switch (paymentCate?.value) {
            case "freelancer":
              return item.freelancer == selectedByTypeOption?.value
            case "Introducer":
              return item.introducer == selectedByTypeOption?.value
            case "other":
              return item.other == selectedByTypeOption?.value
            default:
              return false // Or return true if you want to include items that don't match any case
          }
        })

        filteredData = filteredData.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        )
        if (selectedByTypeOption) {
          setDatas([...filterData])
        } else {
          setDatas([...filteredData])
        }
      } catch (error) {
        console.error(error)
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Unable to apply filters. please try again.",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please enter at least one filter criterion to proceed.",
      })
    }
  }
  const clearFilters = () => {
    setIsfilter(false)
    setIsFilterView(false)
    setFStartDate("")
    setFendDate("")
    setPaymentVia(null)
    setPaymentCate(null)
    setSelectedByTypeOption(null)
    const expense = allData?.payment?.filter(list => {
      const modifyAmount =
        Number(list.cost)?.toFixed(1) - Number(list.tbal)?.toFixed(1)
      return modifyAmount >= 1
    })
    setDatas(expense)
    // setFormPopup(false)
  }
  const handleClose = () => {
    if (
      fStartDate ||
      fendDate ||
      paymentVia ||
      paymentCate ||
      selectedByTypeOption
    ) {
      setIsfilter(false)
      setIsFilterView(false)
      const expense = allData?.payment?.filter(list => {
        const modifyAmount =
          Number(list.cost)?.toFixed(1) - Number(list.tbal)?.toFixed(1)
        return modifyAmount >= 1
      })
      setDatas(expense)
      setFStartDate("")
      setFendDate("")
      setPaymentVia(null)
      setPaymentCate(null)
      setSelectedByTypeOption(null)
      setFormPopup(false)
      Swal.fire({
        icon: "success",
        text: "Filter cleared and popup closed.",
      })
    } else {
      setFormPopup(false)
    }
  }
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.9)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const handlePayAddPayment = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 41)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          setAddVendorPay(true)
        } else {
          setAccessDenied(true)
        }
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 mt-3 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          onClick={() => handlePayAddPayment()}
          className="btn btn-save  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Payment</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>

      <Row className="d-flex mt-4">
        <div className="d-flex col-md-8 justify-flex-start align-items-center gap-2">
          <div
            className={`col-md-2 col-lg-2 budget_status ${
              allData?.vendor_pay >= 0 ? "text-success" : "text-danger"
            }  pt-2 px-2 me-5 rounded proposal-status text-center`}
          >
            <h6>Total Vendor Amount</h6>
            <h3>
              ₹{" "}
              {allData?.vendor_pay
                ? Number(allData?.vendor_pay)?.toFixed(2)
                : 0}
            </h3>
          </div>
          <div
            className={`col-md-2 col-lg-2 budget_status ${
              allData?.balance >= 0 ? "text-success" : "text-danger"
            } pt-2 px-2 me-5 rounded proposal-status text-center`}
          >
            <h6>Vendor Balance Amount </h6>
            <h3>
              ₹ {allData?.balance ? Number(allData?.balance)?.toFixed(2) : 0}
            </h3>
          </div>
          <div
            className={`col-md-2 col-lg-2 budget_status ${
              allData?.balance >= 0 ? "text-primary" : "text-danger"
            } pt-2 px-1 rounded proposal-status text-center bank-bal-box`}
          >
            <h6>Bank Balance</h6>
            <h3>₹ {allData?.bank ? Number(allData?.bank)?.toFixed(2) : 0}</h3>
          </div>
          <div
            className={`col-md-2 col-lg-2 budget_status ${
              allData?.credit_card >= 0 ? "text-secondary" : "text-danger"
            } pt-2 px-2 rounded proposal-status text-center`}
          >
            <h6>Credit Card Balance</h6>
            <h3>
              ₹{" "}
              {allData?.credit_card
                ? Number(allData?.credit_card)?.toFixed(2)
                : 0}
            </h3>
          </div>
        </div>

        <div className="col-md-4 text-end d-flex justify-content-end align-items-start">
          <div>
            <button
              onClick={() => setFormPopup(!formPopup)}
              className="btn btn-outline-secondary  me-3"
            >
              <i className="fas fa-filter"></i>
            </button>
          </div>
          <div className="">
            <button
              className=" btn btn-save btn-label"
              onClick={() => handlePayAddPayment()}
            >
              <i className="fas fa-plus me-2 label-icon"></i>
              Payment
            </button>
          </div>
        </div>
      </Row>

      <Row className="m-0 d-flex justify-space-between align-items-center">
        <div className="p-0 col-md-2">
          <select
            className=" form-select mt-4"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="p-0 col-md-2">
          <div className="search-box my-xxl-0 d-inline-block ">
            <div className="position-relative  mt-4">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => setGlobalFilter(e.target.value)}
                  id="search-bar-0"
                  type="text"
                  className=" form-control rounded h-100 "
                  placeholder={` Search...`}
                  value={globalFilter || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
        </div>
      </Row>

      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${
              fixedContent && page.length >= 10 ? "fix-header" : ""
            } fix-introducer-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="d-flex align-items-center">
                      <span className="flex-grow-1 text-center header-text">
                        {column.render("Header")}
                      </span>
                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="d-flex align-items-center ms-2"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${
                              column.isSorted && !column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${
                              column.isSorted && column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-introducer-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td className="text-center" colSpan={10}>
                  No Data Found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      {/* Filter Modal  */}
      {formPopup ? (
        <Draggable>
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "30%",
              right: "3%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            {/* <div className="expense-filter">
          <div className="popup-form p-4"> */}
            <Form
              onSubmit={onFilter}
              className="row d-flex justify-content-center align-items-center"
            >
              <div className="col-md-12 d-flex ">
                <div className="col-md-2 ps-2">
                  <label htmlFor="startDate">From Date</label>
                  <input
                    required={fendDate ? true : false}
                    type="date"
                    id="startDate"
                    value={fStartDate}
                    onChange={e => setFStartDate(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-2 ps-2">
                  <label htmlFor="endDate">To Date</label>
                  <input
                    type="date"
                    id="endDate"
                    value={fendDate}
                    onChange={e => setFendDate(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-2 ps-2 mb-4">
                  <Label htmlFor="formrow-firstname-Input">Payment Via</Label>
                  <Select
                    placeholder="Payment Via"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={paymentsOption}
                    value={paymentVia}
                    onChange={selectedOption => setPaymentVia(selectedOption)}
                  />
                </div>
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">Category</Label>
                  <Select
                    placeholder="Payment Category"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Categories}
                    value={paymentCate}
                    onChange={selectedOption =>
                      handleSelectedByFilter(selectedOption)
                    }
                  />
                </div>
                {isFilterView ? (
                  <>
                    <div className="col-md-3 ps-2">
                      <>
                        {selectedByIsShowFilter ? (
                          <>
                            <Label htmlFor="formrow-firstname-Input">
                              Select {selectedByLabelFilter}
                            </Label>
                            <Select
                              placeholder="select"
                              className="basic-multi-select"
                              classNamePrefix=" select"
                              options={selectedByTypeFilterOption}
                              value={selectedByTypeOption}
                              onChange={selectedOption =>
                                setSelectedByTypeOption(selectedOption)
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Label htmlFor="formrow-firstname-Input">
                              Select {selectedByLabel}{" "}
                            </Label>
                            <div className="d-flex justify-content-start align-items-center gap-2">
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter Amount"
                                value={selectedByTypeOption?.value || ""}
                                onChange={e =>
                                  setSelectedByTypeOption({
                                    label: e.target.value,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`col-md-3 mt-2 d-flex justify-content-end align-items-center gap-2`}
                    >
                      <a
                        className="btn  btn-danger"
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#fed36d",
                          borderColor: "#fed36d",
                          color: "black",
                        }}
                        onClick={() => clearFilters()}
                      >
                        Clear Filters
                      </a>
                      <button className="btn  btn-purple">
                        <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                        Filter
                      </button>
                      <a className="btn  btn-red" onClick={() => handleClose()}>
                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                        Close
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div
                className={`col-md-12 mt-2 d-flex justify-content-end align-items-center gap-2 ${
                  isFilterView ? "" : "d-none"
                }`}
              >
                <a
                  className="btn  btn-danger"
                  style={{
                    fontSize: "14px",
                    backgroundColor: "#fed36d",
                    borderColor: "#fed36d",
                    color: "black",
                  }}
                  onClick={() => clearFilters()}
                >
                  Clear Filters
                </a>
                <button className="btn  btn-purple">
                  <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                  Filter
                </button>
                <a className="btn  btn-red" onClick={() => handleClose()}>
                  <i className="dripicons-cross me-1 icon-center"></i> Close
                </a>
              </div>
            </Form>
            <i className="bi bi-triangle-fill pop-icon"></i>
            {/* </div>
        </div> */}
          </ResizableBox>
        </Draggable>
      ) : null}

      {/* Add Expense Modal */}

      <Modal isOpen={addVendorPay} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Add Vendor Pay</h5>
          <button
            type="button"
            onClick={() => {
              setAddVendorPay(false)
              setRecurring(0)
              setRecurringPeriod(null)
              setRecurringEndDate("")
              setAddDate("")
              setLocationValue(null)
              setSelectedByTypeOp(null)
              setSelectedByLabel("")
              setSelectedByIsShow(true)
              setSelectedProject(null)
              setAddAmount("")
              setAddTds("")
              setAddGst("")
              setSelectedBy(null)
              setAddPaymentVia(null)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            onSubmit={handleAddVendar}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={addvendorpay} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Date <span className="text-danger">*</span>
                  </Label>
                  <input
                    required
                    type="date"
                    className="form-control"
                    value={addDate}
                    onChange={e => setAddDate(e.target.value)}
                  />
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Location <span className="text-danger">*</span>
                  </Label>
                  <Select
                    // value={lead_belong}
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={location}
                    value={locationValue}
                    onChange={selectedOption =>
                      setLocationValue(selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Selected By <span className="text-danger">*</span>
                  </Label>
                  <Select
                    // value={lead_belong}
                    placeholder="- Select -"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    // styles={colourStyles}
                    options={Categories}
                    value={selectedBy}
                    onChange={selectedOption =>
                      handleSelectedBy(selectedOption)
                    }
                  />
                </div>
                {/* //NOte :-  If category value == Intoducer / Freelancer than only show following dropdown with there respecive value.*/}
                <div className="col-md-5 ps-2">
                  {addVShow && (
                    <>
                      {selectedByIsShow ? (
                        <>
                          <Label htmlFor="formrow-firstname-Input">
                            Selecte {selectedByLabel}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Select
                            placeholder="select"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            options={selectedByType}
                            value={selectedByTypeOp}
                            onChange={selectedOption =>
                              setSelectedByTypeOp(selectedOption)
                            }
                          />
                        </>
                      ) : (
                        <>
                          <Label htmlFor="formrow-firstname-Input">
                            Select {selectedByLabel}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <div className="d-flex justify-content-start align-items-center gap-2">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter..."
                              value={selectedByTypeOp?.value || ""}
                              onChange={e =>
                                setSelectedByTypeOp({
                                  label: e.target.value,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-10 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Project <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Project"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    options={projects}
                    value={selectedProject}
                    onChange={selectedOption =>
                      setSelectedProject(selectedOption)
                    }
                  />
                </div>
              </div>

              <div className="col-md-12 d-flex  justify-content-start align-items-start gap-2 ps-2 mb-4">
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">
                    Amount <span className="text-danger">*</span>
                  </Label>

                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={addAmount}
                      onChange={e => setAddAmount(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">TDS</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter TDS"
                    value={addTds}
                    onChange={e => setAddTds(e.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">GST</Label>

                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter GST"
                      value={addGst}
                      onChange={e => setAddGst(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex  justify-content-start align-items-start mb-4">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Payment Via <span className="text-danger">*</span>
                  </Label>
                  <Select
                    // value={lead_belong}
                    placeholder="Payment Via"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    // styles={colourStyles}
                    options={paymentsOption}
                    value={addPaymentVia}
                    onChange={selectedOption =>
                      setAddPaymentVia(selectedOption)
                    }
                  />
                </div>
                <div className="col-md-3 mb-2 ps-3">
                  <Label htmlFor="formrow-firstname-Input">Recurring</Label>
                  <div className="form-switch form-switch-md ">
                    <input
                      type="checkbox"
                      className="form-check-input mt-2"
                      id="customSwitchsizemd"
                      defaultChecked={recurring == 1 ? true : false}
                      onChange={e => setRecurring(e.target.checked ? 1 : 0)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex mb-3 ps-2 justify-content-start align-items-end gap-2">
                {recurring == 1 && (
                  <>
                    {" "}
                    <div className="col-md-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Recurring Period <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select recurring"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={recurringOptions}
                        value={recurringPeriod}
                        onChange={selectedOption =>
                          setRecurringPeriod(selectedOption)
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Recurring End Date{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        required
                        type="date"
                        className="form-control"
                        placeholder="Enter Amount"
                        value={recurringEndDate}
                        onChange={e => setRecurringEndDate(e.target.value)}
                        min={
                          addDate
                            ? new Date(addDate).toISOString().split("T")[0]
                            : ""
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="my-3 text-center pe-5 col-md-12">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => {
                    setAddVendorPay(false)
                    setRecurring(0)
                    setRecurringPeriod(null)
                    setRecurringEndDate("")
                    setAddDate("")
                    setLocationValue(null)
                    setSelectedByTypeOp(null)
                    setSelectedByLabel("")
                    setSelectedByIsShow(true)
                    setSelectedProject(null)
                    setAddAmount("")
                    setAddTds("")
                    setAddGst("")
                    setSelectedBy(null)
                    setAddPaymentVia(null)
                  }}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const VendorsPay = () => {
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: state.isDisabled ? "gray" : "white",
      // other styles
    }),
    // other style properties
  }
  //meta title
  document.title = "Vendor Pay | Zithas Crm"
  const [isFilter, setIsfilter] = useState(false)

  const [data, setDatas] = useState([])
  const [allData, setAllData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [paymentMode, setPaymentMode] = useState(false)
  const [leftAmount, setLeftAmount] = useState(0)
  const [payMentHistory, setPaymentHistory] = useState(false)
  const [payMentHistoryData, setPaymentHistoryData] = useState([])
  const [editVendorPay, setEditVendorPay] = useState(false)
  const [viewVendorPay, setViewVendorPay] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const [location, setLocation] = useState([])
  const [payments, setPayments] = useState([])

  const [paymentModeOption, setPaymentModeOption] = useState([])

  const [Currency, setCurrency] = useState([])
  const [editId, setEditId] = useState("")
  const [updateSelectedByType, setUpdateSelectedByType] = useState([])
  const [updatedDate, setUpdatedDate] = useState("")
  const [updatedLocation, setUpdatedLocation] = useState(null)
  const [updateselectedBy, setUpdateSelectedBy] = useState(null)
  const [updateSelectedByTypeOp, setUpdateSelectedByTypeOp] = useState(null)
  const [updateSelectedByIsShow, setUpdateSelectedByIsShow] = useState(true)
  const [updateVShow, setUpdateVShow] = useState(false)
  const [updateSelectedProject, setUpdateSelectedProject] = useState(null)
  const [updatePaymentVia, setUpdatePaymentVia] = useState(null)
  const [updateSelectedByLabel, setUpdateSelectedByLabel] = useState("")
  const [updateAmount, setUpdateAmount] = useState("")
  const [updateTds, setUpdateTds] = useState("")
  const [updateGst, setUpdateGst] = useState("")
  const [viewVShow, setViewVShow] = useState(false)
  const [viewSelectedByType, setViewSelectedByType] = useState([])
  const [viewdDate, setViewdDate] = useState("")
  const [viewdLocation, setViewdLocation] = useState(null)
  const [viewselectedBy, setViewSelectedBy] = useState(null)
  const [viewSelectedByTypeOp, setViewSelectedByTypeOp] = useState(null)
  const [viewSelectedByIsShow, setViewSelectedByIsShow] = useState(true)
  const [viewSelectedProject, setViewSelectedProject] = useState(null)
  const [viewPaymentVia, setViewPaymentVia] = useState(null)
  const [viewSelectedByLabel, setViewSelectedByLabel] = useState("")
  const [viewAmount, setViewAmount] = useState("")
  const [viewTds, setViewTds] = useState("")
  const [viewGst, setViewGst] = useState("")

  // ...................................
  const [paymentId, setPaymentId] = useState("")
  const [paymentDate, setPaymentDate] = useState("")
  const [payPaymentMode, setPayPaymentMode] = useState(null)
  const [payPaymentAmount, setPayPaymentAmount] = useState("")
  const [payPaymentCurrency, setPayPaymentCurrency] = useState(null)
  const [payPaymentGst, setPayPaymentGst] = useState("")
  const [payPaymentTds, setPayPaymentTds] = useState("")
  const [payPaymentFile, setPayPaymentFile] = useState("")
  const [projects, setProjects] = useState([])
  const [updateRecurring, setupdateRecurring] = useState(0)
  const [updateRecurringPeriod, setUpdateRecurringPeriod] = useState(null)
  const [updateRecurringEndDate, setUpdateRecurringEndDate] = useState("")
  const [viewRecurring, setViewRecurring] = useState(0)
  const [viewRecurringPeriod, setViewRecurringPeriod] = useState(null)
  const [viewRecurringEndDate, setViewRecurringEndDate] = useState("")
  const [recurringOptions, setRecurringOptions] = useState([
    { value: 1, label: "1 Month" },
    { value: 2, label: "2 Month" },
    { value: 12, label: "1 Year" },
  ])
  const Categories = [
    { label: "Freelancer", value: "freelancer" },
    { label: "Introducer", value: "Introducer" },
    { label: "Vendor", value: "vendor" },
    { label: "Other", value: "other" },
  ]

  const paymentsOption = [
    {
      label: "Bank Account",
      value: "1",
    },
    {
      label: "Credit Card",
      value: "2",
    },
    // {
    //   label: "Others",
    //   value: "3",
    // },
  ]

  const fetchAllProjects = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/getProjects`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const createProjectOption = response.data.data?.map(items => {
          return {
            label: items.project_name,
            value: items.id,
          }
        })
        setProjects(createProjectOption)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllProjects()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/payment`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const expense = response.data.data?.payment?.filter(list => {
          const modifyAmount =
            Number(list.cost)?.toFixed(1) - Number(list.tbal)?.toFixed(1)
          return modifyAmount >= 1
        })
        setDatas(expense)
        setAllData(response?.data?.data)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setEditVendorPay(false),
        setViewVendorPay(false),
        setPaymentMode(false),
        setPaymentHistory(false)
  })

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center",
      },
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>Location</div>
        },
        accessor: "location",
        grow: false,
        className: "location-box exp-2",
        isShortIcon: true,
        Cell: cellProps => {
          return (
            <div className="text-center d-flex justify-content-center align-items-center">
              {cellProps.row.original.location == 1 ? (
                <img
                  src={India}
                  className=" mx-1 location-img text-center"
                  alt="flag"
                  height="20"
                  width="30"
                />
              ) : (
                <img
                  src={Uk}
                  className=" mx-1 location-img"
                  alt="flag"
                  height="20"
                  width="30"
                />
              )}
            </div>
          )
        },
        isInfoIcon: true,
      },

      {
        Header: "Category",
        accessor: "category",
        isInfoIcon: true,
        isShortIcon: true,
        className: "exp-5",
        Cell: cellProps => {
          const categoryValue = Categories?.find(items => {
            return items.value == cellProps.row.original.category
          })
          return <div>{categoryValue?.label}</div>
        },
      },

      {
        Header: "Project Name",
        accessor: "project_name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "project-col",
        Cell: cellProps => {
          const projectValue = projects?.find(items => {
            return items.value == cellProps.row.original.projectname
          })
          return <div>{projectValue?.label}</div>
        },
      },
      {
        Header: "Amount",
        accessor: "cost",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center cost-col",
      },
      {
        Header: "Balance Amount",
        accessor: "balance_amount",
        isInfoIcon: true,
        isShortIcon: true,
        className: `text-center bal-col`,
        Cell: cellProps => {
          const findAmount =
            cellProps.row.original.cost - cellProps.row.original.tbal
          return (
            <div>
              {findAmount <= 0 ? (
                <span className="font-size-12 badge-soft-success badge bg-secondary">
                  Fully Paid
                </span>
              ) : (
                findAmount
              )}
            </div>
          )
        },
      },
      // {
      //   Header: "Approved Amount",
      //   accessor: "approve",
      //   isInfoIcon: true,
      //   className: "text-center approved-col",
      // },
      {
        Header: "GST",
        accessor: "gst",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center gst-col",
        Cell: cellProps => {
          return <>{cellProps.row.original.gst || "-"}</>
        },
      },
      {
        Header: "TDS",
        accessor: "tds",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center tds-col",
        Cell: cellProps => {
          return <>{cellProps.row.original.tds || "-"}</>
        },
      },
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>Action</div>
        },
        accessor: "action",
        classNames: "text-center",
        Cell: cellProps => {
          const isView = viewData => {
            const locationFind = location?.find(
              obj => obj.value == viewData.location
            )
            const paymentmethod = paymentsOption?.find(
              obj => obj.value == viewData.payment_method
            )
            const recurrenceOp = recurringOptions?.find(
              obj => obj.value == cellProps.row.original.recurrence_period
            )
            const categoryFind = Categories?.find(
              obj => obj.value == cellProps.row.original.category
            )
            const projectFind = projects?.find(
              obj => obj.label == cellProps.row.original.project_name
            )

            setEditId(cellProps.row.original.id)
            setViewdDate(viewData.date)
            setViewdLocation(locationFind)

            let subData
            if (categoryFind?.value == "freelancer") {
              subData = viewData?.freelancer
            } else if (categoryFind?.value == "Introducer") {
              subData = viewData?.introducer
            } else if (categoryFind?.value == "vendor") {
              subData = ""
            } else if (categoryFind?.value == "other") {
              subData = viewData?.other
            }
            handleSelectedByView(categoryFind, subData)
            setViewPaymentVia(paymentmethod)
            setViewSelectedProject(projectFind)
            setViewAmount(viewData.cost)
            setViewTds(viewData.tds)
            setViewGst(viewData.gst ? viewData.gst : "")
            // recurring
            setViewRecurring(cellProps.row.original.recurrence)
            setViewRecurringPeriod(recurrenceOp)
            setViewRecurringEndDate(cellProps.row.original.recurrence_date)
            // ............
            setViewVendorPay(true)
          }

          const isEdit = async editData => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 41)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  const locationFind = location?.find(
                    obj => obj.value == editData.location
                  )
                  const paymentmethod = paymentsOption?.find(
                    obj => obj.value == editData.payment_method
                  )
                  const recurrenceOp = recurringOptions?.find(
                    obj => obj.value == cellProps.row.original.recurrence_period
                  )
                  const categoryFind = Categories?.find(
                    obj => obj.value == cellProps.row.original.category
                  )
                  const projectFind = projects?.find(
                    obj => obj.label == cellProps.row.original.project_name
                  )

                  setEditId(cellProps.row.original.id)
                  setUpdatedDate(editData.date)
                  setUpdatedLocation(locationFind)

                  let subData
                  if (categoryFind?.value == "freelancer") {
                    subData = editData?.freelancer
                  } else if (categoryFind?.value == "Introducer") {
                    subData = editData?.introducer
                  } else if (categoryFind?.value == "vendor") {
                    subData = ""
                  } else if (categoryFind?.value == "other") {
                    subData = editData?.other
                  }
                  handleSelectedBy(categoryFind, subData)
                  // setUpdateSelectedByLabel(categoryFind?.label)
                  // if(categoryFind?.value == "0") {
                  //   setUpdateSelectedByIsShow(false)
                  // } else {
                  //   setUpdateSelectedByIsShow(true)
                  // }
                  setUpdateSelectedProject(projectFind)
                  setUpdateAmount(editData.cost)
                  setUpdateTds(editData.tds)
                  setUpdateGst(editData.gst ? editData.gst : "")
                  setUpdatePaymentVia(paymentmethod)

                  // Calling function
                  // recurring
                  setupdateRecurring(cellProps.row.original.recurrence)
                  setUpdateRecurringPeriod(recurrenceOp)
                  setUpdateRecurringEndDate(
                    cellProps.row.original.recurrence_date
                  )
                  // ............
                  setEditVendorPay(true)
                } else {
                  setAccessDenied(true)
                }
              })
            } catch (error) {
              console.log(error)
            }
          }

          const isDelete = async () => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 41)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to delete this !",
                    icon: "warning",
                    buttons: true,
                    showCancelButton: true,
                  }).then(async willDelete => {
                    if (willDelete.isConfirmed) {
                      setLoading(true)
                      try {
                        const response = await axios({
                          method: "get",
                          url: `${configure.apiUrl}/deletePayment/${cellProps.row.original.id}`,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        }).then(response => {
                          Swal.fire({
                            icon: "success",
                            text: "Vendor deleted successfully",
                          }).then(data => {
                            const expense = response.data.data?.payment?.filter(
                              list => {
                                const modifyAmount =
                                  Number(list.cost)?.toFixed(1) -
                                  Number(list.tbal)?.toFixed(1)
                                return modifyAmount >= 1
                              }
                            )
                            setDatas(expense)
                            setAllData(response?.data?.data)
                          })
                          setLoading(false)
                        })
                      } catch (err) {
                        swal({
                          icon: "error",
                          text: "Something went wrong",
                        })
                        setLoading(false)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
              })
            } catch (error) {
              console.log(error)
            }
          }

          const handlepayment = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 41)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
                  setPaymentId(cellProps.row.original.id)
                  setPaymentMode(true)
                  setLeftAmount(
                    cellProps.row.original.cost - cellProps.row.original.tbal
                  )
                  const findOption = payments?.filter(item => {
                    return (
                      item.payment_via == cellProps.row.original.payment_method
                    )
                  })
                  setPaymentModeOption(findOption)
                } else {
                  setAccessDenied(true)
                }
              })
            } catch (error) {
              console.log(error)
            }
          }
          const findAmount =
            cellProps.row.original.cost - cellProps.row.original.tbal

          return (
            <div className="d-flex justify-content-center">
              <button
                onClick={() => isView(cellProps.row.original)}
                type="button"
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-eye"></i>
              </button>
              <button
                onClick={() => isEdit(cellProps.row.original)}
                type="button"
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fa fa-pencil-alt"></i>
              </button>

              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {findAmount > 0 ? (
                    <Link
                      onClick={() => {
                        handlepayment(cellProps.row.original.id)
                      }}
                      title="paid"
                      className="dropdown-item"
                    >
                      <i className="bi bi-currency-dollar pr-10 fill-green"></i>{" "}
                      Paid
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link
                    onClick={() => {
                      handlepaymentHistory(cellProps.row.original.id)
                      setPaymentHistory(true)
                    }}
                    title="edit lead"
                    className="dropdown-item"
                  >
                    <i className="bi bi-credit-card-2-front-fill pr-10"></i>{" "}
                    Payment History
                  </Link>
                  <a
                    onClick={() => isDelete()}
                    className="dropdown-item"
                    title="delete"
                  >
                    <i className="fas fa-trash-alt pr-10 text-danger"></i>{" "}
                    Delete Vendor Pay
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [location, isFilter, projects]
  )

  const fetchOptions = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/expenseData`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const modifyLocation = response?.data?.data?.location?.map(items => {
          return {
            label: items.location,
            value: items.id,
          }
        })
        const modifyCategories = response?.data?.data?.payment_category?.map(
          items => {
            return {
              label: items.categoryname,
              value: items.id,
            }
          }
        )

        const modifyModes = response?.data?.data?.modes?.map(items => {
          const labelD =
            items.payment_via == 1
              ? "Bank Account"
              : items.payment_via == 2
              ? "Credit Card"
              : items.payment_via == 3
              ? "Others"
              : ""

          return {
            // label: `${labelD} - ${items.payment_mode}`,
            label: items.payment_mode,
            value: items.id,
            payment_via: items.payment_via,
          }
        })

        const modifyCurrency = response?.data?.data?.currency?.map(items => {
          return {
            label: items.currency,
            value: items.id,
          }
        })

        setCurrency(modifyCurrency)
        setPayments(modifyModes)
        setLocation(modifyLocation)
        // setCategories(listCate)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOptions()
  }, [])

  const handleUpdateVendar = async e => {
    e.preventDefault()
    if (
      updatedLocation !== null &&
      updateselectedBy !== null &&
      updatePaymentVia !== null &&
      updateSelectedProject !== null
    ) {
      setLoading(true)
      const userId = localStorage.getItem("auth_id")
      const formData = new FormData()
      formData.append("user_id", userId)
      formData.append("payment_id", editId)
      formData.append("date", updatedDate)
      formData.append("location", updatedLocation?.value)
      formData.append("payment_via", updatePaymentVia?.value)
      formData.append("category", updateselectedBy?.value)
      formData.append("cate_name", updateSelectedByTypeOp?.value)
      formData.append("project_id", updateSelectedProject?.value)
      formData.append("amount", updateAmount)
      formData.append("tds", updateTds ? updateTds : "")
      formData.append("gst", updateGst ? updateGst : "")
      if (updateRecurring == 1) {
        formData.append("recurrence", updateRecurring)
        formData.append("recurrence_period", updateRecurringPeriod?.value)
        formData.append("recurrence_date", updateRecurringEndDate)
      } else {
        formData.append("recurrence", 0)
        formData.append("recurrence_period", "")
        formData.append("recurrence_date", "")
      }
      try {
        const response = await axios.post(
          `${configure.apiUrl}/editPayment`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response.data.success) {
          setLoading(false)
          const expense = response.data.data?.payment?.filter(list => {
            const modifyAmount =
              Number(list.cost)?.toFixed(1) - Number(list.tbal)?.toFixed(1)
            return modifyAmount >= 1
          })
          setDatas(expense)
          setAllData(response?.data?.data)
          Swal.fire({
            icon: "success",
            text: "Vendor pay edited successfully",
          }).then(() => {
            setUpdatedDate("")
            setUpdatedLocation(null)
            setUpdatePaymentVia(null)
            setUpdateSelectedBy(null)
            setUpdateSelectedByTypeOp(null)
            setUpdateSelectedProject(null)
            setUpdateAmount("")
            setUpdateTds("")
            setUpdateGst("")
            setEditVendorPay(false)
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${response.data.data}`,
          })
        }
      } catch (error) {
        console.log("Error", error)
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please fill all fields",
      })
    }
  }
  const handleSelectedBy = async (selectedOption, subData) => {
    setUpdateSelectedByTypeOp(null)
    setUpdateVShow(false)
    setUpdateSelectedByLabel("")
    setUpdateSelectedBy(selectedOption)
    if (selectedOption?.value == "freelancer") {
      setUpdateVShow(true)
      setUpdateSelectedByIsShow(true)
      setUpdateSelectedByLabel("Freelancer")
      setUpdateSelectedByType([])
      // try {
      //   setLoading(true)
      //   const formData = new FormData()
      //   formData.append("user_id", localStorage.getItem("auth_id"))
      //   const response = await axios({
      //     method: "post",
      //     url: `${configure.apiUrl}/introducer`,
      //     data: formData,
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }).then(response => {
      //     console.log("introducer", response.data.data)
      //     const modifyArr = response.data.data?.map(item => {
      //       return {
      //         label: item.company,
      //         value: item.id,
      //       }
      //     })
      //     setSelectedByType(modifyArr)
      //     setLoading(false)
      //   })
      // } catch (error) {
      //   console.log(err)
      //   setLoading(false)
      // }
    } else if (selectedOption?.value == "Introducer") {
      setUpdateSelectedByIsShow(true)
      setUpdateVShow(true)

      setUpdateSelectedByLabel("Introducer")
      try {
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/introducer`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const modifyArr = response.data.data?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          setUpdateSelectedByType(modifyArr)
          if (subData) {
            const findSelectedData = modifyArr?.find(list => {
              return list.value == subData
            })
            setUpdateSelectedByTypeOp(findSelectedData)
          }
          setLoading(false)
        })
      } catch (error) {
        console.log(err)
        setLoading(false)
      }
    } else if (selectedOption?.value == "vendor") {
      setUpdateSelectedByLabel("")
      setUpdateSelectedByType(null)
      setUpdateSelectedByIsShow(false)
      setUpdateVShow(false)
    } else if (selectedOption?.value == "other") {
      setUpdateSelectedByLabel("Other")
      setUpdateSelectedByType(null)
      setUpdateSelectedByIsShow(false)
      setUpdateVShow(true)
      if (subData) {
        setUpdateSelectedByTypeOp({
          label: subData,
          value: subData,
        })
      }
    }
  }
  const handleSelectedByView = async (selectedOption, subData) => {
    setUpdateSelectedByTypeOp(null)
    setViewVShow(false)
    setViewSelectedByLabel("")
    setViewSelectedBy(selectedOption)
    if (selectedOption?.value == "freelancer") {
      setViewVShow(true)
      setViewSelectedByIsShow(true)
      setViewSelectedByLabel("Freelancer")
      setViewSelectedByType([])
      // try {
      //   setLoading(true)
      //   const formData = new FormData()
      //   formData.append("user_id", localStorage.getItem("auth_id"))
      //   const response = await axios({
      //     method: "post",
      //     url: `${configure.apiUrl}/introducer`,
      //     data: formData,
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }).then(response => {
      //     console.log("introducer", response.data.data)
      //     const modifyArr = response.data.data?.map(item => {
      //       return {
      //         label: item.company,
      //         value: item.id,
      //       }
      //     })
      //     setSelectedByType(modifyArr)
      //     setLoading(false)
      //   })
      // } catch (error) {
      //   console.log(err)
      //   setLoading(false)
      // }
    } else if (selectedOption?.value == "Introducer") {
      setViewSelectedByIsShow(true)
      setViewVShow(true)

      setViewSelectedByLabel("Introducer")
      try {
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/introducer`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const modifyArr = response.data.data?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          setViewSelectedByType(modifyArr)
          if (subData) {
            const findSelectedData = modifyArr?.find(list => {
              return list.value == subData
            })
            setViewSelectedByTypeOp(findSelectedData)
          }
          setLoading(false)
        })
      } catch (error) {
        console.log(err)
        setLoading(false)
      }
    } else if (selectedOption?.value == "vendor") {
      setViewSelectedByLabel("")
      setViewSelectedByType(null)
      setViewSelectedByIsShow(false)
      setViewVShow(false)
    } else if (selectedOption?.value == "other") {
      setViewSelectedByLabel("Other")
      setViewSelectedByType(null)
      setViewSelectedByIsShow(false)
      setViewVShow(true)
      if (subData) {
        setViewSelectedByTypeOp({
          label: subData,
          value: subData,
        })
      }
    }
  }
  const handlepayment = async e => {
    e.preventDefault()
    if (payPaymentMode !== null) {
      try {
        setLoading(true)
        const userId = localStorage.getItem("auth_id")
        const formData = new FormData()
        formData.append("payment_id", paymentId)
        formData.append("date", paymentDate)
        formData.append("payment_via", payPaymentMode?.value)
        formData.append("amount", payPaymentAmount)
        formData.append("gst", payPaymentGst ? payPaymentGst : "")
        formData.append("tds", payPaymentTds ? payPaymentTds : "")
        formData.append("file", payPaymentFile)
        // invoice_id ,date, payment_mode, amount, gst, tds,  uploaded-file
        const response = await axios.post(
          `${configure.apiUrl}/paidPayment`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response) {
          setLoading(false)
          const expense = response.data.data?.payment?.filter(list => {
            const modifyAmount =
              Number(list.cost)?.toFixed(1) - Number(list.tbal)?.toFixed(1)
            return modifyAmount >= 1
          })
          setDatas(expense)
          setAllData(response?.data?.data)
          setPaymentDate("")
          setPaymentId("")
          setPayPaymentMode(null)
          setPayPaymentGst("")
          setPayPaymentAmount("")
          setPayPaymentTds("")
          setPayPaymentFile("")
          Swal.fire({
            icon: "success",
            text: "Payment added successfully.",
          }).then(() => {
            setPaymentMode(false)
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }
  const handlepaymentHistory = async pId => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${configure.apiUrl}/viewPaidPayment/${pId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (response) {
        setLoading(false)
        setPaymentHistoryData(response?.data?.data)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handlePayAmount = value => {
    const numericValue = parseFloat(value)
    if (numericValue > leftAmount) {
      Swal.fire({
        icon: "warning",
        text: `You can only pay up to ${leftAmount}.`,
      })
    } else {
      setPayPaymentAmount(numericValue)
    }
  }
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content" id="vendorspay">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Vendor Pay"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setDatas={setDatas}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                      location={location}
                      payments={payments}
                      Currency={Currency}
                      projects={projects}
                      allData={allData}
                      setAllData={setAllData}
                      recurringOptions={recurringOptions}
                      Categories={Categories}
                      paymentsOption={paymentsOption}
                      setIsfilter={setIsfilter}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Edit Vender */}

      <Modal isOpen={editVendorPay} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Edit Vendor Pay</h5>
          <button
            type="button"
            onClick={() => {
              setEditVendorPay(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            onSubmit={handleUpdateVendar}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img
                  src={editvendorpay}
                  alt="Addepe"
                  style={{ width: "90%" }}
                />
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Date <span className="text-danger">*</span>
                  </Label>
                  <input
                    required
                    type="date"
                    className="form-control"
                    value={updatedDate}
                    onChange={e => setUpdatedDate(e.target.value)}
                  />
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Location <span className="text-danger">*</span>
                  </Label>
                  <Select
                    // value={lead_belong}
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={location}
                    value={updatedLocation}
                    onChange={selectedOption =>
                      setUpdatedLocation(selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Selected By <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="- Select -"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Categories}
                    value={updateselectedBy}
                    onChange={selectedOption =>
                      handleSelectedBy(selectedOption)
                    }
                  />
                </div>
                {/* //NOte :-  If category value == Intoducer / Freelancer than only show following dropdown with there respecive value.*/}
                <div className="col-md-5 ps-2">
                  {updateVShow && (
                    <>
                      {updateSelectedByIsShow ? (
                        <>
                          <Label htmlFor="formrow-firstname-Input">
                            Select {updateSelectedByLabel}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Select
                            placeholder="select"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            options={updateSelectedByType}
                            value={updateSelectedByTypeOp}
                            onChange={selectedOption =>
                              setUpdateSelectedByTypeOp(selectedOption)
                            }
                          />
                        </>
                      ) : (
                        <>
                          <Label htmlFor="formrow-firstname-Input">
                            Select {updateSelectedByLabel}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <div className="d-flex justify-content-start align-items-center gap-2">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Enter..."
                              value={updateSelectedByTypeOp?.value || ""}
                              onChange={e =>
                                setUpdateSelectedByTypeOp({
                                  label: e.target.value,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-10 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Project <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Project"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    options={projects}
                    value={updateSelectedProject}
                    onChange={selectedOption =>
                      setUpdateSelectedProject(selectedOption)
                    }
                  />
                </div>
              </div>

              <div className="col-md-12 d-flex  justify-content-start align-items-start gap-2 ps-2 mb-4">
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">
                    Amount <span className="text-danger">*</span>
                  </Label>

                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={updateAmount}
                      onChange={e => setUpdateAmount(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">TDS</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter TDS"
                    value={updateTds}
                    onChange={e => setUpdateTds(e.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">GST</Label>

                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter GST"
                      value={updateGst}
                      onChange={e => setUpdateGst(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex  justify-content-start align-items-start mb-4">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Payment Via <span className="text-danger">*</span>
                  </Label>
                  <Select
                    // value={lead_belong}
                    placeholder="Payment Via"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    // styles={colourStyles}
                    options={paymentsOption}
                    value={updatePaymentVia}
                    onChange={selectedOption =>
                      setUpdatePaymentVia(selectedOption)
                    }
                  />
                </div>
                <div className="col-md-2 mb-2">
                  <Label htmlFor="formrow-firstname-Input">Recurring</Label>
                  <div className="form-switch form-switch-md ">
                    <input
                      type="checkbox"
                      className="form-check-input mt-2"
                      id="customSwitchsizemd"
                      defaultChecked={updateRecurring == 1 ? true : false}
                      onChange={e =>
                        setupdateRecurring(e.target.checked ? 1 : 0)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex ps-2 mb-3 justify-content-start align-items-end gap-2">
                {updateRecurring == 1 && (
                  <>
                    {" "}
                    <div className="col-md-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Recurring Period <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select recurring"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={recurringOptions}
                        value={updateRecurringPeriod}
                        onChange={selectedOption => {
                          Swal.fire({
                            icon: "warning",
                            title: "Are you sure ?",
                            text: "You want to change the recurring period.",
                            showCancelButton: true,
                          }).then(willOkay => {
                            if (willOkay.isConfirmed) {
                              setUpdateRecurringPeriod(selectedOption)
                            }
                          })
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Recurring End Date{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        placeholder="Enter Amount"
                        value={updateRecurringEndDate}
                        onChange={e =>
                          setUpdateRecurringEndDate(e.target.value)
                        }
                        min={
                          updatedDate
                            ? new Date(updatedDate).toISOString().split("T")[0]
                            : ""
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="my-3 text-center pe-5 col-md-12">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => setEditVendorPay(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={viewVendorPay} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">View Vendor Pay</h5>
          <button
            type="button"
            onClick={() => {
              setViewVendorPay(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form className="row d-flex justify-content-center align-items-center">
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img
                  src={viewvendorpay}
                  alt="Addepe"
                  style={{ width: "90%" }}
                />
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Date <span className="text-danger">*</span>
                  </Label>
                  <input
                    required
                    type="date"
                    className="form-control"
                    value={viewdDate}
                    disabled
                  />
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Location <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={viewdLocation}
                    isDisabled="true"
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Selected By <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="- Select -"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Categories}
                    value={viewselectedBy}
                    isDisabled="true"
                  />
                </div>
                {/* //NOte :-  If category value == Intoducer / Freelancer than only show following dropdown with there respecive value.*/}
                <div className="col-md-5 ps-2">
                  {viewVShow && (
                    <>
                      {viewSelectedByIsShow ? (
                        <>
                          <Label htmlFor="formrow-firstname-Input">
                            Select {viewSelectedByLabel}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            placeholder="select"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            options={updateSelectedByType}
                            value={viewSelectedByTypeOp}
                            isDisabled="true"
                          />
                        </>
                      ) : (
                        <>
                          <Label htmlFor="formrow-firstname-Input">
                            Select {updateSelectedByLabel}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="d-flex justify-content-start align-items-center gap-2">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Please Enter"
                              value={viewSelectedByTypeOp?.value || ""}
                              disabled
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-10 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Project <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Project"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    options={projects}
                    value={viewSelectedProject}
                    isDisabled="true"
                  />
                </div>
              </div>

              <div className="col-md-12 d-flex  justify-content-start align-items-start gap-2 ps-2 mb-4">
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">
                    Amount <span className="text-danger">*</span>
                  </Label>

                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="number"
                      className="form-control"
                      placeholder={`${viewAmount ? viewAmount : "-"}`}
                      value={viewAmount}
                      required
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">TDS</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter TDS"
                    value={viewTds}
                    disabled
                  />
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">GST</Label>

                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter GST"
                      value={viewGst}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex  justify-content-start align-items-start mb-4">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Payment Via <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Payment Via"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={paymentsOption}
                    value={viewPaymentVia}
                    isDisabled="true"
                  />
                </div>
                <div className="col-md-2 mb-2 ps-2">
                  <Label htmlFor="formrow-firstname-Input">Recurring</Label>
                  <div className="form-switch form-switch-md ">
                    <input
                      type="checkbox"
                      className="form-check-input mt-2"
                      id="customSwitchsizemd"
                      defaultChecked={viewRecurring == 1 ? true : false}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex ps-2 mb-3 justify-content-start align-items-end gap-2">
                {viewRecurring == 1 && (
                  <>
                    {" "}
                    <div className="col-md-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Recurring Period <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select recurring"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={recurringOptions}
                        value={viewRecurringPeriod}
                        isDisabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <Label htmlFor="formrow-firstname-Input">
                        Recurring End Date{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        placeholder="Enter Amount"
                        value={viewRecurringEndDate}
                        disabled={true}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Paymet Modal  */}
      <Modal isOpen={paymentMode} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Payment Mode</h5>
          <button
            type="button"
            onClick={() => {
              setPaymentMode(false)
              setPaymentId("")
              setPaymentDate("")
              setPayPaymentMode(null)
              setPayPaymentAmount("")
              setPayPaymentGst("")
              setPayPaymentTds("")
              setPayPaymentFile("")
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4 my-4">
          <Form
            onSubmit={handlepayment}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={paymentdone} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                <div className="col-md-5">
                  <Label htmlFor="formrow-firstname-Input">
                    Date <span className="text-danger">*</span>
                  </Label>
                  <input
                    required
                    type="date"
                    className="form-control"
                    value={paymentDate}
                    onChange={e => setPaymentDate(e.target.value)}
                  />
                </div>
                <div className="col-md-5">
                  <Label htmlFor="formrow-firstname-Input">
                    Payment Mode <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Payment Mode"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={paymentModeOption}
                    value={payPaymentMode}
                    onChange={selectedOption =>
                      setPayPaymentMode(selectedOption)
                    }
                  />
                </div>
              </div>

              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">
                    Amount <span className="text-danger">*</span>
                  </Label>
                  <Input
                    required
                    type="number"
                    className="form-control"
                    placeholder="Enter Amount"
                    value={payPaymentAmount}
                    onChange={e => {
                      handlePayAmount(e.target.value)
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">GST</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter GST"
                    value={payPaymentGst}
                    onChange={e => setPayPaymentGst(e.target.value)}
                  />
                </div>
                <div className="col-md-3">
                  <Label htmlFor="formrow-firstname-Input">TDS</Label>
                  <Input
                    type="text"
                    className="form-control"
                    // id="formrow-firstname-Input"
                    placeholder="Enter TDS"
                    // style={{width : "80%"}}
                    // disabled
                    value={payPaymentTds}
                    onChange={e => setPayPaymentTds(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-10 ">
                  <label htmlFor="">Upload File</label>
                  <input
                    type="file"
                    className="form-control border-radius"
                    onChange={e => setPayPaymentFile(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="my-3 text-center pe-5">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => {
                    setPaymentMode(false)
                    setPaymentId("")
                    setPaymentDate("")
                    setPayPaymentMode(null)
                    setPayPaymentAmount("")
                    setPayPaymentGst("")
                    setPayPaymentTds("")
                    setPayPaymentFile("")
                  }}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Payment History Popup  */}

      <Modal isOpen={payMentHistory} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Payment History</h5>
          <button
            type="button"
            onClick={() => {
              setPaymentHistory(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4 my-5">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={viewbill} alt="Addepe" style={{ width: "80%" }} />
              </div>
            </div>
            <div className="col-md-8">
              <Table bordered hover responsive size="" striped>
                <thead>
                  <tr>
                    <th className="text-center credit-bill-col-1">Sr. No</th>
                    <th className="credit-bill-col-2">Payment Mode</th>
                    <th className="credit-bill-col-3 text-center">Amount</th>
                    <th className="credit-bill-col-4 text-center">Date</th>
                    <th className="text-center credit-bill-col-5">Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  {payMentHistoryData?.map((list, index) => {
                    return (
                      <tr key={list.id}>
                        <td
                          scope="row"
                          className="text-center credit-bill-col-1"
                        >
                          {index + 1}
                        </td>
                        <td className="credit-bill-col-2">
                          {list.payment_mode}
                        </td>
                        <td className="credit-bill-col-3 text-center">
                          {list.amount}
                        </td>
                        <td className="credit-bill-col-4 text-center">
                          {list.date
                            ? moment(list.date).format("DD-MM-YYYY")
                            : ""}
                        </td>
                        <td className="text-center credit-bill-col-5">
                          {list.link ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              download
                              href={list.link}
                              className=" btn btn-download bg-purple"
                            >
                              <i className="fas fa-download label-icon"></i>
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    )
                  })}
                  {payMentHistoryData.length == 0 && (
                    <tr>
                      <td className="text-center" colSpan={10}>
                        No Data Found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Modal>
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default VendorsPay
