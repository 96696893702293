import React, { useEffect, useState } from "react"
import { Row, Col, Form, Table, Modal } from "reactstrap"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import axios from "axios"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"

const Documents = ({ id,verticalActiveTab }) => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [file, setFile] = useState("")
  const [requirementType, setRequirementType] = useState("")
  const [name, setName] = useState("")

  // Edit Document Integration
  const [editDocument, setEditDocument] = useState("")
  const [editDocumentName, setEditDocumentName] = useState("")
  const [editDocumentType, setEditDocumentType] = useState(null)
  const [editDocumentImg, setEditDocumentImg] = useState("")
  const [docId, setDocId] = useState("")

  const navigate = useNavigate()
  const docType = [
    { label: "Enrolling Documents", value: "Enrolling Documents" },
    { label: "Zithas Documents", value: "Zithas Documents" },
  ]
  const handleSubmit = async e => {
    e.preventDefault()
    const bearerToken = secureLocalStorage.getItem("auth_token")
    if (requirementType !== "") {
      setLoading(true)
      const formData = new FormData()
      formData.append("staff_id", id)
      formData.append("file", file)
      formData.append("requirement_type", requirementType?.value)
      formData.append("name", name)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/addStaffDocument`,
          data: formData,
          headers: {
            Authorization: "Bearer " + bearerToken,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          const modifyArray = response?.data?.data?.filter(item => {
            const type = ["enrollingdocuments", "zithasdocuments"]
            const itemtypes = item.requirement_type
              ?.replace(/\s+/g, "")
              .toLowerCase()
            return type.includes(itemtypes)
          })
          setLoading(false)
          setName("")
          setRequirementType([])
          setFile("")
          Swal.fire({
            icon: "success",
            text: "Staff documents details added successfully",
          }).then(() => {
            setData(modifyArray)
          })
        }
      } catch (err) {
        setLoading(false)
        console.log(err)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory fields",
      })
    }
  }
  const getDocuments = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("staff_id", id)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/showStaffDocument`,
        data: formData,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const modifyArray = response?.data?.data?.filter(item => {
          const type = ["enrollingdocuments", "zithasdocuments"]
          const itemtypes = item.requirement_type
            ?.replace(/\s+/g, "")
            .toLowerCase()
          return type.includes(itemtypes)
        })
        setData(modifyArray)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    if(verticalActiveTab == "tab5"){
      if(data?.length == 0){
        getDocuments()
      }
    }
  }, [verticalActiveTab])

  const deleteDocument = (fileId) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You want to delete document.",
      showCancelButton: true,
    }).then(async willOkay => {
      if (willOkay.isConfirmed) {
        setLoading(true)
        const formData = new FormData()
        formData.append("staff_id", id)
        formData.append("file_id", fileId)
        try {
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}/deleteStaffDocument`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          if (response) {
            setLoading(false)
            Swal.fire({
              icon: "success",
              text: "Document deleted successfully !",
            }).then(() => {
              const modifyArray = response?.data?.data?.filter(item => {
                const type = ["enrollingdocuments", "zithasdocuments"]
                const itemtypes = item.requirement_type
                  ?.replace(/\s+/g, "")
                  .toLowerCase()
                return type.includes(itemtypes)
              })
              setData(modifyArray)
            })
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      }
    })
  }
  const EditDocument = ob => {
    setDocId(ob.id)
    setEditDocumentName(ob.file_name)
    const type = {
      value: ob.requirement_type,
      label: ob.requirement_type,
    }
    setEditDocumentType(type)
    setEditDocument(true)
  }
  const handleEditDoc = async e => {
    e.preventDefault()
    if (editDocumentType !== null) {
      setLoading(true)
      const formData = new FormData()
      formData.append("staff_id", id)
      formData.append("file_id", docId)
      if (editDocumentImg) {
        formData.append("file", editDocumentImg)
      }
      formData.append("requirement_type", editDocumentType.value)
      formData.append("name", editDocumentName)

      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/editStaffDocument`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          setLoading(false)
          setEditDocumentImg("")
          setEditDocumentType(null)
          setEditDocumentName("")
          Swal.fire({
            icon: "success",
            text: "Document edited successfully !",
          }).then(() => {
            setEditDocument(false)
            const modifyArray = response?.data?.data?.filter(item => {
              const type = ["enrollingdocuments", "zithasdocuments"]
              const itemtypes = item.requirement_type
                ?.replace(/\s+/g, "")
                .toLowerCase()
              return type.includes(itemtypes)
            })
            setData(modifyArray)
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory fields",
      })
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 p-4 bg-white shadow">
            <Row>
              <div className="col-md-3">
                <h4 className="text-dark mb-3">Document Upload</h4>
              </div>
              <div className="col-md-9  text-md-end">
                <button className=" btn text-white  me-2 btn-save btn-label">
                  <i className="bi bi-cloud-arrow-up me-2 label-icon"></i>Upload
                </button>
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className=" btn text-white  btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>

            <div className="p-4 bg-white ">
              <Row>
                <div className="col-md-3 mb-4">
                  <label htmlFor="type">
                    Document Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="type"
                    required
                    placeholder="Enter Document Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="type">
                    Document Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Document Type"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    options={docType}
                    value={requirementType}
                    onChange={e => setRequirementType(e)}
                  />
                </div>
                <div className="col-md-4 mb-4 ">
                  <label htmlFor="">
                    Upload Document <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control "
                    id="document"
                    required
                    onChange={e => setFile(e.target.files[0])}
                  />
                </div>
              </Row>
              <Row className="mt-4">
                <div className="col-12">
                  <Table className="table table-striped table-bordered table-hover shadow">
                    <thead>
                      <tr>
                        <th className="col-1 text-center">
                          Sr.No
                          <i
                            title="Document Id"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-5">
                          Name{" "}
                          <i
                            title="Document Name"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-4">
                          type{" "}
                          <i
                            title="Document Type"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-2">
                          Action{" "}
                          <i
                            title="Document Actions"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((ob, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td>{ob.file_name}</td>
                            <td>{ob.requirement_type}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm me-1 btn-outline-secondary"
                                // onClick={() => deleteDocument(ob.id)}
                                onClick={() => {
                                  EditDocument(ob)
                                }}
                                title="Edit Document"
                              >
                                <i className="fas fa-pen-alt"></i>
                              </button>
                              <a
                                // type="button"
                                href={ob.link}
                                target="_blank"
                                className="btn btn-sm me-1 btn-outline-secondary"
                                // onClick={() => downloadImage(ob.link)}
                                title="Download Document"
                                rel="noreferrer"
                              >
                                <i className="bi bi-file-earmark-arrow-down"></i>
                              </a>
                              <button
                                type="button"
                                className="btn btn-sm  btn-outline-danger"
                                onClick={() => deleteDocument(ob.id)}
                                title="Delete Document"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                      {data.length == 0 && (
                        <tr>
                          <td colSpan={8} className="text-center">
                            {" "}
                            No Document Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </div>
        </form>
      </div>

      {/* Edit Documents  */}
      <Modal isOpen={editDocument} size="lg" className="" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Document</h5>
          <button
            type="button"
            onClick={() => {
              setEditDocument(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleEditDoc}>
            <div className="row">
              <div className="col-md-4 mb-4">
                <label htmlFor="type">
                  Document Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control "
                  id="type"
                  required
                  placeholder="Enter Document Name"
                  value={editDocumentName}
                  onChange={e => setEditDocumentName(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-4">
                <label htmlFor="type">
                  Document Type <span className="text-danger">*</span>
                </label>
                <Select
                  value={editDocumentType}
                  placeholder="Select Document Type"
                  className="basic-multi-select "
                  classNamePrefix="select"
                  options={docType}
                  onChange={e => setEditDocumentType(e)}
                />
              </div>
              <div className="col-md-4 mb-4 ">
                <div className="row">
                  <label htmlFor="">Upload Document</label>
                </div>
                <input
                  type="file"
                  className="form-control "
                  id="document"
                  onChange={e => setEditDocumentImg(e.target.files[0])}
                />
              </div>
              <div className="col-md-4 mb-3 ">
                <button className=" btn text-white  me-2 btn-save btn-label">
                  <i className="bi bi-cloud-arrow-up me-2 label-icon"></i>Upload
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default Documents
