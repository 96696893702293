import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import "../../assets/css/RolesResponse/Roles.css"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"

import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Form
} from "reactstrap"
import classnames from "classnames"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import TicektConfirmImg from "../../assets/images/TicketConfirm.jpg"
import confirmImg from "../../assets/images/confirmImg.jpg"
import RoleDetails from "./EditRole/RoleDetails"
import { extractChanges, getUpdatedEntries } from "utils/common-helpers"

const EditRoles = () => {
  const [openIndex, setOpenIndex] = useState(null)
  const [isChangePermission, setIsChangePermission] = useState([])
  const [all_module, setModules] = useState([])
  const [dashboardList, setDashboardList] = useState([])
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [activetab, setActivetab] = useState(1)
  const [fixedContent, setFixedContent] = useState(false)
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [imageLink, setImageLink] = useState("")
  const [permissionActiveTab, setPermissionActiveTab] = useState({
    i: 0,
    countryId: null,
  })
  console.log(permissionActiveTab)
  const [global_modal, setGlobalModal] = useState(false)
  const [view_modal, setViewModal] = useState(false)
  const [add_modal, setAddModal] = useState(false)
  const [edit_modal, setEditModal] = useState(false)
  const [delete_modal, setDeleteModal] = useState(false)
  const [permissionChange, setPermissionChange] = useState(false)
  const [locations, setLocations] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [show_module, setShowModule] = useState([])
  const [selected_list, setSelectedList] = useState([])
  const [countryChange, setCountryChange] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)
  const [roleData, setRoleData] = useState({})
  const [staffs, setStaffs] = useState([])
  const [checkedIndexes, setCheckedIndexes] = useState([])
  const handleCheckboxChange = (index) => {
    setCheckedIndexes((prevCheckedIndexes) => {
      if (prevCheckedIndexes.includes(index)) {
        return prevCheckedIndexes.filter(item => item !== index);
      } else {
        return [...prevCheckedIndexes, index];
      }
    });
  };


  const params = useParams()

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.60)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])


  const getRoleDetails = async (locaId) => {
    const formData = new FormData()
    formData.append("role_id", params.id)
    formData.append("country_id", locaId)
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/showRoles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        setStaffs(response.data.data?.staff)
        setRoleData(response.data.data?.role)
        const Permissions = response?.data?.data?.role.permissions?.map(
          item => {
            return item.permission
          }
        )
        const permissionsArray = Permissions != undefined ? Permissions[0] : []
        setIsChangePermission(permissionsArray)

        if (permissionsArray !== undefined) {
          let newArrays
          if (typeof permissionsArray === "string") {
            newArrays = JSON.parse(Permissions[0])?.map(str => {
              const [moduleId, Global, View, Add, Edit, Delete] = str.split(",")
              return {
                moduleId: Number(moduleId),
                Global: Number(Global),
                View: Number(View),
                Add: Number(Add),
                Edit: Number(Edit),
                Delete: Number(Delete),
              }
            })
          } else {
            newArrays = permissionsArray?.map(str => {
              const [moduleId, Global, View, Add, Edit, Delete] = str.split(",")
              return {
                moduleId: Number(moduleId),
                Global: Number(Global),
                View: Number(View),
                Add: Number(Add),
                Edit: Number(Edit),
                Delete: Number(Delete),
              }
            })
          }

          const ConvertArray = response.data.data.modules.map(obj => {
            const matchingObj = newArrays.find(
              item => item.moduleId === obj.module_id
            )
            const mergedObj = { ...obj, ...matchingObj }
            mergedObj.submodules = obj.submodules.map(sub => ({
              ...sub,
              ...newArrays.find(item => item.moduleId === sub.module_id),
            }))
            return mergedObj
          })
          setShowModule(ConvertArray)
          setLoading(false)
        } else {
          const ConvertArray = response.data.data.modules.map(obj => {
            const isModuleIdOne = obj.module_id === 1
            const mergedObj = {
              ...obj,
              ...(isModuleIdOne
                ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                : {}),
            }

            mergedObj.submodules = obj.submodules.map(sub => {
              return {
                ...sub,
                ...(isModuleIdOne
                  ? { Global: 1, View: 1, Add: 1, Edit: 1, Delete: 1 }
                  : {}),
              }
            })
            return mergedObj
          })
          setLoading(false)

          setShowModule(ConvertArray)
        }
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const fetchLocation = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      setLocations(response.data.data)
      setPermissionActiveTab({ i: 0, countryId: response?.data?.data[0]?.id })
      setLoading(false)
      return response?.data?.data[0]?.id
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (verticalActiveTab == "1") {
      getStarted()
    }
  }, [])

  async function getStarted() {
    const dashList = await dashdList()
    const locaId = await fetchLocation()
    await getRoleDetails(locaId)
  }

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const checkAllSubmodule = ({ e, name, count, globe }) => {
    let idName = name
    if (name.includes(" ")) {
      idName = name.replace(/ /g, "_")
    }
    const checkboxes = document.getElementsByClassName(`${idName}${count}`)
    let allUnchecked = true

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        allUnchecked = false
      }
    }
    const checkbox = document.getElementById(`${idName}${globe}`)
    checkbox.checked = !allUnchecked
  }

  const allGlobChecked = ({ e, name }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}one`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }

  const allViewChecked = ({ e, name }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}two`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }
  const allAddChecked = ({ e, name, isCheck }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}three`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }

  const allUpdateChecked = ({ e, name, isCheck }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}four`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }
  const allDeleteChecked = ({ e, name, isCheck }) => {
    if (e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      }
    } else if (!e.target.checked) {
      if (name.includes(" ")) {
        let idName = name.replace(/ /g, "_")
        const checkboxes = document.getElementsByClassName(`${idName}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      } else {
        const checkboxes = document.getElementsByClassName(`${name}five`)
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.addEventListener("scroll", () => {
    if (window.scrollY > 250) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setGlobalModal(false)
    setViewModal(false)
    setAddModal(false)
    setEditModal(false)
    setDeleteModal(false)
    removeBodyCss()
  }

  const navigate = useNavigate()
  document.title = "Roles & Permission  | Zithas Crm"
  async function dashdList() {
    try {
      const response = await axios({
        method: 'get',
        url: `${configure.apiUrl}/dashboardList`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data && response?.data?.data) {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.name,
        }));
        setDashboardList(options);
        return options;
      } else {
        console.error('Invalid response structure:', response.data);
        return [];
      }
    } catch (err) {
      console.error('Error in dashdList:', err);
      return [];
    }
  }

  const fetchModules = async () => {
    setLoading(true)
    const bearerToken = secureLocalStorage.getItem("auth_token")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/allModules`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setModules(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchModules()
  }, [])

  const onEditRoles = async (e, navigateTrue, roleIdCH, countryId) => {
    e.preventDefault();
    var x1 = document.querySelectorAll(".first_global")
    var x2 = document.querySelectorAll(".second_view")
    var x3 = document.querySelectorAll(".third_add")
    var x4 = document.querySelectorAll(".fourth_edit")
    var x5 = document.querySelectorAll(".fifth_delete")
    var role = document.querySelectorAll("#module_id")

    var module_id = []
    for (var i = 0; i < role.length; i++) {
      module_id.push(parseInt(role[i].value))
    }

    var one = 0
    for (var i = 0; i < x1.length; i++) {
      if (x1[i].checked == true) {
        one = one + "1"
      } else {
        one = one + "0"
      }
    }
    var second = 0
    for (var i = 0; i < x2.length; i++) {
      if (x2[i].checked == true) {
        second = second + "1"
      } else {
        second = second + "0"
      }
    }
    var third = 0
    for (var i = 0; i < x3.length; i++) {
      if (x3[i].checked == true) {
        third = third + "1"
      } else {
        third = third + "0"
      }
    }
    var fourth = 0
    for (var i = 0; i < x4.length; i++) {
      if (x4[i].checked == true) {
        fourth = fourth + "1"
      } else {
        fourth = fourth + "0"
      }
    }
    var five = 0
    for (var i = 0; i < x5.length; i++) {
      if (x5[i].checked == true) {
        five = five + "1"
      } else {
        five = five + "0"
      }
    }

    var gloabal = one.substring(1).split("").map(Number)
    var view = second.substring(1).split("").map(Number)
    var add = third.substring(1).split("").map(Number)
    var edit = fourth.substring(1).split("").map(Number)
    var delte = five.substring(1).split("").map(Number)

    const array = [module_id, gloabal, view, add, edit, delte]

    const newArray = []
    const subarrayLength = array[0].length

    for (let i = 0; i < subarrayLength; i++) {
      const subarray = array.map(sub => sub[i])
      newArray.push(subarray)
    }

    const StringArray = newArray.map(subarray => subarray.join(","))

    const result = selected_list.map(obj => obj.value).join(",")
    setLoading(true)
    setShowModule([])
    const permissions = getUpdatedEntries(isChangePermission != undefined ? isChangePermission : [], StringArray)
    const formData = new FormData();
    formData.append("role_id", params.id)
    formData.append("country_id", permissionActiveTab.countryId)
    formData.append("permissions", JSON.stringify(StringArray))
    formData.append("updated_permissions", permissions?.length == 0 ? 0 : JSON.stringify(permissions))
    formData.append("staff_id", checkedIndexes?.join(","))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/editPermission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setPermissionChange(false)
        Swal.fire({
          icon: "success",
          title: "Role and permissions edited successfully",
        }).then(data => {
          if (navigateTrue) {
            navigate(`${configure.appUrl}roles`)
          } else {
            setPermissionActiveTab({
              i: roleIdCH,
              countryId: countryId,
            })
            getRoleDetails(countryId)
          }

        })
        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    const imageLinks = locations.filter(item => activetab === item.id)
    setImageLink(imageLinks ? imageLinks[0]?.Link : "")
  }, [activetab, locations])

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const handlePermission = (e) => {
    e.preventDefault()
    if (staffs?.length == 0) {
      onEditRoles(e, true, 0, 1)
    } else {
      setPermissionChange(true)
    }
  }


  const [roleIdCH, setRoleIdCh] = useState('')
  const [countryId, setCountryId] = useState('')
  // Api integration
  const handleWarningPopup = ({ e, roleIdCH, countryId }) => {
    Swal.fire({
      title: "Please do save permissson before changing the country.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok save it",
      customClass: {
        confirmButton: "swal2-confirm-button btn-purple border-0 shadow-none",
        cancelButton: "swal2-cancel-button",
      },
    }).then(async willOkay => {
      if (willOkay.isConfirmed) {
        setCountryChange(true)
        setPermissionChange(true)
        setRoleIdCh(roleIdCH);
        setCountryId(countryId);
      } else {
        setLoading(false)
      }
    })
  }

  const handleActionClick = ({ e, moduleId, actionType, name, isCheck }) => {
    const globalChecked = document.getElementById(`${moduleId}global`).checked
    const viewChecked = document.getElementById(`${moduleId}view`).checked
    if (viewChecked == true || globalChecked == true) {
      if (isCheck == 3) {
        allAddChecked({
          e: e,
          name: name,
          isCheck: isCheck,
        })
      } else if (isCheck == 4) {
        allUpdateChecked({
          e: e,
          name: name,
          isCheck: isCheck,
        })
      } else if (isCheck == 5) {
        allDeleteChecked({
          e: e,
          name: name,
          isCheck: isCheck,
        })
      }
    } else {
      e.preventDefault()
      Swal.fire({
        icon: "warning",
        title: "Action Restricted",
        text: 'Please ensure that "Global" and "View" checkboxes are checked before proceeding.',
        confirmButtonText: "OK",
      }).then(() => {
        e.target.checked = false
      })
    }
  }

  const handleActionSubClick = ({
    e,
    subModuleId,
    actionType,
    name,
    count,
    globe,
  }) => {
    const globalCheckbox = document.querySelector(`.${subModuleId}global`)
    const viewCheckbox = document.querySelector(`.${subModuleId}view`)
    const globalChecked = globalCheckbox ? globalCheckbox.checked : false
    const viewChecked = viewCheckbox ? viewCheckbox.checked : false

    if (viewChecked == true || globalChecked == true) {
      checkAllSubmodule({
        e: e,
        name: name,
        count: count,
        globe: globe,
      })
    } else {
      e.preventDefault() // Prevent checkbox change
      Swal.fire({
        icon: "warning",
        title: "Action Restricted",
        text: 'Please ensure that "Global" and "View" checkboxes are checked before proceeding.',
        confirmButtonText: "OK",
      }).then(() => {
        e.target.checked = false
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content mb-4" id="editRoles">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Edit Roles" />

          <Row className="gx-4 justify-content-between">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab == 1 ? true : false,
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Personal Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab == 2 ? true : false,
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Permission
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="pe-0">
              <div className="card p-4 bg-white">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <RoleDetails dashboardList={dashboardList} roleData={roleData} toggleVertical={toggleVertical} />
                  </TabPane>
                  <TabPane tabId="2">
                    <form>
                      <div
                        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
                          }`}
                      >
                        <button className="btn btn-save fix-btn btn-label pe-0">
                          <i className="fas fa-save me-2 label-icon"></i>
                          <span>Save</span>
                        </button>
                        <a
                          onClick={() => navigate(-1)}
                          className="btn btn-red fix-btn btn-label pe-0 "
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          <span>Cancel</span>
                        </a>
                      </div>
                      <Row className="align-items-end justify-space-between">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-3">
                            {locations.map((ob, i) => {
                              return (
                                <h4 className="mb-3 text-dark" key={ob.id}>
                                  <input
                                    className="form-check-input input-location"
                                    type="radio"
                                    name="exampleRadios1"
                                    id={`exampleRadios1`}
                                    checked={
                                      permissionActiveTab.i == i ? true : false
                                    }
                                    onClick={e =>
                                      handleWarningPopup({
                                        e,
                                        roleIdCH: i,
                                        countryId: ob.id,
                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label pl-10"
                                    htmlFor={`exampleRadios`}
                                  >
                                    <img
                                      src={ob.Link}
                                      alt={ob.loaction}
                                      width="28px"
                                      height="18px"
                                      className="ps-1"
                                    />{" "}
                                  </label>
                                </h4>
                              )
                            })}
                          </div>

                          <div className="col-md-3 mb-3 text-md-end">
                            <button className="btn me-md-0 me-2  btn-save btn-label"
                              onClick={handlePermission}>
                              <i className="fas fa-save me-2 label-icon"></i>
                              SAVE
                            </button>
                            <a
                              onClick={() => navigate(-1)}
                              className="btn ms-md-2 btn-red btn-label"
                            >
                              <i className="dripicons-cross me-2 icon-center label-icon"></i>
                              CANCEL
                            </a>
                          </div>
                        </div>
                      </Row>
                      {/* {roleTable} */}
                      <div className="responsive-table mt-2 overflow-auto shadow">
                        <Table className="table table-bordered m-0 text-center mt-4 role-table">
                          <thead
                            className={`${fixedContent ? "fixed-header pt-1" : ""
                              }`}
                          >
                            <tr>
                              <th className="text-start ps-4">Module</th>
                              <th className="cell-width">
                                Global
                                <a onClick={() => setGlobalModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon "
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                View
                                <a onClick={() => setViewModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                Add
                                <a onClick={() => setAddModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                Update
                                <a onClick={() => setEditModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">
                                Delete
                                <a onClick={() => setDeleteModal(true)}>
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              </th>
                              <th className="cell-width">More</th>
                            </tr>
                          </thead>
                          <tbody>
                            {show_module.map((module, i) => {
                              const checkboxsGlobal =
                                document.getElementById("Dashboardglobal")
                              const checkboxsView =
                                document.getElementById("Dashboardview")
                              const checkboxsAdd =
                                document.getElementById("Dashboardadd")
                              const checkboxsEdit =
                                document.getElementById("Dashboardedit")
                              const checkboxsDelete =
                                document.getElementById("Dashboarddelete")
                              if (checkboxsGlobal != null) {
                                checkboxsGlobal.setAttribute("checked", "true")
                                checkboxsGlobal.setAttribute("disabled", "true")
                              }
                              if (checkboxsView != null) {
                                checkboxsView.setAttribute("checked", "true")
                                checkboxsView.setAttribute("disabled", "true")
                              }
                              if (checkboxsAdd != null) {
                                checkboxsAdd.setAttribute("checked", "true")
                                checkboxsAdd.setAttribute("disabled", "true")
                              }
                              if (checkboxsEdit != null) {
                                checkboxsEdit.setAttribute("checked", "true")
                                checkboxsEdit.setAttribute("disabled", "true")
                              }
                              if (checkboxsDelete != null) {
                                checkboxsDelete.setAttribute("checked", "true")
                                checkboxsDelete.setAttribute("disabled", "true")
                              }
                              const hasSubmodules = module.submodules.length > 0
                              const collapseId = `panelsStayOpen-collapse${i}`
                              const headerId = `panelsStayOpen-heading${i}`
                              const moduleId = module.module_name.replace(
                                " ",
                                "_"
                              )
                              return (
                                <>
                                  <tr key={i} className="">
                                    <td className="p-0" colSpan={7}>
                                      <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                      >
                                        <div className="accordion-item accordian-box">
                                          <div
                                            className="accordion-header"
                                            id={headerId}
                                          >
                                            <button
                                              className={`accordion-button fw-bold accordian-btn bd-accordian-btn ${openIndex === i
                                                ? ""
                                                : "collapsed"
                                                } ${hasSubmodules ? "" : "no-after"
                                                }`}
                                              type="button"
                                              onClick={() => handleToggle(i)}
                                            >
                                              {module.module_name}
                                            </button>

                                            <input
                                              id={`module_id`}
                                              value={module.module_id}
                                              readOnly
                                              className="display-none bd-text-center"
                                            />
                                            <div className="outer-toggle-btns">
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input first_global"
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "global"
                                                  }
                                                  defaultChecked={
                                                    module.Global == "1"
                                                  }
                                                  onClick={e => {
                                                    allGlobChecked({
                                                      e: e,
                                                      name: module.module_name,
                                                      isCheck: 1,
                                                    })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input second_view`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "view"
                                                  }
                                                  defaultChecked={
                                                    module.View == "1"
                                                  }
                                                  onClick={e => {
                                                    allViewChecked({
                                                      e: e,
                                                      name: module.module_name,
                                                      isCheck: 2,
                                                    })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input third_add ${module.module_id === 1
                                                    ? "d-none"
                                                    : ""
                                                    }`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "add"
                                                  }
                                                  defaultChecked={
                                                    module.Add == "1"
                                                  }
                                                  onClick={e => {
                                                    handleActionClick({
                                                      e: e,
                                                      moduleId: moduleId,
                                                      actionType: "add",
                                                      name: module.module_name,
                                                      isCheck: 3,
                                                    })
                                                    // allAddChecked({
                                                    //   e: e,
                                                    //   name: module.module_name,
                                                    //   isCheck: 3,
                                                    // })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input fourth_edit"
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "edit"
                                                  }
                                                  defaultChecked={
                                                    module.Edit == "1"
                                                  }
                                                  onClick={e => {
                                                    handleActionClick({
                                                      e: e,
                                                      moduleId: moduleId,
                                                      actionType: "edit",
                                                      name: module.module_name,
                                                      isCheck: 4,
                                                    })
                                                    // allUpdateChecked({
                                                    //   e: e,
                                                    //   name: module.module_name,
                                                    //   isCheck: 4,
                                                    // })
                                                  }}
                                                />
                                              </div>
                                              <div className="form-switch form-switch-md">
                                                <input
                                                  type="checkbox"
                                                  className={`form-check-input fifth_delete ${module.module_id === 1
                                                    ? "d-none"
                                                    : ""
                                                    }`}
                                                  id={
                                                    module.module_name.replace(
                                                      " ",
                                                      "_"
                                                    ) + "delete"
                                                  }
                                                  defaultChecked={
                                                    module.Delete == "1"
                                                  }
                                                  onClick={e => {
                                                    // allDeleteChecked({
                                                    //   e: e,
                                                    //   name: module.module_name,
                                                    //   isCheck: 5,
                                                    // })
                                                    handleActionClick({
                                                      e: e,
                                                      moduleId: moduleId,
                                                      actionType: "delete",
                                                      name: module.module_name,
                                                      isCheck: 5,
                                                    })
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            id={collapseId}
                                            className={`accordion-collapse collapse ${openIndex === i ? "show" : ""
                                              }`}
                                            aria-labelledby={`heading${i}`}
                                          >
                                            <div className="accordion-body p-0 ">
                                              <Table className="mb-0 bd-hover-inner bg-grey">
                                                {module.submodules.map(
                                                  (innerModule, index) => {
                                                    const subModuleId =
                                                      innerModule.module_name.replace(
                                                        " ",
                                                        "_"
                                                      )
                                                    if (module.module_name) {
                                                      var one = 0
                                                      var two = 0
                                                      var three = 0
                                                      var four = 0
                                                      var five = 0
                                                      var x1 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "one"
                                                        )
                                                      var x2 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "two"
                                                        )
                                                      var x3 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "three"
                                                        )
                                                      var x4 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "four"
                                                        )
                                                      var x5 =
                                                        document.querySelectorAll(
                                                          "#" +
                                                          module.module_name.replace(
                                                            " ",
                                                            "_"
                                                          ) +
                                                          "five"
                                                        )

                                                      for (
                                                        var i = 0;
                                                        i < x1.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x1[i].checked == true
                                                        ) {
                                                          one = one + "1"
                                                        } else {
                                                          one = one + "0"
                                                        }
                                                      }
                                                      for (
                                                        var i = 0;
                                                        i < x2.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x2[i].checked == true
                                                        ) {
                                                          two = two + "1"
                                                        } else {
                                                          two = two + "0"
                                                        }
                                                      }
                                                      // Particular Loop for Three
                                                      for (
                                                        var i = 0;
                                                        i < x3.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x3[i].checked == true
                                                        ) {
                                                          three = three + "1"
                                                        } else {
                                                          three = three + "0"
                                                        }
                                                      }
                                                      // Particular Loop for Four
                                                      for (
                                                        var i = 0;
                                                        i < x4.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x4[i].checked == true
                                                        ) {
                                                          four = four + "1"
                                                        } else {
                                                          four = four + "0"
                                                        }
                                                      }
                                                      // Particular Loop for Five
                                                      for (
                                                        var i = 0;
                                                        i < x5.length;
                                                        i++
                                                      ) {
                                                        if (
                                                          x5[i].checked == true
                                                        ) {
                                                          five = five + "1"
                                                        } else {
                                                          five = five + "0"
                                                        }
                                                      }

                                                      let percentage
                                                      let Twopercentage
                                                      let Threepercentage
                                                      let Fourpercentage
                                                      let Fivepercentage
                                                      if (one.length > 0) {
                                                        const onesLength = (
                                                          one
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const towLength = (
                                                          two
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const threeLength = (
                                                          three
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const fourLength = (
                                                          four
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        const fiveLength = (
                                                          five
                                                            .substring(1)
                                                            .match(/1/g) || []
                                                        ).length
                                                        percentage =
                                                          (onesLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Twopercentage =
                                                          (towLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Threepercentage =
                                                          (threeLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Fourpercentage =
                                                          (fourLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                        Fivepercentage =
                                                          (fiveLength /
                                                            module.submodules
                                                              .length) *
                                                          100
                                                      }

                                                      if (percentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            )}global`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        percentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            )}global`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }

                                                      if (Twopercentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "view"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Twopercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "view"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }

                                                      if (
                                                        Threepercentage >= 1
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "add"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Threepercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "add"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }
                                                      if (Fourpercentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "edit"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Fourpercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "edit"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }

                                                      if (Fivepercentage >= 1) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "delete"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = true
                                                      } else if (
                                                        Fivepercentage <= 0
                                                      ) {
                                                        const checkbox =
                                                          document.getElementById(
                                                            `${module.module_name.replace(
                                                              " ",
                                                              "_"
                                                            ) + "delete"
                                                            }`
                                                          )
                                                        checkbox.defaultChecked = false
                                                      }
                                                    }

                                                    return (
                                                      <tr key={index}>
                                                        <td className="border text-start ps-4 text-black">
                                                          <i
                                                            className="bx bx-chevrons-right"
                                                            style={{
                                                              padding:
                                                                "0px 5px 0px 0px",
                                                            }}
                                                          ></i>
                                                          <input
                                                            id="module_id"
                                                            value={
                                                              innerModule.module_id
                                                            }
                                                            className="display-none"
                                                            readOnly
                                                          />
                                                          {
                                                            innerModule.module_name
                                                          }
                                                        </td>
                                                        <td className="border cell-width ">
                                                          <div className=" form-switch form-switch-md text-center">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input first_global ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "one"
                                                                } ${subModuleId}global`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "one"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Global ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                checkAllSubmodule(
                                                                  {
                                                                    e: e,
                                                                    name: module.module_name,
                                                                    count:
                                                                      "one",
                                                                    globe:
                                                                      "global",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width ">
                                                          <div className="form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input second_view ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "two"
                                                                } ${subModuleId}view`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "two"
                                                              }
                                                              defaultChecked={
                                                                innerModule.View ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                checkAllSubmodule(
                                                                  {
                                                                    e: e,
                                                                    name: module.module_name,
                                                                    count:
                                                                      "two",
                                                                    globe:
                                                                      "view",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width">
                                                          <div className="form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input third_add ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "three"
                                                                } ${subModuleId}`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "three"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Add ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                handleActionSubClick(
                                                                  {
                                                                    e: e,
                                                                    subModuleId:
                                                                      subModuleId,
                                                                    actionType:
                                                                      "add",
                                                                    name: module.module_name,
                                                                    count:
                                                                      "three",
                                                                    globe:
                                                                      "add",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width">
                                                          <div className=" form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input fourth_edit ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "four"
                                                                }`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "four"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Edit ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                handleActionSubClick(
                                                                  {
                                                                    e: e,
                                                                    subModuleId:
                                                                      subModuleId,
                                                                    actionType:
                                                                      "edit",
                                                                    name: module.module_name,
                                                                    count:
                                                                      "four",
                                                                    globe:
                                                                      "edit",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="border cell-width">
                                                          <div className=" form-switch form-switch-md">
                                                            <input
                                                              type="checkbox"
                                                              className={`form-check-input fifth_delete ${module.module_name.replace(
                                                                " ",
                                                                "_"
                                                              ) + "five"
                                                                }`}
                                                              id={
                                                                module.module_name.replace(
                                                                  " ",
                                                                  "_"
                                                                ) + "five"
                                                              }
                                                              defaultChecked={
                                                                innerModule.Delete ==
                                                                "1"
                                                              }
                                                              onChange={e => {
                                                                handleActionSubClick(
                                                                  {
                                                                    e: e,
                                                                    subModuleId:
                                                                      subModuleId,
                                                                    actionType:
                                                                      "delete",
                                                                    name: module.module_name,
                                                                    count:
                                                                      "five",
                                                                    globe:
                                                                      "delete",
                                                                  }
                                                                )
                                                              }}
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="cell-width"></td>
                                                      </tr>
                                                    )
                                                  }
                                                )}
                                              </Table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </form>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Global Modal */}
      <Modal
        isOpen={global_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Global Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setGlobalModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* View Modal */}
      <Modal
        isOpen={view_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            View Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setViewModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Add Modal */}
      <Modal
        isOpen={add_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        className="bd-info-popup"
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Add Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setAddModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={edit_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        className="bd-info-popup"
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Update Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setEditModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={delete_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Delete Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setDeleteModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Permission Change Popup  */}
      <div
        className={`note-sticky-form ${permissionChange ? "d-block" : "d-none"}`}
      >
        <Draggable>
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "19%",
              right: "19%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header mb-3">
              <h5 className="modal-title mt-0">Permission Change</h5>
              <button
                type="button"
                onClick={() => {
                  setPermissionChange(false)
                }}
                className="btn-modal-close pe-1"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pe-4">
              <Form className="row d-flex flex-column justify-content-center align-items-center">
                <div className="col-md-12 d-flex flex-column ">
                  <div className="col-md-12 d-flex mb-4 justify-content-center align-items-center gap-4">
                    <div className="col-4">
                      <img src={confirmImg} className="img-fluid" />
                    </div>
                    <div className=" col-8 table-responsive text-start">
                      <div className="col-md-12 d-flex flex-column mb-4 justify-content-start align-items-start heading-text-roles">
                        <h3 className="text-danger text-start">
                          You are about to modify the permissions for this role.
                        </h3>
                        <p className="text-danger text-start text-bold mb-1">
                          These updates will automatically apply to the following staff members.
                        </p>
                        <p className="text-danger text-start text-bold">
                          If you want the changes to apply to specific staff, please check their names before proceeding.
                        </p>
                      </div>
                      <div className="table-responsive text-start ticket-close-box">
                        <table className="table mb-0 table ">
                          <thead className="table-white">
                            <tr>
                              <th className=" text-center" style={{ width: "18%" }}>Add</th>
                              <th className=" text-start" style={{ width: "70%" }}>Staff Name</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {staffs?.map((staff, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="text-center">
                                      <input type="checkbox"
                                        onChange={() => handleCheckboxChange(staff?.id)}
                                      // checked={checkedIndexes.includes(i)}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <p className="text-start mb-0">
                                      {staff?.name}
                                    </p>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        {staffs?.length == 0 ? (
                          <p className="mt-3 text-centers">No Staff is assigned for this role, please save & continue</p>
                        ) : null}
                      </div>

                    </div>
                  </div>
                </div>
                <div className="my-2 text-end pe-0">
                  <button className=" btn btn-save btn-label"
                    onClick={(e) => {
                      e.preventDefault()
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You want to Update this role !",
                        icon: "warning",
                        showCancelButton: true,
                      }).then(willDelete => {
                        if (willDelete?.isConfirmed) {
                          if (countryChange) {
                            onEditRoles(e, false, roleIdCH, countryId)
                          } else {
                            onEditRoles(e, true, 0, 1)
                          }
                        } else {
                          setPermissionChange(false)
                        }
                      })
                    }
                    }>
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => setPermissionChange(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>
    </React.Fragment>
  )
}

export default EditRoles
